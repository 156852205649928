import { IFsNodeSchema } from "@cloudike/web_files"

export const initFilesCacheService = () => {
  const cachedFiles: {[id: string]: IFsNodeSchema[]} = {}

  const getCachedFiles = (id: string) => {
    return cachedFiles[id]
  }

  const setCachedFiles = (id: string, files: IFsNodeSchema[]) => {
    cachedFiles[id] = files
  }

  const addCachedFiles = (id: string, files: IFsNodeSchema[]) => {
    cachedFiles[id] = [...cachedFiles[id], ...files]
  }

  const deleteFile = (parentId: string, id: string) => {
    const files = getCachedFiles(parentId)

    if (!files) {
      return
    }

    const index = files.findIndex(file => file.id === id)

    if (index !== -1) {
      cachedFiles[parentId] = [...files.slice(0, index), ...files.slice(index + 1)]
    }
  }

  const updateFile = (parentId: string, file: IFsNodeSchema) => {
    const files = getCachedFiles(parentId)

    if (!files) {
      return
    }

    const index = files.findIndex(f => f.id === file.id)

    if (index !== -1) {
      cachedFiles[parentId] = [...files.slice(0, index), file, ...files.slice(index + 1)]
    }
  }

  const deleteKey = (key) => {
    delete cachedFiles[key]
  }

  const clear = () => {
    Object.keys(cachedFiles).forEach(key => {
      delete cachedFiles[key]
    })
  }

  return {
    getCachedFiles,
    setCachedFiles,
    deleteFile,
    updateFile,
    addCachedFiles,
    deleteKey,
    clear
  }
}

