import { useEffect, useState } from 'react'

import { useLocation, useNavigate } from "react-router-dom"
import queryString from 'query-string'

function mergeFilters <T>(defaultFilters: T, filtersFromQuery: any): T {
  return Object.entries(defaultFilters).reduce((res, [key, value]) => ({
    ...res,
    [key]: filtersFromQuery[key] || value
  }), {}) as T
}

export function useFilters<T> (defaultFilters: T): [T, (data: Partial<T>) => void] {
  const location = useLocation()
  const navigate = useNavigate()

  const [filters, setFiltersState] = useState<T>(mergeFilters<T>(defaultFilters, queryString.parse(location.search)))

  const setFilters = (newFilters: Partial<T>) => {
    const newQueryParams = { ...queryString.parse(location.search), ...newFilters }

    Object.entries(newQueryParams).forEach(([key, value]) => {
      if (value === null) {
        delete newQueryParams[key]
      }
    })

    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`, { replace: true })
  }

  useEffect(() => {
    setFiltersState(mergeFilters(defaultFilters, queryString.parse(location.search)))
  }, [location])

  return [filters, setFilters]
}
