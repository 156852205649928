import { THEME_COLORS } from 'constants/configs/theme.config'

import React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

interface SpinnerProps {
  size?: 'small' | 'medium',
  className?: string,
  color?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ className = '', size = 'medium', color = THEME_COLORS['accent-normal']  }) => {
  return (
    <SpinnerBox className={classNames(className, { small: size === 'small' }) }>
      <SpinnerItem color={color} />

      <SpinnerItem color={color} />

      <SpinnerItem color={color} />

      <SpinnerItem color={color} />
    </SpinnerBox>
  )
}

const SpinnerBox = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  &.small {
    width: 24px;
    height: 24px;
  }
`

const SpinnerItem = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 10%;
  border: 4px solid ${props => props.color};
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${props => props.color} transparent transparent transparent;

  ${SpinnerBox}.small & {
    width: 20px;
    height: 20px;
    margin: 2px;
    border: 2px solid ${props => props.color};
    border-color: ${props => props.color} transparent transparent transparent;
  }

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
