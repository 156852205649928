export const generateRandomHex = (length) => {
  const maxLength = 8
  const min = Math.pow(16, Math.min(length, maxLength) - 1)
  const max = Math.pow(16, Math.min(length, maxLength)) - 1

  const n = Math.floor(Math.random() * (max - min + 1)) + min
  let result = n.toString(16)

  while (result.length < length) {
    result = result + generateRandomHex( length - maxLength )
  }

  return result
}