import React from 'react'

import styled from 'styled-components'
import _ from 'lodash'
import { SpriteIcon } from '@cloudike/web_ui_components'

const defaultIconType = 'unknown'

const FILE_TO_MIME_TABLE = {
  image: ['image/bmp', 'image/webp', 'image/png', 'image/tiff', 'image/jpeg', 'image/gif'],
  document_text: ['text/plane', 'application/msword'],
  spreadsheet_office: ['application/vnd.ms-excel'],
  presentation_office: ['application/vnd.ms-powerpoint'],
  ebook_adobe: ['application/pdf'],
  document_office: ['application/rtf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  application: ['application/x-7z-compressed'],
  video: ['video/webm', 'video/mp2t', 'video/mpeg'],
  vector: ['image/svg+xml'],
  archive: ['application/zip', 'application/gzip', 'application/vnd.rar'],
  audio: ['audio/webm', 'audio/wav', 'audio/ogg', 'audio/aac']
}

const FILE_TO_ICON_TABLE = {
  document_text: 'file',
  spreadsheet_office: 'file_excel',
  presentation_office: 'file',
  ebook_adobe: 'file_pdf',
  document_office: 'file_word',
  unknown: 'file',
  application: 'file',
  internet: 'file',
  video: 'file_video',
  image: 'file_image',
  vector: 'file_image',
  archive: 'file_archive',
  audio: 'file_audio',
  folder: 'folder'
}

function isMimeType(fileType) {
  if (fileType && _.isString(fileType)) {
    return fileType.split('/').length > 1
  }
}

function getIconIdByIconType(iconType) {
  return FILE_TO_ICON_TABLE[iconType] || 'file'
}

function getIconIdByMimeType(fileType) {
  let iconType = defaultIconType
  const table = FILE_TO_MIME_TABLE

  for (const type of Object.keys(table)) {
    if (table[type].indexOf(fileType) >= 0) {
      iconType = type
      break
    }
  }

  return getIconIdByIconType(iconType)
}

function getIconByFileType(fileType) {
  getIconIdByMimeType(fileType)

  if (isMimeType(fileType)) {
    return getIconIdByMimeType(fileType)
  } else {
    return getIconIdByIconType(fileType)
  }
}

interface FileIconProps extends React.ComponentProps<typeof SpriteIcon> {
  fileType: string
}

export const FileIcon: React.FC<FileIconProps> = ({ fileType, ...props }) => {
  return (
    <StyledSpriteIcon
      iconName={getIconByFileType(fileType)}
      {...props}
    />
  )
}

const StyledSpriteIcon = styled(SpriteIcon)`
  color: var(--text-secondary);
`