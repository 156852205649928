import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import styled from 'styled-components'
import {
  loadFlashbackAlbumsThunk,
  loadTimelineItemsFirstTimeThunk,
  subscribeTimelineToWSThunk,
  timelineActions,
  unsubscribeTimelineFromWSThunk
} from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import {
  getTimelineLoadingSmartStatusSelector,
  getTimelineLoadingStatusSelector,
  getTimelineSelectedItemsCountSelector
} from "features/photo/timeline/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"

import { WithSearch } from "../../features/photo-search/WithSearch"
import { albumsPersonActions, fetchPersonAlbumsThunk } from "../../features/albums/albums-person/albumPersonSlice"

const TimelinePage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItemsCount = getTimelineSelectedItemsCountSelector()

  const status = getTimelineLoadingStatusSelector()
  const loadingSmartStatus = getTimelineLoadingSmartStatusSelector()

  const isMobile = useMobileDetection()

  usePageTitle('a_nav_timeline')

  useEffect(() => {
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.DEFAULT))
    dispatch(albumsPersonActions.setCurrentAlbumsType(SDK_TYPES.DEFAULT))
    dispatch(subscribeTimelineToWSThunk())
    dispatch(loadTimelineItemsFirstTimeThunk())
    dispatch(loadFlashbackAlbumsThunk())
    dispatch(fetchPersonAlbumsThunk({ }))

    return () => {
      dispatch(unsubscribeTimelineFromWSThunk())
      dispatch(timelineActions.resetState())
    }
  }, [])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const pageContent = (
    <>
      {
        status === LOADING_STATUSES.LOADING || loadingSmartStatus === LOADING_STATUSES.LOADING &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && loadingSmartStatus !== LOADING_STATUSES.LOADING && (
          <Timeline
            type={SDK_TYPES.DEFAULT}
            withMemories
          />
        )
      }

      <MobileToolbarMenuToggle iconName={!selectedItemsCount ? 'plus' : 'more_horiz'} />
    </>
  )

  return (
    <WithSearch
      isSearchHidden={!!selectedItemsCount}
      defaultPageContent={pageContent}
    >
      <PageTopbar
        title={t('a_nav_timeline')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const StyledSkeleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

export default TimelinePage
