import i18n from "i18n"
import moment from "moment"

import APP_CONFIG from "../constants/configs/app.config"

const langs = APP_CONFIG.i18n.langs

langs.forEach(elem => {
  elem === 'en' ? require(`moment/locale/${elem}-gb`) : require(`moment/locale/${elem}`)
})

moment.locale(i18n.language)

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
})

export const formatDate = (date: number, format: string | { default: string, [key: string]: string }) => {
  const currentLocale = i18n.language

  if (typeof format === 'string') {
    return moment(date).format(format)
  }

  return moment(date).format(format[currentLocale] ?? format.default)
}