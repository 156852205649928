import { formatImgUrl, SIDEBAR_LOGO_URL, SIDEBAR_LOGO_URL_DARK } from 'constants/configs/theme.config'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { PrimaryButton, SpriteIcon } from '@cloudike/web_ui_components'
import { getUserDataSelector, THEME, updateUserDataThunk } from 'features/user/userSlice'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { useAppDispatch } from  "store"
import { joinToFamilyCloudThunk } from 'features/family/familySlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { DialogModal } from 'ui/DialogModal'
import { getErrorData } from 'utils/getErrorData'
import { usePageTitle } from 'features/common/hooks/usePageTitle'

import { getUiTheme } from "../../../features/user/selectors"


const FamilyInvitation = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const userData = getUserDataSelector()

  const [warningModalOpened, toggleWarningModal] = useState(false)

  const theme = getUiTheme()

  usePageTitle('l_common_invitation')

  const handleUnauthorizedClick = () => {
    redirectToExternalSSO()
  }

  const handleCloseWarningModal = () => {
    toggleWarningModal(false)
  }

  const handleManageFamilyClick = () => {
    navigate('/family/manage')
  }

  const handleJoin = () => {
    const successCallback = (familyUserId: number) => {
      navigate('/family/photos')
      dispatch(updateUserDataThunk({ family_user_id: familyUserId }))
    }

    const errorCallback = (error) => {
      if (error.code) {
        if (error.status === 409) {
          switch (error.code) {
          // if user was accepted
          case 'UserAlreadyMemberOfFamily':
            navigate('/family/photos')
            break
            // if user is 'owner' other family cloud
          case 'UserMemberOfOtherFamily':
            toggleWarningModal(true)
            break
          }
        } else {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_confirmationFailed'),
            message: error.data.description
          })
        }
      } else {
        if(error.toString() === 'NotFoundError') {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_inviteLinkIncorrect'),
          })
        } else {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            ...getErrorData(error),
          })
        }
      }
    }

    dispatch(joinToFamilyCloudThunk({ hash: searchParams.get('fchash'), successCallback, errorCallback }))
  }

  return (
    <PageBox>
      <LogoLinkBox>
        <LogoLink
          logo={theme === THEME.LIGHT ? SIDEBAR_LOGO_URL : SIDEBAR_LOGO_URL_DARK}
          href="/"
          title={t('l_common_appName')}
        />
      </LogoLinkBox>

      <Content>
        <Img
          src={theme === THEME.LIGHT ? formatImgUrl('/assets/placeholders-images/family-invite.svg') : formatImgUrl('/assets/placeholders-images-black/family-invite.svg')}
        />

        <Title>
          {t('l_family_inviteTitle')}
        </Title>

        <Description>
          {t('l_common_descriptionYouInvited')}
        </Description>

        {
          !!userData.id && !!userData.token && (
            <StyledPrimaryBtn
              actionName={t('a_common_joinFamily')}
              onAction={handleJoin}
            />
          )
        }

        {
          !userData.family_user_id && !userData.id && (
            <StyledPrimaryBtn
              actionName={t('l_common_loginJoin')}
              onAction={handleUnauthorizedClick}
            />
          )
        }

        <MobileCloseLink href={(!!userData.id && !!userData.token) ? '/drive' : '/'}>
          {t('a_common_close')}
        </MobileCloseLink>

        <CloseBtnLink href={(!!userData.id && !!userData.token) ? '/drive' : '/'}>
          <CloseIcon iconName="close" />
        </CloseBtnLink>
      </Content>

      <DialogModal
        withLongButtons={true}
        isOpened={warningModalOpened}
        title={t('l_common_alert')}
        onClose={handleCloseWarningModal}
        okText={t('l_family_manageFamilyTitle')}
        onOk={handleManageFamilyClick}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseWarningModal}
      >
        <TextInModalBox>
          {t('l_common_alertMessage')}
        </TextInModalBox>
      </DialogModal>
    </PageBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const PageBox = styled.div`
  width: 100vw;
  height: 100vh;
  background: var(--background-primary);
  display: flex;
  flex-flow: column;
  align-items: center;
`

const LogoLinkBox = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1 1 auto;
  max-height: 156px;
  min-height: 100px;
`

const LogoLink = styled.a`
  display: block;
  background: ${props => `url(${props.logo}) no-repeat`};
  background-size: contain;
  align-items: center;
  padding-left: 50px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  min-width: 100px;
  min-height: 71px;
`

const Content = styled.div`
  width: 580px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 48px 30px 48px 30px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    background: var(--background-primary);
    border: 1px solid #E7ECF1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
`

const CloseBtnLink = styled.a`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    display: block;
  }
`

const CloseIcon = styled(SpriteIcon)`
  color: var(--text-primary);
`

const Img = styled.img`
  display: block;
  width: 296px;
  height: 194px;
`

const Title = styled.h2`
  margin-top: 32px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--text-primary);
  max-width: 343px;
  width: 100%;
  text-align: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
    max-width: 260px;
  }
`

const Description = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  width: 100%;
  max-width: 343px;
  text-align: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 20px;
    max-width: 430px;
  }
`

const StyledPrimaryBtn = styled(PrimaryButton)`
  margin-top: 32px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 270px;
  }
`

const MobileCloseLink = styled.a`
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--accent-normal);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: none;
  }
`

export default FamilyInvitation
