import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs } from 'ui/Tabs'
import styled from 'styled-components'
import { getUserDataSelector } from 'features/user/userSlice'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import {
  getTrashBinFamilyPhotosCountSelector,
  getTrashBinFilesCountSelector,
  getTrashBinPhotosCountSelector
} from './selectors'
import { TRASH_BIN_TYPES } from './trashBinSlice'
import { getTrashBinFilesSortedNodesSelector } from "./files/selectors"


interface TrashBinTabsProps {
  className?: string,
  activeTab: TRASH_BIN_TYPES
}

const generateLabel = (name, count) => {
  return `${name}${!!Number(count) ? ` (${count})` : ''}`
}

export const TrashBinTabs: React.FC<TrashBinTabsProps> = ({ className = '', activeTab }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const photosCount = getTrashBinPhotosCountSelector()
  const familyPhotosCount = getTrashBinFamilyPhotosCountSelector()
  const nodes = getTrashBinFilesSortedNodesSelector()
  const filesCount = getTrashBinFilesCountSelector() || nodes.length
  const userData = getUserDataSelector()

  const tabs = [
    {
      label: generateLabel(t('a_nav_myFiles'), filesCount),
      active: activeTab === TRASH_BIN_TYPES.FILES,
      link: '/global-trash/files',
      value: TRASH_BIN_TYPES.FILES
    },
    {
      label: generateLabel(t('l_nav_photos'), photosCount),
      active: activeTab === TRASH_BIN_TYPES.PHOTOS,
      link: '/global-trash/photos',
      value: TRASH_BIN_TYPES.PHOTOS
    },
    userData?.family_user_id && {
      label: generateLabel(t('l_trash_familyPhotosTab'), familyPhotosCount),
      active: activeTab === TRASH_BIN_TYPES.FAMILY_PHOTOS,
      link: '/global-trash/family-photos',
      value: TRASH_BIN_TYPES.FAMILY_PHOTOS
    }
  ].filter(item => !!item)

  const handleChangeTab = (tab: Tab) => {
    if (Number(tab.value) === TRASH_BIN_TYPES.PHOTOS) {
      analytics.push(ANALYTICS_EVENTS.WEB_TRASHBIN_PHOTOS_CLICK)
    }

    if (Number(tab.value) === TRASH_BIN_TYPES.FAMILY_PHOTOS) {
      analytics.push(ANALYTICS_EVENTS.WEB_TRASHBIN_FC_PHOTOS_CLICK)
    }

    navigate(tab.link)
  }

  return (
    <StyledTabs
      items={tabs}
      onChangeTab={handleChangeTab}
      className={className}
    />
  )
}

const StyledTabs = styled(Tabs)`
  margin-bottom: 16px;
`
