import { useEffect } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const ConnectionsPage = () => {
  const { source } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    if (state && code) {
      navigate('/profile/account', { state: { code, state, source }, replace: true })
    } else {
      navigate('/drive', { replace: true })
    }
  }, [])

  return null
}