import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"

import { ReservePhoneModal } from './ReservePhoneModal'
import { fetchReservePhoneThunk, reservePhoneActions } from './reservePhoneSlice'
import { getReservePhoneModalPhoneSelector } from './selectors'

export const ReservePhone = ({ children }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const phone = getReservePhoneModalPhoneSelector()

  useEffect(() => {
    dispatch(fetchReservePhoneThunk())
  }, [])

  const handleOpenModal = () => {
    dispatch(reservePhoneActions.toggleModal(true))
  }

  return (
    <>
      {children({
        isPhoneExists: !!phone,
        phone: phone || t('l_settings_noBackupNumber'),
        openChangePhoneModal: handleOpenModal
      })}

      <ReservePhoneModal />
    </>
  )
}
