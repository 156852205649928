import { createSelector } from "@reduxjs/toolkit"

import { useAppSelector } from "../../../store"

import { albumsPersonSelectors } from "./albumPersonSlice"

const ITEMS_COUNT_NAME = 'live_items_count'
const filterEmptyAlbums = (albums: any[], fieldName = ITEMS_COUNT_NAME) => albums.filter(album => album[fieldName] > 0)

export const getAlbumsPersonItemsSelector =  state => albumsPersonSelectors.selectAll(state.albumsPerson)

export const getAlbumsPersonItems = () => useAppSelector(getAlbumsPersonItemsSelector)

export const getAlbumsPersonWithNoneZeroContentSelector = createSelector(getAlbumsPersonItemsSelector, (albums) => filterEmptyAlbums(albums))

export const getAlbumsPersonWithNoneZeroContent = () => useAppSelector(getAlbumsPersonWithNoneZeroContentSelector)

export const getAlbumsPersonStatus = () => useAppSelector(state => state.albumsPerson.status)
