export enum FileItemsTypes {
  DIR = 'dir',
  FILE = 'file',
}

export enum FILE_UPLOADING_STATUS {
  UPLOADED = 'uploaded',
  UPLOADING = 'uploading',
  CLOUD_ONLY = 'cloudOnly',
  LOCAL_ONLY = 'localOnly',
}
