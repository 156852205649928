import { SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useTranslation } from 'react-i18next'
import { getUserSettingsByPathSelector } from 'features/user/selectors'
import { USER_SETTINGS_PATHS } from 'features/user/constants'

import { addOtherCloudThunk } from './otherCloudsSlice'
import { DROPBOX, GOOGLE_DRIVE } from './constants'
import { getUserLinkedCloudsSelector } from './selectors'

export const ProfileAddAccountButtons = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const linkedClouds = getUserLinkedCloudsSelector()
  const userSettings = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.AVAILABLE_OTHER_CLOUDS)

  const isGoogleAvailable = userSettings?.enable_google_drive
  const isDropboxAvailable = userSettings.enable_dropbox && !linkedClouds.some(cloud => cloud.type === DROPBOX.name)

  const handleOptionClick = (source) => {
    dispatch(addOtherCloudThunk({ source }))
  }

  return (
    <AddAccountBox className={className}>
      {
        isDropboxAvailable && (
          <DropboxButton onClick={() => handleOptionClick(DROPBOX.name)}>
            <CloudIcon iconName="dropbox_filled" />

            {t('a_settings_addOtherCloudAccount', { name: DROPBOX.label })}
          </DropboxButton>
        )}

      {
        isGoogleAvailable && (
          <GoogleButton onClick={() => handleOptionClick(GOOGLE_DRIVE.name)}>
            <CloudIcon iconName="google_filled" />

            {t('a_settings_addOtherCloudAccount', { name: GOOGLE_DRIVE.label })}
          </GoogleButton>
        )}
    </AddAccountBox>
  )
}

const AddAccountBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    flex-direction: row;
  }
`

const DropboxButton = styled.button`
  height: 40px;
  min-width: 220px;
  background: #2065F5;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
  padding: 0 11px;
  width: 100%;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: unset;
    justify-content: center;
  }
`

const GoogleButton = styled.button`
  height: 40px;
  min-width: 220px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 11px;
  width: 100%;

  ${DropboxButton} ~ & {
    margin-top: 16px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: unset;
    justify-content: center;

    ${DropboxButton} ~ & {
      margin-left: 19px;
      margin-top: 0;
    }
  }
`

const CloudIcon = styled(SpriteIcon)`
  margin-right: 21px;
`
