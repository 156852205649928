import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { filesSelectors } from "./filesSlice"


export const getFilesLoadingStatusSelector = () => useAppSelector(state =>
  state.files.status
)

export const getSelectType = () => useAppSelector(state =>
  state.files.selectType
)

export const getCheckboxVisibility = () => useAppSelector(state =>
  state.files.checkboxVisibility
)

const getFilesNodesRawSelector = state =>
  filesSelectors.selectAll(state.files)

export const getFilesNodesSelector = () => useAppSelector(getFilesNodesRawSelector)

export const getFilesSortSelector = () => useAppSelector(state =>
  state.files.sort
)

export const getFilesSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getFilesNodesRawSelector,
    getFilesSelectedItemsIdsRawSelector,
    (files, selectedItemsIds) => {
      return files.filter(item => selectedItemsIds.includes(item.id))
    }
  ))

const getFilesSelectedItemsIdsRawSelector = state =>
  state.files.selectedItemsIds

export const getFilesSelectedItemsIdsSelector = () => useAppSelector(getFilesSelectedItemsIdsRawSelector)

export const getFilesRenamingItemIdSelector = () => useAppSelector(state =>
  state.files.renamingItemId
)

export const getFilesSelectedItemsCountSelector = () => useAppSelector(state =>
  state.files.selectedItemsIds.length
)

export const getCurrentFolderIdSelector = () => useAppSelector(state =>
  state.files.currentFolderId
)

export const getRemovedFolderIdSelector = () => useAppSelector(state =>
  state.files.removedFolderId
)

export const getCurrentFolderSelector = () => useAppSelector(state =>
  state.files.currentFolder
)

export const getReplaceFilesItemModalData = () => useAppSelector(state =>
  state.files.replaceFilesNodeModalData
)

export const getIsActiveSearchSelector = () => useAppSelector(state =>
  state.files.isActiveSearch
)

export const getBreadcrumbsSelector = () => useAppSelector(state =>
  state.files.breadcrumbs
)

export const getAllFilesNodesSelector = () => useAppSelector(state => filesSelectors.selectAll(state.files))
