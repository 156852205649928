import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { AlbumPlaceCard, AlbumsPlacesGrid } from "@cloudike/web_ui_components"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "@emotion/styled"

import { AlbumsPlacesPageTopbar } from "../../features/albums/albums-places/AlbumsPlacesTopbar"
import { getPhotoSearchSuggestionsSelector, getPhotoSearchType } from "../../features/photo-search/selectors"
import { analytics, ANALYTICS_EVENTS } from "../../features/common/analytics"
import { SDK_TYPES } from "../../sdk/sdkConstants"

const SearchPlacesPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const type = getPhotoSearchType()

  const suggestions = getPhotoSearchSuggestionsSelector()

  usePageTitle('l_photos_searchPlaces')

  const handleBackButtonClick = () => {
    const from = (location?.state as any)?.from

    if (from) {
      navigate(from, { replace: true })

      return
    } else {
      navigate(-1)
    }
  }

  const handleCardClick = (id) => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_SEARCH_ALBUM_PLACES_CLICK)

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/places/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    } else {
      navigate(`/photos/albums/places/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    }
  }

  useEffect(() => {
    const isHistoryAvailable = !!history.length

    if (!suggestions?.address_suggestions?.length && isHistoryAvailable) {
      navigate(-1)
    }

    if (!suggestions?.address_suggestions?.length && !isHistoryAvailable) {
      navigate(`/photos`)
    }
  }, [])

  return (
    <>
      <SAlbumsPlacesPageTopbar
        title={t('l_photos_searchPlaces')}
        onBack={handleBackButtonClick}
      />

      <AlbumsPlacesGrid
        isAlbumsPage={true}
      >
        {suggestions.address_suggestions.map(album => (
          <AlbumPlaceCard
            key={album.id}
            id={album.id}
            albumName={album.name}
            albumPhotoCount={t('l_common_countElements', { number: album.refs_count })}
            imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
            onClick={() => handleCardClick(album.id)}
          />
        ))}
      </AlbumsPlacesGrid>
    </>
  )
}

const SAlbumsPlacesPageTopbar = styled(AlbumsPlacesPageTopbar)`
  &&& {
    padding-left: 0;
  }
`

export default SearchPlacesPage
