import { PrimaryButton } from "@cloudike/web_ui_components"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import { calculateSize, formatFileName } from "../../files/filesUtils"

import { FileIcon, FilePreviewIconBox } from "./styles"

const DEFAULT_MESSAGES = ['l_notification_unsupportedFile', 'l_notification_unsupportedFileDownload']
const DEFAULT_BUTTON_TEXT = 'a_common_download'

const getFileIconByType = (type: string) => {
  switch (type) {
  case 'audio':
    return 'file_audio'
  case 'video':
    return 'file_video'
  case 'image':
    return 'file_image'
  case 'archive':
    return 'file_archive'
  case 'document_office':
    return 'file_word'
  case 'document_text':
    return 'file'
  case 'spreadsheet_office':
    return 'file_excel'
  case 'ebook_adobe':
    return 'file_pdf'
  default:
    return 'file'
  }
}

interface ContentPlaceholderProps {
  currentItem: any,
  onClickDownload: () => void,
  iconComponent?: JSX.Element,
  actionButtonText?: string,
  messages?: string[],
}
export const ContentPlaceholder = ({
  iconComponent,
  currentItem,
  onClickDownload,
  messages = DEFAULT_MESSAGES }: ContentPlaceholderProps) => {

  const { t } = useTranslation()
  const defaultButtonText = t(DEFAULT_BUTTON_TEXT)
  const fileSize = calculateSize(currentItem.data_size || currentItem.file_info?.size, t)
  const buttonText = defaultButtonText + ' ' + '(' + fileSize + ')'
  const fileName = currentItem.name
  const fileType = currentItem.type || currentItem?.file_info?.type
  const fileIconType = getFileIconByType(fileType)

  const messagesText = messages.map((message, index) => {
    return (
      <p key={index}>
        {t(message)}
      </p>
    )
  })

  return (
    <PlaceholderBox>
      <FilePreviewIconBox>
        {iconComponent || <FileIcon iconName={fileIconType} />}
      </FilePreviewIconBox>

      {!!fileName && (
        <PlaceholderTitleBox>
          {formatFileName(currentItem?.name, [{ width: 0, length: 9 },{ width: 425, length: 12 },{ width: 610, length: 35 }])}
        </PlaceholderTitleBox>
      )}

      <PlaceholderMessageBox>
        {messagesText}
      </PlaceholderMessageBox>

      <PrimaryButton
        onAction={onClickDownload}
        actionName={buttonText}
      />
    </PlaceholderBox>
  )
}

const PlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;  
  align-items: center;
  color: var(--white);
`

const PlaceholderTitleBox = styled.h2`
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 16px;
`

const PlaceholderMessageBox = styled.div`
  
    margin-bottom: 16px;
    
    p {
        color: var(--white);
        opacity: 0.5;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }
`
