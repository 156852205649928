import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useMemo } from 'react'

import styled from 'styled-components'
import { UserMenu as UserMenuFromUiKit, ThemeMenuItem } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { changeElectronThemeThunk, logoutThunk, userActions } from 'features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import APP_CONFIG from "../../constants/configs/app.config"
import { appActions } from "../../store/app"
import { getFormattedUsername } from "../../utils/getFormattedUsername"

import { getUserDataSelector, getTheme } from './selectors'

export const UserMenu = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userData = getUserDataSelector()
  const phoneMask = APP_CONFIG.user_phone_mask
  const activeThemeInUserMenu = getTheme()

  const handleClickTheme =  (theme: ThemeMenuItem) => {
    theme === ThemeMenuItem.SYSTEM
      ? analytics.push(ANALYTICS_EVENTS.WEB_THEME_AUTOMATIC_SWITCH)
      : analytics.push(ANALYTICS_EVENTS.WEB_THEME_COLOR_SWITCH)
    dispatch(userActions.setTheme(theme))
    dispatch(changeElectronThemeThunk(theme))
  }

  const menuItems = useMemo(() => [
    {
      label: t('l_common_profileAndSettingsMenu'),
      iconName: 'profile',
      onClickItem: () => {
        navigate('/profile/account')
        analytics.push(ANALYTICS_EVENTS.WEB_PROFILE_SETTINGS_CLICK)
      }
    },
    !APP_CONFIG.is_feedback_in_left_menu && {
      label: t('l_more_aboutFeedbackHint'),
      iconName: 'feedback',
      onClickItem: () => {
        dispatch(appActions.setFeedbackModalVisibility(true))
        analytics.push(ANALYTICS_EVENTS.WEB_FEEDBACK_CLICK)
      }
    },
    {
      label: t('l_settings_colorTheme'),
      themeMenuItems:[
        {
          theme: ThemeMenuItem.LIGHT,
          isActive: activeThemeInUserMenu === ThemeMenuItem.LIGHT,
          label:t('l_settings_colorTheme_light'),
          onClickItem: handleClickTheme
        },
        {
          theme: ThemeMenuItem.DARK,
          isActive: activeThemeInUserMenu === ThemeMenuItem.DARK,
          label:t('l_settings_colorTheme_dark'),
          onClickItem: handleClickTheme
        },
        {
          theme: ThemeMenuItem.SYSTEM,
          isActive: activeThemeInUserMenu === ThemeMenuItem.SYSTEM,
          label:t('l_settings_colorThemeSystem'),
          onClickItem: handleClickTheme
        }
      ],
      iconName: 'theme_icon',
      isTheme: true,
    },
    {
      label: t('l_settings_logout'),
      iconName: 'logout',
      onClickItem: () => {
        analytics.push(ANALYTICS_EVENTS.WEB_LOGOUT_CLICK)
        dispatch(logoutThunk())
      }
    }
  ].filter(item => item), [t,activeThemeInUserMenu])

  return (
    <StyledUserMenu
      menuItems={menuItems}
      userName={getFormattedUsername(userData, phoneMask)}
      className={className}
    />
  )
}

const StyledUserMenu = styled(UserMenuFromUiKit)`
  padding-right: 15px;
  margin: 32px 0 41px;

  .dropDown__usermenu {
    left: 6px;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.DESKTOP}) {
    padding-right: 15px;
    padding-left: 0;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin: 0 0 16px;
  }
`
