export const USER_DATA_KEY_IN_STORAGE = 'CLOUDIKECLIENT'
export const CLIENT_KEY_IN_STORAGE = 'CLIENT'
export const USER_DATA_ADDITIONAL_KEY_IN_STORAGE = 'CLOUDIKECLIENT'
export const USER_DATA_KEY_HEADER_MOUNTBIT_AUTH = 'Mountbit-Auth'
export const USER_DATA_KEY_HEADER_MOUNTBIT_USER_AGENT  = 'Mountbit-User-Agent'
export const USER_DATA_KEY_HEADER_CONTENT_TYPE  = 'Content-Type'
export const USER_DATA_KEY_TIMEZONE_SENT = 'TIMEZONE_SENT'

export enum USER_SETTINGS_PATHS {
  AVAILABLE_OTHER_CLOUDS = 'user_cloud_import',
  USER_DOCUMENT_WALLET = 'user_document_wallet',
  PHOTOS_DUPLICATES = 'photos.duplicates',
  CLIENTS_ACCOUNT = 'clients.account',
}
