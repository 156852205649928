import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { trashBinSelectors } from "./trashBinSlice"

export const getTrashBinLoadingStatusSelector = () => useAppSelector(state =>
  state.trashBin.status
)

export const getTrashBinSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getTrashBinItemsRawSelector,
    getTrashBinSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) => items.filter(item => selectedItemsIds.includes(item.id))
  )
)

export const getTrashBinSelectedItemsIdsRawSelector = state => state.trashBin.selectedItemsIds

export const getTrashBinSelectedItemsIdsSelector = () => useAppSelector(getTrashBinSelectedItemsIdsRawSelector)

export const getTrashBinSelectedItemsCountSelector = () => useAppSelector(state =>
  state.trashBin.selectedItemsIds.length
)

export const getTrashBinItemsRawSelector = state => trashBinSelectors.selectAll(state.trashBin)

export const getTrashBinItemsSelector = () => useAppSelector(getTrashBinItemsRawSelector)

export const getTrashBinTotalItemsCountSelector = () => useAppSelector(state => state.trashBin.totalItemsCount)

export const getTrashBinPhotosCountSelector = () => useAppSelector(state => state.trashBin.photosCount)

export const getTrashBinFamilyPhotosCountSelector = () => useAppSelector(state => state.trashBin.familyPhotosCount)

export const getTrashBinFilesCountSelector = () => useAppSelector(state => state.trashBin.filesCount)
