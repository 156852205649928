import { useAppSelector } from "store"

import { photoPreviewFlashbackSelectors } from "./photoPreviewFlashbackSlice"

export const getPhotoPreviewFlashbackItemsSelector = () => useAppSelector(state => photoPreviewFlashbackSelectors.selectAll(state.photoPreviewFlashback))

export const getCurrentPhotoPreviewFlashbackItemIndexSelector = () => useAppSelector(state => state.photoPreviewFlashback.currentItemIndex)

export const getCurrentPhotoPreviewFlashbackModalStatusSelector = () => useAppSelector(state => state.photoPreviewFlashback.isModalOpened)

export const getCurrentPhotoPreviewFlashbackSdkTypeSelector = () => useAppSelector(state => state.photoPreviewFlashback.sdkType)

export const getCurrentPhotoPreviewFlashbackTypeSelector = () => useAppSelector(state => state.photoPreviewFlashback.type)

export const getPhotoPreviewFlashbackLoadingSelector = () => useAppSelector(state => state.photoPreviewFlashback.loading)

export const getPhotoPreviewFlashbackLoadingMoreSelector = () => useAppSelector(state => state.photoPreviewFlashback.loadingMore)

export const getPhotoPreviewFlashbackErrorSelector = () => useAppSelector(state => state.photoPreviewFlashback.hasError)

export const getPhotoPreviewFlashbackTotalItemsCountSelector = () => useAppSelector(state => state.photoPreviewFlashback.totalItemsCount)

export const getPhotoPreviewFlashbackAlbumNameSelector = () => useAppSelector(state => state.photoPreviewFlashback.albumName)

export const getPhotoPreviewFlashbackIndexCurrentAlbumSelector = () => useAppSelector(state => state.photoPreviewFlashback.indexCurrentAlbum)

export const getPhotoPreviewFlashbackIsViewedSelector = () => useAppSelector(state => state.photoPreviewFlashback.isViewed)

