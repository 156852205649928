import React,  { useCallback } from "react"

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from  "store"

import { IExtendedAlbumSchema } from '../albums-list/albumsSlice'
import { analytics,ANALYTICS_EVENTS } from "../../common/analytics"

import { getAlbumsSeasonsItemsSelector } from './selectors'
import { AlbumSeasonCard } from './AlbumSeasonCard'
import { AlbumsSeasonGrid } from "./AlbumsSeasonsGrid"
import { albumsSeasonsActions } from "./albumsSeasonsSlice"

export const AlbumsSeasons = ({ className = '' , isAlbums , countAlbums }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const albumsSeasons = getAlbumsSeasonsItemsSelector()

  const handleCardClick = (album: IExtendedAlbumSchema) => {
    const link =  isAlbums ? `/photos/albums/seasons/${album.id}?backTo=albums` : `/photos/albums/seasons/${album.id}`
    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_SEASON_CLICK)

    navigate(link)
  }

  const handleItemSelection = useCallback((id, isSelected) => {
    dispatch(albumsSeasonsActions.updateItem({ id, selected: isSelected }))
  }, [])

  return (
    <>
      <AlbumsBox className={className}>
        <AlbumsSeasonGrid>
          {
            albumsSeasons.slice(0, countAlbums).map(album => (
              <AlbumSeasonCard
                key={album.id}
                id={album.id}
                albumName={album.description}
                albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
                description={album.description}
                onSelect={handleItemSelection}
                isSelected={album.selected}
                imgUrls={album?._embedded?.cover_items}
                onClick={() => handleCardClick(album)}
              />
            ))
          }
        </AlbumsSeasonGrid>
      </AlbumsBox>
    </>
  )
}

const AlbumsBox = styled.div`
  width: 100%;
  padding-bottom: 40px;
`
