import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect, useRef } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import styled from 'styled-components'
import {
  insertElementsToTimelineThunk,
  loadTimelineItemsFirstTimeThunk,
  subscribeTimelineToWSThunk,
  timelineActions,
  unsubscribeTimelineFromWSThunk
} from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import {
  getTimelineLoadingStatusSelector,
  getTimelineSelectedItemsCountSelector
} from "features/photo/timeline/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { CreateFamilyPlaceholder } from "features/family/CreateFamilyPlaceholder"
import { getUserDataSelector } from "features/user/selectors"
import { useMobileDetection } from "features/common/hooks"
import {
  familyActions,
  fetchFamilyThunk,
  subscribeFamilyToWSThunk,
  unsubscribeFamilyFromWSThunk
} from "features/family/familySlice"
import { getFamilyDataSelector, getFamilyMembersSelector } from "features/family/selectors"
import { hideNotification, INotification, NOTIFICATION_TYPES, showNotification } from "features/common/notifications"
import { useNavigate } from "react-router-dom"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { getUploadingFilesSelector } from "features/common/files-uploading/selectors"
import { UPLOADER_LIST_TYPES } from "features/common/files-uploading/filesUploadingSlice"

import { WithSearch } from "../../../features/photo-search/WithSearch"
import { albumsPersonActions, fetchPersonAlbumsThunk } from "../../../features/albums/albums-person/albumPersonSlice"

const CreateFamilyCloudPage = () => {
  const { t } = useTranslation()

  const texts = {
    title: t('l_family_noFamilyCloud'),
    description: t('l_family_emptyPlaceholderDescription'),
    createBtn: t('l_family_timelineTitle'),
  }

  return (
    <>
      <PageTopbar
        title={t('l_family_timelineTitle')}
      />

      <CreateFamilyPlaceholder texts={texts} />
    </>
  )
}

const TimelinePage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const familyData = getFamilyDataSelector()
  const familyMembers = getFamilyMembersSelector()
  const status = getTimelineLoadingStatusSelector()
  const uploadingFiles = getUploadingFilesSelector()

  const isMobile = useMobileDetection()

  const notificationRef = useRef<INotification>()

  usePageTitle('a_nav_timeline')

  useEffect(() => {
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.FAMILY))
    dispatch(albumsPersonActions.setCurrentAlbumsType(SDK_TYPES.FAMILY))
    dispatch(subscribeTimelineToWSThunk())
    dispatch(loadTimelineItemsFirstTimeThunk())
    dispatch(subscribeFamilyToWSThunk({ navigate }))
    dispatch(fetchFamilyThunk())
    dispatch(fetchPersonAlbumsThunk({ }))

    return () => {
      dispatch(unsubscribeTimelineFromWSThunk())
      dispatch(unsubscribeFamilyFromWSThunk())
      hideNotification(notificationRef.current)
      dispatch(timelineActions.resetState())
    }
  }, [])

  useEffect(() => {
    if (!familyData?.invite_hash || familyMembers.length > 1 || notificationRef.current) {
      return () => {
        familyMembers.length > 1 && hideNotification(notificationRef.current)
      }
    }

    const callback = () => {
      dispatch(familyActions.toggleFamilyinviteModal(true))
    }

    notificationRef.current = showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_common_inviteText'),
      message: t('l_notification_familyCloudCreated'),
      isPermanent: true,
      callback
    })

  }, [familyData?.invite_hash, familyMembers.length])

  useEffect(() => {
    const uploadedFamilyCloudFiles = uploadingFiles.filter(item =>
      item.state.isCompleted &&
      [UPLOADER_LIST_TYPES.FAMILY_TIMELINE, UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR].includes(item.uploadingSourceType)
      && item.uploadedItem)
      .map(item => item.uploadedItem)

    if (uploadedFamilyCloudFiles.length) {
      dispatch(insertElementsToTimelineThunk({ items: uploadedFamilyCloudFiles }))
    }
  }, [uploadingFiles])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const pageContent = (
    <>
      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <Timeline type={SDK_TYPES.FAMILY} />
        )
      }

      <MobileToolbarMenuToggle />
    </>
  )

  return (
    <WithSearch
      isSearchHidden={!!selectedItemsCount}
      defaultPageContent={pageContent}
      type={SDK_TYPES.FAMILY}
    >
      <PageTopbar
        title={t('a_nav_timeline')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const StyledSkeleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

export default () => {
  const userData = getUserDataSelector()

  if (!!userData?.family_user_id) {
    return <TimelinePage />
  }

  return (
    <CreateFamilyCloudPage />
  )
}
