import { ChangeEvent, useCallback, useState } from "react"

import { ModalFeedbackForm } from "@cloudike/web_ui_components"
import * as yup from 'yup'
import { useTranslation } from "react-i18next"
import JSZip from 'jszip'
import _ from "lodash"

import { NOTIFICATION_TYPES, showNotification } from "../notifications"
import {  getUiTheme } from "../../user/selectors"
import { feedbackApi } from "../../../api/feedbackApi"
import { LoadingOverlay } from "../../../ui/LoadingOverlay"

export interface IFeedbackModalProps {
  isShown: boolean
  onCloseModal: () => void
  onSubmitForm: () => void
}

const FeedbackModal = ({ isShown, onCloseModal, onSubmitForm }: IFeedbackModalProps) => {
  const { t } = useTranslation()
  const zip = new JSZip()
  const formDataFiles = new FormData()
  const parentBlock = document.getElementsByTagName('body')[0]
  const ERROR_MSG_REQUIRED = t('l_common_fillField')
  const ERROR_MSG_INVALID_EMAIL = t('l_common_enteredWrongEmail')
  const theme = getUiTheme()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showNotifAttachmentTooBig, setShowNotifAttachmentTooBig] = useState<boolean>(false)
  const maxSizeLog = 50_000_000

  const [files, setFiles] = useState<File[]>([])

  const handleCancelFile = (elem: File) => {
    const newFiles = [...files]
    files.forEach((item, index) => {
      if (item.name === elem.name) {
        newFiles.splice(index, 1)
      }
    })
    setFiles(newFiles)
  }

  const handleClickAttachFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = [...Array.from(e.target.files || []), ... files]
    const selectedUniqFiles = _.uniqBy(selectedFiles, (item) => {
      return item.name
    })

    const fullSize = selectedUniqFiles.reduce((acc, item) => {
      return acc + item.size
    }, 0)

    if (fullSize > maxSizeLog) {
      setShowNotifAttachmentTooBig(true)
      return
    }

    setFiles(selectedUniqFiles)
  }

  const addFilesInZip = (files: any) => {
    files.forEach(file => zip.file(file.name, file))
  }

  const handleSubmitForm = async (values: any) => {
    const { email, message } = values
    setLoading(true)

    try {
      const response = await feedbackApi.sendFeedback({ name: '', email, phone: '', comment: message })

      if (files.length !== 0) {
        addFilesInZip(files)
        const zipFile = await zip.generateAsync({ type: 'blob' })
        await formDataFiles.append('log', zipFile)
        await feedbackApi.sendFilesFeedback(`${response.url_upload_logs}.zip`, formDataFiles )
      }

      onSubmitForm()
      setFiles([])
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_feedbackSent'),
      })
    } catch (e) {
      console.log(e)
      // if (e.status === 413) {
      //   return setShowNotifAttachmentTooBig(true)
      // }
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_feedbackError'),
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    onCloseModal()
    setFiles([])
  }

  const handleFinishTimeoutNotification = useCallback((isActive: boolean) => {
    setShowNotifAttachmentTooBig(isActive)
  }, [showNotifAttachmentTooBig])

  const validationSchema = yup.object().shape({
    email: yup.string().email(ERROR_MSG_INVALID_EMAIL).required(ERROR_MSG_REQUIRED),
    message: yup.string().required(ERROR_MSG_REQUIRED)
  })

  const feedbackFormTexts = {
    TITLE: t('l_more_aboutFeedbackHint'),
    SUB_TITLE: t('l_common_useFeedbackForm'),
    NAME_BUTTON_CANCEL: t('a_common_cancel'),
    NAME_BUTTON_ACTION: t('a_common_send'),
    TITLE_ICON: 'feedback',
    ATTACH_FILE: t('a_common_attach'),
  }

  const feedbackFormFields = [
    {
      name: 'email',
      label: t('l_common_emailField'),
      placeholder: t('l_common_enterEmailplaceholder'),
      type: 'email'
    },
    {
      name: 'message',
      label: t('l_common_messageField'),
      placeholder: t('l_settings_feedbackMessageBody'),
      type: 'textarea',
      defaultText: ''
    }
  ]

  return (
    <>
      <LoadingOverlay
        text={t('l_common_loading')}
        visible={isLoading}
      />

      <ModalFeedbackForm
        showNotif={showNotifAttachmentTooBig}
        notifText={t('l_notification_attachmentTooBig')}
        theme={theme}
        fields={feedbackFormFields}
        validationSchema={validationSchema}
        parentBlock={parentBlock}
        onCloseModal={handleCloseModal}
        onSubmitForm={handleSubmitForm}
        isShown={isShown}
        texts={feedbackFormTexts}
        onCancelFile={handleCancelFile}
        onClickAttachFile={handleClickAttachFile}
        files={files}
        onFinishTimeoutNotification={handleFinishTimeoutNotification}
      />
    </>
  )
}

export default FeedbackModal
