import styled from "@emotion/styled"
import { SpriteIcon } from "@cloudike/web_ui_components"

interface CircleButtonBackProps {
  className?: string
  onClick: () => void
}


export const CircleButtonBack: React.FC<CircleButtonBackProps> = ({
  className = '',
  onClick
}) => {
  return (
    <CircleButtonBackBox
      type="button"
      className={className}
    >
      <ButtonBackIconBox
        iconName={'arrow_back'}
        onClick={onClick}
      />
    </CircleButtonBackBox>
  )
}

const CircleButtonBackBox = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: var(--background-secondary);
    min-width: 40px;
    height: 40px;
    margin-right: 16px;
    cursor: pointer;
    `
const ButtonBackIconBox = styled(SpriteIcon)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: var(--icon-secondary);
`
