import { useEffect, useRef, useState } from 'react'

import { SpriteIcon, STYLED_VARIABLES, useOnClickOutside } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import {
  albumActions,
  copyAlbumItemsToFamilyCloudThunk,
  copyAlbumItemsToPersonalCloudThunk,
  createAndShareAlbumThunk,
  createNewAlbumWithItemsThunk,
  downloadAlbumItemsThunk,
  removeAlbumItemsThunk
} from 'features/albums/album/albumSlice'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getUserDataSelector, getUserSettingsByPathSelector } from 'features/user/selectors'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { DialogModal } from 'ui/DialogModal'
import { toMb } from 'utils/utils'
import moment from 'moment'
import classNames from 'classnames'
import {
  addItemsToPersonalCloudThunk,
  downloadSharedAlbumItemThunk,
  removeSharedAlbumSelectedItemsThunk
} from 'features/public-link/publicLinkSlice'
import { getPublicLinkPermissionSelector } from 'features/public-link/selectors'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { deleteDocumentItems, downloadSelectedDocumentItems } from 'features/documents/documentsSlice'
import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'

import {
  copyTimelineItemsToFamilyThunk,
  copyTimelineItemsToPersonalCloudThunk,
  downloadTimelineItemsThunk,
  removeSelectedItemsThunk
} from '../timeline/timelineSlice'
import { getIsUserAuthorizedSelector } from "../../user/userSlice"
import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { USER_SETTINGS_PATHS } from "../../user/constants"
import {
  getPhotosDuplicateThunk,
  photoPreviewDuplicateActions
} from "../photo-preview-duplicate/photoPreviewDuplicateSlice"
import {
  getPhotoPreviewDuplicateItemsSelector,
  getPhotoPreviewDuplicateModeSelector
} from "../photo-preview-duplicate/selectors"
import { InfoModalPreview } from "../../common/info-modal/InfoModalPreview"
import { useMobileDetection } from "../../common/hooks"

import { photoPreviewActions, PREVIEW_TYPES } from './photoPreviewSlice'
import { DropMenuPreview } from "./DropMenuPreview"
import {
  getCurrentPhotoPreviewItemIndexSelector,
  getCurrentPhotoPreviewSdkTypeSelector,
  getCurrentPhotoPreviewTypeSelector,
  getIsLivePhotoCanPlaySelector,
  getIsLivePhotoPlaying,
  getPhotoPreviewItemsSelector
} from './selectors'

export const PhotoPreviewToolbar = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useMobileDetection()

  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled

  const items = getPhotoPreviewItemsSelector()
  const currentIndex = getCurrentPhotoPreviewItemIndexSelector()
  const sdkType = getCurrentPhotoPreviewSdkTypeSelector()
  const previewType = getCurrentPhotoPreviewTypeSelector()
  const userData = getUserDataSelector()
  const isAuthorized = getIsUserAuthorizedSelector()
  const isEnabledPreviewDuplicateMode = getPhotoPreviewDuplicateModeSelector()

  const sharedPermission = getPublicLinkPermissionSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [isShowDropMenu, toggleShowDropMenu] = useState(false)
  const [isOpenedInfoModal, setOpenedInfoModal] = useState(false)
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    if (isShowDropMenu) toggleShowDropMenu(!isShowDropMenu)
  })

  const [currentItem, setCurrentItem] = useState(items[currentIndex] as IItemSchema)

  useEffect(() => {
    if (isEnabledPreviewDuplicateMode && !!duplicateItems.length) return

    setCurrentItem(items[currentIndex] as IItemSchema)
  }, [currentIndex, items])

  const duplicateItems = getPhotoPreviewDuplicateItemsSelector()
  const selectDuplicateItem = duplicateItems.find(item => item.isSelect)

  useEffect(() => {
    if (!!selectDuplicateItem) setCurrentItem(selectDuplicateItem as IItemSchema)
  }, [selectDuplicateItem])

  const isLivePhotoCanPlay = getIsLivePhotoCanPlaySelector()
  const isLivePhotoPlaying = getIsLivePhotoPlaying()

  const handleClose = () => {
    dispatch(photoPreviewActions.resetState())
    dispatch(photoPreviewDuplicateActions.resetState())

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_CLOSE)
  }

  const handleTogglePlay = () => {
    dispatch(photoPreviewActions.toggleLivePhotoPlaying())
  }

  const handleShare = () => {
    dispatch(createAndShareAlbumThunk({
      items: [currentItem],
      type: sdkType === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_PUBLIC_LINK)
  }

  const handleCreateAlbum = () => {
    const callback = (id: string) => {
      dispatch(photoPreviewActions.resetState())

      if (sdkType === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (sdkType === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }
    }

    if (isEnabledPreviewDuplicateMode) {
      analytics.push(ANALYTICS_EVENTS.WEB_SIMILAR_PHOTOS_ALBUM_ADD_CLICK)
    }

    dispatch(createNewAlbumWithItemsThunk({
      items: [currentItem],
      callback,
      type: sdkType === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_ADD_TO_ALBUM)
  }

  const handleCopyToFamily = () => {
    if (previewType === PREVIEW_TYPES.ALBUM || previewType === PREVIEW_TYPES.PLACE_ALBUM) {
      dispatch(copyAlbumItemsToFamilyCloudThunk([currentItem]))
    }

    if (previewType === PREVIEW_TYPES.TIMELINE || previewType === PREVIEW_TYPES.FAVORITES) {
      dispatch(copyTimelineItemsToFamilyThunk([currentItem]))
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_COPY_TO_FC_CLICK)
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_ADD_TO_FAMILYCLOUD)
  }

  const handleCopyToPersonal = () => {
    if (previewType === PREVIEW_TYPES.ALBUM || previewType === PREVIEW_TYPES.PLACE_ALBUM) {
      dispatch(copyAlbumItemsToPersonalCloudThunk([currentItem]))
    }

    if (previewType === PREVIEW_TYPES.TIMELINE) {
      dispatch(copyTimelineItemsToPersonalCloudThunk([currentItem]))
    }

    if (previewType === PREVIEW_TYPES.SHARED_ALBUM) {
      dispatch(addItemsToPersonalCloudThunk([currentItem]))
    }
  }

  const handleInfo = (event: MouseEvent) => {

    if (isMobile) {
      setOpenedInfoModal(true)
    } else {
      event.stopPropagation()
      event.preventDefault()
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_INFO)
  }

  const handleDownload = () => {
    if (previewType === PREVIEW_TYPES.ALBUM || previewType === PREVIEW_TYPES.PLACE_ALBUM) {
      dispatch(downloadAlbumItemsThunk({ items: [currentItem], type: sdkType }))
    }

    if (previewType === PREVIEW_TYPES.TIMELINE || previewType === PREVIEW_TYPES.FAVORITES) {
      dispatch(downloadTimelineItemsThunk({ items: [currentItem], type: sdkType }))
    }

    if (previewType === PREVIEW_TYPES.SHARED_ALBUM) {
      dispatch(downloadSharedAlbumItemThunk({ item: currentItem }))
    }

    if (previewType === PREVIEW_TYPES.DOCUMENTS) {
      dispatch(downloadSelectedDocumentItems({ items: [currentItem] as any }))
    }

    if (isEnabledPreviewDuplicateMode) {
      analytics.push(ANALYTICS_EVENTS.WEB_SIMILAR_PHOTOS_DOWNLOAD_CLICK)
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DOWNLOAD)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleRemove = () => {
    if (previewType === PREVIEW_TYPES.ALBUM) {
      isEnabledPreviewDuplicateMode && !!duplicateItems.length
        ? dispatch(removeSelectedItemsThunk({ items: [currentItem], type: SDK_TYPES.DEFAULT }))
        : dispatch(removeAlbumItemsThunk({ items: [currentItem], type: sdkType }))
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_REMOVE_CLICK)
    }

    if (previewType === PREVIEW_TYPES.TIMELINE || previewType === PREVIEW_TYPES.FAVORITES) {
      dispatch(removeSelectedItemsThunk({ items: [currentItem], type: sdkType }))
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_REMOVE_CLICK)
    }

    if (previewType === PREVIEW_TYPES.SHARED_ALBUM) {
      dispatch(removeSharedAlbumSelectedItemsThunk({ items: [currentItem] }))
    }

    if (previewType === PREVIEW_TYPES.DOCUMENTS) {
      dispatch(deleteDocumentItems({ itemsIds: [currentItem.id] }))
    }

    dispatch(albumActions.unselectAll())

    if (isEnabledPreviewDuplicateMode) {
      analytics.push(ANALYTICS_EVENTS.WEB_SIMILAR_PHOTOS_DELETE_CLICK)
    }

    handleCloseRemovingConfirmationModal()

    if (!!selectDuplicateItem && selectDuplicateItem.isOrigin) {
      handleClose()

      return
    }

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(photoPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DELETE)
  }

  const handleShowHiddenItemsOnMobile = () => {
    toggleShowDropMenu(prev => !prev)
  }

  const handleRemoveFromFavorites = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: [currentItem] }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)

    if (previewType === PREVIEW_TYPES.FAVORITES) {

      if (!!selectDuplicateItem && selectDuplicateItem.isOrigin) {
        handleClose()

        return
      }

      if (items.length === 1) {
        handleClose()
        return
      }

      if (currentIndex === items.length - 1) {
        dispatch(photoPreviewActions.setCurrentItemIndex(currentIndex - 1))
        return
      }
    }
  }

  const handleAddToFavorites = () => {
    dispatch(addSelectedToFavoritesItemsThunk({ items: [currentItem], withNotification: true }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  const handleDuplicate = async () => {
    dispatch(getPhotosDuplicateThunk({ item: currentItem }))
  }

  const onCloseInfoModal = () => {
    setOpenedInfoModal(false)
  }

  const actionItems = (() => {
    if (previewType === PREVIEW_TYPES.DOCUMENTS) {
      return [
        {
          handler: handleDownload,
          title: t('a_common_download'),
          iconName: 'download',
          hiddenOnMobile: false,
          orderOnMobile: 1,
        },
        {
          handler: handleOpenRemovingConfirmationModal,
          title: t('a_common_remove'),
          iconName: 'delete',
          hiddenOnMobile: false,
          orderOnMobile: 2,
        },
        {
          handler: handleClose,
          title: t('a_common_close'),
          iconName: 'close',
          orderOnMobile: 3,
        },
      ]
    }

    const items = [
      !!isLivePhotoCanPlay && {
        handler: handleTogglePlay,
        title: !isLivePhotoPlaying ? t('a_common_play') : t('a_common_pause'),
        iconName: !!isLivePhotoPlaying ? 'stop' : 'play',
        hiddenOnMobile: false,
        orderOnMobile: 0
      },
      {
        handler: handleDownload,
        title: t('a_common_download'),
        label: t('a_common_download'),
        iconName: 'download',
        hiddenOnMobile: false,
        orderOnMobile: 1,
      },
      previewType === PREVIEW_TYPES.SHARED_ALBUM && isAuthorized && {
        handler: handleCopyToPersonal,
        title: t('a_common_addToMyCloud'),
        label: t('a_common_addToMyCloud'),
        iconName: 'import_photo_preview',
        hiddenOnMobile: false,
        orderOnMobile: 4,
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && sdkType === SDK_TYPES.DEFAULT && (
        currentItem?.favorite ? {
          handler: handleRemoveFromFavorites,
          title: t('a_common_removeFavorite'),
          label: t('a_common_removeFavorite'),
          iconName: 'heart',
          hiddenOnMobile: false,
          orderOnMobile: 3,
        } : {
          handler: handleAddToFavorites,
          title: t('a_common_addToFavorites'),
          label: t('a_common_addToFavorites'),
          iconName: 'heart_empty',
          hiddenOnMobile: false,
          orderOnMobile: 3,
        }),
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleShare,
        title: t('a_common_createPublicLink'),
        label: t('a_common_createPublicLink'),
        iconName: 'link',
        hiddenOnMobile: true,
        orderOnMobile: 3,
      },
      isEnabledPhotoDuplicates && currentItem?.type === 'image' && !isEnabledPreviewDuplicateMode && previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleDuplicate,
        title: t('a_common_searchForSimilarPhotos'),
        label: t('a_common_searchForSimilarPhotos'),
        iconName: 'search_duplicate',
        hiddenOnMobile: true,
        orderOnMobile: 4,
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleCreateAlbum,
        title: t('a_common_createAlbum'),
        label: t('a_common_createAlbum'),
        iconName: 'create_album',
        hiddenOnMobile: true,
        orderOnMobile: 2,
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && !!userData?.family_user_id &&
      (sdkType === SDK_TYPES.DEFAULT ? {
        handler: handleCopyToFamily,
        title: t('a_common_copyToFamily'),
        label: t('a_common_copyToFamily'),
        iconName: 'family',
        hiddenOnMobile: false,
        orderOnMobile: 4,
      } : {
        handler: handleCopyToPersonal,
        title: t('a_common_copyToPersonal'),
        label: t('a_common_copyToPersonal'),
        iconName: 'add_photo_alternate_1',
        hiddenOnMobile: false,
        orderOnMobile: 4,
      }),
      {
        handler: handleInfo,
        title: t('l_common_info'),
        label: t('l_common_info'),
        iconName: 'info_outline',
        popoverContent:
          !isMobile && (
            <>
              {t('l_common_size')}

            :
              {toMb(currentItem?.data_size)}

              {' '}

              <br />

              {t('l_common_added', { DATE: moment(currentItem?.created).format("DD.MM.YYYY, HH:mm") })}
            </>
          ),
        hiddenOnMobile: false,
        orderOnMobile: 5,
      },
      (previewType !== PREVIEW_TYPES.SHARED_ALBUM || sharedPermission === 'write') &&
      previewType !== PREVIEW_TYPES.PLACE_ALBUM && {
        handler: handleOpenRemovingConfirmationModal,
        title: t('a_common_remove'),
        label: t('a_common_remove'),
        iconName: 'delete',
        hiddenOnMobile: true,
        orderOnMobile: 6,
      },
      {
        handler: handleShowHiddenItemsOnMobile,
        title: t('l_common_showMore'),
        label: t('l_common_showMore'),
        iconName: 'action_container',
        hiddenOnDesktop: true,
        orderOnMobile: 6,
      },
      {
        handler: handleClose,
        title: t('a_common_close'),
        label: t('a_common_close'),
        iconName: 'close',
        orderOnMobile: 7,
      },

    ].filter(item => !!item)

    const hiddenOnMobileItemsLength = items.filter(item => item.hiddenOnMobile).length

    if (!hiddenOnMobileItemsLength) {
      const index = items.findIndex(item => item.iconName === 'action_container')

      if (index !== -1) {
        items.splice(index, 1)
      }
    }

    return items
  })()

  return (
    <PhotoPreviewToolbarBox className={className}
      ref={ref}
    >
      {
        actionItems.map(item => (
          <ActionItem
            key={item.title}
            item={item}
          />
        ))
      }

      {isShowDropMenu && (
        <DropMenuPreview
          menuItems={actionItems}
          handleClick={handleShowHiddenItemsOnMobile}
        />
      )}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: 1 })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemove}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>

      <InfoModalPreview isOpened={isOpenedInfoModal}
        handleCloseModal={onCloseInfoModal}
        size={toMb(currentItem?.data_size)}
        date={moment(currentItem?.created).format("DD.MM.YYYY, HH:mm")}
      />
    </PhotoPreviewToolbarBox>
  )
}

const ActionItem = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)

  const handleClick = (event: MouseEvent) => {
    if (item.popoverContent) {
      togglePopover(state => !state)
    }

    item.handler(event)
  }

  return (
    <ActionIconBox
      onClick={handleClick}
      title={item.title}
      className={classNames(className, { 'hidden-on-mobile': item.hiddenOnMobile, 'hidden-on-desktop': item.hiddenOnDesktop })}
      orderOnMobile={item.orderOnMobile}
    >
      <ActionIcon iconName={item.iconName} />

      {!!item.popoverContent && popoverOpened && (
        <Popover>
          {item.popoverContent}
        </Popover>
      )}
    </ActionIconBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const PhotoPreviewToolbarBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ActionIconBox = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: #DADADA;
  order: ${props => props.orderOnMobile};

  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }

  &.hidden-on-mobile {
    display: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    order: unset;

    &.hidden-on-desktop {
      display: none;
    }

    &.hidden-on-mobile {
      display: flex;
    }
  }
`

const ActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  background: #fff;
  border-radius: 6px;
  width: 180px;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

  &.active {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: #fff;
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`
