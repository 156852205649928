import { useEffect, useState } from "react"

import { t } from "i18next"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { useAppDispatch } from  "store"

import { NOTIFICATION_TYPES, showNotification } from "../notifications"
import { getAvailableExtensionsForUploading } from "../selectors"

import { callUploadHandlerThunk, dropzoneActions } from "./dropzoneSlice"
import { getDropzoneLocalAvailableExtensionsSelector, getDropzoneOptionsSelector } from "./selectors"

export const useGlobalDropzone = () => {
  const dispatch = useAppDispatch()
  const options = getDropzoneOptionsSelector()

  const globalAvailableExtensions = getAvailableExtensionsForUploading()
  const extensionsFromOptions = getDropzoneLocalAvailableExtensionsSelector()

  const availableExtensions = extensionsFromOptions || globalAvailableExtensions

  const [isMouseOnWindow, setIsMouseOnWindow] = useState(true)

  const uploadHandler = (...args: Parameters<DropzoneOptions['onDrop']>) => {
    const acceptedFromDropzone = [...args[0]]
    const rejectedFromDropzone = [...args[1]]

    const acceptedFiles = []
    const rejectedFiles = rejectedFromDropzone.map(file => file.file)

    acceptedFromDropzone.forEach(file => {
      const splittedName = file.name.split('.')
      let extension = ''

      if (splittedName.length === 1 || (splittedName[0] === '' && splittedName.length === 2) ) {
        extension =  ''
      } else {
        extension = `.${splittedName.pop()}`
      }

      if ((!!extension && !availableExtensions.includes(extension.toLowerCase()) && availableExtensions?.length !== 0)) {
        rejectedFiles.push(file)
      } else {
        acceptedFiles.push(file)
      }
    })

    rejectedFiles.forEach(file => {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_fileNotUploaded', { FILE_NAME: file.name })
      })
    })

    dispatch(callUploadHandlerThunk({ acceptedFiles, rejectedFiles }))
  }

  useEffect(() => {
    if (!navigator.userAgent.includes("Firefox")) {
      return
    }

    //react-dropzone dragLeave event doesn't work in Firefox when mouse goes out from the window
    let timeoutForDragover

    const mouseOverListener = () => {
      setIsMouseOnWindow(true)
      clearTimeout(timeoutForDragover)

      timeoutForDragover = setTimeout(() => {
        setIsMouseOnWindow(false)
      }, 100)
    }

    document.documentElement.addEventListener('dragover', mouseOverListener)

    return () => {
      document.documentElement.removeEventListener('dragover', mouseOverListener)
    }
  }, [])

  const { ...dropzoneState } = useDropzone({ ...options, onDrop: uploadHandler, noKeyboard: true })

  useEffect(() => {
    dispatch(dropzoneActions.toggleDropzoneAreaVisibility(dropzoneState.isDragActive && isMouseOnWindow))
  }, [dropzoneState])

  return dropzoneState
}
