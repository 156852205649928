import React, { useState } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { SpriteIcon, STYLED_VARIABLES } from "@cloudike/web_ui_components"

import { DOCUMENT_TYPE_ICONS } from "./constants"
import { DOCUMENT_TYPES } from "./types"

export interface DocumentTypeCardProps {
    className?: string,
    isSelected?: boolean,
    id: string,
    onSelect?: (id: string, isSelected: boolean) => void,
    onClick?: (id: string) => void,
    imgUrl?: string,
    isCheckboxVisibleWithoutHover?: boolean,
    description?: string
    documentTypeName: string
    documentTypePhotoCount: any,
    type: DOCUMENT_TYPES,
}

export const DocumentTypeCard: React.FC<DocumentTypeCardProps> = ({
  className = '',
  imgUrl,
  description,
  isSelected,
  onSelect,
  onClick,
  id,
  isCheckboxVisibleWithoutHover,
  documentTypeName,
  documentTypePhotoCount,
  type,
}) => {
  const [imageLoaded, toggleImageLoading] = useState(false)

  const handleImgLoad = () => {
    toggleImageLoading(true)
  }

  const handleClick = () => {
    if (isCheckboxVisibleWithoutHover) {
            onSelect!(id, !isSelected)
            return
    }
        onClick!(id)
  }

  function handleSelect(e: MouseEvent) {
    e.stopPropagation()
        onSelect!(id, !isSelected)
  }

  return (
    <DocumentTypeBox>
      <DocumentTypeCardBox
        className={classNames(className, { selected: isSelected })}
        onClick={handleClick}
      >
        {!!imgUrl ? (
          <DocumentTypeImg
            src={imgUrl}
            alt={description}
            onLoad={handleImgLoad}
            className={classNames({ loading: !imageLoaded })}
          />
        ) : (
          <ImageContent>
            <Icon src={DOCUMENT_TYPE_ICONS[type]} />
          </ImageContent>
        )}

        <CheckCircleIconBox className={classNames({ visible: isCheckboxVisibleWithoutHover })}>
          <SpriteIcon iconName='circle' />
        </CheckCircleIconBox>

        <CheckIconBox className={classNames({ checked: isSelected })}
          onClick={handleSelect}
        >
          <CheckedIcon iconName='circle_checked'
            className={classNames({ checked: isSelected })}
          />
        </CheckIconBox>

        <PhotoOverlay className={classNames({ checked: isSelected })} />
      </DocumentTypeCardBox>

      <DocumentTypeName>
        {documentTypeName}
      </DocumentTypeName>

      <DocumentTypeDescription>
        {documentTypePhotoCount}
      </DocumentTypeDescription>
    </DocumentTypeBox>
  )
}

const DocumentTypeBox = styled.div`
`

const DocumentTypeCardBox = styled.div`
  position: relative;
  cursor: pointer;
  background-color: var(--background-tertiary);
  padding-bottom: 100%;
  user-select: none;
  border-radius: 4px;

  &::before {
    content: "";
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: opacity 300ms;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    height: 205px;
    padding-bottom: unset;
  }
`

const Icon = styled.img`
  width: 48px;
  height: 48px;
`

const ImageContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-fourth);
  border-radius: 4px;

  ${DocumentTypeCardBox}.selected & {
    transform: scale(0.9, 0.84);
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    ${DocumentTypeCardBox}.selected & {
      transform: scale(0.9);
    }
  }
`

const PhotoOverlay = styled.div`
  background: transparent;
  transition: all 300ms;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  border-radius: 4px;

  ${DocumentTypeCardBox}:hover & {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 50.57%);
  }

  &.checked {
    background: transparent;
  }
`

const CheckCircleIconBox = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 4;
  color: var(--tag-icon);
  transition: all 300ms;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
`

const CheckIconBox = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0;
  z-index: 4;
  color: var(--tag-icon);
  transition: all 300ms;

  &.checked {
    color: var(--accent-normal);
    opacity: 1;

    ${DocumentTypeCardBox}:hover & {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    ${DocumentTypeCardBox}:hover & {
      opacity: 1;
    }
  }
`

const DocumentTypeImg = styled.img`
  z-index: 2;
  width: 100%;
  transition: all 200ms ease;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  background-color: transparent;
  border-radius: 4px;
  object-fit: cover;
  height: 100%;

  &.loading {
    opacity: 0;
  }

  ${DocumentTypeCardBox}.selected & {
    transform: scale(0.9, 0.84);
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    ${DocumentTypeCardBox}.selected & {
      transform: scale(0.9);
    }
  }
`

const DocumentTypeName = styled.h1`
  margin-top: 8px;
  font-size: 14px;
  color: var(--text-primary);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const DocumentTypeDescription = styled.div`
  margin-top: 4px;
  font-size: 12px;
  display: flex;
  color: var(--text-secondary);
  flex-wrap: wrap;
`

const CheckedIcon = styled(SpriteIcon)`
  &.checked {
    border-radius: 50%;
    background-color: var(--background-tertiary);
  }
`
