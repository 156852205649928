import { request } from "./request"

export const photosApi = {
  getItemsExtensions(userId:number, itemIds: string[]) {
    const promises = []
    for(const itemId of itemIds) {
      promises.push(photosApi.getItemExtensions(userId, itemId))
    }
    return Promise.all(promises).then((extensions) => {
      return extensions.reduce((acc, { id, extensions }) => {
        acc[id] = extensions
        return acc
      },{})
    })
  },
  async getItemExtensions(userId:number, itemId: string) {
    const { _embedded:{ extensions } }  = await request('GET', `/api/2/users/${userId}/photos/items/${itemId}/extensions`,{})
    return { id: itemId, extensions }
  },
  async getCurrentItemFaceData(userId: number, itemId: string) {
    return request('GET', `/api/2/users/${userId}/photos/items/${itemId}/faces`, {})
  },
  async getLivePhotoPreviewContentDataUrl(contentUrl) {
    const { _links:{ data:{ href } } } = await request( 'GET', contentUrl, {}, { host:null } )
    return href
  },
  getDuplicate: (userId, itemId) => {
    return request('GET', `/api/2/users/${userId}/photos/items/${itemId}/duplicates/`)
  },
  //toDo move to sdk
  getSuggestions: (userId, params) => {
    return request('GET', `/api/2/users/${userId}/photos/suggestions`, params)
  }
}
