import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { subscribeAlbumsToWSThunk, unsubscribeAlbumsFromWSThunk } from "features/albums/albums-list/albumsSlice"
import { albumsPlacesActions, fetchPlacesAlbumsThunk } from "features/albums/albums-places/albumsPlacesSlice"
import { AlbumSkeleton, PhotosGrid } from "@cloudike/web_ui_components"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { useNavigate } from "react-router-dom"
import { AlbumType } from "@cloudike/web_photos"

import {
  getAlbumsPlacesItemsSelector,
  getAlbumsPlacesLoadingStatusSelector
} from "../../../features/albums/albums-places/selectors"
import { AlbumsPlaces } from "../../../features/albums/albums-places/AlbumsPlaces"
import { AlbumsPlacesPageTopbar } from "../../../features/albums/albums-places/AlbumsPlacesTopbar"
import { SDK_TYPES } from "../../../sdk/sdkConstants"

const AlbumsPlacesPage = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const status = getAlbumsPlacesLoadingStatusSelector()
  const items = getAlbumsPlacesItemsSelector()
  const albumsTypeQA = new URLSearchParams(location.search).get('albumType')

  usePageTitle('a_files_uploadPhotoAlbums_title')

  useEffect(() => {
    dispatch(subscribeAlbumsToWSThunk())
    dispatch(albumsPlacesActions.setCurrentAlbumsType(type))
    dispatch(albumsPlacesActions.setPlacesType(albumsTypeQA || AlbumType.PLACE))
    dispatch(fetchPlacesAlbumsThunk())

    return () => {
      dispatch(albumsPlacesActions.resetState())
      dispatch(unsubscribeAlbumsFromWSThunk())
    }
  }, [])

  const handleBackButtonClick = () => {
    if(type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums`)
      return
    }
    navigate(`/photos/albums`)
  }

  return (
    <>
      <AlbumsPlacesPageTopbar
        title={t('l_photos_searchPlaces')}
        onBack={handleBackButtonClick}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && !!items.length && (
          <AlbumsPlaces type={type} />
        )
      }
    </>
  )
}

const Skeleton = () => {
  return (
    <PhotosGrid>
      {Array.from({ length: 16 }).map((_, index:number) => <AlbumSkeleton key={index} />)}
    </PhotosGrid>
  )
}

export default AlbumsPlacesPage
