import { getModalsRootElement } from 'constants/modals'

import * as yup from "yup"
import { CreateNewFolderForm } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { getUiTheme, getUserNameModalStatusSelector } from 'features/user/selectors'
import { useAppDispatch } from  "store"
import { changeUserNameThunk, getUserDataSelector, userActions } from 'features/user/userSlice'

export const ProfileNameModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userData = getUserDataSelector()
  const opened = getUserNameModalStatusSelector()

  const theme = getUiTheme()

  const texts = {
    TITLE: t('l_settings_changeName'),
    SUB_TITLE: t('l_settings_accountName'),
    PLACEHOLDER: t('l_settings_accountName'),
    NAME_BUTTON_ACTION: t('a_common_save'),
    NAME_BUTTON_CANCEL: t('a_common_cancel'),
    TITLE_ICON: ''
  }

  const ValidationMessage = {
    REQUIRED: t('l_notification_EmptyFieldError'),
    TOO_LONG_STRING: t('l_notification_nameLongError'),
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(ValidationMessage.REQUIRED)
      .max(255, ValidationMessage.TOO_LONG_STRING)
  })


  const handleSubmit = (name: string) => {
    dispatch(changeUserNameThunk({ name }))
    dispatch(userActions.toggleUserNameModal(false))
  }

  const handleCloseModal = () => {
    dispatch(userActions.toggleUserNameModal(false))
  }

  return (
    <CreateNewFolderForm
      theme={theme}
      validationSchema={validationSchema}
      texts={texts}
      onCreateFolder={handleSubmit}
      onCloseModal={handleCloseModal}
      isShown={opened}
      parentBlock={getModalsRootElement()}
      defaultValue={userData.name}
    />
  )
}
