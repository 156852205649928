import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

export interface Tab {
  label: string,
  link?: string,
  disabled?: boolean,
  active?: boolean,
  value?: string,
  sort?: string,
}

interface TabsProps {
  className?: string,
  items: Tab[],
  onChangeTab: (tab: Tab) => void
}

export const Tabs: React.FC<TabsProps> = ({ className = '', items, onChangeTab }) => {
  return (
    <TabsBox className={className}>
      {items.map(item => (
        <Tab
          key={item.label}
          className={classNames({ active: item.active })}
          onClick={() => onChangeTab(item)}
        >
          {item.label}
        </Tab>
      ))}
    </TabsBox>
  )
}

const TabsBox = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  overflow: auto;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 1px;
  }
`

const Tab = styled.a`
  height: 40px;
  display: block;
  padding: 10px 16px;
  margin-right: 8px;
  text-align: center;
  color: var(--text-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--button-secondary-default-border);

  &:hover {
    background: var(--action-default);
    border-radius: 4px;
  }

  &.active {
    background: var(--button-tertiary-default);
    color: var(--text-primary);
    font-weight: 500;
    border: 1px solid var(--button-tertiary-default);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    min-width: unset;
  }
`
