import styled from 'styled-components'
import { useTranslation } from "react-i18next"

import { getOwnerData } from "../../../pages/Documents/Persons"

import { PersonsCard } from "./PersonsCard"
import { AddPersonCard } from "./AddPersonCard"
import { SkeletonPersons } from "./SkeletonPersons"

export const PersonsLine = ({
  items,
  textTitle,
  textEdit,
  textAdd,
  onClickItem,
  onClickEdit,
  onClickAddPerson,
  maxPersons,
  isLoading
}) => {
  const { t } = useTranslation()

  return (
    <PersonsLineBox>
      <TextBox>
        <TitleBox>
          {textTitle}
        </TitleBox>

        <EditBox onClick={onClickEdit}>
          {textEdit}
        </EditBox>
      </TextBox>


      <LineBox>
        {!isLoading && items.map(item => (
          <PersonsCard
            key={item.id}
            isSelect={item.isSelect}
            name={item.name}
            color={item.color_id}
            onClickItem={() => onClickItem(item.id)}
          />
        ))}

        {isLoading && <SkeletonPersons ownerItem={getOwnerData(t)} />}

        {items.length - 1 < maxPersons && !isLoading && (
          <AddPersonCard
            textAdd={textAdd}
            onClickItem={onClickAddPerson}
          />
        )}
      </LineBox>

    </PersonsLineBox>
  )
}

const PersonsLineBox = styled.div`
  height: 136px;
  margin-bottom: 40px;
`

const TextBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`

const EditBox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--accent-normal);
  cursor: pointer;
`

const TitleBox = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`

const LineBox = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`
