import CLPhotos, { LogLevelName } from "@cloudike/web_photos"
import { USER_DATA_KEY_IN_STORAGE } from "features/user/constants"

import { getCurrentAppConfig, getCurrentAppConfigApiHost } from "../features/common/configs/additionalConfigService"

import { WebSocketService } from "./webSocketService"
import { getSdkWS } from "./sdkWS"

let photosWS: WebSocketService

const options = {
  logLevel: LogLevelName.TRACE
}

const getConfig = () => {
  const wsConfig = {
    sockjs: getCurrentAppConfig().sockjs,
    websocket_event_groups: [
      "fs2",
      "fs",
      "photos",
      "family"
    ],
  }

  return {
    authToken: localStorage.getItem(USER_DATA_KEY_IN_STORAGE) && JSON.parse(`${localStorage.getItem(USER_DATA_KEY_IN_STORAGE)}`)?.CurrentUser?.token,
    baseUrl: getCurrentAppConfigApiHost(),
    userAgent: `web-client ${process.env.APP_VERSION}`,
    wsConfig
  }
}

export const getPhotoSdk = (): CLPhotos => {
  let sdk = null

  try {
    sdk = CLPhotos.init(options, getConfig())
  } catch (e) {
    console.error(`Couldn't init photos sdk`, e)
  }

  return sdk
}

export const getPhotosWS = () => {
  if (photosWS) {
    return photosWS
  }

  const sdk = getPhotoSdk()
  const ws = getSdkWS(sdk)

  photosWS = new WebSocketService(ws)

  return photosWS
}
