import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { useNavigate } from "react-router-dom"

import { AddingToolbar } from "../common/add-toolbar/AddingToolbar"
import { appActions } from "../../store/app"
import { analytics,ANALYTICS_EVENTS } from "../common/analytics"
import { getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "../photo/timeline/selectors"

import { addSelectedToFavoritesItemsADDThunk } from "./favoritesSlice"

export const AddingFavoritesToolbar = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()

  const selectedItems = getTimelineSelectedItemsSelector()

  const handleAddPhotos = () => {
    const callback = () => {
      navigate('/photos/favorites')
    }

    dispatch(addSelectedToFavoritesItemsADDThunk({ items: selectedItems, callback }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  return (
    <AddingToolbar onAction={handleAddPhotos}
      actionName={t('l_common_addToFavorite')}
      selectedItemsCount={selectedItemsCount}
    />
  )
}
