import { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { useAppDispatch } from  "store"
import styled from "styled-components"

import { getOffersContentThunk } from "../../../store/app"
import { getUiTheme } from "../../user/selectors"

const OfferView = () => {
  const dispatch = useAppDispatch()
  const { offerId } = useParams()
  const theme = getUiTheme()
  const navigateToOffer = (url: string) => window.location.href = url

  const [htmlString, setHtmlString] = useState('')
  useEffect(() => {
    dispatch(getOffersContentThunk({ offerId }))
      .unwrap()
      .then(({ content, url }) => {
        // if(url) navigateToOffer(url)
        setHtmlString(content)
      })
      .catch(() => {
        setHtmlString('')
      })

  }, [])

  return (
    <StyledOfferView theme={theme}
      dangerouslySetInnerHTML={{ __html: htmlString }}
    />
  )
}

const StyledOfferView = styled.div`
    padding: 2rem;
    & * {
      color: ${props => props.theme === 'dark' ? 'white !important' : null};
    }
`
export default OfferView
