import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs } from 'ui/Tabs'
import styled from 'styled-components'
import { getUserDataSelector } from 'features/user/userSlice'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { getPublicLinksAlbumsCountSelector, getPublicLinksFamilyAlbumsCountSelector, getPublicLinksFilesCountSelector } from './selectors'
import { PUBLIC_LINKS_TYPES } from './publicLinksSlice'


interface PublicLinksTabsProps {
  className?: string,
  activeTab: PUBLIC_LINKS_TYPES
}

const generateLabel = (name, count) => {
  return `${name}${!!Number(count) ? ` (${count})` : ''}`
}

export const PublicLinksTabs: React.FC<PublicLinksTabsProps> = ({ className = '', activeTab }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const albumsCount = getPublicLinksAlbumsCountSelector()
  const familyAlbumsCount = getPublicLinksFamilyAlbumsCountSelector()
  const filesCount = getPublicLinksFilesCountSelector()
  const userData = getUserDataSelector()

  const tabs = [
    {
      label: generateLabel(t('a_nav_myFiles'), filesCount),
      active: activeTab === PUBLIC_LINKS_TYPES.FILES,
      link: '/shared/files',
      value: PUBLIC_LINKS_TYPES.FILES
    },
    {
      label: generateLabel(t('l_common_sharedAlbums'), albumsCount),
      active: activeTab === PUBLIC_LINKS_TYPES.ALBUMS,
      link: '/photos/shared-by-me',
      value: PUBLIC_LINKS_TYPES.ALBUMS
    },
    userData?.family_user_id && {
      label: generateLabel(t('l_family_sharedFamilyAlbums'), familyAlbumsCount),
      active: activeTab === PUBLIC_LINKS_TYPES.FAMILY_ALBUMS,
      link: '/photos/family/shared-by-me',
      value: PUBLIC_LINKS_TYPES.FAMILY_ALBUMS
    }
  ].filter(item => !!item)

  const handleChangeTab = (tab: Tab) => {
    if (Number(tab.value) === PUBLIC_LINKS_TYPES.ALBUMS) {
      analytics.push(ANALYTICS_EVENTS.WEB_PUBLIC_ALBUMS_CLICK)
    }

    if (Number(tab.value) === PUBLIC_LINKS_TYPES.FAMILY_ALBUMS) {
      analytics.push(ANALYTICS_EVENTS.WEB_PUBLIC_FC_ALBUMS_CLICK)
    }

    navigate(tab.link)
  }

  return (
    <StyledTabs
      items={tabs}
      onChangeTab={handleChangeTab}
      className={className}
    />
  )
}

const StyledTabs = styled(Tabs)`
  margin-bottom: 16px;
`