import styled from 'styled-components'

export const SkeletonPersonsList = () => {
  const items = [0, 1, 2, 3, 4, 5, 6, 7]

  return (
    <SkeletonBox>
      {items.map(item => (
        <SkeletonRow key={item}>
          <SkeletonAvatarBox />

          <SkeletonNameBox />
        </SkeletonRow>
      ))}
    </SkeletonBox>
  )
}
const SkeletonBox = styled.div`
  margin-top: 20px;
`

const SkeletonRow = styled.div`
  margin-left: 16px;
  height: 70px;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`

const SkeletonAvatarBox = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: var(--background-tertiary);
  margin-right: 20px;
`

const SkeletonNameBox = styled.div`
  height: 12px;
  display: flex;
  background-color: var(--background-tertiary);
  width: 150px;
`

