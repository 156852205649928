import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'

import { AlbumType } from '@cloudike/web_photos'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import i18n from 'i18n'
import { getAlbumsSdkByType } from 'sdk/albums'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'
import { getErrorData } from 'utils/getErrorData'
import { getPhotosWS } from 'sdk/photo'

import { fetchSeasonsAlbumsThunk } from "../albums-season/albumsSeasonsSlice"

export type IExtendedAlbumSchema = { selected?: boolean } & IAlbumSchema

const adapter = createEntityAdapter<IExtendedAlbumSchema>()

export const albumsSelectors = adapter.getSelectors()

const getCurrentAlbumsType = (state: RootState) => state.albums.type

export const subscribeAlbumsToWSThunk = createAsyncThunk(
  'timeline/subscribeAlbumsToWSThunk',
  async function (_, { dispatch }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CREATED, () => {
      dispatch(fetchAlbumsThunk())
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_DELETED, ({ output }) => {
      output.type === AlbumType.SMART ? dispatch(fetchSeasonsAlbumsThunk(3)) : dispatch(fetchAlbumsThunk())
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED, ({ output }) => {
      dispatch(actions.updateItem(output))
    })
  }
)

export const unsubscribeAlbumsFromWSThunk = createAsyncThunk(
  'timeline/unsubscribeAlbumsFromWSThunk',
  async function () {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CREATED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_DELETED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED)
  }
)

export const fetchAlbumsThunk = createAsyncThunk(
  'albums/fetchAlbumsThunk',
  async function (_, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumsSdk = getAlbumsSdkByType(type)

    const response = await albumsSdk.getAlbums({
      limit: 300,
      offset: 0,
      type: [AlbumType.SIMPLE, AlbumType.SHARED],
      preview_jwt: true
    })

    return response.data._embedded.albums
  }
)

export const removeAlbumsThunk = createAsyncThunk(
  'albums/removeAlbumsThunk',
  async function (items: IExtendedAlbumSchema[], { getState }) {
    const ids = items.map(item => item.id)

    try {
      const state = getState() as RootState
      const type = getCurrentAlbumsType(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.removeAlbums(ids)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumDeleted', { number: ids.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }

    return ids
  }
)

export const downloadAlbumThunk = createAsyncThunk(
  'albums/downloadAlbumThunk',
  async function (id: string, { getState, dispatch }) {
    try {
      const state = getState() as RootState
      const type = getCurrentAlbumsType(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      const response = await albumsSdk.createAlbumZipStream(id)

      window.location.href = response

    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const renameAlbumThunk = createAsyncThunk(
  'albums/renameAlbumThunk',
  async function ({ id, name }: { name: string, id: string }, { getState, dispatch }) {
    try {
      const state = getState() as RootState
      const type = getCurrentAlbumsType(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.renameAlbum(id, name)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumRenamed')
      })

      dispatch(actions.updateItem({ id, description: name }))
      dispatch(actions.unselectAll())
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const albumsSlice = createSlice({
  name: 'albums',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    error: '',
    type: SDK_TYPES.DEFAULT
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    unselectAll: (state) => {
      adapter.updateMany(state, state.ids.map(id => ({
        id,
        changes: {
          selected: false
        }
      })))
    },
    setCurrentAlbumsType: (state, action) => {
      state.type = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      adapter.removeAll(state)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAlbumsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchAlbumsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload)
      })
      .addCase(fetchAlbumsThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(removeAlbumsThunk.pending, () => {
        showGlobalProgressLoader()
      })
      .addCase(removeAlbumsThunk.fulfilled, (state, action) => {
        adapter.removeMany(state, action.payload)
        hideGlobalProgressLoader()
      })
      .addCase(removeAlbumsThunk.rejected, () => {
        hideGlobalProgressLoader()
      })
  },
})

const {
  reducer, actions
} = albumsSlice

export { reducer as albumsReducer, actions as albumsActions }
