import React from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { SpriteIcon } from '@cloudike/web_ui_components'

interface CheckboxProps {
    checked: boolean,
    name: string,
    className?: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked, onChange, name, className = ''
}) => (
  <CheckboxBox className={className}>
    <HiddenInput
      type="checkbox"
      checked={checked}
      name={name}
      onChange={onChange}
      className="hidden-input"
    />

    <IconBox className={classNames({ checked })}>
      {checked && <CheckedIcon iconName="check" />}
    </IconBox>
  </CheckboxBox>
)

const CheckboxBox = styled.label`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  width: 20px;
  height: 20px;
`

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

const IconBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.25px solid var(--text-disable);
  border-radius: 3.375px;
  background: transparent;
  color: var(--background-primary);

  &:hover {
    border-color: var(--accent-normal)
  }

  &.checked {
    background: var(--accent-normal);
    border-color: var(--accent-normal)
  }
`

const CheckedIcon = styled(SpriteIcon)`
  color: var(--icon-toast-primary);

  && {
    width: 12px;
    height: 12px;
  }
`
