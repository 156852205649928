import { useEffect,useState } from "react"
export const useElectronDetection = () => {
  const [isElectronAvailable, setIsElectronAvailable] = useState(false)
  useEffect(() => {
    try {
      setIsElectronAvailable(!!window.electron)
    } catch (e) {}
  },[])
  return isElectronAvailable
}
