import { LOADING_STATUSES } from "constants/loadingStatuses"
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { loadTimelineItemsFirstTimeThunk, subscribeTimelineToWSThunk, timelineActions, unsubscribeTimelineFromWSThunk } from "features/photo/timeline/timelineSlice"
import { getTimelineLoadingStatusSelector, getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "features/photo/timeline/selectors"
import { useNavigate } from "react-router-dom"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"

import { Timeline } from "../../../features/photo/timeline/Timeline"
import { addSelectedToFavoritesItemsADDThunk } from "../../../features/favorites/favoritesSlice"
import { appActions } from "../../../store/app"
import { analytics,ANALYTICS_EVENTS } from "../../../features/common/analytics"


interface AddingFavoriteToAlbumPageProps {
  type: SDK_TYPES
}

const AddingPhotosToFavorite: React.FC<AddingFavoriteToAlbumPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const status = getTimelineLoadingStatusSelector()

  const isMobile = useMobileDetection()

  usePageTitle('l_common_addToFavorite')

  useEffect(() => {
    dispatch(timelineActions.setCurrentTimelineType(type))
    dispatch(loadTimelineItemsFirstTimeThunk())
    dispatch(subscribeTimelineToWSThunk())

    return () => {
      dispatch(unsubscribeTimelineFromWSThunk())
      dispatch(timelineActions.resetState())
    }
  }, [])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleBack = () => {
    navigate('/photos/favorites')
  }

  const handleAddPhotos = () => {
    const callback = () => {
      navigate('/photos/favorites')
    }

    dispatch(addSelectedToFavoritesItemsADDThunk({ items: selectedItems, callback }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  return (
    <>
      <PageTopbarBox
        title={t('l_common_addToFavorite')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
        description={!isMobile ? t('l_common_addToFavoriteMessage') : ''}
        onAccept={isMobile && handleAddPhotos}
        isAcceptBtnActive={isMobile && !!selectedItems.length}
        onBack={handleBack}
        isMobile={isMobile}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSceleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <TimelineBox
            type={type}
            withDisabledPreview
            withDisabledPhotoCard
          />
        )
      }
    </>
  )
}

const StyledSceleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

const PageTopbarBox = styled(PageTopbar)`
  top: 0;
  background: var(--background-primary);
  z-index: 5;
  position: fixed !important;
  width: calc(100% - 542px) !important;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    width: calc(100% - 330px) !important;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: calc(100% - 48px) !important;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: calc(100%) !important;
    margin-left: -16px;
  }
`

const TimelineBox = styled(Timeline)`
  margin-top: 100px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 80px;
  }
`

export default AddingPhotosToFavorite
