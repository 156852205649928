import React from 'react'

import styled from 'styled-components'

export const FilesSkeleton = ({ className = '' }) => {
  return (
    <FilesSkeletonBox className={className}>
      <StyledTable>
        <thead>
          <tr>
            <th>
              <Placeholder />
            </th>

            <th>
              <Placeholder />
            </th>

            <th>
              <Placeholder />
            </th>

            <th>
              <Placeholder />
            </th>

            <th>
              <Placeholder />
            </th>
          </tr>
        </thead>

        <tbody>
          {Array.from({ length: 15 }).map((_, index) => (
            <tr key={index}>
              <td>
                <Placeholder />

                <Placeholder />
              </td>

              <td>
                <Placeholder />
              </td>

              <td>
                <Placeholder />
              </td>

              <td>
                <Placeholder />
              </td>

              <td>
                <Placeholder />
              </td>
            </tr>
          ))}

        </tbody>
      </StyledTable>

    </FilesSkeletonBox>
  )
}

const FilesSkeletonBox = styled.div`
`

const StyledTable = styled.table`
  border-color: var(--divider-primary);
  table-layout: fixed;
  width: 100%;

  th {
    border-bottom: 2px solid var(--divider-primary);
    padding: 15px;

    &:nth-child(1) {
      width: 40%;
      padding-left: 0;

      > div {
        width: 48px;
      }
    }

    &:nth-child(2) {
      width: 10%;

      > div {
        width: 48px;
      }
    }

    &:nth-child(3) {
      width: 10%;

      > div {
        width: 48px;
      }
    }

    &:nth-child(4) {
      width: 20%;

      > div {
        margin-right: auto;
        margin-left: auto;
        width: 64px;
      }
    }

    &:nth-child(5) {
      width: 20%;

      > div {
        margin-left: auto;
        margin-right: 0;
        width: 64px;
      }
    }
  }

  td {
    border-bottom: 1px solid var(--divider-primary);
    padding: 15px;

    &:nth-child(1) {
      width: 40%;
      padding-left: 0;

      > div {
        float: left;

        &:first-child {
          width: 24px;
          margin-right: 12px;
          margin-left: 8px;
        }

        &:nth-child(2) {
          width: 40%;
          max-width: 200px;
        }
      }
    }

    &:nth-child(2) {
      width: 10%;

      > div {
        width: 48px;
      }
    }

    &:nth-child(3) {
      width: 10%;

      > div {
        width: 48px;
      }
    }

    &:nth-child(4) {
      width: 20%;

      > div {
        margin-right: auto;
        margin-left: auto;
        width: 64px;
      }
    }

    &:nth-child(5) {
      width: 20%;

      > div {
        margin-left: auto;
        margin-right: 0;
        width: 24px;
      }
    }
  }
`

const Placeholder = styled.div`
  @keyframes progress {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }

  position: relative;
  overflow: hidden;
  background: var(--background-tertiary);
  z-index: 1;
  border-radius: 2px;
  width: 100%;
  height: 24px;
  margin-bottom: 4px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-tertiary);
    animation: progress 1s ease-in-out infinite;
  }
`
