import { useState } from 'react'

import { SpriteIcon } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getUserDataSelector } from 'features/user/selectors'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { IMember } from '@cloudike/web_photos/dist/types/intarfaces/IMember'

import { getFamilyDataSelector, getFamilyMembersSelector } from './selectors'
import { deleteFamilyMemberThunk } from './familySlice'

export const ManageFamilyMembers = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userData = getUserDataSelector()
  const members = getFamilyMembersSelector()
  const familyData = getFamilyDataSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [currentMember, setCurrentMember] = useState<IMember | null>(null)

  const handleOpenRemovingConfirmationModal = (member: IMember) => {
    setCurrentMember(member)
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleDeleteUser = () => {
    dispatch(deleteFamilyMemberThunk(currentMember))
    toggleRemovingConfirmationModal(false)
  }

  return (
    <MembersBox className={className}>
      {members.map(member => (
        <MemberRow key={member.id}>
          <MemberIconCol>
            <MemberIcon iconName="user_avatar_default" />
          </MemberIconCol>

          <MemberCol>
            <MemberInfo>
              {member.name || member.id}
            </MemberInfo>
          </MemberCol>

          <MemberCol>
            {
              member.role !== 'owner' && member.id == userData.id && (
                <MemberLabel>
                  {t('l_common_memberYou')}
                </MemberLabel>
              )}

            {
              member.role === 'owner' && member.id == userData.id && (
                <MemberLabel>
                  {t('l_common_ownerYou')}
                </MemberLabel>
              )}

            {
              member.role === 'owner' && member.id != userData.id && (
                <MemberLabel>
                  {t('l_common_owner')}
                </MemberLabel>
              )}

            {
              member.role !== 'owner' && familyData.owner_id == userData.id && (
                <MemberLink onClick={() => handleOpenRemovingConfirmationModal(member)}>
                  {t('a_common_removeUser')}
                </MemberLink>
              )}
          </MemberCol>
        </MemberRow>
      ))}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('a_nav_familyManage')}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_remove')}
        onOk={handleDeleteUser}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_confirmationToRemove')}
        </TextInModalBox>
      </DialogModal>
    </MembersBox>
  )
}

const MemberIconCol = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextInModalBox = styled.span`
  font-size: 14px;
`

const MembersBox = styled.ul`
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
`

const MemberRow = styled.li`
  display: grid;
  grid-template-columns: 52px 1fr auto;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

const MemberCol = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const MemberInfo = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-right: 10px;
`

const MemberIcon = styled(SpriteIcon)`
  && {
    width: 24px;
    height: 24px;
    color: var(--icon-secondary);
  }
`

const MemberLabel = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

const MemberLink = styled.a`
  ${MemberLabel};
  color: var(--accent-normal);

  &:hover,
  &:active,
  &:focus {
    color: var(--accent-normal);
    text-decoration: none;
  }
`
