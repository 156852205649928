import APP_CONFIG from 'constants/configs/app.config'

import React, { useEffect, useState } from 'react'

import { disableWebDavThunk, enableWebDavThunk, fetchBasicAuthThunk, setWebDavPasswordThunk } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'ui/Checkbox'
import { getBasicAuthSelector, getUiTheme } from 'features/user/selectors'
import { Form, Formik } from 'formik'
import { SpriteIcon } from '@cloudike/web_ui_components'
import { t } from 'i18next'

export const ProfileWebDav = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const basicAuth = getBasicAuthSelector()
  useEffect(() => {
    dispatch(fetchBasicAuthThunk())
  }, [])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked

    if (!checked) {
      dispatch(disableWebDavThunk({ login: basicAuth.login }))
    } else {
      dispatch(enableWebDavThunk())
    }
  }

  if (APP_CONFIG.webdev_hidden) {
    return null
  }

  const enabled = !!basicAuth?.login

  return (
    <ProfileWebDavBox className={className}>
      <Title>
        {t('l_settings_webDavTitle')}
      </Title>

      <CheckboxRow>
        <Checkbox
          name="webDavEnabled"
          checked={enabled}
          onChange={handleCheckboxChange}
        />

        <CheckboxLabel>
          {t('l_common_enabled')}
        </CheckboxLabel>
      </CheckboxRow>

      {enabled && (
        <InputsBox>
          <InputLabel>
            URL
          </InputLabel>

          <Input
            value={basicAuth.url}
            readOnly
          />

          <InputLabel>
            {t('l_common_login')}
          </InputLabel>

          <Input
            value={basicAuth.login}
            readOnly
          />

          <InputLabel htmlFor="password">
            {t('l_common_passwordField')}
          </InputLabel>

          <PasswordInput />

        </InputsBox>
      )}
    </ProfileWebDavBox>
  )
}

const ProfileWebDavBox = styled.div`
  background: var(--background-default);
  margin-top: 16px;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const CheckboxRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

const CheckboxLabel = styled.label`
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--text-primary);
`

const InputsBox = styled.div`
  max-width: 520px;
`

const InputLabel = styled.label`
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: .02em;
  margin-bottom: 3px;
`

const PasswordInputBox = styled.div`
  position: relative;
`

const Input = styled.input`
  margin-bottom: 20px;
  display: block;
  flex-shrink: 0;
  width: 100%;
  background: var(--background-primary);
  outline: none;
  padding: 10px 32px 10px 16px;
  height: 40px;
  border-radius: 4px;
  text-align: left;
  color: var(--text-primary);
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--stroke-primary);

  :active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--accent-normal);
  }

  &[readonly] {
    cursor: default;
    background-color: transparent;
  }
`

const PasswordInput = () => {
  const dispatch = useAppDispatch()
  const basicAuth = getBasicAuthSelector()

  const theme = getUiTheme()

  const [editable, toggleEditable] = useState(false)

  const handleSubmit = (values, { setErrors }) => {
    if (!values.password) {
      setErrors({ password: 'required' })

      return
    }

    const callback = () => {
      toggleEditable(false)
    }

    dispatch(setWebDavPasswordThunk({ login: basicAuth.login, password: values.password, callback }))
  }

  const handleEditClick = () => {
    toggleEditable(true)
  }

  const handleCloseClick = () => {
    toggleEditable(false)
  }

  return (
    <Formik
      initialValues={{ password: basicAuth.password }}
      onSubmit={handleSubmit}
      validateOnChange
      enableReinitialize
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Form>
          <PasswordInputBox>
            <Input
              value={values.password}
              readOnly={!editable}
              name="password"
              onChange={handleChange}
            />

            {!editable && (
              <PasswordInputButton onClick={handleEditClick}>
                <SpriteIcon iconName="edit" />
              </PasswordInputButton>
            )}

            {
              !!editable && (
                <>
                  <PasswordInputButton
                    onClick={handleCloseClick}
                    className="left"
                  >
                    <SpriteIcon iconName="close_24px" />
                  </PasswordInputButton>

                  <PasswordInputButton onClick={handleSubmit}>
                    <SpriteIcon iconName="done" />
                  </PasswordInputButton>
                </>
              )
            }

            <InputError theme={theme}>
              {errors.password === 'required' && t('l_common_fillField')}
            </InputError>
          </PasswordInputBox>
        </Form>
      )}
    </Formik>
  )
}

const PasswordInputButton = styled.button`
  background-color: transparent;
  color: var(--text-secondary);
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    right: 36px;
  }
`

const InputError = styled.div`
  position: absolute;
  top: 100%;
  color: ${(props: any) => props.theme === 'light' ? 'var(--red-normal)' : 'var(--text-secondary)'};
`
