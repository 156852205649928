import { LOADING_STATUSES } from 'constants/loadingStatuses'

import React, { useEffect } from 'react'

import { fetchStatisticsThunk } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserStatisticsSelector } from 'features/user/selectors'
import { convertFromBytes } from 'utils/utils'
import { getFileTypeKey } from 'utils/files'
import { FileIcon } from '@cloudike/web_ui_components'

import { ProfileTable, ProfileTableBox, ProfileTableText, ProfileTBody, ProfileTd, ProfileTHead, ProfileTHeadTh, ProfileTHeadTr, ProfileTr } from './ProfileStyledComponents'

export const ProfileStatistics = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { items: statistics, status } = getUserStatisticsSelector()

  useEffect(() => {
    dispatch(fetchStatisticsThunk())
  }, [])

  return (
    <ProfileStatisticsBox className={className}>
      <Title>
        {t('l_settings_statisticsTitle')}
      </Title>

      <ProfileTableBox>
        <ProfileTable>
          <Colgroup>
            <Col className="description" />

            <Col className="size" />

            <Col />
          </Colgroup>

          <ProfileTHead>
            <ProfileTHeadTr>
              <ProfileTHeadTh>
                {t('l_common_description')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_common_size')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_common_count')}
              </ProfileTHeadTh>
            </ProfileTHeadTr>
          </ProfileTHead>

          {status === LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="3">
                  <ProfileTableText>
                    {t('l_common_loading')}
                    ...
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!statistics.length && status !== LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="3">
                  <ProfileTableText>
                    {t('statistics')}
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!!statistics.length && (
            <ProfileTBody>
              {statistics.map(row => (
                <ProfileTr key={row.name}>
                  <ProfileTd>
                    <ProfileTableText>
                      <StyledFileIcon fileType={row.name} />

                      {t(getFileTypeKey(row.name))}
                    </ProfileTableText>
                  </ProfileTd>

                  <ProfileTd>
                    {convertFromBytes(row.size)}
                  </ProfileTd>

                  <ProfileTd>
                    {row.count}
                  </ProfileTd>
                </ProfileTr>
              ))}
            </ProfileTBody>
          )}
        </ProfileTable>
      </ProfileTableBox>
    </ProfileStatisticsBox>
  )
}

const ProfileStatisticsBox = styled.div`
  margin-top: 16px;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const StyledFileIcon = styled(FileIcon)`
  margin-right: 8px;
`

const Colgroup = styled.colgroup``

const Col = styled.col`
  &.description {
    width: 300px;
  }

  &.size {
    width: 300px;
  }
`
