import React, { useCallback, useEffect, useState } from 'react'

import {
  PageSpiner,
  PageTopbar,
  PeopleAlbumTitle,
  PhotoCard,
  PhotosGrid,
  PhotosGroupTitle
} from "@cloudike/web_ui_components"
import styled from "@emotion/styled"
import { useAppDispatch } from "store"
import { useParams } from "react-router-dom"
import { useInView } from "react-intersection-observer"
import { useTranslation } from "react-i18next"

import {
  albumActions,
  fetchAlbumDataThunk,
  loadAlbumItemsFirstTimeThunk,
  loadMoreAlbumItemsThunk,
  subscribeAlbumToWSThunk,
  unsubscribeAlbumFromWSThunk,
} from "../../../../features/albums/album/albumSlice"
import {
  openPhotoPreviewThunk,
  photoPreviewActions,
  PREVIEW_TYPES
} from "../../../../features/photo/photo-preview/photoPreviewSlice"
import { SDK_TYPES } from "../../../../sdk/sdkConstants"
import {
  getAlbumDataSelector,
  getAlbumDateFormatSelector,
  getAlbumItemsSelector,
  getAlbumSelectedItemsCountSelector,
  getAlbumSelectedItemsIdsSelector,
  getAlbumSortFormatSelector,
  getAlbumTotalItemsCountSelector,
  getPersonItemsByDateSelector
} from "../../../../features/albums/album/selectors"
import { TimelineTabs } from "../../../../features/photo/timeline/TimelineTabs"
import { SORT_FORMATS } from "../../../../features/photo/timeline/timelineSlice"
import { Tab } from "../../../../ui/Tabs"
import { usePreviewItemsUpdate } from "../../../../features/photo/photo-preview/usePreviewItemsUpdate"
import { RenameAlbumModal } from "../../../../features/albums/album/RenameAlbumModal"
import { getModalsRootElement } from "../../../../constants/modals"
import { appActions } from "../../../../store/app"
import { renameAlbumThunk } from "../../../../features/albums/albums-list/albumsSlice"
import { useMobileDetection } from "../../../../features/common/hooks"
import { getPreviewAlbumLink } from "../../../../utils/getPreviewAlbumLink"
import { CircleButtonBack } from "../../../../features/common/circle-button-back/CircleButtonBack"

export const AlbumPeoplePage = ({ type }) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { id } = useParams<{ id?: string }>()

  const albumData = getAlbumDataSelector()
  const items = getAlbumItemsSelector()

  const dateFormat = getAlbumDateFormatSelector()
  const itemsByDate = getPersonItemsByDateSelector(dateFormat)

  const selectedItemsIds = getAlbumSelectedItemsIdsSelector()
  const selectedItemsCount = getAlbumSelectedItemsCountSelector()
  const totalItemsCount = getAlbumTotalItemsCountSelector()
  const isMobile = useMobileDetection()
  const sortFormat = getAlbumSortFormatSelector()

  const withDisabledPhotoCard = false
  const withDisabledPreview = false

  const texts = {
    name: albumData ? albumData.description : '',
    action: !!albumData?.description ? t('a_common_edit') : t('l_common_addName')
  }

  useEffect(() => {
    dispatch(albumActions.setCurrentAlbumId(id))
    dispatch(albumActions.setCurrentAlbumsType(type))
    dispatch(fetchAlbumDataThunk())
    dispatch(loadAlbumItemsFirstTimeThunk())
    dispatch(subscribeAlbumToWSThunk())

    return () => {
      dispatch(unsubscribeAlbumFromWSThunk())
      dispatch(albumActions.resetState())
      dispatch(photoPreviewActions.resetState())
    }
  }, [id])

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()
  const [renameModalOpened, toggleRenameModal] = useState(false)

  const loadMore = () => {
    dispatch(loadMoreAlbumItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items.length])

  usePreviewItemsUpdate(items, totalItemsCount)

  const handleItemSelection = useCallback((id) => {
    dispatch(albumActions.selectItem(id))
  }, [])

  const handleOpenPreview = useCallback((id) => {

    dispatch(openPhotoPreviewThunk({
      items,
      totalItemsCount,
      currentItemId: id,
      sdkType: SDK_TYPES.DEFAULT,
      type: PREVIEW_TYPES.TIMELINE,
      loadMore
    }))
  }, [items, totalItemsCount])

  const switchGridClass = (sortFormat: string) => {
    switch (sortFormat) {
    case SORT_FORMATS.sortFormat.day:
      return SORT_FORMATS.sortGridClass.day
    case SORT_FORMATS.sortFormat.month:
      return SORT_FORMATS.sortGridClass.month
    case SORT_FORMATS.sortFormat.year:
      return SORT_FORMATS.sortGridClass.year
    default:
      return SORT_FORMATS.sortGridClass.day
    }
  }

  const handleWholeGroupSelection = useCallback((items) => {
    let ids = items.map(item => item.id)

    if (withDisabledPhotoCard) {
      const arr = items.filter(item => !item.favorite)
      ids = arr.map(item => item.id)
      items = [...arr]
    }

    if (items.some(item => !selectedItemsIds.includes(item.id))) {
      dispatch(albumActions.selectAllItemsInGroup(ids))
    } else {
      dispatch(albumActions.unselectAllItemsInGroup(ids))
    }
  }, [itemsByDate])

  const handleRenameAlbum = (name) => {
    dispatch(renameAlbumThunk({ name: name.trim(), id: albumData.id }))
    toggleRenameModal(false)
  }

  const handleOpenRenameModal = () => {
    toggleRenameModal(true)
  }

  const handleCloseRenameModal = () => {
    toggleRenameModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleChangeTab = (tab: Tab) => {
    dispatch(albumActions.setDateFormat(tab.value))
    dispatch(albumActions.setSortFormat(tab.sort))
  }

  const handleClickBack = () => {
    window.history.back()
  }

  const handleCancelSelection = useCallback(() => {
    dispatch(albumActions.unselectAll())
  }, [])

  return (
    <AlbumPeoplePageBox>
      {
        selectedItemsCount ? (
          <PageTopbar
            onClickMenuButton={() => {
            }}
            title={texts.name}
            selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
            selectedItemsCount={selectedItemsCount}
            onCancelSelection={handleCancelSelection}
          />
        ) : (
          <TopBarBox>
            <CircleButtonBack onClick={handleClickBack} />

            <TitleTopbar>
              {texts.name}
            </TitleTopbar>
          </TopBarBox>
        )
      }

      {
        albumData?._embedded && (
          <PeopleAlbumTitle
            img={getPreviewAlbumLink(albumData,'album')}
            texts={texts}
            onAction={handleOpenRenameModal}
          />
        )
      }

      <TimelineTabs activeTab={dateFormat}
        onChangeTab={handleChangeTab}
      />

      <TimelineItemsBox>
        {
          itemsByDate.map(itemsGroup => {
            const isTitleChecked = withDisabledPhotoCard
              ? !itemsGroup.items.filter(item => !item.favorite).some(item => !selectedItemsIds.includes(item.id))
              : !itemsGroup.items.some(item => !selectedItemsIds.includes(item.id))
            const isTitleDisabled = itemsGroup.items.some(item => !item.favorite)

            return (
              <React.Fragment key={itemsGroup.date}>
                <PhotosGroupTitleBox
                  title={itemsGroup.date}
                  checked={isTitleChecked}
                  onClick={() => handleWholeGroupSelection(itemsGroup.items)}
                  checkIconVisibleByDefault={selectedItemsCount > 0}
                  isDisabled={withDisabledPhotoCard ? !isTitleDisabled : withDisabledPhotoCard}
                />

                <PhotosGrid className={switchGridClass(sortFormat)}>
                  {itemsGroup.items.map(item => (
                    <MemoizedPhotoCard
                      key={item.id}
                      id={item.id}
                      imgUrl={item._links?.image_middle?.href}
                      isSelected={selectedItemsIds.includes(item.id)}
                      onSelect={handleItemSelection}
                      onClick={withDisabledPreview ? handleItemSelection : handleOpenPreview}
                      isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
                      type={item.type}
                      isFavorite={item.favorite}
                      isDisabled={withDisabledPhotoCard && item.favorite}
                    />
                  ))}
                </PhotosGrid>
              </React.Fragment>
            )
          }
          )
        }

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner />
          </div>
        )}
      </TimelineItemsBox>

      <RenameAlbumModal
        isShown={renameModalOpened}
        albumNameBeforeRenaiming={albumData?.description}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseRenameModal}
        onRenameAlbum={handleRenameAlbum}
        texts={{
          TITLE: albumData?.description ? t('a_albums_renameAlbum') : t('a_common_edit'),
          PLACEHOLDER: t('l_common_untitledAlbum'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_albums_albumNameField'),
          TITLE_ICON: 'PhotoAlbumIcon'
        }}
      />
    </AlbumPeoplePageBox>
  )
}

const TitleTopbar = styled.h3`
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 40px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TopBarBox = styled.div`
  height: 106px;
  padding-top: 20px;
  display: flex;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`

const MemoizedPhotoCard = React.memo(PhotoCard)

const PhotosGroupTitleBox = styled(PhotosGroupTitle)`
  text-transform: capitalize;
  padding: 10px 0 18px;
`

const TimelineItemsBox = styled.div`
  width: 100%;
`

const AlbumPeoplePageBox = styled.div``
