export const ACCEPTED_FILE_TYPES = {
  TIMELINE: 'image/*,image/heic,image/heif,video/*',
  ALBUMS: 'image/*,image/heic,image/heif,video/*',
  FILES: '*'
}

export const ACCEPTED_FILE_TYPES_FOR_DROPZONE = {
  TIMELINE: {
    'image/*': [],
    'image/heic': [],
    'image/heif': [],
    'video/*': []
  },
  ALBUMS: {
    'image/*': [],
    'image/heic': [],
    'image/heif': [],
    'video/*': []
  }
}

export const ACCEPTED_FILES_TYPES_FOR_DOCUMENTS = [
  '.jpg', '.jpeg', '.png', '.pdf', '.bpm', '.heic'
]