import { useMemo, useRef } from 'react'

export type ClickHandler = () => void;

export function useClickAndDoubleClickHandler(onSingleClick: ClickHandler, onDoubleClick: ClickHandler, latency = 250) {
  const clickCount = useRef(0)

  return useMemo(() => {
    return () => {
      clickCount.current += 1
      setTimeout(function () {
        if (clickCount.current === 1) {
          onSingleClick()
        } else if (clickCount.current === 2) {
          onDoubleClick()
        }
        clickCount.current = 0
      }, latency)
    }
  }, [onSingleClick, onDoubleClick, latency])
}
