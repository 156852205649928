import { LOADING_STATUSES } from "constants/loadingStatuses"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getFilesSdk } from "sdk/files"
import { RootState } from "store"
import { hideGlobalProgressLoader, showGlobalProgressLoader } from "features/common/app-progress-bar"
import { NOTIFICATION_TYPES, showNotification } from "features/common/notifications"
import { t } from "i18next"

const initialState = {
  nodeId: null,
  versions: [],
  selectedVersion: null,
  status: LOADING_STATUSES.LOADING
}

export const fetchFileVersionsThunk = createAsyncThunk(
  'fileVersions/fetchFileVersionsThunk',
  async ({ nodeId } : { nodeId: string }) => {
    const sdk = getFilesSdk()

    const response = await sdk.fileVersionsService.getVersions(nodeId, { limit: 1000, preview: true, preview_jwt: true } as any)

    return response.data._embedded.versions
  }
)

export const restoreFileVersionThunk = createAsyncThunk(
  'fileVersions/restoreFileVersionThunk',
  async ({ versionId } : { versionId: string }, { getState, dispatch }) => {
    showGlobalProgressLoader()

    const state = getState() as RootState
    const sdk = getFilesSdk()

    try {
      await sdk.folderOperationsService.changeCurrentVersionByVersionId(state.fileVersions.nodeId, versionId)
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.resetState())
      hideGlobalProgressLoader()
    }
  }
)

export const fileVersionsSlice = createSlice({
  name: 'fileVersionsSlice',
  initialState,
  reducers: {
    setNodeId: (state, action) => {
      state.nodeId = action.payload
    },
    setSelectedVerion: (state, action) => {
      state.selectedVersion = action.payload
    },
    resetState: (state) => {
      state.nodeId = null
      state.versions = []
      state.status = LOADING_STATUSES.LOADING,
      state.selectedVersion = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFileVersionsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchFileVersionsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        state.versions = action.payload
        state.selectedVersion = action.payload[0]?.id
      })
      .addCase(fetchFileVersionsThunk.rejected, (state) => {
        state.status = LOADING_STATUSES.FAILED
      })
  },
})

export default fileVersionsSlice.reducer

const {
  reducer, actions
} = fileVersionsSlice

export { reducer as fileVersionsReducer, actions as fileVersionsActions }