import passportImg from 'assets/icons/passport.svg'
import birthCertificateImg from 'assets/icons/birth_certificate.svg'
import driverImg from 'assets/icons/drivers_license.svg'
import diploma from 'assets/icons/diploma.svg'
import idCard from 'assets/icons/id_card.svg'
import marriageCertificate from 'assets/icons/marriage_certificate.svg'
import militaryId from 'assets/icons/military_id.svg'
import snilsImg from 'assets/icons/snils.svg'
import internationalPassportImg from 'assets/icons/international_passport.svg'
import insurancePolicyImg from 'assets/icons/insurance_policy.svg'
import receiptsImg from 'assets/icons/receipts.svg'
import copiesImg from 'assets/icons/copies.svg'
import customImg from 'assets/icons/custom_type.svg'

import { DOCUMENT_TYPES } from "./types"

export const DOCUMENTS_CREATE_PASSWORD_REGEX = /^(?=.*[a-zA-Z])[\w~!?@#$%^&*\-–+(){}\[\]><|\/"\\]+$/
export const DOCUMENTS_SIGN_IN_PASSWORD_REGEX  = /^[a-zA-Z0-9~!?@#$%^&*_-–+(){}\[\]><|\/\"\\\-]+$/

export const DOCUMENT_TYPE_ICONS = {
  [DOCUMENT_TYPES.PASSPORT]: passportImg,
  [DOCUMENT_TYPES.BIRTH_CERTIFICATE]: birthCertificateImg,
  [DOCUMENT_TYPES.DRIVERS_LICENSE]: driverImg,

  [DOCUMENT_TYPES.SNILS]: snilsImg,
  [DOCUMENT_TYPES.INTERNATIONAL_PASSPORT]: internationalPassportImg,
  [DOCUMENT_TYPES.INSURANCE_POLICY]: insurancePolicyImg,
  [DOCUMENT_TYPES.RECEIPTS]: receiptsImg,
  [DOCUMENT_TYPES.DOCUMENT_COPIES]: copiesImg,

  [DOCUMENT_TYPES.ID_CARD]: idCard,
  [DOCUMENT_TYPES.DIPLOMA]: diploma,
  [DOCUMENT_TYPES.MARRIAGE_CERTIFICATE]: marriageCertificate,
  [DOCUMENT_TYPES.MILITARY_ID]: militaryId,
  [DOCUMENT_TYPES.INN]: idCard,

  [DOCUMENT_TYPES.DOCUMENT]: customImg,
}
