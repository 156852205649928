import { useEffect } from "react"

import styled from 'styled-components'
import { useAppDispatch } from  "store"

import { getNotificationModalTextSelector } from "./selectors"
import { documentsActions } from "./documentsSlice"

export const ModalNotification = () => {
  const text = getNotificationModalTextSelector()

  const dispatch = useAppDispatch()

  useEffect(() => {
    let timerId
    if(!!text) {
      timerId = setTimeout(() => dispatch(documentsActions.setNotificationModalText('')), 5000)
    }
    return () => clearTimeout(timerId)
  },[text])

  return (
    !!text && (
      <NotificationBox>
        {text}
      </NotificationBox>
    )
  )
}


const NotificationBox = styled.div`
  height: 32px;
  background-color: var(--background-toast-primary);
  color: var(--text-toast-primary);
  position: absolute;
  top: 8px;
  padding: 6px 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`
