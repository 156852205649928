import { useEffect, useState } from "react"

import _ from "lodash"


export const useResizeWindow = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = _.debounce(() => {
      setWidth(window.innerWidth)
    }, 200)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return width
}
