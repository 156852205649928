import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import { getUserDataSelector } from 'features/user/selectors'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpriteIcon } from '@cloudike/web_ui_components'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { QrCode } from 'ui'
import { useAppDispatch } from  "store"
import classNames from 'classnames'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { getFamilyDataSelector } from './selectors'
import { createFamilyLinkThunk, deleteFamilyLinkThunk } from './familySlice'

export const FamilyInvite = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const familyData = getFamilyDataSelector()
  const userData = getUserDataSelector()

  const link = familyData.invite_hash ? `${window.location.origin}/invite?fchash=${familyData.invite_hash}` : ''

  const isOwner = userData.id == familyData.owner_id

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)

    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_notification_inviteLinkCopied')
    })
  }

  const handleDeleteFamilyLink = () => {
    dispatch(deleteFamilyLinkThunk())

    analytics.push(ANALYTICS_EVENTS.WEB_FC_MANAGE_DELETE_LINK_CLICK)
  }

  const handleCreateFamilyLink = () => {
    dispatch(createFamilyLinkThunk())
  }

  return (
    <FamilyInviteBox className={className}>
      {familyData.invite_hash ? (
        <Title>
          {t('l_common_sharedLink')}
        </Title>
      ) : (
        <Title>
          {t('l_common_sharedLinkNotCreated')}
        </Title>
      )}

      <FormRow>
        <InputBox>
          <GlobusIcon iconName="globus" />

          <Input
            placeholder={isOwner ? t('l_family_createInvitationLink') : t('l_family_linkNotCreated')}
            readOnly
            value={link}
          />

          <CopyIcon
            iconName="copy_stroke"
            onClick={handleCopyLink}
            className={classNames({
              disabled: !link
            })}
          />
        </InputBox>

        {isOwner && (
          <>
            {link && (
              <Button onClick={handleDeleteFamilyLink}>
                {t('a_common_deleteFamilyLink')}
              </Button>
            )}

            {!link && (
              <Button onClick={handleCreateFamilyLink}>
                {t('a_common_createLink')}
              </Button>
            )}
          </>
        )}
      </FormRow>

      <Description>
        {!!link && (
          <span>
            {t('l_family_manageFamilyMessage')}
          </span>
        )}

        {!!link && (
          <span>
            {t('l_family_QRCodeMessage')}
          </span>
        )}

        {!link && isOwner && (
          <span>
            {t('family__settingsDescription__sharedLinkNotCreated')}
          </span>
        )}

        {!link && !isOwner && (
          <span>
            {t('l_family_waitForSharedLink')}
          </span>
        )}
      </Description>

      {
        !!link && <StyledQrCode value={link} />
      }
    </FamilyInviteBox>
  )
}

const FamilyInviteBox = styled.div`
  margin-top: 48px;
`

const Title = styled.h4`
  margin-top: 9px;
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: .001em;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
  }
`

const InputBox = styled.div`
  flex-grow: 1;
  position: relative;
`

const Input = styled.input`
  cursor: default;
  display: block;
  flex-shrink: 0;
  width: 100%;
  outline: none;
  height: 44px;
  padding-left: 48px;
  padding-right: 40px;
  border-radius: 4px;
  text-align: left;
  color: var(--text-primary);
  background: var(--background-primary);
  border: 1px solid var(--stroke-primary);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: var(--text-secondary);

  &::placeholder {
    color: var(--text-secondary);
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--accent-normal);
  }
`

const GlobusIcon = styled(SpriteIcon)`
  && {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 16px;
    top: 10px;
    color: var(--text-secondary);
  }
`

const CopyIcon = styled(SpriteIcon)`
  && {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--text-secondary);
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`

const Button = styled.button`
  margin-top: 10px;
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: var(--button-primary-default);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--button-primary-text);
  border-radius: 4px;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 0;
    margin-left: 8px;
    width: 172px;
  }
`

const Description = styled.p`
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
`

const StyledQrCode = styled(QrCode)`
  background-color: var(--white);
  padding: 16px;
  margin-top: 20px;
  display: inline-block;
  border-radius: 8px;
  height: calc(127px + 16px * 2);
`
