import { THEME_COLORS, THEME_COLORS_DARK } from 'constants/configs/theme.config'
import { FONTS } from 'constants/fonts'

import { nprogressStyles } from 'features/common/app-progress-bar/nprogressStyle'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {

  }

  ${FONTS.declarations}
  html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
    font-family: ${FONTS.font} !important;
  }

  html {
    font-size: 62.5%;

    .theme-white {
      --background-primary: #ffffff;
      --background-secondary: #f5f7f9;
      --background-fourth: #D5DDE3;
      --background-tertiary: #e8edf1;
      --background-banner: #FBFCFD;
      --background-modal-primary: #ffffff;
      --background-modal-secondary: #ffffff;
      --text-primary: #252A31;
      --text-secondary: #5F738C;
      --text-disable: #BAC7D5;
      --text-brand-primary: rgba(255, 255, 255);
      --text-brand-secondary: rgba(255, 255, 255, 0.6);
      --text-brand-disable: rgba(255, 255, 255, 0.38);
      --icon-primary: #5F738C;
      --icon-secondary: #BAC7D5;
      --icon-disable: #D6DFE9;
      --icon-files-primary: #E8EDF1;
      --icon-files-secondary: #D6DFE8;
      --icon-files-tertiary: #5F738C;
      --icon-files-fourth: rgba(255, 255, 255, 0.8);
      --background-toast-primary: #252A31;
      --text-toast-primary: #FFFFFF;
      --icon-toast-primary: #FFFFFF;
      --divider-primary: #E8EDF1;
      --divider-secondary: #CAD5DF;
      --stroke-primary: #BAC7D5;
      --brand-stroke-primary: rgba(255, 255, 255, 0.6);
      --button-secondary-default: ${THEME_COLORS['button-secondary-default']};
      --button-secondary-default-border: ${THEME_COLORS['button-secondary-default-border']};
      --button-secondary-hover: ${THEME_COLORS['button-secondary-hover']};
      --button-secondary-press: ${THEME_COLORS['button-secondary-press']};
      --button-secondary-disabled: ${THEME_COLORS['button-secondary-disabled']};
      --float-button-secondary-default: #FFFFFF;
      --float-button-secondary-hover: #FAFAFA;
      --float-button-secondary-press: #F5F5F5;
      --action-default: #F5F7F9;
      --action-press: #E8EDF1;
      --tag-background: #7F8FA3;
      --tag-text: #FFFFFF;
      --tag-icon: #FFFFFF;
      --button-primary-default: ${THEME_COLORS['button-primary-default']};
      --button-primary-hover: ${THEME_COLORS['button-primary-hover']};
      --button-primary-press: ${THEME_COLORS['button-primary-press']};
      --button-primary-text: ${THEME_COLORS['button-primary-text']};
      --button-primary-disabled: ${THEME_COLORS['button-primary-disabled']};
      --button-primary-text-disabled: ${THEME_COLORS['button-primary-text-disabled']};
      --button-tertiary-default: ${THEME_COLORS['button-tertiary-default']};
      --button-tertiary-hover: ${THEME_COLORS['button-tertiary-hover']};
      --button-tertiary-press: ${THEME_COLORS['button-tertiary-press']};
      --button-tertiary-text: ${THEME_COLORS['button-tertiary-text']};
      --button-tertiary-disabled: ${THEME_COLORS['button-tertiary-brand-disabled']};
      --button-tertiary-text-disabled: ${THEME_COLORS['button-tertiary-text-disabled']};
      --brand-dark: ${THEME_COLORS['brand-dark']};
      --brand-normal: ${THEME_COLORS['brand-normal']};
      --brand-light: ${THEME_COLORS['brand-light']};
      --brand-darker: ${THEME_COLORS['brand-darker']};
      --accent-dark: ${THEME_COLORS['accent-dark']};
      --accent-normal: ${process.env.APP_NAME === 'beelinecloud' ? THEME_COLORS['brand-normal'] : THEME_COLORS['accent-normal']};
      --accent-light: ${THEME_COLORS['accent-light']};
      --accent-darker: ${THEME_COLORS['accent-darker']};
      /* global */
      --white: ${THEME_COLORS['white']};
      --ink-normal: ${THEME_COLORS['ink-normal']};
      --text-small-medium: ${THEME_COLORS['text-small-medium']};
      --ink-light: ${THEME_COLORS['ink-light']};
      --green-darker: ${THEME_COLORS['green-darker']};
      --cloud-dark: ${THEME_COLORS['cloud-dark']};
      --cloud-normal: ${THEME_COLORS['cloud-normal']};
      --cloud-light: ${THEME_COLORS['cloud-light']};
      --ink-lighter: ${THEME_COLORS['ink-lighter']};
      --gray-1: ${THEME_COLORS['gray-1']};
      --gray-2: ${THEME_COLORS['gray-2']};
      --gray-3: ${THEME_COLORS['gray-3']};
      --gray-4: ${THEME_COLORS['gray-4']};
      --gray-5: ${THEME_COLORS['gray-5']};
      --white-smoke: ${THEME_COLORS['white-smoke']};
      --red-normal: ${THEME_COLORS['red-normal']};
      /* clx */
      --brand-normal-opacity-10: ${THEME_COLORS['brand-normal-opacity-10']};
      --brand-normal-opacity-15: ${THEME_COLORS['brand-normal-opacity-15']};
      --brand-text: ${THEME_COLORS['brand-text']};
      --brand-progress-line: ${THEME_COLORS['brand-progress-line']};
      --color-GMS-button-text: ${THEME_COLORS['color-GMS-button-text']};
      --color-GMS-button-border: ${THEME_COLORS['color-GMS-button-border']};
      --simple-white: #fff;
      --simple-dark: #252A31;
    }

    .theme-dark {
      --background-primary: #17171E;
      --background-fourth: #46464D;
      --background-secondary: #27272E;
      --background-tertiary: #36363D;
      --background-banner: #27272E;
      --background-modal-primary: #27272E;
      --background-modal-secondary: #F5F7F9;
      --text-primary: #E1E1E2;
      --text-secondary: #97979B;
      --text-disable: #6A6A6F;
      --text-brand-primary: rgba(0, 0, 0);
      --text-brand-secondary: rgba(0, 0, 0, 0.6);
      --text-brand-disable: rgba(0, 0, 0, 0.38);
      --icon-primary: #97979B;
      --icon-secondary: #7D7D80;
      --icon-disable: #5C5C62;
      --icon-files-primary: #36363D;
      --icon-files-secondary: #27272E;
      --icon-files-tertiary: #97979B;
      --icon-files-fourth: rgba(0, 0, 0, 0.7);
      --background-toast-primary: #FFFFFF;
      --text-toast-primary: #252A31;
      --icon-toast-primary: #252A31;
      --divider-primary: #3A3A40;
      --divider-secondary: #414147;
      --stroke-primary: #79797D;
      --brand-stroke-primary: rgba(0, 0, 0, 0.38);
      --button-secondary-default: ${THEME_COLORS_DARK['button-secondary-default']};
      --button-secondary-default-border: ${THEME_COLORS_DARK['button-secondary-default-border']};
      --button-secondary-hover: #2A2A30;
      --button-secondary-press: #333339;
      --button-secondary-disabled: #17171E;
      --float-button-secondary-default: #3C3C42;
      --float-button-secondary-hover: #45454B;
      --float-button-secondary-press: #4F4F54;
      --action-default: #36363D;
      --action-press: #424249;
      --tag-background: #5C5C62;
      --tag-text: #E1E1E2;
      --tag-icon: #E1E1E2;
      --button-primary-default: ${THEME_COLORS_DARK['button-primary-default']};
      --button-primary-hover: ${THEME_COLORS_DARK['button-primary-hover']};
      --button-primary-press: ${THEME_COLORS_DARK['button-primary-press']};
      --button-primary-text: ${THEME_COLORS_DARK['button-primary-text']};
      --button-primary-disabled: ${THEME_COLORS_DARK['button-primary-disabled']};
      --button-primary-text-disabled: ${THEME_COLORS_DARK['button-primary-text-disabled']};
      --button-tertiary-default: ${THEME_COLORS_DARK['button-tertiary-default']};
      --button-tertiary-hover: ${THEME_COLORS_DARK['button-tertiary-hover']};
      --button-tertiary-press: ${THEME_COLORS_DARK['button-tertiary-press']};
      --button-tertiary-disabled: ${THEME_COLORS_DARK['button-tertiary-brand-disabled']};
      --brand-dark: ${THEME_COLORS_DARK['brand-dark']};
      --brand-normal: ${THEME_COLORS_DARK['brand-normal']};
      --brand-light: ${THEME_COLORS_DARK['brand-light']};
      --brand-darker: ${THEME_COLORS_DARK['brand-darker']};
      --accent-dark: ${THEME_COLORS_DARK['accent-dark']};
      --accent-normal: ${THEME_COLORS_DARK['accent-normal']};
      --accent-light: ${THEME_COLORS_DARK['accent-light']};
      --accent-darker: ${THEME_COLORS_DARK['accent-darker']};
      /* global */
      --white: ${THEME_COLORS_DARK['white']};
      --ink-normal: ${THEME_COLORS_DARK['ink-normal']};
      --text-small-medium: ${THEME_COLORS_DARK['text-small-medium']};
      --ink-light: ${THEME_COLORS_DARK['ink-light']};
      --green-darker: ${THEME_COLORS_DARK['green-darker']};
      --cloud-dark: ${THEME_COLORS_DARK['cloud-dark']};
      --cloud-normal: ${THEME_COLORS_DARK['cloud-normal']};
      --cloud-light: ${THEME_COLORS_DARK['cloud-light']};
      --ink-lighter: ${THEME_COLORS_DARK['ink-lighter']};
      --gray-1: ${THEME_COLORS_DARK['gray-1']};
      --gray-2: ${THEME_COLORS_DARK['gray-2']};
      --gray-3: ${THEME_COLORS_DARK['gray-3']};
      --gray-4: ${THEME_COLORS_DARK['gray-4']};
      --gray-5: ${THEME_COLORS_DARK['gray-5']};
      --white-smoke: ${THEME_COLORS_DARK['white-smoke']};
      --red-normal: ${THEME_COLORS_DARK['red-normal']};
      /* clx */
      --brand-normal-opacity-10: ${THEME_COLORS_DARK['brand-normal-opacity-10']};
      --brand-normal-opacity-15: ${THEME_COLORS_DARK['brand-normal-opacity-15']};
      --brand-text: ${THEME_COLORS_DARK['brand-text']};
      --brand-progress-line: ${THEME_COLORS_DARK['brand-progress-line']};
      --accent-normal-hover: ${THEME_COLORS_DARK['accent-normal-hover']};
      --color-GMS-button-text: ${THEME_COLORS_DARK['color-GMS-button-text']};
      --color-GMS-button-border: ${THEME_COLORS_DARK['color-GMS-button-border']};
      --simple-white: #fff;
      --simple-dark: #252A31;
    }

  }

  body {
    font-size: 13px;
    line-height: 1.428571429;
    color: var(--text-primary);
    background-color: var(--background-primary);
    //font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a, button {
    text-decoration: none;
    color: initial;
    cursor: pointer;
    outline: none;
    background: none;
  }

  button {
    border: none;
    background: none;
  }

  ol, ul {
    list-style: none;
  }

  .top-bar {
    background: white;
    color: var(--text-primary);
    padding: 0 !important;
  }

  svg:not(:root) {
    overflow: visible;
  }

  ${nprogressStyles}
`

