export const insertStyleToIframe = (win, styles) => {
  const doc = win.document
  const style = doc.createElement('style')

  style.setAttribute('type', 'text/css')
  style.innerHTML = styles

  const head = doc.getElementsByTagName('head')

  if (head && head.length) {
    head[0].appendChild(style)
  } else {
    doc.body.appendChild(style)
  }
}