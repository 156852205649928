import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { ManageFamilyMembers } from "features/family/ManageFamilyMembers"
import { useAppDispatch } from  "store"
import { fetchFamilyThunk, subscribeFamilyToWSThunk, unsubscribeFamilyFromWSThunk } from "features/family/familySlice"
import { FamilyInvite } from "features/family/FamilyInvite"
import { getFamilyLoadingStatusSelector } from "features/family/selectors"
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { usePageTitle } from "features/common/hooks/usePageTitle"

const ManageFamily = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const status = getFamilyLoadingStatusSelector()

  usePageTitle('a_nav_familyManage')

  useEffect(() => {
    dispatch(fetchFamilyThunk())
    dispatch(subscribeFamilyToWSThunk({ navigate }))

    return () => {
      dispatch(unsubscribeFamilyFromWSThunk())
    }
  }, [])

  return (
    <>
      <PageTopbar
        title={t('l_family_manageFamilyTitle')}
      />

      <PageBox>
        {
          status !== LOADING_STATUSES.LOADING && (
            <>
              <ManageFamilyMembers />

              <FamilyInvite />
            </>
          )}
      </PageBox>

      <MobileToolbarMenuToggle />
    </>
  )
}

const PageBox = styled.div`
  padding: 0 16px;
`

export default ManageFamily
