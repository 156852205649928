import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { getAlbumsPersonWithNoneZeroContentSelector } from "../albums/albums-person/selectors"

export const getPhotoSearchType = () => useAppSelector(state => state.photoSearch.type)

export const getPhotoSearchSuggestions = state => state.photoSearch.suggestions

export const getPhotoSearchSuggestionsSelector = () => useAppSelector(getPhotoSearchSuggestions)

export const getPhotoSearchLoadingStatusSelector = () => useAppSelector(state => state.photoSearch.status)

export const getAlbumsPersonWithSearchSelector = () => useAppSelector(
  createSelector(
    getAlbumsPersonWithNoneZeroContentSelector,
    getPhotoSearchSuggestions,
    (persons, suggestions) => {
      const personsSuggestions = suggestions?.person_suggestions || []
      return persons.filter(person => {
        return personsSuggestions.find(suggestion => suggestion.id === person.id)
      })
    }
  ))
