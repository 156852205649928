import React from "react"

import { useLocation } from "react-router-dom"

/**
 * Custom hook to parse URL query parameters.
 *
 * @returns {URLSearchParams} The URLSearchParams object containing parsed query parameters.
 */
export function useQuery(): URLSearchParams {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
