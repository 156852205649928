import React, { HTMLAttributes, useState } from 'react'

import { SpriteIcon } from '@cloudike/web_ui_components'
import styled from 'styled-components'

interface Props extends HTMLAttributes<HTMLInputElement> {
  className?: string,
  error?: string
}

const EYE_ICON_TYPES = {
  password: 'visibility',
  text: 'visibility_off'
}

export const PasswordInput: React.FC<Props> = React.forwardRef(({ className = '', onChange, error, ...props }, ref) => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password')

  const handleInputTypeChange = () => {
    setInputType(state => state === 'text' ? 'password' : 'text')
  }

  return (
    <>
      <PasswordInputBox className={className}>
        <Input onChange={onChange}
          type={inputType}
          ref={ref}
          {...props}
        />

        <IconsBox>
          <EyeIcon iconName={EYE_ICON_TYPES[inputType]}
            onClick={handleInputTypeChange}
          />

          {!!error && <WarningIcon iconName="warning" />}
        </IconsBox>
      </PasswordInputBox>

      {error && (
        <ErrorBox>
          {error}
        </ErrorBox>
      )}
    </>
  )
})

const PasswordInputBox = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  background: var(--background-primary);
  border: 1px solid var(--text-disable);
  border-radius: 4px;
  padding: 0 35px 0 16px;
  outline: none;
  color: var(--text-primary);

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.444444px;
    color: var(--text-secondary);
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--button-secondary-default-border);
  }
`

const IconsBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
`

const EyeIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: var(--text-secondary);
  user-select: none;
  cursor: pointer;
`

const WarningIcon = styled(SpriteIcon)`
  margin-left: 9px;
  width: 24px;
  height: 24px;
  color: var(--text-secondary);
  user-select: none;
`

const ErrorBox = styled.p`
  margin-top: 4px;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--text-secondary);
`
