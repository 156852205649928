import React, { useCallback, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { useUploadingFinishDetection } from 'features/common/files-uploading/useUploadingFinishDetection'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { useTranslation } from 'react-i18next'
import { uploadTimelineItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { PageSpiner, PhotoCard, PhotosGrid, PhotosGroupTitle } from '@cloudike/web_ui_components'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import classNames from 'classnames'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import { analyticsService } from 'features/common/analytics/analyticsService'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { openPhotoPreviewThunk, photoPreviewActions, PREVIEW_TYPES } from '../photo-preview/photoPreviewSlice'
import { usePreviewItemsUpdate } from '../photo-preview/usePreviewItemsUpdate'
import { MemoriesComponent } from "../MemoriesComponent"
import { photoPreviewFlashbackActions } from "../photo-preview-flashback/photoPreviewFlashbackSlice"
import { Tab } from "../../../ui/Tabs"
import { useAppDispatch } from "../../../store"

import {
  getDateFormatSelector, getFlashbackAlbumsSelector, getSortFormatSelector,
  getTimelineItemsByDateSelector,
  getTimelineItemsSelector,
  getTimelineSelectedItemsCountSelector,
  getTimelineSelectedItemsIdsSelector,
  getTimelineTotalItemsCountSelector
} from './selectors'
import {
  loadJustUploadedTimelineItemsThunk,
  loadMoreTimelineItemsThunk,
  SORT_FORMATS,
  timelineActions
} from './timelineSlice'
import { EmptyTimelinePlaceholder } from './EmptyTimelinePlaceholder'
import { TimelineTabs } from "./TimelineTabs"

interface TimelineProps {
    type: SDK_TYPES,
    className?: string,
    withDisabledPreview?: boolean,
    withDisabledPhotoCard?: boolean,
    withMemories?: boolean,
}

export const Timeline: React.FC<TimelineProps> = ({
  className = '',
  type,
  withDisabledPreview,
  withDisabledPhotoCard,
  withMemories
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const dateFormat = getDateFormatSelector()
  const items = getTimelineItemsSelector()
  const itemsByDate = getTimelineItemsByDateSelector(dateFormat)
  const selectedItemsIds = getTimelineSelectedItemsIdsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const totalItemsCount = getTimelineTotalItemsCountSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const smartAlbums = getFlashbackAlbumsSelector()
  const sortFormat = getSortFormatSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(loadMoreTimelineItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items.length])

  usePreviewItemsUpdate(items, totalItemsCount)

  useUploadingFinishDetection(() => dispatch(loadJustUploadedTimelineItemsThunk()))

  const handleItemSelection = useCallback((id) => {
    dispatch(timelineActions.selectItem(id))
  }, [])

  const handleWholeGroupSelection = useCallback((items) => {
    let ids = items.map(item => item.id)

    if (withDisabledPhotoCard) {
      const arr = items.filter(item => !item.favorite)
      ids = arr.map(item => item.id)
      items = [...arr]
    }

    if (items.some(item => !selectedItemsIds.includes(item.id))) {
      dispatch(timelineActions.selectAllItemsInGroup(ids))
    } else {
      dispatch(timelineActions.unselectAllItemsInGroup(ids))
    }
  }, [itemsByDate])

  const handleOpenPreview = useCallback((id) => {
    analyticsService.push(ANALYTICS_EVENTS.WEB_PHOTO_VIDEO_CLICK)

    dispatch(openPhotoPreviewThunk({
      items,
      totalItemsCount,
      currentItemId: id,
      sdkType: type,
      type: PREVIEW_TYPES.TIMELINE,
      loadMore
    }))
  }, [items, totalItemsCount])

  const handleUploadFiles = (acceptedFiles: FileList) => {
    if (acceptedFiles.length) {
      dispatch(uploadTimelineItemsThunk({ files: acceptedFiles, type }))
    }

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_UPLOAD_CLICK)
    }

    if (type === SDK_TYPES.FAMILY) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_PHOTO_UPLOAD_CLICK)
    }
  }

  const switchGridClass = (sortFormat: string) => {
    switch (sortFormat) {
    case SORT_FORMATS.sortFormat.day:
      return SORT_FORMATS.sortGridClass.day
    case SORT_FORMATS.sortFormat.month:
      return SORT_FORMATS.sortGridClass.month
    case SORT_FORMATS.sortFormat.year:
      return SORT_FORMATS.sortGridClass.year
    default:
      return SORT_FORMATS.sortGridClass.day
    }
  }

  const handleChangeTab = (tab: Tab) => {
    dispatch(timelineActions.setDateFormat(tab.value))
    dispatch(timelineActions.setSortFormat(tab.sort))
  }

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFiles }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false
    }))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
      dispatch(photoPreviewActions.resetState())
      dispatch(photoPreviewFlashbackActions.resetState())
    }
  }, [])

  if (!items.length) {
    return (
      <TimelineBox>
        <EmptyTimelinePlaceholder
          texts={{
            title: t('l_photos_emptyPageTitle'),
            description: t('l_photos_emptyPageMessage'),
            uploadBtn: t('a_common_uploadPhotos')
          }}
          onUploadFiles={handleUploadFiles}
        />

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      </TimelineBox>
    )
  }

  return (
    <>
      {withMemories && (
        <MemoriesComponent
          smartAlbums={smartAlbums}
        />
      )}

      <TimelineBox
        className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
      >
        <TimelineTabs activeTab={dateFormat}
          onChangeTab={handleChangeTab}
        />

        <TimelineItemsBox>
          {
            itemsByDate.map(itemsGroup => {
              const isTitleChecked = withDisabledPhotoCard
                ? !itemsGroup.items.filter(item => !item.favorite).some(item => !selectedItemsIds.includes(item.id))
                : !itemsGroup.items.some(item => !selectedItemsIds.includes(item.id))
              const isTitleDisabled = itemsGroup.items.some(item => !item.favorite)

              return (
                <React.Fragment key={itemsGroup.date}>
                  <PhotosGroupTitleBox
                    title={itemsGroup.date}
                    checked={isTitleChecked}
                    onClick={() => handleWholeGroupSelection(itemsGroup.items)}
                    checkIconVisibleByDefault={selectedItemsCount > 0}
                    isDisabled={withDisabledPhotoCard ? !isTitleDisabled : withDisabledPhotoCard}
                  />

                  <PhotosGrid className={switchGridClass(sortFormat)}>
                    {itemsGroup.items.map(item => (
                      <MemoizedPhotoCard
                        key={item.id}
                        id={item.id}
                        imgUrl={item._links?.image_middle?.href}
                        isSelected={selectedItemsIds.includes(item.id)}
                        onSelect={handleItemSelection}
                        onClick={withDisabledPreview ? handleItemSelection : handleOpenPreview}
                        isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
                        type={item.type}
                        isFavorite={item.favorite}
                        isDisabled={withDisabledPhotoCard && item.favorite}
                      />
                    ))}
                  </PhotosGrid>
                </React.Fragment>
              )
            }
            )
          }

          {items.length < totalItemsCount && (
            <div ref={loaderBoxRef}>
              <StyledPageSpinner />
            </div>
          )}
        </TimelineItemsBox>

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      </TimelineBox>
    </>
  )
}

const MemoizedPhotoCard = React.memo(PhotoCard)

const PhotosGroupTitleBox = styled(PhotosGroupTitle)`
  text-transform: capitalize;
  padding: 10px 0 18px;
`

const TimelineBox = styled.div`
  width: 100%;
  position: relative;
  min-height: calc(100vh - 120px);

  &.with-overflow {
    max-height: calc(100vh - 120px);
    overflow: hidden;
  }
`

const TimelineItemsBox = styled.div`
  width: 100%;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`
