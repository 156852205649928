export let DATES_FORMATS = {
  timelineDay: 'DD MMM YYYY',
  timelineMonth: 'MMM YYYY',
  timelineYear: 'YYYY',

  albumStartDate: 'D MMM YYYY',
  albumEndDate: 'D MMM YYYY',
  albumStartMonth: 'MMM',
  albumEndMonth: 'MMM',

  profileTables: { default: 'MMM D, YYYY', ru: 'D MMM YYYY г.' }
}

try {
  const rewrites = require(`./../apps/${process.env.APP_NAME}/src/constants/datesFormats.ts`).default

  DATES_FORMATS = { ...DATES_FORMATS, ...rewrites }
} catch (error) {}
