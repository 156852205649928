import React from 'react'

import { getUserDataSelector } from 'features/user/userSlice'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { convertFromBytes } from 'utils/utils'

export const ProfileStorage = ({ className = '' }) => {
  const { t } = useTranslation()

  const userData = getUserDataSelector()

  const { storage_size: storageSize, quota_size: quotaSize } = userData

  const percents = storageSize && quotaSize ? Math.round(((storageSize * 100) / quotaSize) * 10) / 10 : 0
  const percentsText = (storageSize > 0 && quotaSize === 0) || storageSize > quotaSize ? 100 : percents

  const usedText = quotaSize < 0 ?
    t('l_common_quotaUnlim', { STORAGE_SIZE: convertFromBytes(storageSize) }) :
    t('l_common_quotaSize', { PERCENT: percentsText, STORAGE_SIZE: convertFromBytes(storageSize), QUOTA_SIZE: convertFromBytes(quotaSize) })

  return (
    <ProfileStorageBox className={className}>
      <TopRow>
        <Title>
          {t('l_settings_storageSectionTitle')}
        </Title>

        <StorageSize>
          {usedText}
        </StorageSize>
      </TopRow>

      <ProgressLineBox>
        <ProgressLineInner width={storageSize / quotaSize * 100} />
      </ProgressLineBox>
    </ProfileStorageBox>
  )
}

const ProfileStorageBox = styled.div`

`

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
`

const StorageSize = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--text-secondary);
`

const ProgressLineBox = styled.div`
  margin-top: 11px;
  height: 8px;
  border-radius: 100px;
  background: #EFEFF4;
  border-radius: 100px;
  overflow: hidden;
`

const ProgressLineInner = styled.div`
  background: var(--brand-progress-line);
  border-radius: 100px;
  height: 100%;
  width: ${props => props.width}%;
`
