import { useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { ProfileTabs, ProfileTabsTypes } from "features/user/features/profile/ProfileTabs"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { fetchSubscriptionsThunk } from "features/user/userSlice"
import { ProfileTokens } from "features/user/features/profile/ProfileTokens"
import { ProfileWebDav } from "features/user/features/profile/ProfileWebDav"
import { ProfilePageContentBox } from "features/user/features/profile/ProfileStyledComponents"
import { usePageTitle } from "features/common/hooks/usePageTitle"

const ProfileSecurity = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  usePageTitle('l_settings_profileAndSettingsTitle')

  useEffect(() => {
    dispatch(fetchSubscriptionsThunk())
  }, [])

  return (
    <>
      <PageTopbar title={t('l_settings_profileAndSettingsTitle')} />

      <ProfilePageContentBox>
        <ProfileTabs activeTab={ProfileTabsTypes.SECURITY} />

        <ProfileWebDav />

        <ProfileTokens />
      </ProfilePageContentBox>
    </>
  )
}

export default ProfileSecurity
