import { useQuery } from "../../common/hooks/useQuery"

const WITH_COUNTS_PARAM_NAME = 'with_counts'

/**
 * Determines if the "withCounts" parameter is equal to 1
 * in the current URL search parameters.
 */
export const useIsWithCountVisible = (): boolean => {
  const urlSearchParams = useQuery()
  const withCounts = urlSearchParams.get(WITH_COUNTS_PARAM_NAME)

  try {
    return Boolean(Number(withCounts))
  } catch (e) {
    return false
  }

}
