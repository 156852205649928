import { request } from "./request"

export const filesApi = {
  getFsRoot: (userId) => {
    return request('GET', `/api/3/users/${userId}/fs/root`)
  },
  getCurrentFolderData: (userId, nodeId) => {
    return request('GET', `/api/3/users/${userId}/fs/root/nodes/${nodeId}/`)
  },
  getAncestorsItems: (userId, node_id) => {
    return request('GET', `/api/3/users/${userId}/fs/root/nodes/${node_id}/ancestors`)
  },
}
