import React from 'react'

import { PeopleCard, PeopleList } from "@cloudike/web_ui_components"
import { useTranslation } from "react-i18next"

import { getPreviewAlbumLink } from "../../../utils/getPreviewAlbumLink"

import { getAlbumsPersonWithNoneZeroContent } from "./selectors"


export const AlbumsPersonList = ({ onClickSeeAll, onClickAlbum }) => {
  const albumsPerson = getAlbumsPersonWithNoneZeroContent()
  const { t } = useTranslation()

  const texts = {
    title: t('l_search_people'),
    seeAll: t('a_albums_seeAll')
  }
  return (
    <PeopleList
      texts={texts}
      onClickSeeAll={onClickSeeAll}
    >
      {albumsPerson.map((item) => {
        return item._embedded && (
          <PeopleCard
            isLittle={true}
            key={item.id}
            id={item.id}
            img={getPreviewAlbumLink(item,'album')}
            onClick={onClickAlbum}
            name={item.description}
          />
        )
      })}
    </PeopleList>
  )
}
