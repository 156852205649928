import { useEffect } from "react"

export const useDocumentKeyDown = (keyCode, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.keyCode === keyCode) {
        handler(event)
      }
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [keyCode, handler])
}