import { APP_NAME } from "./app"

export const LINKED_ACCOUNT_SOURCES = {
  DROPBOX: { name: 'dropbox', label: 'Dropbox' },
  GOOGLE: { name: 'google', label: 'Google Drive' }
}

export const USER_PROFILE_FIELDS = (() => [
  {
    name: 'name',
    label: 'l_common_displayName',
    value: null,
    editable: true,
    action: {
      label: 'l_common_edit',
      name: 'onEditName'
    }
  },
  {
    name: 'email',
    label: 'l_common_email',
    value: '',
    placeholder: 'l_settings_noEmail',
    editable: false,
    action: {
      label: 'a_common_remove',
      name: 'onRemoveEmail'
    }
  },
  ['cloudike', 'elifedrive'].includes(APP_NAME) && {
    name: 'password',
    label: 'l_common_passwordField',
    value: '',
    editable: true,
    action: {
      label: 'a_settings_changePassword',
      name: 'onEditPassword'
    }
  }
].filter(value => !!value))()

export const USER_PASSWORD_REGEX = /^(?=.*[a-zA-Z])[\w~!?@#$%^&*\-–+(){}\[\]><|\/"\\]+$/
