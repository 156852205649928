import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { t } from "i18next"

import { LOADING_STATUSES } from "../../../constants/loadingStatuses"
import { personsApi } from "../../../api/personsApi"
import {
  documentsActions,
  generateDocumentFileUrl,
  resetDocumentsAccessTimerThunk,
  sessionStorageKeys
} from "../documentsSlice"
import { documentsWalletApi } from "../../../api/documentsWalletApi"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"
import { DOCUMENT_ITEMS_ACTION_STATUSES, DOCUMENTS_WALLET_STATUSES, EnhancedPersonItem } from "../types"

const personsItemsAdapter = createEntityAdapter<EnhancedPersonItem>()

export const personsItemsSelectors = personsItemsAdapter.getSelectors()

export enum ADD_PERSON_MODAL_STEPS {
  START = 'START',
  EDIT_COLOR = 'EDIT_COLOR',
}

export enum ADD_PERSON_MODAL_TYPE {
  ADD_PERSON = 'ADD_PERSON',
  EDIT_PERSON = 'EDIT_PERSON'
}

let personCreationInProgress = false

export const getPersonsThunk = createAsyncThunk(
  'persons/getPersonsThunk',
  async function(ownerData: any, { getState, dispatch }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    await dispatch(actions.setDefaultPersonState({ ...ownerData }))

    const response = await personsApi.getPersonsList(userId)

    return response._embedded.persons
  }
)

export const createPersonThunk = createAsyncThunk(
  'persons/createPersonThunk',
  async function({ name, colorId }:{name: string, colorId: string}, { getState, dispatch }) {
    if (personCreationInProgress) {
      return
    }

    personCreationInProgress = true
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      const response = await personsApi.createPerson(userId, { name, color_id: colorId })
      dispatch(actions.addNewPerson(response))
      dispatch(actions.togglePersonalSettingModalOpened(false))
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_dwProfileCreated')
      })
    } catch (e) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_createProfileError')
      })
      console.error(e)
    } finally {
      personCreationInProgress = false
    }
  }
)

export const deletePersonThunk = createAsyncThunk(
  'persons/deletePersonThunk',
  async function({ personId }: { personId: string }, { getState, dispatch }) {
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      await personsApi.deletePerson(userId, personId)
      dispatch(actions.togglePersonalSettingModalOpened(false))
      dispatch(actions.removePerson(personId))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_profileDeleted')
      })
    } catch (e) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_deleteProfileError')
      })
      console.error(e)
    }
  }
)

export const patchPersonThunk = createAsyncThunk(
  'persons/patchPersonThunk',
  async function({ name, colorId, personId  }:{name: string, colorId: string, personId: string}, { getState, dispatch }) {
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      const response = await personsApi.patchPerson(userId, personId, { name, color_id: colorId })
      dispatch(actions.togglePersonalSettingModalOpened(false))
      dispatch(actions.updatePerson(response))
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_changesSaved')
      })
    } catch (e) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_changesNotSaved')
      })
      console.error(e)
    }
  }
)

export const patchSortPersonsThunk = createAsyncThunk(
  'persons/patchPersonThunk',
  async function({ sortArray, startArray } : any, { getState, dispatch }) {
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const userId = state.user.userData.id
    const personsIdList = sortArray.map(item => item.id).slice(1)

    try {
      await personsApi.patchSortPerson(userId, { person_ids: personsIdList })
      dispatch(actions.updatePersonsList(sortArray))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_changesSaved')
      })
    } catch (e) {
      dispatch(actions.updatePersonsList(startArray))

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_changesNotSaved')
      })
      console.error(e)
    }
  }
)

export const getDocumentTypePersonThunk = createAsyncThunk(
  'persons/getDocumentTypePersonThunk',
  async function({ currentPersonId } : {currentPersonId: string}, { getState, dispatch }) {
    const state = getState() as RootState
    const { userid: userId, lang } = state.user.userData
    const masterKeyHex = state.documents.masterKey
    if (!currentPersonId) return

    dispatch(documentsActions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.LOADING_TYPE))

    try {
      const documentTypesResponse = currentPersonId !== 'owner'
        ? await personsApi.getPersonDocumentTypes(userId, currentPersonId, lang)
        : await documentsWalletApi.getDocumentTypes(userId, lang)

      const types = documentTypesResponse._embedded.types

      for (const type of types) {
        const coverDocument = type?._embedded?.cover_document

        if (coverDocument) {
          type.previewUrl = await generateDocumentFileUrl(coverDocument, masterKeyHex, 'middle')
        }
      }

      sessionStorage.setItem(sessionStorageKeys.TYPES, JSON.stringify(types))
      dispatch(documentsActions.setTypes(types))
      dispatch(documentsActions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED))
    } catch (e) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
      console.error(e)
    } finally {
      dispatch(documentsActions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
    }
  }
)

export const personsSlice = createSlice({
  name: 'persons',
  initialState: personsItemsAdapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    error: '',
    personalSettingModal: {
      typeModal: '',
      opened: false,
      step: ADD_PERSON_MODAL_STEPS.START,
      name: '',
      color: '',
      personId: '',
    }
  }),
  reducers: {
    setDefaultPersonState: (state, action) => {
      personsItemsAdapter.addOne(state, action.payload)
    },
    changeSelectPerson: (state, action) => {
      const items = personsItemsSelectors.selectAll(state)
      items.forEach( item => {
        item.id === action.payload
          ? personsItemsAdapter.updateOne(state, { id: item.id,
            changes: { isSelect: true }
          })
          : personsItemsAdapter.updateOne(state, { id: item.id,
            changes: { isSelect: false }
          })
      })
    },
    addNewPerson: (state, action) => {
      personsItemsAdapter.addOne(state, action.payload)
    },
    updatePerson: (state, action) => {
      personsItemsAdapter.updateOne(state,
        {
          id: action.payload.id,
          changes: action.payload
        })
    },
    updatePersonsList: (state, action) => {
      personsItemsAdapter.setAll(state, action.payload)
    },
    removePerson: (state, action) => {
      const items = personsItemsSelectors.selectAll(state)
      const removeItem = items.find(item => item.id === action.payload)
      removeItem.isSelect && personsItemsAdapter.updateOne(state, { id: 'owner',
        changes: { isSelect: true }
      })

      personsItemsAdapter.removeOne(state, action.payload)
    },
    togglePersonalSettingModalOpened: (state, action) => {
      state.personalSettingModal.opened = action.payload
    },
    toggleStep: (state, action) => {
      state.personalSettingModal.step = action.payload
    },
    setNamePerson: (state, action) => {
      state.personalSettingModal.name = action.payload
    },
    setColorPerson: (state, action) => {
      state.personalSettingModal.color = action.payload
    },
    setTypeModal: (state, action) => {
      state.personalSettingModal.typeModal = action.payload
    },
    setPersonId: (state, action) => {
      state.personalSettingModal.personId = action.payload
    },
    setDefaultPersonParams: (state) => {
      state.personalSettingModal.name = ''
      state.personalSettingModal.color = ''
      state.personalSettingModal.personId = ''
      state.personalSettingModal.step = ADD_PERSON_MODAL_STEPS.START
    },
    resetPersonsState: (state) => {
      personsItemsAdapter.setAll(state, [])
      state.status = LOADING_STATUSES.LOADING
      state.error = ''
      state.personalSettingModal.opened = false
      state.personalSettingModal.step = ADD_PERSON_MODAL_STEPS.START
      state.personalSettingModal.name = ''
      state.personalSettingModal.color = ''
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPersonsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(getPersonsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        action.payload.forEach( item => item.isSelect = false)
        personsItemsAdapter.addMany(state, [...action.payload])
      })
      .addCase(getPersonsThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_loadPeopleError')
        })
      })

  },
})

const {
  reducer, actions
} = personsSlice

export { reducer as personsReducer, actions as personsActions }
