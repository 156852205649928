import React from 'react'

import { SpriteIcon } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import classNames from 'classnames'

export const DropzoneArea = ({ className = '', visible, ...props }) => {
  const { t } = useTranslation()

  return (
    <DropzoneAreaBox
      className={classNames(className, { visible })}
      {...props}
    >

      <BackgroundBox />

      <Content>
        <StyledIcon iconName="cloud_upload" />

        <Title>
          {t('l_files_uploadTitle')}
        </Title>

        <Description>
          {t('l_common_filesWillBeUploaded')}
        </Description>
      </Content>
    </DropzoneAreaBox>
  )
}

const BackgroundBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--background-secondary);
  opacity: 0.8;
`

const DropzoneAreaBox = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  transition: all 0.3s ease;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  border-color: var(--accent-normal);

  &.visible {
    display: flex;
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 500px;
  align-items: center;
`

const Title = styled.h3`
  margin: 7px 0 8px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: var(--text-primary);
  position: relative;
`

const Description = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
  text-align: center;
  position: relative;
`

const StyledIcon = styled(SpriteIcon)`
  position: relative;

  && {
    width: 80px;
    height: 80px;
    color: var(--accent-normal);
  }
`
