import React, { useEffect, useRef, useState } from 'react'

import { ConnectedPageTopbarProps, PageTopbar } from 'features/common/page-topbar'
import styled from 'styled-components'
import classNames from 'classnames'
import { Icon } from '@cloudike/web_ui_components'

type AlbumPageTopbarProps = ConnectedPageTopbarProps & {
  onBack: () => void,
  isEditing: boolean,
  onStartEdit: () => void,
  onFinishEdit: (value: string) => void,
  placeholder: string,
  texts?: {
    photosCount: string,
    dates: string
  }
}

export const AlbumPageTopbar: React.FC<AlbumPageTopbarProps> = ({
  className = '',
  title,
  placeholder,
  selectedText,
  selectedItemsCount,
  onCancelSelection,
  onBack,
  onStartEdit,
  onFinishEdit,
  isEditing,
  texts
}) => {
  const [value, setValue] = useState(title)
  const inputRef = useRef<HTMLInputElement>()

  const isAlbumUnnamed = !title

  const handleInfoClick = () => {
    onStartEdit()
  }

  const handleBlur = () => {
    onFinishEdit(value)
  }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      onFinishEdit(value)
    }
  }

  useEffect(() => {
    if (!isEditing) {
      return
    }

    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isEditing])

  if (selectedItemsCount) {
    return (
      <StyledPageTopbar
        title={title}
        selectedText={selectedText}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={onCancelSelection}
      />
    )
  }

  return (
    <AlbumPageTopbarBox className={className}>
      <BackButton
        onClick={onBack}
        iconName="ArrowLeftCircle"
      />

      {!isEditing && (
        <AlbumInfo onClick={handleInfoClick}>
          <AlbumName className={classNames({ placeholder: isAlbumUnnamed })}>
            {title || placeholder}
          </AlbumName>

          <AlbumDescription>
            <span>
              {texts.dates}
            </span>

            {' '}

            <span>
              {texts.photosCount}
            </span>
          </AlbumDescription>
        </AlbumInfo>
      )}

      {isEditing && (
        <AlbumNameInput
          ref={inputRef}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={value}
          autofocus
          onFocus={() => inputRef.current.select()}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      )}

    </AlbumPageTopbarBox>
  )
}

const StyledPageTopbar = styled(PageTopbar)`
  width: 100%;
  min-height: 96px;
  display: flex;
  align-items: unset;
  padding-top: 20px;
`

const AlbumPageTopbarBox = styled.div`
  width: 100%;
  min-height: 96px;
  display: flex;
  padding-top: 20px;
`

const AlbumInfo = styled.div`
  overflow: hidden;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-flow: column;
  flex-flow: column;
  margin-right: 0;
  padding-left: 16px;
  cursor: pointer;
`

const AlbumDescription = styled.p`
  margin: 0 0 18px 0;
  font-size: 12px;
  line-height: 18px;
  color: var(--text-secondary);
  letter-spacing: .32px;
  font-feature-settings: "pnum" on, "lnum" on;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AlbumName = styled.h3`
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 40px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.placeholder {
    /* Ink / Ink Lighter */
    color: var(--text-disable);
  }
`

const BackButton = styled(Icon)`
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const AlbumNameInput = styled.input`
  margin-top: 7px;
  margin-left: 22px;
  flex: 1;
  height: 32.5px;
  padding-bottom: 6.5px;
  outline: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  /* White Theme System/Text Primary */
  color: var(--text-primary);

  &::placeholder {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    /* Ink / Ink Lighter */
    color: var(--text-disable);
  }

  &:focus {
    /* Accent / Accent Normal */
    border-bottom: 2px solid #731982;
  }
`