import APP_CONFIG from "constants/configs/app.config"

import _ from "lodash"
const DEFAULT_ADDITIONAL_CONFIG_PATH = '/assets/configs/additional.config.json'

const ENHANCED_APP_CONFIG = { ...APP_CONFIG }

export enum CONFIG_TYPES {
    PROD = 'prod',
    QA = 'qa',
    DEV = 'dev'
}

async function mergeConfigs(type: CONFIG_TYPES, additionalConfig:Record<string,any> = {}) {
  try {
    checkConfigType(type)
    const configForEnv = additionalConfig[type]
    for (const key in configForEnv) {
      if (configForEnv.hasOwnProperty(key) && APP_CONFIG.hasOwnProperty(key)) {
        ENHANCED_APP_CONFIG[key] = configForEnv[key]
      }
    }
  } catch (e) {
    return ENHANCED_APP_CONFIG
  }
}

export async function initConfig(type: CONFIG_TYPES) {
  checkConfigType(type)
  const additionalConfigObj = isQA() ? await loadConfig() : {}
  checkConfigSection(type, additionalConfigObj)
  await mergeConfigs(type, additionalConfigObj)
  return additionalConfigObj
}

async function loadConfig(url: string = DEFAULT_ADDITIONAL_CONFIG_PATH) {
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (e) {
    return {}
  }
}

function checkConfigType(type:CONFIG_TYPES) {
  if(_.isNil(type))  throw new Error(`Config type hasn't been provided`)
}

function checkConfigSection(type: CONFIG_TYPES, additionalConfig: Record<string,any>) {
  const configForEnv = additionalConfig[type]
  if(_.isNil(configForEnv)) throw new Error(`Additional config section of type: ${type} is unavailable`)
}

function isQA() {
  return !_.isNil(ENHANCED_APP_CONFIG.isProd) && !ENHANCED_APP_CONFIG.isProd
}

export const getCurrentAppConfig = () => ENHANCED_APP_CONFIG

export const getCurrentAppConfigApiHost = () => ENHANCED_APP_CONFIG.api