import { IFsNodeSchema } from "@cloudike/web_files"
import { AlbumType, ShareAccessTypes, SharePermissionType } from "@cloudike/web_photos"
import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"
import { ILinks } from "@cloudike/web_photos/dist/types/intarfaces/ILinks"
import { IMyShareSchema } from "@cloudike/web_photos/dist/types/intarfaces/IMyShareSchema"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { filesApi } from "api/filesApi"
import { request } from "api/request"
import { hideGlobalProgressLoader, showGlobalProgressLoader } from "features/common/app-progress-bar"
import { NOTIFICATION_TYPES, showNotification } from "features/common/notifications"
import { t } from "i18next"
import _ from "lodash"
import { getAlbumsSdkByType } from "sdk/albums"
import { getFilesSdk } from "sdk/files"
import { getPhotoSdk } from "sdk/photo"
import { SDK_TYPES } from "sdk/sdkConstants"
import { RootState } from "store"

import { filesActions, setCurrentFolderThunk } from "../files/filesSlice"

export interface ICreateMyShareSchema {
  access_type?: ShareAccessTypes.ALL | ShareAccessTypes.PASSWORD | ShareAccessTypes.COLLABORATORS;
  password?: string;
  expires?: string;
  permission?: SharePermissionType.READ | SharePermissionType.WRITE;
}

export interface ICollaboratorSchema {
  created: string;
  updated: string;
  id: string;
  phone_or_email: string;
  permission: SharePermissionType.READ | SharePermissionType.WRITE;
  first_opened: string | null;
  _links?: ILinks;
}

type Config = {
  sdkType: SDK_TYPES,
  sharedAlbumType?: AlbumType,
  isNewAlbum?: boolean,
  albumElementsCount?: number
} | null

interface SharingState {
  sharedItemData: IAlbumSchema & IFsNodeSchema,
  sharingData: IMyShareSchema | null
  config: Config,
  collaborators: ICollaboratorSchema[],
  collaboratorsForAdd: ICollaboratorSchema[],
  collaboratorsForChange: ICollaboratorSchema[],
  collaboratorsForRemove: ICollaboratorSchema[],
  availableTypesPublicLink: string[],
  isBusy: boolean
}

const initialState: SharingState = {
  sharedItemData: null,
  sharingData: null,
  config: null,
  collaborators: [],
  collaboratorsForAdd: [],
  collaboratorsForChange: [],
  collaboratorsForRemove: [],
  availableTypesPublicLink: [],
  isBusy: false
}

export const fetchShareDataThunk = createAsyncThunk(
  'sharing/fetchShareDataThunk',
  async function (__, { dispatch, getState }) {
    const state = getState() as RootState
    const sharedItemData = state.sharing.sharedItemData
    const sdkType = state.sharing.config.sdkType
    dispatch(actions.setIsBusy(true))

    if (sdkType === SDK_TYPES.FILES) {
      const filesSdk = getFilesSdk()
      const filesPublicLinksService = filesSdk.publicLinksService

      if (sharedItemData.is_shared) {
        let nodeData = sharedItemData

        if (!sharedItemData?._links?.share) {
          const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
          const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
          const url = urlTemplate({ node_id: sharedItemData.id })

          nodeData = await request('GET', url, {}, { host: null })
        }

        const filesResponse = await filesPublicLinksService.getShare(nodeData as any)

        let collaborators = []

        if (filesResponse.data.collaborators_count) {
          try {
            const collaboratorsResponse = await filesPublicLinksService.getCollaborators(nodeData)

            collaborators = collaboratorsResponse.data._embedded.collaborators
          } catch (error) {
            collaborators = []
          }
        }

        dispatch(actions.setCollaborators(collaborators))
        dispatch(actions.setSharingData(filesResponse.data))
        dispatch(actions.setSharedItemData(nodeData))
        dispatch(actions.setIsBusy(false))

        const currentFolderId = state.files.currentFolderId

        if (currentFolderId === nodeData.id) {
          dispatch(filesActions.setCurrentFolder(nodeData))
        }

        return
      }


      const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
      const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
      const url = urlTemplate({ node_id: sharedItemData.id })

      const nodeData = await request('GET', url, {}, { host: null })

      const filesResponse = await filesPublicLinksService.createShare(nodeData, { access_type: ShareAccessTypes.ALL })

      const nodeDataWithShareLink = await request('GET', url, {}, { host: null })

      dispatch(actions.setSharingData(filesResponse.data))
      dispatch(actions.setSharedItemData({ ...nodeDataWithShareLink, is_shared: true }))
      dispatch(actions.setIsBusy(false))

      return
    }

    const publicLinksService = getPhotoSdk().publicLinksService

    if (sharedItemData.shared_hash) {
      const response = await publicLinksService.getShare(sharedItemData)

      let collaborators = []

      if (response.data.collaborators_count) {
        try {
          const collaboratorsResponse = await publicLinksService.getCollaborators(sharedItemData)

          collaborators = collaboratorsResponse.data._embedded.collaborators
        } catch (error) {
          collaborators = []
        }
      }

      dispatch(actions.setCollaborators(collaborators))
      dispatch(actions.setSharingData(response.data))
      dispatch(actions.setIsBusy(false))

      return
    }

    const response = await publicLinksService.createShare(sharedItemData, { access_type: ShareAccessTypes.ALL })

    const albumsSdk = getAlbumsSdkByType(state.sharing.config.sdkType)
    const albumDataResponse = await albumsSdk.getAlbum(sharedItemData.id)

    dispatch(actions.setSharingData(response.data))
    dispatch(actions.setSharedItemData(albumDataResponse.data))
    dispatch(actions.setIsBusy(false))
  }
)

export const deleteSharedLinkThunk = createAsyncThunk(
  'sharing/deleteSharedLinkThunk',
  async function (_, { dispatch, getState }) {
    showGlobalProgressLoader()
    dispatch(actions.setIsBusy(true))
    const state = getState() as RootState
    const sharedItemData = state.sharing.sharedItemData
    const sdkType = state.sharing.config.sdkType
    const currentFolderId = state.files.currentFolderId

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    await publicLinksService.deleteShare(sharedItemData)

    if (currentFolderId === sharedItemData.id) {
      dispatch(setCurrentFolderThunk({ nodeId: sharedItemData.id }))
    }


    dispatch(actions.resetState())
    dispatch(actions.setIsBusy(false))

    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_notification_linkDeleted')
    })

    hideGlobalProgressLoader()
  }
)

export const updateSharedDataThunk = createAsyncThunk(
  'sharing/updateSharedDataThunk',
  async function ({ data, closeAfterUpdate }: { data: ICreateMyShareSchema, closeAfterUpdate?: boolean }, { dispatch, getState }) {
    const state = getState() as RootState
    const sharedItemData = state.sharing.sharedItemData
    const sdkType = state.sharing.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    const response = await publicLinksService.updateShare(sharedItemData, data as any)

    if (!closeAfterUpdate) {
      dispatch(actions.setSharingData(response.data))

      return
    }

    dispatch(actions.resetState())

    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_notification_changesSaved')
    })
  }
)

export const saveSharedDataThunk = createAsyncThunk(
  'sharing/saveSharedDataThunk',
  async function ({ data, closeAfterUpdate }: { data: ICreateMyShareSchema, closeAfterUpdate?: boolean }, { dispatch, getState }) {
    dispatch(actions.setIsBusy(true))
    const state = (getState() as RootState).sharing
    const sharedItemData = state.sharedItemData
    const collaboratorsForAdd = state.collaboratorsForAdd
    const collaboratorsForChange = state.collaboratorsForChange
    const collaboratorsForRemove = state.collaboratorsForRemove
    const sdkType = state.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    try {
      showGlobalProgressLoader()

      const response = await publicLinksService.updateShare(sharedItemData, data as any)

      if (data.access_type === ShareAccessTypes.COLLABORATORS) {
        let collaboratorsMaxCountReached = false
        let collaboratorsAddingErrorReceived = false
        let collaboratorsUpdatingErrorReceived = false
        let collaboratorsRemovingErrorReceived = false
        let successfullyAddedCollaboratorsCount = 0

        if (collaboratorsForAdd.length) {
          for (let i = 0; i < collaboratorsForAdd.length; i++) {
            try {
              await publicLinksService.addCollaborator(sharedItemData, collaboratorsForAdd[i] as any)
              successfullyAddedCollaboratorsCount += 1
            } catch (error) {
              if (error?.cause?.cause?.code === 'CollaboratorsCountExceededError ') {
                collaboratorsMaxCountReached = true

                return
              }

              collaboratorsAddingErrorReceived = true
            }
          }
        }

        if (collaboratorsForRemove.length) {
          for (let i = 0; i < collaboratorsForRemove.length; i++) {
            try {
              await publicLinksService.deleteCollaborator(collaboratorsForRemove[i])
            } catch (error) {
              collaboratorsRemovingErrorReceived = true
            }
          }
        }

        if (collaboratorsForChange.length) {
          for (let i = 0; i < collaboratorsForChange.length; i++) {
            try {
              await publicLinksService.updateCollaborator(collaboratorsForChange[i], { permission: collaboratorsForChange[i].permission })
            } catch (error) {
              collaboratorsUpdatingErrorReceived = true
            }
          }
        }

        if (successfullyAddedCollaboratorsCount && successfullyAddedCollaboratorsCount === collaboratorsForAdd.length) {
          showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: t('l_notification_inviteSentToUsers', { 0: successfullyAddedCollaboratorsCount, 1: collaboratorsForAdd.length }),
          })
        }

        if (successfullyAddedCollaboratorsCount && successfullyAddedCollaboratorsCount !== collaboratorsForAdd.length) {
          showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: t('l_notification_inviteSentToUsers', { 0: successfullyAddedCollaboratorsCount, 1: collaboratorsForAdd.length }) + ' ' + t('l_notification_checkInfo'),
          })
        }

        if (collaboratorsMaxCountReached) {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_usersListLimit')
          })
        }

        if (collaboratorsAddingErrorReceived || collaboratorsUpdatingErrorReceived || collaboratorsRemovingErrorReceived) {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_common_wentWrong')
          })
        }
      }

      if (!closeAfterUpdate) {
        dispatch(actions.setSharingData(response.data))
        return
      }

      dispatch(actions.resetState())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_changesSaved')
      })
    } finally {
      dispatch(actions.setIsBusy(false))
      hideGlobalProgressLoader()
    }
  }
)

export const addCollaboratorsThunk = createAsyncThunk(
  'sharing/addCollaboratorsThunk',
  async function ({ collaborators }: { collaborators: ICollaboratorSchema[] }, { dispatch, getState }) {
    const state = getState() as RootState
    const oldCollaboratorsForAdd = state.sharing.collaboratorsForAdd

    dispatch(actions.setCollaboratorsForAdd([...oldCollaboratorsForAdd, ...collaborators]))
  }
)

export const removeCollaboratorsThunk = createAsyncThunk(
  'sharing/removeCollaboratorsThunk',
  async function ({ phone_or_email }: { phone_or_email: ICollaboratorSchema['phone_or_email'] }, { dispatch, getState }) {
    const state = (getState() as RootState).sharing
    const oldCollaboratorsForRemove = state.collaboratorsForRemove
    const collaboratorsForChange = state.collaboratorsForChange
    const collaborators = state.collaborators
    const collaboratorsForAdd = state.collaboratorsForAdd

    const collaboratorExistedInCollaborators = collaborators.find(collaborator => collaborator.phone_or_email === phone_or_email)
    const collaboratorExistedInCollaboratorsForChange = collaboratorsForChange.find(collaborator => collaborator.phone_or_email === phone_or_email)

    if (collaboratorExistedInCollaboratorsForChange) {
      const indexOfExistedCollaborator = collaboratorsForChange.indexOf(collaboratorExistedInCollaboratorsForChange)

      dispatch(actions.setCollaboratorsForChange([...collaboratorsForChange.slice(0, indexOfExistedCollaborator), ...collaboratorsForChange.slice(indexOfExistedCollaborator + 1)]))
    }

    if (collaboratorExistedInCollaborators) {
      const indexOfExistedCollaborator = collaborators.indexOf(collaboratorExistedInCollaborators)

      dispatch(actions.setCollaborators([...collaborators.slice(0, indexOfExistedCollaborator), ...collaborators.slice(indexOfExistedCollaborator + 1)]))
      dispatch(actions.setCollaboratorsForRemove([...oldCollaboratorsForRemove, collaboratorExistedInCollaborators]))

      return
    }

    const collaboratorExistedInCollaboratorsForAdd = collaboratorsForAdd.find(collaborator => collaborator.phone_or_email === phone_or_email)
    const indexOfExistedCollaborator = collaboratorsForAdd.indexOf(collaboratorExistedInCollaboratorsForAdd)

    dispatch(actions.setCollaboratorsForAdd([...collaboratorsForAdd.slice(0, indexOfExistedCollaborator), ...collaboratorsForAdd.slice(indexOfExistedCollaborator + 1)]))
  }
)

export const updateCollaboratorsThunk = createAsyncThunk(
  'sharing/updateCollaboratorsThunk',
  async function ({ phone_or_email, permission }: { phone_or_email: ICollaboratorSchema['phone_or_email'], permission: SharePermissionType }, { dispatch, getState }) {
    const state = (getState() as RootState).sharing
    const oldCollaboratorsForChange = state.collaboratorsForChange
    const collaborators = state.collaborators
    const collaboratorsForAdd = state.collaboratorsForAdd

    const collaboratorExistedInCollaborators = collaborators.find(collaborator => collaborator.phone_or_email === phone_or_email)
    const collaboratorExistedInCollaboratorsForAdd = collaboratorsForAdd.find(collaborator => collaborator.phone_or_email === phone_or_email)
    const collaboratorExistedInCollaboratorsForChange = oldCollaboratorsForChange.find(collaborator => collaborator.phone_or_email === phone_or_email)

    if (collaboratorExistedInCollaboratorsForChange) {
      const indexOfExistedCollaborator = oldCollaboratorsForChange.indexOf(collaboratorExistedInCollaboratorsForChange)

      dispatch(actions.setCollaboratorsForChange(
        [...oldCollaboratorsForChange.slice(0, indexOfExistedCollaborator),
          { ...collaboratorExistedInCollaborators, permission },
          ...oldCollaboratorsForChange.slice(indexOfExistedCollaborator + 1)
        ]))
    }

    if (collaboratorExistedInCollaborators) {
      const indexOfExistedCollaborator = collaborators.indexOf(collaboratorExistedInCollaborators)

      dispatch(actions.setCollaborators([
        ...collaborators.slice(0, indexOfExistedCollaborator),
        { ...collaboratorExistedInCollaborators, permission },
        ...collaborators.slice(indexOfExistedCollaborator + 1)
      ]))

      if (!collaboratorExistedInCollaboratorsForChange) {
        dispatch(actions.setCollaboratorsForChange([...oldCollaboratorsForChange, { ...collaboratorExistedInCollaborators, permission }]))
      }
    }

    if (collaboratorExistedInCollaboratorsForAdd) {
      const indexOfExistedCollaborator = collaboratorsForAdd.indexOf(collaboratorExistedInCollaboratorsForAdd)

      dispatch(actions.setCollaboratorsForAdd([
        ...collaboratorsForAdd.slice(0, indexOfExistedCollaborator),
        { ...collaboratorExistedInCollaboratorsForAdd, permission },
        ...collaboratorsForAdd.slice(indexOfExistedCollaborator + 1)
      ]))
    }
  }
)

export const addAvailableTypesPublicLinkThunk = createAsyncThunk(
  'sharing/addAvailableTypesPublicLinkThunk',
  async function (_, { dispatch }) {

    const userSettingsService = getPhotoSdk().userSettingsService

    const userSharingData = await userSettingsService.getValue('user_sharing')

    const accessTypes = userSharingData.data['access_types']

    if (!!accessTypes) dispatch(actions.setAvailableTypesPublicLink([...accessTypes]))
  }
)

export const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    setSharingData: (state, action) => {
      state.sharingData = action.payload
    },
    setSharedItemData: (state, action) => {
      state.sharedItemData = action.payload
    },
    setSharingAlbumConfig: (state, action: PayloadAction<Config>) => {
      state.config = action.payload
    },
    setCollaborators: (state, action) => {
      state.collaborators = action.payload
    },
    setCollaboratorsForAdd: (state, action) => {
      state.collaboratorsForAdd = action.payload
    },
    setCollaboratorsForChange: (state, action) => {
      state.collaboratorsForChange = action.payload
    },
    setCollaboratorsForRemove: (state, action) => {
      state.collaboratorsForRemove = action.payload
    },
    setAvailableTypesPublicLink: (state, action) => {
      state.availableTypesPublicLink = action.payload
    },
    resetState: (state) => {
      state.sharedItemData = null
      state.sharingData = null
      state.config = null
      state.collaborators = []
      state.collaboratorsForAdd = []
      state.collaboratorsForChange = []
      state.collaboratorsForRemove = []
    },
    setIsBusy: (state, action) => {
      state.isBusy = action.payload
    }
  }
})

const {
  reducer, actions
} = sharingSlice

export { reducer as sharingReducer, actions as sharingActions }
