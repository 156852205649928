const colorsJson = require(`../../apps/${process.env.APP_NAME}/src/theme/brandColors.json`)
const colorsJsonDark = require(`../../apps/${process.env.APP_NAME}/src/theme/brandColors-black.json`)

export const THEME_COLORS = colorsJson
export const THEME_COLORS_DARK = colorsJsonDark

export const formatImgUrl = (url) => `${process.env.NODE_ENV === 'production' ? '/static' : ''}${url}`

export const SIDEBAR_LOGO_URL_DARK = formatImgUrl('/assets/menu-logo-black.svg')
export const SIDEBAR_LOGO_URL = formatImgUrl('/assets/menu-logo.svg')
export const INVITE_PAGE_IMG_URL = formatImgUrl('/assets/login-and-join.svg')