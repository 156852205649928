import APP_CONFIG from "constants/configs/app.config"

import { analytics } from "features/common/analytics"

import { events } from './events'

analytics.init({
  enableLogs: true,
  events,
  firebaseConfig: APP_CONFIG.firebaseConfig
})