import { useCallback, useEffect, useRef,useState } from "react"

import styled from 'styled-components'
import _ from "lodash"

import { useLongPress } from "../../common/hooks"



enum VIDEO_EVENTS {
  PLAY = 'play',
  ENDED = 'ended',
  LOADEDMETADATA = 'loadedmetadata',
  CANPLAY = 'canplay',
  ERROR = 'error',
  CANPLAYTHROUGH = 'canplaythrough',
  PAUSE = 'pause',
}
export const LivePhotoPreview = ({ src, isPlaying, onPaused, onEnded, onPlay, posterUrl, onLoad, onCanPlay, onResetCanPlay, onError }) => {

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  }
  const videoRef = useRef(null)
  const handleEnded = useCallback(onEnded,[])
  const handlePaused = useCallback(onPaused,[])
  const handlePlay = useCallback(onPlay,[])
  const handleLoaded = useCallback(onLoad,[])
  const handleCanPlay = useCallback(onCanPlay,[])
  const handleResetCanPlay = useCallback(onResetCanPlay,[])
  const handleError = useCallback(onError,[])
  const [bounds, setBounds]  = useState(null)
  const [videoEl, setVideoEl] = useState<HTMLMediaElement>(null)

  const startPlayback = useCallback(() => {
    if(!_.isNil(videoEl)) {
      if(!videoEl.paused) return
      videoEl.currentTime = 0
      videoEl.play().catch(_.noop)
    }
  }, [videoEl])

  const pausePlayback = useCallback(() => {
    !_.isNil(videoEl) && videoEl.pause()
  },[videoEl])

  const onLongPress = useCallback(() => {
    startPlayback()
  },[videoEl])

  const onLongPressEnd = useCallback(() => {
    pausePlayback()
    // to show poster after the finishing of long press gestures
    !_.isNil(videoEl) && videoEl.load()
  },[videoEl])

  const longPressEvent = useLongPress(onLongPress, onLongPressEnd, _.noop, defaultOptions)

  const events = [
    { name:VIDEO_EVENTS.ENDED, callback: handleEnded },
    { name:VIDEO_EVENTS.PAUSE, callback: handlePaused },
    { name:VIDEO_EVENTS.PLAY, callback: handlePlay },
    { name:VIDEO_EVENTS.CANPLAY, callback: handleCanPlay },
    { name:VIDEO_EVENTS.ERROR, callback: handleError },
  ]

  useEffect(() => {
    return () => {
      if(_.isNil(videoEl)) return
      videoEl.pause()
      removeListeners()
      videoRef.current = null
    }
  },[])

  useEffect(() => {
    if(_.isNil(videoEl)) return
    // set 'playsinline', 'webkit-playsinline' to 'true'
    // to prevent start playing in fullscreen mode
    videoEl.setAttribute('playsinline', 'true')
    videoEl.setAttribute('webkit-playsinline', 'true')
  },[videoEl])

  useEffect(() => {
    setVideoEl(videoRef.current)
  },[videoRef.current])

  useEffect(() => {
    if(_.isNil(videoEl)) return
    let imgEl = null
    if(posterUrl) {
      imgEl = new Image()
      imgEl.src = posterUrl
      imgEl.onload = handleLoaded
    }
    handleResetCanPlay()
    handleEnded(null)
    removeListeners()
    const { width, height }  = videoEl.getBoundingClientRect()
    setInitialBounds({ width, height })
    setupListeners()
  },[src, videoEl])

  useEffect(() => {
    !_.isNil(videoEl) ? isPlaying ? startPlayback() : pausePlayback() : null
  }, [isPlaying, videoEl])

  const setInitialBounds = ({ width, height }) => {
    if(_.isNull(bounds) && !_.isNil(width) && !_.isNil(height)) {
      setBounds({ width, height })
    }
  }

  const setupListeners = () => {
    for(const { name, callback } of events) {
      videoEl.addEventListener(name, callback)
    }
  }

  const removeListeners = () => {
    for(const { name, callback } of events) {
      videoEl.removeEventListener(name, callback)
    }
  }

  const handleContextMenu = event => {
    event.preventDefault()
    event.stopPropagation()
    return false
  }

  return (
    <VideoBox
      {...longPressEvent}
      width={bounds && bounds.width}
      height={bounds && bounds.height}
      poster={posterUrl}
      preload="auto"
      src={src}
      ref={videoRef}
      webkit-playsinline="true"
      playsinline="true"
      onContextMenu={handleContextMenu}
    />
  )
}

const VideoBox = styled.video`
  width: auto;
  height: auto;
  display: block;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  user-select: none;
`
