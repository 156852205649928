export enum DOCUMENT_TYPES {
  PASSPORT = 'passport',
  DRIVERS_LICENSE = 'drivers_license',
  BIRTH_CERTIFICATE = 'birth_certificate',
  SNILS = 'SNILS',
  INTERNATIONAL_PASSPORT = 'international_passport',
  INSURANCE_POLICY = 'insurance_policy',
  RECEIPTS = 'receipts',
  DOCUMENT_COPIES = 'documents_copies',
  ID_CARD = 'id_card',
  DIPLOMA = 'diploma',
  MARRIAGE_CERTIFICATE = 'marriage_certificate',
  MILITARY_ID = 'military_id',
  INN = 'INN',
  DOCUMENT = 'document',
}

export enum DOCUMENTS_WALLET_STATUSES {
  FIRST_LOADING,
  CREATION,
  CREATION_IN_PROGRESS,
  DELETING_IN_PROGESS,
  SIGN_IN,
  DOCUMENT_TYPES_LOADING,
  DOCUMENT_TYPES_LOADED
}

export enum DOCUMENT_ITEMS_ACTION_STATUSES {
  IDLE,
  UPLOADING,
  DOWNLOADING,
  REMOVING,
  LOADING_TYPE,
  RENAMING,
  CREATING,
}

export interface UserDocumentsWalletSettings {
  documents_count: number,
  documents_size: number,
  documents_updated: string,
  is_enabled: boolean,
  salt: string,
  _links: {
    upload_document: {
      href: string,
      templated: boolean
    }
  }
}

export interface DocumentType {
  created: string,
  documents_count: number,
  id: string,
  name: string,
  preview_id: DOCUMENT_TYPES,
  person_id: string,
  updated: string,
  selected: boolean,
}

export interface DocumentItem {
  checksum: string,
  client_data: any,
  created: string,
  encrypted_size: number,
  id: string,
  iv: string,
  key: string,
  key_alg: string,
  source_size: number
  updated: string,
  _links: Record<'content' | 'self' | 'middle' | 'preview' | 'small', {
    href: string
  }>
}

interface MetaData {
  name?: string,
  size?: number,
  type?: string,
  lastModified?: string
}

export interface EnhancedDocumentItem extends DocumentItem {
  previewUrl?: string,
  metaData?: MetaData,
  type: 'image' | 'pdf'
}

export interface DocumentTypeWithPreview extends DocumentType {
  previewUrl?: string
}

export interface PreviewSize {
  crop: boolean,
  height: number,
  size_name: 'small' | 'middle' | 'preview',
  upsize: boolean,
  width: number
}

export interface EnhancedPersonItem extends DocumentItem {
  name: string,
  color_id: string,
  isSelect: boolean
}
