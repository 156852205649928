import { ACCEPTED_FILES_TYPES_FOR_DOCUMENTS } from 'constants/acceptedFileTypes'

import { useState } from 'react'

import { MenuWithActionItems, PrimaryButton, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { useNavigate, useParams } from 'react-router-dom'
import { appActions } from 'store/app'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'

import { TypeModal } from "../../pages/Documents"
import { getModalsRootElement } from "../../constants/modals"

import { DOCUMENTS_WALLET_STATUSES } from './types'
import {
  getDocumentSelectedItemsIdsSelector,
  getDocumentSelectedItemsSelector,
  getDocumentsWalletLoadingStatusSelector,
  getDocumentsWalletTypesSelector,
  getShowCreateModalSelector
} from './selectors'
import {
  deleteDocumentItems,
  documentsActions,
  downloadSelectedDocumentItems,
  removeDocumentTypeThunk,
  renameDocumentTypeThunk,
  uploadDocumentWalletFilesThunk
} from './documentsSlice'
import { CreateDocumentModal } from "./CreateDocumentModal"

interface DocumentTypesToolbarProps {
    className?: string,
}

export const DocumentAlbumToolbar: React.FC<DocumentTypesToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id?: string }>()
  const navigate = useNavigate()

  const status = getDocumentsWalletLoadingStatusSelector()
  const selectedItems = getDocumentSelectedItemsSelector()
  const selectedItemsIds = getDocumentSelectedItemsIdsSelector()
  const selectedItemsCount = selectedItemsIds.length
  const isShowCreateDocumentModal = getShowCreateModalSelector()
  const types = getDocumentsWalletTypesSelector()

  const type = types.find(t => t.id === id)

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleDeleteSelectedItems = () => {
    dispatch(deleteDocumentItems({ itemsIds: selectedItemsIds }))
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const callbackDeleteDocument = () => {
    navigate('/documents')
  }

  const handleDeleteDocument = () => {
    dispatch(removeDocumentTypeThunk({ items: [{ id }], callback: callbackDeleteDocument }))
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleUploadPhotos = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      dispatch(uploadDocumentWalletFilesThunk({ files, id, callback }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const handleDownloadSelectedItems = () => {
    dispatch(downloadSelectedDocumentItems({ items: selectedItems }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameDocument = () => {
    dispatch(documentsActions.setTypeModal(TypeModal.RENAME))
    dispatch(documentsActions.setShowCreateModal(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    return [
      !!selectedItemsCount && {
        label: t('a_dw_deleteDocuments', { number: selectedItemsCount }),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      !selectedItemsCount && !!type && type.preview_id === 'document' && {
        label: t('a_common_rename'),
        iconName: 'edit',
        onClickItem: handleRenameDocument
      },
      !selectedItemsCount && {
        label: t('a_common_delete'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    return [
      !selectedItemsCount && {
        label: t('a_common_upload'),
        iconName: 'download2',
        onClickItem: handleUploadPhotos,
        type: 'file',
        accept: ACCEPTED_FILES_TYPES_FOR_DOCUMENTS.join(',')
      },
      selectedItemsCount === 1 && {
        label: t('a_common_download'),
        iconName: 'download',
        onClickItem: handleDownloadSelectedItems
      },
      !!selectedItemsCount && {
        label: t('a_dw_deleteDocuments', { number: selectedItemsCount }),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      !selectedItemsCount && !!type && type.preview_id === 'document' && {
        label: t('a_common_rename'),
        iconName: 'edit',
        onClickItem: handleRenameDocument
      },
      !selectedItemsCount && {
        label: t('a_common_delete'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)
  })()

  const handleCloseCreateDocumentModal = () => {
    dispatch(documentsActions.setShowCreateModal(false))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleSubmitRenameDocumentModal = (name: string) => {
    dispatch(renameDocumentTypeThunk({ id, name }))
    dispatch(documentsActions.setShowCreateModal(false))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  if (status !== DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED || id === 'persons') {
    return null
  }

  return (
    <>
      <DocumentAlbumToolbarBox className={className}>
        {!selectedItemsCount && (
          <InputPrimaryButton
            actionName={t('a_common_upload')}
            htmlFor="upload-btn"
          >
            {t('a_common_upload')}

            <HiddenFilesInput
              id="upload-btn"
              onChange={handleUploadPhotos}
              type="file"
              multiple
              accept={ACCEPTED_FILES_TYPES_FOR_DOCUMENTS.join(',')}
            />
          </InputPrimaryButton>
        )}

        {
          selectedItemsCount === 1 && (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onAction={handleDownloadSelectedItems}
            >
              {t('a_common_download')}
            </StyledPrimaryButton>
          )
        }

        {!!menuItems.length &&
                    <MenuWithActionItems menuItems={menuItems} />
        }

        <DialogModal
          isOpened={removingConfirmationModalOpened}
          title={selectedItems.length === 0
            ? t('l_dw_deleteDocsFolder', { number: 1, name: type.name })
            : t('l_common_headerRemovePhotos', { number: selectedItems.length })}
          onClose={handleCloseRemovingConfirmationModal}
          okText={t('a_common_ok')}
          onOk={selectedItems.length === 0 ? handleDeleteDocument : handleDeleteSelectedItems}
          cancelText={t('a_common_cancel')}
          onCancel={handleCloseRemovingConfirmationModal}
        >
          <TextInModalBox>
            {selectedItems.length === 0
              ? t('l_dw_deleteDocsFolderMessage', { number: 1, name: type.name })
              : t('l_common_areYouSure')}
          </TextInModalBox>
        </DialogModal>

        <CreateDocumentModal
          isShown={isShowCreateDocumentModal}
          parentBlock={getModalsRootElement()}
          onCloseModal={handleCloseCreateDocumentModal}
          onSubmitModal={handleSubmitRenameDocumentModal}
          renamingName={type.name}
          texts={{
            TITLE: t('a_common_rename'),
            PLACEHOLDER: '',
            NAME_BUTTON_CANCEL: t('a_common_cancel'),
            NAME_BUTTON_ACTION: t('a_common_save'),
            SUB_TITLE: t('l_common_name'),
          }}
        />
      </DocumentAlbumToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={null}
      />
    </>
  )
}

const DocumentAlbumToolbarBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const TextInModalBox = styled.span`
  max-width: 328px;
  font-size: 14px;
  color: var(--text-primary)
`

const InputPrimaryButton = styled.label`
  background: var(--button-primary-default);
  border: 0;
  border-radius: 4px;
  color: var(--brand-text);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`

const HiddenFilesInput = styled.input`
  display: none;
`
