import { getModalsRootElement } from 'constants/modals'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useEffect, useRef, useState } from 'react'

import * as yup from 'yup'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon
} from '@cloudike/web_ui_components'
import React, { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { Input } from 'ui/Input'

import avatar from "../../../assets/icons/avatar.svg"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"

import {
  getColorPersonSelector,
  getNamePersonSelector,
  getOpenedPersonalSettingModalSelector,
  getPersonIdSelector,
  getStepAddPersonModalSelector,
  getTypeModalSelector
} from "./selectors"
import {
  ADD_PERSON_MODAL_STEPS,
  ADD_PERSON_MODAL_TYPE,
  createPersonThunk,
  deletePersonThunk,
  patchPersonThunk,
  personsActions
} from "./personsSlice"
import { getFirstLetter } from "./PersonsCard"
import { SubmitDeletePersonModal } from "./SubmitDeletePersonModal"

export const AddPersonModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const opened = getOpenedPersonalSettingModalSelector()
  const step = getStepAddPersonModalSelector()

  const inputRef = useRef<any>(null)
  const color = getColorPersonSelector()
  const name = getNamePersonSelector()
  const personId = getPersonIdSelector()
  const typeModal = getTypeModalSelector()

  const [isVisibleDeletePersonSubmitModal, setVisibleDeletePersonSubmitModal] = useState(false)

  const colors = ['#6A6D70', '#EA3333', '#C0A313', '#2D9C26', '#1FA6EC', '#A735E5', '#E86405', '#4B65F2']
  const [pickColor, setPickColor] = useState(colors[0])
  const [value, setValue] = useState(name)

  useEffect(() => {
    if (!opened) {
      return
    }

    setPickColor(color ? color : colors[0])
    setValue(name)

    return () => {
      dispatch(personsActions.setDefaultPersonParams())
      setValue('')
      setPickColor(colors[0])
    }

  }, [opened])

  const handleCloseAndReset = () => {
    dispatch(personsActions.togglePersonalSettingModalOpened(false))
  }

  const handleSubmit = (values: any, { resetForm }) => {
    typeModal === ADD_PERSON_MODAL_TYPE.ADD_PERSON
      ? dispatch(createPersonThunk({ name: value, colorId: pickColor }))
      : dispatch(patchPersonThunk({ name: value, colorId: pickColor, personId }))

    resetForm()
  }

  const validation = yup.object().shape({
    person: yup
      .string()
      .max(20)
  })

  const handleClickEditColor = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PERSON_ADD_COLOR_CLICK)

    dispatch(personsActions.toggleStep(ADD_PERSON_MODAL_STEPS.EDIT_COLOR))
  }

  const handleBack = () => {
    dispatch(personsActions.toggleStep(ADD_PERSON_MODAL_STEPS.START))
  }

  const handleDeletePerson = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PERSON_DELETE_CLICK)

    setVisibleDeletePersonSubmitModal(true)
  }

  const handleCloseDeletePersonModal = () => {
    setVisibleDeletePersonSubmitModal(false)
  }

  const handleSubmitDeletePersonModal = async () => {
    await dispatch(deletePersonThunk({ personId }))
    setVisibleDeletePersonSubmitModal(false)
  }

  const textSubmitDeletePersonModal = {
    TITLE: t('a_dw_deleteProfile'),
    CLOSE: t('a_common_cancel'),
    SUBMIT: t('a_common_delete'),
    CONTENT: t('l_dw_deleteProfileMessage', { name: value }),
  }

  const handleKeyUp = handleSubmit => (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleInputRef = (ref: HTMLInputElement) => {
    inputRef.current = ref
  }

  return (
    <Formik
      initialValues={{ person: value }}
      onSubmit={handleSubmit}
      validationSchema={validation}
      validateOnChange
      validateOnMount
    >
      {({ errors, handleChange, handleSubmit }) => (
        <Form>
          <ModalDialog
            header={
              (
                <>
                  {typeModal === ADD_PERSON_MODAL_TYPE.EDIT_PERSON && step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                    <ModalHeader
                      title={t('l_dw_editProfile')}
                    />
                  )}

                  {typeModal === ADD_PERSON_MODAL_TYPE.ADD_PERSON && step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                    <ModalHeader
                      title={t('l_dw_newProfile')}
                    />
                  )}

                  {step === ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                    <ModalHeaderBox>
                      <ButtonArrowBackBox>
                        <StyledBackIcon
                          iconName={'arrow_back'}
                          onClick={handleBack}
                        />
                      </ButtonArrowBackBox>

                      <TitleBox>
                        {t('l_dw_editColor')}
                      </TitleBox>
                    </ModalHeaderBox>
                  )}
                </>
              )
            }
            footer={
              (
                <>
                  {typeModal === ADD_PERSON_MODAL_TYPE.EDIT_PERSON && step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                    <FooterBox>
                      <LeftBox>
                        <SecondaryButtonDeletePerson
                          key={'buttonDelete'}
                          actionName={t('a_dw_deleteProfile')}
                          onAction={handleDeletePerson}
                        />
                      </LeftBox>

                      <RightBox>
                        <SecondaryButtonCancel
                          key={'buttonCancel'}
                          actionName={t('a_common_cancel')}
                          onAction={handleCloseAndReset}
                          className={'asd'}
                        />

                        <StyledPrimaryButtonSaveEdit
                          key={'buttonAction'}
                          actionName={t('a_common_save')}
                          onAction={handleSubmit}
                          disabled={!value}
                        />
                      </RightBox>
                    </FooterBox>
                  )}

                  {typeModal === ADD_PERSON_MODAL_TYPE.ADD_PERSON && step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                    <ModalFooter
                      buttonList={[
                        (
                          <SecondaryButton
                            key={'buttonCancel'}
                            actionName={t('a_common_cancel')}
                            onAction={handleCloseAndReset}
                          />
                        ),
                        <StyledPrimaryButton
                          key={'buttonAction'}
                          actionName={t('a_common_create')}
                          onAction={handleSubmit}
                          disabled={!value}
                        />
                      ]}
                    />
                  )}

                  {step === ADD_PERSON_MODAL_STEPS.EDIT_COLOR && <></>}

                </>
              )}

            parentBlock={getModalsRootElement()}
            isOpened={opened}
            onCloseModal={handleCloseAndReset}
          >
            <ContentBox alignItems={step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR ? 'unset' : 'center'}>
              {
                (
                  <>
                    <PersonBox>
                      <AvatarBox
                        backgroundColor={pickColor}
                      >
                        {value ? (
                          <AvatarTextBox>
                            {getFirstLetter(value)}
                          </AvatarTextBox>
                        ) :
                          <AvatarImage src={avatar} />}
                      </AvatarBox>

                      {step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR ? (
                        <EditColorBox color={value ? 'var(--accent-normal)' : '#BAC7D5'}
                          cursor={value ? 'pointer' : 'unset'}
                          onClick={() => {
                            value && handleClickEditColor()
                          }}
                        >
                          {t('l_dw_editColor')}
                        </EditColorBox>
                      ) : (
                        <NameBox>
                          {value}
                        </NameBox>
                      )}
                    </PersonBox>


                    {step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                      <InputLabel>
                        {t('l_common_name')}
                      </InputLabel>
                    )}

                    {step !== ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                      <InputBox>
                        <StyledInput
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value)
                            handleChange(e)
                          }
                          }
                          name="person"
                          inputRef={handleInputRef}
                          maxLength="20"
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.style.borderColor = 'var(--accent-normal)'
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = ''
                          }}
                          onKeyUp={handleKeyUp(handleSubmit)}
                          autoFocus
                        />

                        {!!errors.code && <WarningIcon iconName="warning" />}
                      </InputBox>
                    )}

                    {step === ADD_PERSON_MODAL_STEPS.EDIT_COLOR && (
                      <ColorsBox>
                        {colors.map(item => {
                          return (
                            <AvatarSmallBox
                              backgroundColor={item}
                              key={item}
                              onClick={() => {
                                setPickColor(item)
                              }}
                            >
                              <AvatarTextSmallBox>
                                {getFirstLetter(value)}
                              </AvatarTextSmallBox>
                            </AvatarSmallBox>
                          )
                        })}
                      </ColorsBox>

                    )}
                  </>
                )
              }
            </ContentBox>
          </ModalDialog>

          <SubmitDeletePersonModal
            handleCloseModal={handleCloseDeletePersonModal}
            handleSubmitModal={handleSubmitDeletePersonModal}
            parentBlock={getModalsRootElement()}
            isDialogVisible={isVisibleDeletePersonSubmitModal}
            textsModalSubmit={textSubmitDeletePersonModal}
          />

        </Form>
      )}
    </Formik>
  )
}

const ButtonArrowBackBox = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: var(--background-secondary);
  width: 40px;
  height: 40px;
`

const ContentBox = styled.div`
  max-width: 452px;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: calc(100vw - 80px);
  }
`

const InputLabel = styled.label`
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: var(--text-primary);
`

const InputBox = styled.div`
  margin-top: 5px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const WarningIcon = styled(SpriteIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: #DADADA;
`

const StyledInput = styled(Input)`
  &.phone {
    padding-left: 30px;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 8px;
`

const AvatarTextBox = styled.label`
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
`

const AvatarBox = styled.div`
  width: 138px;
  height: 138px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  margin-bottom: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`

const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
`

const EditColorBox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.color};
  cursor: ${props => props.cursor};
`

const PersonBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
`

const ModalHeaderBox = styled.div`
  display: flex;
  height: 40px;
  margin: 22px 24px 18px;
  align-items: center;
`

const StyledBackIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: var(--icon-secondary);
`

const TitleBox = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const NameBox = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin: 16px auto 40px;
`

const ColorsBox = styled.div`
  width: 288px;
  height: 136px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 40px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    grid-column-gap: 29px;
    grid-row-gap: 29px;
    width: 327px;
    height: 149px;
    margin-bottom: 64px;
  }
`

const AvatarTextSmallBox = styled.label`
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`

const AvatarSmallBox = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  -webkit-tap-highlight-color: var(--divider-primary);
`

const FooterBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 14px 24px;
  border-top: solid 1px var(--divider-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    flex-direction: unset;
    justify-content: space-between;
  }
`

const LeftBox = styled.div``

const RightBox = styled.div`
  display: flex;
`

const SecondaryButtonDeletePerson = styled(SecondaryButton)`
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    -webkit-tap-highlight-color: var(--divider-primary);
    margin-top: 14px;
    margin-right: 0 !important;
    min-width: 295px;
    width: 100%;
  }
`

const SecondaryButtonCancel = styled(SecondaryButton)`
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    -webkit-tap-highlight-color: var(--divider-primary);
    width: 100%;
  }
`

const StyledPrimaryButtonSaveEdit = styled(StyledPrimaryButton)`
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    -webkit-tap-highlight-color: var(--divider-primary);
    width: 100%;
  }
`
