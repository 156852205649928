import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { DATES_FORMATS } from 'constants/datesFormats'

import React, { useEffect } from 'react'

import { fetchSubscriptionsThunk } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserSubscriptionsSelector } from 'features/user/selectors'
import { formatDate } from 'utils/formatDate'
import classNames from 'classnames'

import { ProfileTable, ProfileTableText, ProfileTBody, ProfileTd, ProfileTHead, ProfileTHeadTh, ProfileTHeadTr, ProfileTr, ProfileTableBox } from './ProfileStyledComponents'

export const ProfileSubscriptions = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { items: subscriptions, status } = getUserSubscriptionsSelector()

  useEffect(() => {
    dispatch(fetchSubscriptionsThunk())
  }, [])

  return (
    <ProfileSubscriptionsBox className={className}>
      <Title>
        {t('l_settings_subscriptionsTitle')}
      </Title>

      <ProfileTableBox>
        <ProfileTable>
          <Colgroup>
            <Col className="description" />

            <Col className="date" />

            <Col className="date" />

            <Col className="date" />

            <Col className="action" />
          </Colgroup>

          <ProfileTHead>
            <ProfileTHeadTr>
              <ProfileTHeadTh>
                {t('l_common_description')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_settings_created')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_common_expired')}
              </ProfileTHeadTh>

              <ProfileTHeadTh>
                {t('l_common_stopped')}
              </ProfileTHeadTh>

              <ProfileTHeadTh />
            </ProfileTHeadTr>
          </ProfileTHead>

          {status === LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="5">
                  <ProfileTableText>
                    {t('l_common_loading')}
                    ...
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!subscriptions.length && status !== LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="5">
                  <ProfileTableText>
                    {t('l_common_noOrders')}
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!!subscriptions.length && (
            <ProfileTBody>
              {subscriptions.map(subscription => (
                <ProfileTr key={subscription.name}>
                  <ProfileTd>
                    <ProfileTableText className="ellipsis">
                      {subscription.name}
                    </ProfileTableText>
                  </ProfileTd>

                  <ProfileTd>
                    {formatDate(subscription.created, DATES_FORMATS.profileTables)}
                  </ProfileTd>

                  <ProfileTd>
                    {!!subscription.expired && formatDate(subscription.expired, DATES_FORMATS.profileTables)}
                  </ProfileTd>

                  <ProfileTd>
                    {!!subscription.stopped && formatDate(subscription.stopped, DATES_FORMATS.profileTables)}
                  </ProfileTd>

                  <ProfileTd>
                    <ProfileTableText className="align-right">
                      <Status className={classNames({
                        success: subscription.status === 'running',
                        info: subscription.status === 'completed',
                        error: subscription.status === 'canceled',
                        hold: subscription.status === 'hold',
                        grace: subscription.status === 'grace',
                      })}
                      >
                        {subscription.status === 'canceled' && t('l_common_profileTxtCanceled')}

                        {subscription.status === 'completed' && t('l_common_profileTxtCompleted')}

                        {subscription.status === 'running' && t('l_common_profileTxtRunning')}

                        {subscription.status === 'grace' && t('l_common_profileTxtGrace')}

                        {subscription.status === 'hold' && t('l_common_profileTxtHold')}
                      </Status>
                    </ProfileTableText>
                  </ProfileTd>
                </ProfileTr>
              ))}
            </ProfileTBody>
          )}

        </ProfileTable>
      </ProfileTableBox>
    </ProfileSubscriptionsBox>
  )
}

const ProfileSubscriptionsBox = styled.div`

`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const Status = styled.span`
  height: 20px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--simple-dark);

  &.success {
    background: #B3E4A8;
  }

  &.info {
    background: #CCE8FE;
  }

  &.error {
    background: #FFC9C0;
  }

  &.hold {
    background: #F9E894;
  }

  &.grace {
    background: #D6D6D6;
  }
`

const Colgroup = styled.colgroup``

const Col = styled.col`
  &.description {
    min-width: 180px;
    width: 60%;
  }

  &.date {
    width: 145px;
  }

  &.action {
    width: 175px;
  }
`
