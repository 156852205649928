import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { publicLinksSelectors } from "./publicLinksSlice"

export const getPublicLinksLoadingStatusSelector = () => useAppSelector(state => state.publicLinks.status)

export const getPublicLinksItemsRawSelector = state => publicLinksSelectors.selectAll(state.publicLinks)

export const getPublicLinksItemsSelector = () => useAppSelector(getPublicLinksItemsRawSelector)

export const getPublicLinksSelectedItemsIdsRawSelector = state =>
  state.publicLinks.selectedItemsIds

export const getPublicLinksSelectedItemsIdsSelector = () => useAppSelector(getPublicLinksSelectedItemsIdsRawSelector)

export const getPublicLinksSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getPublicLinksItemsRawSelector,
    getPublicLinksSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) =>
      items.filter(item => selectedItemsIds.includes(item.id))
  )
)

const getPublicLinksTotalItemsCountRawSelector  = state => state.publicLinks.totalItemsCount

export const getPublicLinksTotalItemsCountSelector = () => useAppSelector(getPublicLinksTotalItemsCountRawSelector)

export const getPublicLinksAlbumsCountSelector = () => useAppSelector(state => state.publicLinks.albumsCount)

export const getPublicLinksFamilyAlbumsCountSelector = () => useAppSelector(state => state.publicLinks.familyAlbumsCount)

export const getPublicLinksFilesCountSelector = () => useAppSelector(state => state.publicLinks.filesCount)
