import { createApi } from '@reduxjs/toolkit/query/react'

import api from "../utils/api"

export const clients = createApi({
  reducerPath: 'clients',
  baseQuery: api.baseQueryConfig,
  endpoints: (builder) => ({
    clients: builder.query<any, string | void>({
      query: () => ({
        url: `/api/2/soft/clients`,
      }
      ),
    }),
  }),
})

export const { useClientsQuery } = clients
