import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'
import { useTranslation } from "react-i18next"

import { getAlbumsSdkByType,initAlbumItemsPaginator } from "../../../sdk/albums"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"
import { PHOTOS_COUNT_PER_PAGE } from "../../../constants/pagination"

export enum PREVIEW_TYPES {
  TIMELINE = 'TIMELINE',
}

interface State {
  type: PREVIEW_TYPES,
  sdkType: SDK_TYPES,
  currentItemIndex: number,
  isModalOpened: boolean,
  loading: boolean,
  loadingMore: boolean,
  totalItemsCount: number,
  hasError: boolean,
  albumName: string,
  isViewed: boolean,
  indexCurrentAlbum: number
}

interface PreviewOpenActionType {
  items?: IItemSchema[],
  currentItemId?: string,
  type: PREVIEW_TYPES,
  sdkType: SDK_TYPES,
  loadMore?: () => void,
  totalItemsCount?: number,
  isViewed: boolean,
  albumName: string,
  albumId?: string,
}

const adapter = createEntityAdapter<IItemSchema>()

const showTryAgainNotification = () => {
  const { t } = useTranslation()
  showNotification({
    type: NOTIFICATION_TYPES.WARNING,
    title: t('l_notification_somethingWrongTryAgain')
  })
}

export const openPhotoPreviewFlashbackThunk = createAsyncThunk(
  'photoPreview/openPhotoPreviewFlashbackThunk',
  async function({ type, sdkType,  albumName, isViewed, albumId } : PreviewOpenActionType, { dispatch }) {
    const albumsSdk = getAlbumsSdkByType(sdkType)

    try {
      const paginator = initAlbumItemsPaginator(albumId, SDK_TYPES.DEFAULT, PHOTOS_COUNT_PER_PAGE,  { total_count: true })
      const rsp = await paginator.next()
      if(rsp.data._embedded.items.length === 0 ) {
        showTryAgainNotification()
      }
      dispatch(actions.openPreview({
        items: rsp.data._embedded.items,
        currentItemId: rsp.data._embedded.items[0].id,
        type,
        sdkType,
        totalItemsCount: rsp.data._embedded.items.length,
        albumName,
        isViewed }))
      if(!isViewed) await albumsSdk.updateAlbum(albumId, { is_viewed: true })
    } catch (error) {
      showTryAgainNotification()
      dispatch(actions.resetState())
    }
  }
)

export const handleNextPhotoFlashbackThunk = createAsyncThunk(
  'photoPreview/handleNextPhotoFlashbackThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.photoPreviewFlashback.currentItemIndex
    const totalItemsCount = state.photoPreviewFlashback.totalItemsCount

    if(currentIndex < totalItemsCount - 1) {
      dispatch(actions.setError(false))
      dispatch(actions.setCurrentItemIndex(currentIndex + 1))
      dispatch(actions.setLoading(true))
    }
  }
)

export const handlePrevPhotoFlashbackThunk = createAsyncThunk(
  'photoPreview/handlePrevPhotoFlashbackThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.photoPreviewFlashback.currentItemIndex

    if(currentIndex > 0) {
      dispatch(actions.setError(false))
      dispatch(actions.setCurrentItemIndex(currentIndex - 1))
      dispatch(actions.setLoading(true))
    }
  }
)

export const photoPreviewFlashbackSelectors = adapter.getSelectors()

export const photoPreviewFlashbackSlice = createSlice({
  name: 'photoPreviewFlashback',
  initialState: adapter.getInitialState<State>({
    type: PREVIEW_TYPES.TIMELINE,
    sdkType: SDK_TYPES.DEFAULT,
    currentItemIndex: 0,
    isModalOpened: false,
    loading: true,
    totalItemsCount: 0,
    loadingMore: false,
    hasError: false,
    albumName: '',
    isViewed: false,
    indexCurrentAlbum: 0,
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    setTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload
    },
    setCurrentItemIndexById: (state, action: PayloadAction<string>) => {
      const ids = adapter.getSelectors().selectIds(state)
      const indexOfItem = ids.indexOf(action.payload)

      state.currentItemIndex = indexOfItem
    },
    setCurrentItemIndex: (state, action) => {
      state.currentItemIndex = action.payload
    },
    setIndexCurrentAlbum: (state, action) => {
      state.indexCurrentAlbum = action.payload
    },
    increaseCurrentItemIndex: (state, action) => {
      state.currentItemIndex = state.currentItemIndex + action.payload
    },
    toggleModal: (state, action) => {
      state.isModalOpened = action.payload
    },
    openPreview: (state, action: PayloadAction<PreviewOpenActionType>) => {
      adapter.setAll(state, action.payload.items)
      state.sdkType = action.payload.sdkType
      state.type = action.payload.type
      state.totalItemsCount = action.payload.totalItemsCount
      state.albumName = action.payload.albumName
      state.isViewed = action.payload.isViewed

      const ids = action.payload.items.map(item => item.id)

      state.currentItemIndex = ids.indexOf(action.payload.currentItemId)
      state.isModalOpened = true
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setLoadingMore: (state, action: PayloadAction<boolean>) => {
      state.loadingMore = action.payload
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload
    },
    resetState: (state) => {
      adapter.removeAll(state)
      state.currentItemIndex = 0
      state.isModalOpened = false
      state.loading = true
      state.loadingMore = false
      state.hasError = false
      state.albumName = ''
      state.indexCurrentAlbum = 0
    }
  }
})

const {
  reducer, actions
} = photoPreviewFlashbackSlice

export { reducer as photoPreviewFlashbackReducer, actions as photoPreviewFlashbackActions }
