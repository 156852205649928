import React, { useCallback, useEffect, useRef, useState } from "react"

import styled, { css } from 'styled-components'
import { useAppDispatch } from  "store"

import arrow from "../../assets/icons/arrow.svg"
import { STYLED_VARIABLES } from "../../constants/styledVariables"
import { useMobileDetection } from "../common/hooks"
import { SDK_TYPES } from "../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import { getPreviewAlbumLink } from "../../utils/getPreviewAlbumLink"

import { MemoryCard } from "./MemoryCard"
import { PREVIEW_TYPES, openPhotoPreviewFlashbackThunk, photoPreviewFlashbackActions } from "./photo-preview-flashback/photoPreviewFlashbackSlice"
import { getFlashbackAlbumsSelector } from "./timeline/selectors"

interface IMemoriesComponent {
  smartAlbums: any
}

export const MemoriesComponent = ({ smartAlbums }: IMemoriesComponent) => {
  const isMobile = useMobileDetection()
  const [currentWidth, setCurrentWidth] = useState(document.getElementsByTagName('main')[0].offsetWidth - 80)

  const refCardBox = useRef<any>()
  const refArrowLeft = useRef<any>()
  const refArrowRight = useRef<any>()

  const maxAlbums = smartAlbums.length
  const widthCard = 125

  const dispatch = useAppDispatch()

  const calcMaxFlashback = () => {
    return isMobile ? maxAlbums : Math.floor(currentWidth / widthCard)
  }

  const [limitIndexes, setLimitIndexes] = useState({ min: 0, max: calcMaxFlashback() })


  useEffect(() => {
    const handleResize = () => {
      setCurrentWidth(document.getElementsByTagName('main')[0].offsetWidth - 80)
      setLimitIndexes({ min: 0, max: calcMaxFlashback() })
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [currentWidth])

  const albums = getFlashbackAlbumsSelector()

  const handleCardClick = (item: any) => {
    analytics.push(ANALYTICS_EVENTS.WEB_FLASHBACK_VIEW)

    albums.forEach((element, index) => {
      if (element.id === item.id) dispatch(photoPreviewFlashbackActions.setIndexCurrentAlbum(index))
    })
    handleOpenPreview(item.description, !!item.viewed, item.id)
  }

  const handleOpenPreview = useCallback((albumName, isViewed, albumId) => {
    dispatch(openPhotoPreviewFlashbackThunk({
      sdkType: SDK_TYPES.DEFAULT,
      type: PREVIEW_TYPES.TIMELINE,
      albumName,
      isViewed,
      albumId,
    }))
  }, [])

  const handleClickRight = () => {
    if (limitIndexes.max < maxAlbums) {
      setLimitIndexes(prev => {
        return { max: prev.max + 1, min: prev.min + 1 }
      })
    }
  }

  const handleClickLeft = () => {
    if (limitIndexes.min > 0) {
      setLimitIndexes(prev => {
        return { max: prev.max - 1, min: prev.min - 1 }
      })
    }
  }

  const calcPositionRightArrow = () => {
    return Math.floor(currentWidth / widthCard) * widthCard - 30
  }

  useEffect(() => {
    const memories = document.getElementById('memories')

    if (!memories) return

    const handleMouseOver = () => {
      if (refArrowRight.current) {
        refArrowRight.current.style.opacity = '1'
      }
      if (refArrowLeft.current) {
        refArrowLeft.current.style.opacity = '1'
      }
    }

    const handleMouseOut = () => {
      if (refArrowRight.current) {
        refArrowRight.current.style.opacity = '0'
      }
      if (refArrowLeft.current) {
        refArrowLeft.current.style.opacity = '0'
      }
    }

    memories.addEventListener('mouseover', handleMouseOver)

    memories.addEventListener('mouseout', handleMouseOut)

    return () => {
      memories.removeEventListener('mouseover', handleMouseOver)

      memories.removeEventListener('mouseout', handleMouseOut)
    }
  }, [])

  return (
    (smartAlbums.length !== 0 && (
      <MemoriesBox
        id={'memories'}
      >
        {limitIndexes.max < maxAlbums && !isMobile && (
          <ImgArrowRight
            ref={refArrowRight}
            alt={'arrow_right'}
            src={arrow}
            onClick={handleClickRight}
            left={calcPositionRightArrow()}
          />
        )}

        {limitIndexes.min > 0 && !isMobile && (
          <ImgArrowLeft
            ref={refArrowLeft}
            alt={'arrow_left'}
            src={arrow}
            onClick={handleClickLeft}
          />
        )}

        <CardBox
          ref={refCardBox}
        >
          {
            smartAlbums.slice(limitIndexes.min, limitIndexes.max).map(album => (
              <MemoizedMemoryCard
                key={album.id}
                id={album.id}
                description={album.description}
                imgUrl={getPreviewAlbumLink(album, 'album')}
                onClick={() => handleCardClick(album)}
                isViewed={!!album.viewed}
              />
            ))
          }
        </CardBox>
      </MemoriesBox>
    ))

  )
}

const MemoizedMemoryCard = React.memo(MemoryCard)

const arrowStyle = css`
  height: 32px;
  width: 32px;
  position: absolute;
  top: calc(50% - 22px);
  cursor: pointer;
  -webkit-tap-highlight-color:, 0, 0 var(--divider-primary));
  opacity: 0;
`

const ImgArrowLeft = styled.img`
  left: -16px;
  z-index: 5;
  transform: rotate(180deg);
  ${arrowStyle}
`

const ImgArrowRight = styled.img`
  left: ${props => props.left}px;
  z-index: 5;
  cursor: pointer;
  ${arrowStyle}
`

const CardBox = styled.div`
  padding: 2px;
  overflow: auto;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  -webkit-tap-highlight-color: var(--divider-primary));

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 2px 12px;
  }
`

const MemoriesBox = styled.div`
  display: block;
  height: 170px;
  background-color: var(--background-primary);
  margin-top: 14px;
  position: relative;
`
