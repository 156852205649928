import { useEffect } from "react"

import _ from "lodash"

import { getStandType, initApiSwitcher } from "../api-switcher/apiSwitcherService"
import * as additionalConfigService from "../configs/additionalConfigService"
import { CONFIG_TYPES } from "../configs/additionalConfigService"
import { redirectToExternalSSO } from "../../../utils/externalSSO"

export interface IUseApiSwitcherOptions {
    standType?: string
    onSuccess?: () => void,
    onError?: () => void
}
export const useApiSwitcher = ({ onSuccess = _.noop, onError = _.noop }:IUseApiSwitcherOptions) => {
  useEffect(() => {
    const standType = getStandType() as CONFIG_TYPES

    additionalConfigService
      .initConfig(standType as CONFIG_TYPES)
      .then(onInitSuccess)
      .catch(onInitError)

    function onInitSuccess(additionalConfigObj: Record<string,any>){
      onSuccess()
      initApiSwitcher(
        {
          standType,
          onChange,
        },
        additionalConfigObj
      )
    }

    function onChange(){
      redirectToExternalSSO()
        .catch((error) => {
          console.log(`Can't redirect after changing apt type because of: ${error}`)
        })
    }

    function onInitError(){
      onError()
      // TODO: add error handling is it is needed
      // console.log('Failed to initialize additional configs:', error)
    }
  }, [])
}
