import { API_KEY_NAME, CONFIG_TYPE_PARAM_NAME, init } from "@cloudike/api-switcher"
import _ from "lodash"

import APP_CONFIG from "../../../constants/configs/app.config"
import { CONFIG_TYPES } from "../configs/additionalConfigService"

const defaultSwitcherOptions = [
  { label: 'Prod API', value: CONFIG_TYPES.PROD },
  { label: 'QA API', value: CONFIG_TYPES.QA },
  { label: 'Dev API', value: CONFIG_TYPES.DEV },
]

export function initApiSwitcher(
  options:{standType:CONFIG_TYPES, onChange:(value:string) => void},
  additionalConfigObj?: Record<string,any>): void {
  const standType = options?.standType
  let switcherOptions = defaultSwitcherOptions
  const isSwitcherVisible = isQA()

  if (additionalConfigObj && Object.keys(additionalConfigObj).length > 0) {
    switcherOptions = mapConfigToOptions(switcherOptions, additionalConfigObj)
  }

  const defaultOptions = {
    currentValue: CONFIG_TYPES.QA,
    switcherOptions,
    minimized: false
  }

  if(isSwitcherVisible && standType) {
    forceUpdateStandType(standType)
    const initialOptions = {
      ...defaultOptions,
      ...options
    }
    if(!_.isNil(standType)) {
      initialOptions.currentValue = standType
    }
    init(initialOptions)
  }
}

export function forceUpdateStandType(value: CONFIG_TYPES) {
  validateApiKey(value)
  window.localStorage.setItem(API_KEY_NAME, value)
}

export function getStandType():string {
  updateStandTypeFromUrl()
  return window.localStorage.getItem(API_KEY_NAME) || CONFIG_TYPES.QA
}

function updateStandTypeFromUrl() {
  const configType = getApiKeyNameFromUrl()
  if(!_.isNil(configType)) {
    try {
      forceUpdateStandType(configType)
    } catch (error) {
      console.log('getStandTypeFromUrl() error', error)
    }
    removeConfigTypeParam()
  }
}

function getApiKeyNameFromUrl() {
  try{
    const url = new URL(window.location.href)
    return url.searchParams.get(CONFIG_TYPE_PARAM_NAME) as CONFIG_TYPES
  } catch (error) {
    return null
  }
}

function removeConfigTypeParam() {
  const url = new URL(window.location.href)
  url.searchParams.delete(CONFIG_TYPE_PARAM_NAME)
  window.location.href = url.href
}
function validateApiKey(value:CONFIG_TYPES) {
  if(!value && !Object.values(CONFIG_TYPES).includes(value)) {
    throw Error(`api key isn't valid`)
  }
}

export function isQA() {
  return !_.isNil(APP_CONFIG.isProd) && !APP_CONFIG.isProd
}

export function clearApiKey() {
  window.localStorage.removeItem(API_KEY_NAME)
}

function mapConfigToOptions(
  defaultSwitcherOptions: { value:CONFIG_TYPES, label:string }[],
  additionalConfigObj: Record<string,any>) {
  return  Object.keys(additionalConfigObj).map((apiConfigType: CONFIG_TYPES) => {
    return defaultSwitcherOptions.find((item) => {
      return item.value === apiConfigType
    })
  })
}
