import React from "react"

import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { useAppDispatch } from  "store"

import { Tab, Tabs } from "../../../ui/Tabs"
import { DATES_FORMATS } from "../../../constants/datesFormats"

import { SORT_FORMATS, timelineActions } from "./timelineSlice"

interface TimelineTabsProps {
    className?: string,
    activeTab: string,
    onChangeTab: (tab: Tab) => void
}

export const TimelineTabs: React.FC<TimelineTabsProps> = ({ className = '', activeTab, onChangeTab }) => {
  const { t } = useTranslation()

  const tabs = [
    {
      label: t('a_common_sortDay'),
      active: activeTab === DATES_FORMATS.timelineDay,
      value: DATES_FORMATS.timelineDay,
      sort: SORT_FORMATS.sortFormat.day,
    },
    {
      label: t('a_common_sortMonth'),
      active: activeTab === DATES_FORMATS.timelineMonth,
      value: DATES_FORMATS.timelineMonth,
      sort: SORT_FORMATS.sortFormat.month,
    },
    {
      label: t('a_common_sortYear'),
      active: activeTab === DATES_FORMATS.timelineYear,
      value: DATES_FORMATS.timelineYear,
      sort: SORT_FORMATS.sortFormat.year,
    },
  ]

  return (
    <StyledTabs
      items={tabs}
      onChangeTab={onChangeTab}
      className={className}
    />
  )
}

const StyledTabs = styled(Tabs)`
  margin-bottom: 8px;
  margin-top: 12px;
  padding-bottom: 0;
`
