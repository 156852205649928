import styled from 'styled-components'
import { SpriteIcon } from "@cloudike/web_ui_components"

import plus from '../../../assets/icons/plus.svg'


export const AddPersonLine = ({ textAdd, isFullListPerson, limitText, onClickAdd }) => {

  return (

    <PersonsCardBox onClick={onClickAdd}>
      {!isFullListPerson && (
        <ImageBox>
          <PlusIcon iconName={'add'} />
        </ImageBox>
      )}

      <NameBox>
        {isFullListPerson ? limitText : textAdd}
      </NameBox>
    </PersonsCardBox>
  )
}

const PlusIcon = styled(SpriteIcon)`
  color: var(--text-secondary);
  width: 32px;
  height: 32px;
`

const PersonsCardBox = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--divider-primary);
  -webkit-tap-highlight-color: var(--divider-primary);
  padding-left: 16px;
`

const ImageBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--background-tertiary);
  margin-right: 12px;
  border-radius: 50%;
`


const NameBox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: var(--text-primary);
  cursor: pointer;
`
