const modifyTimeValue = (value) => {
  if (value < 0) {
    return '00'
  }

  const str = String(value)

  if (str.length < 2) {
    return `0${str}`
  }

  return str
}

export const convertMilliseconds = (ms) => {
  const days = modifyTimeValue(Math.floor(ms / (24 * 60 * 60 * 1000)))
  const daysMs = ms % (24 * 60 * 60 * 1000)

  const hours = modifyTimeValue(Math.floor((daysMs) / (60 * 60 * 1000)))
  const hoursMs = ms % (60 * 60 * 1000)

  const minutes = modifyTimeValue(Math.floor((hoursMs) / (60 * 1000)))
  const minutesMs = ms % (60 * 1000)

  const seconds = modifyTimeValue(Math.floor((minutesMs) / (1000)))

  return {
    days,
    hours,
    minutes,
    seconds
  }
}