import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

import { AnalyticsEvent } from './types'
import { DEFAULT_ANALYTICS_EVENTS } from './events'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

const isDev = process.env.NODE_ENV === 'development'

let isInitialized = false
let firebaseEnabled = false
let gtmEnabled = false
let firebaseAnalytics = null
let withDevLogs = false

export let ANALYTICS_EVENTS = { ...DEFAULT_ANALYTICS_EVENTS }

export const analyticsService = {
  init: ({ enableLogs, enableGTM, firebaseConfig, events }: { enableLogs?: boolean, enableGTM?: boolean, firebaseConfig?: Record<string, any>, events?: Record<string, AnalyticsEvent> }) => {
    isInitialized = true

    if (enableLogs) {
      withDevLogs = true
    }

    if (events) {
      ANALYTICS_EVENTS = { ...ANALYTICS_EVENTS, ...events }
    }

    if (firebaseConfig) {
      firebaseEnabled = true

      const app = initializeApp(firebaseConfig)
      firebaseAnalytics = getAnalytics(app)
    }

    if (enableGTM) {
      if (!window.dataLayer) {
        console.error("Analytics: GTM isn't included")
      } else {
        gtmEnabled = true
      }
    }
  },

  push: (data: AnalyticsEvent) => {
    if (!isInitialized && isDev) {
      console.log('Analytics: Analytics not enabled. You should call analyticsService.init method first')

      return
    }

    if (withDevLogs && Object.keys(data).length && isDev) {
      console.log('Analytics event: ', data)
    }

    if (firebaseEnabled) {
      if (!data.firebase) {
        console.log(`Analytics: Firebase event data not found in events.ts`)
      } else {
        const eventName =  data.firebase.eventName
        const eventParams =  data.firebase.eventParams || {}
        logEvent(firebaseAnalytics, eventName, eventParams)
      }
    }

    if (gtmEnabled) {
      if (!data.gtm) {
        console.log(`Analytics: GTM event data not found in events.ts`)
      } else {
        window.dataLayer.push(data.gtm)
      }
    }
  }
}
