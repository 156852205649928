import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false })

export const showGlobalProgressLoader = () => {
  NProgress.start()
}

export const hideGlobalProgressLoader = () => {
  NProgress.done()
}