import { formatImgUrl } from 'constants/configs/theme.config'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { useNavigate } from "react-router-dom"
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { appActions } from "../../store/app"
import { getUiTheme } from "../user/selectors"
import { THEME } from "../user/userSlice"

interface EmptyTimelinePlaceholderProps {
    className?: string,
    texts: {
        title: string,
        description: string,
        uploadBtn: string
    },
}

export const EmptyFavoritesPlaceholder: React.FC<EmptyTimelinePlaceholderProps> = ({ className = '', texts }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const theme = getUiTheme()

  const handleAddFavorites = () => {
    navigate('/photos/favorites/add')
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  return (
    <EmptyFavoritesPlaceholderBox
      className={className}
    >
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/no-photos.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/no-photos.svg")} />}

      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>

      <StyledSecondaryButton
        actionName={t('a_common_addToFavorites')}
        onClick={handleAddFavorites}
      >
        {t('a_common_addToFavorites')}
      </StyledSecondaryButton>

    </EmptyFavoritesPlaceholderBox>
  )
}

const EmptyFavoritesPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
`

const ContainerImg = styled.img`
  width: 292px;
  height: 186px;
`

const EmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 520px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: flex;
  }
`

const StyledSecondaryButton = styled.label`
  margin-top: 32px;
  background: transparent;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  padding: 8px 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-secondary-hover)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`
