import { useAppSelector } from "store"

import { filesPreviewSelectors } from "./filesPreviewSlice"

export const getFilesPreviewItemsSelector = () => useAppSelector(state => filesPreviewSelectors.selectAll(state.filesPreview))

export const getCurrentFilesPreviewItemIndexSelector = () => useAppSelector(state => state.filesPreview.currentItemIndex)

export const getFilesPreviewTypeSelector = () => useAppSelector(state => state.filesPreview.type)

export const getCurrentFilesPreviewModalStatusSelector = () => useAppSelector(state => state.filesPreview.isModalOpened)

export const getFilesPreviewLoadingSelector = () => useAppSelector(state => state.filesPreview.loading)

export const getFilesPreviewLoadingMoreSelector = () => useAppSelector(state => state.filesPreview.loadingMore)

export const getFilesPreviewErrorSelector = () => useAppSelector(state => state.filesPreview.hasError)

export const getFilesPreviewTotalItemsCountSelector = () => useAppSelector(state => state.filesPreview.totalItemsCount)