import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { TransitionGroup, Transition } from 'react-transition-group'

import { Notification } from './Notification'
import { addNotificationsListener, hideNotification, setTimeoutToHideNotification } from './notificationsService'
import { INotification } from './types'

interface NotificationsProps {
    defaultTimeoutToHideNotification?: number
}

interface INotificationWithHeight extends INotification {
    height?: number
}

export const Notifications: React.FC<NotificationsProps> = React.memo(({ defaultTimeoutToHideNotification }) => {
  const [notifications, setNotifications] = useState<INotificationWithHeight[]>([])

  useEffect(() => {
    addNotificationsListener((newNotifications) => {
      setNotifications(notifications => newNotifications.map(notification => {
        const existed = notifications.find(n => n.id === notification.id)

        if (existed) {
          return {
            ...notification,
            height: existed.height
          }
        }

        return notification
      }))
    })
  }, [])

  useEffect(() => {
    if (defaultTimeoutToHideNotification) {
      setTimeoutToHideNotification(defaultTimeoutToHideNotification)
    }
  }, [defaultTimeoutToHideNotification])

  const setNotificationHeight = (id, height) => {
    setNotifications(state => {
      const notification = state.find(n => n.id === id)
      const index = notifications.indexOf(notification)

      return [...state.slice(0, index), { ...notification, height }, ...state.slice(index + 1)]
    })
  }

  const calculateTranslateY = (index) => {
    return notifications.slice(index + 1).reduce((r) => r += 8, 0)
  }

  const handleNotificationClick = (notification: INotification) => {
    if (notification.callback) {
      notification.callback()
      hideNotification(notification)

      return
    }

    if (notification.userCantHide) {
      return
    }

    hideNotification(notification)
  }

  return (
    <TransitionGroup component={null}>
      {notifications.map((notification, index) => (
        <Transition
          timeout={{
            appear: 0,
            enter: 0,
            exit: 500
          }}
          key={notification.id}
        >
          {state => (
            <StyledNotification
              key={notification.id}
              notification={notification}
              state={state}
              translateY={calculateTranslateY(index)}
              onClick={handleNotificationClick}
              onSetHeight={(height) => setNotificationHeight(notification.id, height)}
            />
          )}

        </Transition>
      ))}
    </TransitionGroup>
  )
})

const StyledNotification = styled(Notification)`
  opacity: ${({ state }) => {
    switch (state) {
    case "entering":
      return 1
    case "entered":
      return 1
    case "exiting":
      return 0
    case "exited":
      return 0
    }
  }};
  transform: ${({ state, translateY }) => {
    switch (state) {
    case "entering":
      return "translateY(100%)"
    case "entered":
      return `translateY(${-translateY}px)`
    case "exiting":
      return `translateY(${-translateY}px)`
    default:
      return `translateY(100%)`
    }
  }}

`
