import { LOADING_STATUSES } from "constants/loadingStatuses"
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { insertElementsToTimelineThunk, loadTimelineItemsFirstTimeThunk, subscribeTimelineToWSThunk, timelineActions, unsubscribeTimelineFromWSThunk } from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import { getTimelineLoadingStatusSelector, getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "features/photo/timeline/selectors"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { addItemsToAlbumThunk, albumActions, fetchAlbumDataThunk } from "features/albums/album/albumSlice"
import { getAlbumDataSelector } from "features/albums/album/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { useMobileDetection } from "features/common/hooks"
import { subscribeFamilyToWSThunk, unsubscribeFamilyFromWSThunk } from "features/family/familySlice"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import _ from "lodash"

import { UPLOADER_LIST_TYPES } from "../../../features/common/files-uploading/filesUploadingSlice"
import { getUploadingFilesSelector } from "../../../features/common/files-uploading/selectors"
import { REDIRECT_TO } from "../../../constants/searchParams"
import { appActions } from "../../../store/app"

interface AddingPhotosToAlbumPageProps {
  type: SDK_TYPES
}

const AddingPhotosToAlbumPage: React.FC<AddingPhotosToAlbumPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams<{ id?: string, redirect_to?: string }>()
  const [searchParams] = useSearchParams()

  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const albumData = getAlbumDataSelector()
  const status = getTimelineLoadingStatusSelector()

  const isMobile = useMobileDetection()
  const redirectToUrl = searchParams.get(REDIRECT_TO)

  usePageTitle('l_common_addPhotoToAlbum')

  useEffect(() => {
    dispatch(albumActions.setCurrentAlbumId(id))
    if (type === SDK_TYPES.FAMILY) dispatch(albumActions.setCurrentAlbumsType(type))
    dispatch(fetchAlbumDataThunk())

    dispatch(timelineActions.setCurrentTimelineType(type))
    dispatch(loadTimelineItemsFirstTimeThunk())
    dispatch(subscribeTimelineToWSThunk())

    if (type === SDK_TYPES.FAMILY) {
      dispatch(subscribeFamilyToWSThunk({ navigate }))
    }

    return () => {
      dispatch(albumActions.resetState())
      dispatch(timelineActions.resetState())
      dispatch(unsubscribeTimelineFromWSThunk())

      if (type === SDK_TYPES.FAMILY) {
        dispatch(unsubscribeFamilyFromWSThunk())
      }
    }
  }, [])

  const uploadingFiles = getUploadingFilesSelector()

  useEffect(() => {
    if (type === SDK_TYPES.FAMILY) {
      const uploadedFamilyCloudFiles = uploadingFiles.filter(item =>
        item.state.isCompleted &&
        [UPLOADER_LIST_TYPES.FAMILY_TIMELINE, UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR].includes(item.uploadingSourceType)
        && item.uploadedItem)
        .map(item => item.uploadedItem)

      if (uploadedFamilyCloudFiles.length) {
        dispatch(insertElementsToTimelineThunk({ items: uploadedFamilyCloudFiles }))
      }
    }
  }, [uploadingFiles])


  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleBack = () => {
    // if 'redirect_to' is in searchParams, go back to this url
    if (!_.isNil(redirectToUrl)) {
      navigate(redirectToUrl)
      return
    }

    if (type === SDK_TYPES.DEFAULT) {
      navigate(`/photos/albums/${albumData.id}`)
    }

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/${albumData.id}`)
    }
  }

  const handleAddPhotos = useCallback(() => {
    dispatch(addItemsToAlbumThunk({ items: selectedItems, callback: handleBack }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }, [searchParams, selectedItems])

  const getPageTitle = useCallback(() => {
    let title = `${t(isMobile ? 'l_albums_addToAlbum' : 'a_common_addPhotosToAlbum')} ${albumData?.description || ''}`
    const redirectUrl = searchParams.get(REDIRECT_TO)

    if (!_.isNil(redirectUrl)) {
      if (location.pathname.startsWith('/family')) {
        title = `${t(isMobile ? 'l_family_timeline' : 'l_family_timeline')}`
      } else {
        title = `${t(isMobile ? 'l_common_personalTimeline' : 'l_common_personalTimeline')}`
      }
    }

    return title
  }, [searchParams])

  return (
    <>
      <PageTopbarBox
        title={getPageTitle()}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
        description={!isMobile ? t('l_common_selectPhotosToCopy') : ''}
        onAccept={isMobile && handleAddPhotos}
        isAcceptBtnActive={isMobile && !!selectedItems.length}
        onBack={handleBack}
        isMobile={isMobile}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSceleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <TimelineBox
            type={type}
            withDisabledPreview
          />
        )
      }
    </>
  )
}

const StyledSceleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

const PageTopbarBox = styled(PageTopbar)`
  position: fixed !important;
  top: 0;
  background: var(--background-primary);
  z-index: 5;
  width: calc(100% - 542px) !important;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    width: calc(100% - 250px) !important;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: calc(100% - 24px) !important;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: calc(100%) !important;
    margin-left: -16px;
  }
`

const TimelineBox = styled(Timeline)`
  margin-top: 100px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 80px;
  }
`

export default AddingPhotosToAlbumPage
