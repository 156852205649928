import { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { SpriteIcon } from "@cloudike/web_ui_components"

export const getFirstLetter = (name: string) => {
  return name.split(' ').map(item => item[0]).slice(0, 2).join('')
}

export const PersonsCard = ({ isSelect, name, color, onClickItem }) => {
  const ref = useRef()

  function isOverflowed(el) {
    if (!el) return
    return el.scrollWidth > el.offsetWidth
  }

  const [isOverflow, setIsOverflowed] = useState(false)

  useEffect(() => {
    setIsOverflowed(isOverflowed(ref.current))
  }, [ref])

  return (
    <PersonsCardBox onClick={onClickItem}>
      <AvatarBox border={isSelect && '2px solid rgba(0, 0, 0, 0.60)'}
        backgroundColor={color}
      >
        <AvatarTextBox>
          {getFirstLetter(name)}
        </AvatarTextBox>

        {isSelect && (
          <IconBox>
            <CheckedIcon iconName="circle_checked" />

          </IconBox>
        )}

      </AvatarBox>

      <NameBox color={isSelect ? 'var(--text-primary)' : 'var(--text-secondary)'}
        ref={ref}
        textAlign={isOverflow ? 'start' : 'center'}
      >
        {name}
      </NameBox>
    </PersonsCardBox>
  )
}

const PersonsCardBox = styled.div`
  height: 92px;
  width: 64px;
  margin-right: 20px;

  :last-child {
    margin-right: 0;
  }
`

const AvatarTextBox = styled.label`
  color: white;
  font-size: 22px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 28px;
`

const AvatarBox = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  margin-bottom: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  border: ${props => props.border};
  cursor: pointer;
`

const NameBox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
  cursor: pointer;
  height: 20px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
`

const CheckedIcon = styled(SpriteIcon)`
  border-radius: 50%;
  background-color: var(--background-primary);
  color: var(--accent-normal);
`
