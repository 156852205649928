import { getModalsRootElement } from 'constants/modals'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useEffect, useRef } from 'react'

import * as yup from 'yup'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon
} from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { Input } from 'ui/Input'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { publicLinkFilesActions, submitPasswordForSharedFiles } from 'features/public-link-files/publicLinkFilesSlice'
import { getFilesPublicLinkPasswordModalStatusSelector } from 'features/public-link-files/selectors'

import { getPublicLinkPasswordModalStatusSelector } from './selectors'
import { publicLinkActions, PublicLinksErrorTypes, submitPasswordForSharedAlbum } from './publicLinkSlice'

interface PublicLinkPasswordModalProps {
  type?: SDK_TYPES
}

export const PublicLinkPasswordModal: React.FC<PublicLinkPasswordModalProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const opened = type === SDK_TYPES.FILES ? getFilesPublicLinkPasswordModalStatusSelector() : getPublicLinkPasswordModalStatusSelector()

  const inputRef = useRef<any>(null)

  const handleClose = () => {
    if (!opened) {
      return
    }

    if (type === SDK_TYPES.FILES) {
      dispatch(publicLinkFilesActions.togglePasswordModal(false))
      dispatch(publicLinkFilesActions.setError(PublicLinksErrorTypes.NO_ACCESS))

      return
    }

    dispatch(publicLinkActions.togglePasswordModal(false))
    dispatch(publicLinkActions.setError(PublicLinksErrorTypes.NO_ACCESS))
  }

  const handleSubmit = (values: any, { setErrors }) => {
    const errorCallback = () => {
      setErrors({ password: t('l_notification_invalidPassword') })
    }

    if (type === SDK_TYPES.FILES) {
      dispatch(submitPasswordForSharedFiles({ password: values.password, errorCallback }))

      return
    }

    dispatch(submitPasswordForSharedAlbum({ password: values.password, errorCallback }))
  }

  const handleKeyUp = handleSubmit => (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const validation = yup.object().shape({
    password: yup.string().required(t('l_notification_invalidPassword'))
  })

  useEffect(() => {
    if (!opened) {
      return
    }

    inputRef.current?.focus()
  }, [opened])

  const handleInputRef = (ref: HTMLInputElement) => {
    inputRef.current = ref
  }

  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validation}
      validateOnChange
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Form>
          <ModalDialog
            header={(
              <ModalHeader
                title={t('l_common_passwordProtection')}
              />
            )}
            footer={(
              <ModalFooter
                buttonList={[
                  <SecondaryButton
                    key={'buttonCancel'}
                    actionName={t('a_common_cancel')}
                    onAction={handleClose}
                  />,
                  <StyledPrimaryButton
                    key={'buttonAction'}
                    actionName={t('a_common_ok')}
                    onAction={handleSubmit}
                    disabled={!values.password}
                  />
                ]}
              />
            )}
            parentBlock={getModalsRootElement()}
            isOpened={opened}
            onCloseModal={handleClose}
          >
            <ContentBox>
              <InputLabel>
                {t('l_common_passwordField')}
              </InputLabel>

              <InputBox>
                <StyledInput
                  placeholder={t('l_common_enterPasswordField')}
                  value={values.password}
                  onChange={handleChange}
                  onKeyUp={handleKeyUp(handleSubmit)}
                  name="password"
                  inputRef={handleInputRef}
                  type="password"
                />

                {!!errors.password && <WarningIcon iconName="warning" />}
              </InputBox>

              <ErrorBox>
                {errors.password}
              </ErrorBox>
            </ContentBox>

            <EmptyDivider />
          </ModalDialog>
        </Form>
      )}
    </Formik>
  )
}

const ContentBox = styled.div`
  width: calc(100vw - 80px);
  max-width: 460px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 460px;
  }
`

const InputLabel = styled.label`
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: var(--text-primary);
`

const ErrorBox = styled.p`
  margin-top: 8px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--text-secondary);
`

const InputBox = styled.div`
  margin-top: 5px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

const WarningIcon = styled(SpriteIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: #DADADA;
`

const StyledInput = styled(Input)`
  &.phone {
    padding-left: 30px;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 8px;
`

const EmptyDivider = styled.div`
  height: 10px;
`
