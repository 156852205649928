export const generatePassword = (len: number) :string => {
  let password = ''
  const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!?@#$%^&*_-+()[]{}></\\|\""

  for (let i = 0; i < len; i++){
    password += symbols.charAt(Math.floor(Math.random() * symbols.length))
  }

  const letters = 'abcdefghijklmnopqrstuvwxyz'
  const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'
  const newPassword = password.split('')

  const randomCapitalLetter = capitalLetters.charAt(Math.floor(Math.random() * capitalLetters.length))
  const randomNumber = numbers.charAt(Math.floor(Math.random() * numbers.length))
  const randomLetter = letters.charAt(Math.floor(Math.random() * letters.length))

  const indexes = Array.from({ length: len }).map((_, index) => index)

  const [indexOfNumber] = indexes.splice(Math.floor(Math.random() * indexes.length), 1)
  const [indexOfCapitalLetter] = indexes.splice(Math.floor(Math.random() * indexes.length), 1)
  const [indexOfLetter] = indexes.splice(Math.floor(Math.random() * indexes.length), 1)

  newPassword.splice(indexOfNumber, 1, randomNumber)
  newPassword.splice(indexOfCapitalLetter, 1, randomCapitalLetter)
  newPassword.splice(indexOfLetter, 1, randomLetter)

  password = newPassword.join('')

  return password
}