import APP_CONFIG from "constants/configs/app.config"

import _ from "lodash"
import i18n from 'i18n'
import { USER_DATA_KEY_IN_STORAGE } from 'features/user/constants'

import { getSubscriptionPageUrl } from "./utils"

export const goToSubscriptionsPage = () => {
  const userDataFromLocalStorage = localStorage.getItem(USER_DATA_KEY_IN_STORAGE)
  const userData = JSON.parse(userDataFromLocalStorage)?.CurrentUser || {}
  const back_to = window.location.href
  if(!_.isNil(APP_CONFIG.subscriptions_page_url)) {
    const url = getSubscriptionPageUrl({
      url:APP_CONFIG.subscriptions_page_url,
      lang: i18n.language,
      token: _.get(userData, 'token', ''),
      back_to
    })

    if(!_.isNil(url)) {
      window.location.href = url
    }
  }
}