import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { AlbumType } from "@cloudike/web_photos"
import { IGetAlbumsSchema } from "@cloudike/web_photos/dist/types/intarfaces/IGetAlbumsSchema"

import { LOADING_STATUSES } from "../../../constants/loadingStatuses"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { RootState } from "../../../store"
import { getAlbumsSdkByType } from "../../../sdk/albums"
import { TOTAL_COUNT_HEADER } from "../../../constants/headers"

export type IExtendedAlbumPersonSchema = IAlbumSchema

const adapter = createEntityAdapter<IExtendedAlbumPersonSchema>()

export const albumsPersonSelectors = adapter.getSelectors()

const getCurrentAlbumsType = (state: RootState) => state.albumsPerson.type
const getAlbumType = (state: RootState) => state.albumsPerson.albumType

export const fetchPersonAlbumsThunk = createAsyncThunk(
  'albumsPerson/fetchAlbumsPersonThunk',
  async function (inputParams:IGetAlbumsSchema | null = {}, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumsSdk = getAlbumsSdkByType(type)
    const albumType = getAlbumType(state)
    const defaultParams = { offset: 0, type: [albumType], total_count: true, preview_jwt: true }
    const params: IGetAlbumsSchema = {
      ...defaultParams,
      ...inputParams
    }
    const response = await albumsSdk.getAlbums(params)

    return {
      items: response.data._embedded.albums,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }
  }
)

export const albumsPersonSlice = createSlice({
  name: 'albumsPerson',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    error: '',
    type: SDK_TYPES.DEFAULT,
    albumType: AlbumType.PERSON
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setCurrentAlbumsType: (state, action) => {
      state.type = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      adapter.removeAll(state)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPersonAlbumsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchPersonAlbumsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
      })
      .addCase(fetchPersonAlbumsThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

const {
  reducer, actions
} = albumsPersonSlice

export { reducer as albumsPersonReducer, actions as albumsPersonActions }
