import React from "react"

import styled from 'styled-components'
import { useTranslation } from "react-i18next"

export const MobileToolbarCounter = ({ selectedItemsCount }) => {
  const { t } = useTranslation()

  return (
    <MobileToolbarCounterBox>
      <MobileToolbarCount>
        {selectedItemsCount}
      </MobileToolbarCount>

      <MobileToolbarText>
        {t('l_common_selectedItems', { number: selectedItemsCount })}
      </MobileToolbarText>
    </MobileToolbarCounterBox>
  )
}

const MobileToolbarCounterBox = styled.div`
  display: flex;
  padding: 16px;
  vertical-align: baseline;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  font-weight: 600;
  color: #252a31;
  align-items: center;
`

const MobileToolbarCount = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: var(--background-tertiary);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 16px;
`

const MobileToolbarText = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-primary);
`
