import React from 'react'

import styled from 'styled-components'
import QRCode from "react-qr-code"

interface QrCodeProps {
  className?: string,
  value: string,
  size?: number
}

export const QrCode: React.FC<QrCodeProps> = ({ className = '', value, size = 127 }) => {
  return (
    <QrCodeBox className={className}>
      <QRCode
        value={value}
        size={size}
      />
    </QrCodeBox>
  )
}

const QrCodeBox = styled.div`

`
