import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { loadTrashBinItemsFirstTimeThunk, TRASH_BIN_TYPES, trashBinActions } from "features/trash-bin/trashBinSlice"
import { TrashBinTabs } from "features/trash-bin/TrashBinTabs"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { getTrashBinFilesCountSelector } from "features/trash-bin/selectors"
import { useMobileDetection } from "features/common/hooks"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { FilesSkeleton } from "features/files/FilesSkeleton"
import { FilesList } from "features/files/FilesList"
import { mapFileItemsToTableRow } from "features/files/filesUtils"
import { useGetFilesTableColumns } from "features/files/useGetFilesTableColumns"
import {
  subscribeFilesTrashBinToWSThunk,
  trashBinFilesActions,
  unsubscribeFilesTrashBinFromWSThunk
} from "features/trash-bin/files/trashBinFilesSlice"
import {
  getTrashBinFilesCheckboxVisibilitySelector,
  getTrashBinFilesLoadingStatusSelector,
  getTrashBinFilesSelectedItemsIdsSelector,
  getTrashBinFilesSelectedItemsSelector,
  getTrashBinFilesSelectType,
  getTrashBinFilesSortedNodesSelector,
  getTrashBinFilesSortSelector
} from "features/trash-bin/files/selectors"
import { FilesTableTypes, SelectType } from "@cloudike/web_ui_components"
import { EmptyTrashBinPlaceholder } from "features/trash-bin/EmptyTrashBinPlaceholder"

const TrashBinFilesPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const status = getTrashBinFilesLoadingStatusSelector()
  const selectedItemsCount = getTrashBinFilesSelectedItemsSelector().length
  const totalItemsCount = getTrashBinFilesCountSelector()
  const nodes = getTrashBinFilesSortedNodesSelector()
  const selectType = getTrashBinFilesSelectType()
  const checkboxVisibility = getTrashBinFilesCheckboxVisibilitySelector()
  const selectedFilesIds = getTrashBinFilesSelectedItemsIdsSelector()
  const sortState = getTrashBinFilesSortSelector()

  const [arrColumns, handleSortByColumn] = useGetFilesTableColumns(
    onChangeSorting,
    sortState,
    ['name', 'deleted', 'size']
  )

  function onChangeSorting(sortState) {
    dispatch(trashBinFilesActions.setSort(sortState))
  }

  const isMobile = useMobileDetection()

  const handleCancelSelection = useCallback(() => {
    dispatch(trashBinFilesActions.unselectAll())
  }, [])

  const handleSelectNode = (node, items) => {
    dispatch(trashBinFilesActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleSelectAll = (items) => {
    dispatch(trashBinFilesActions.setAllItems(items))
  }

  const handleElementsSelection = (node, items) => {
    dispatch(trashBinFilesActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleClickCheckbox = (allFileIds) => {
    if (selectType === SelectType.ALL) {
      handleCancelSelection()
    } else {
      handleSelectAll(allFileIds)
    }
  }

  usePageTitle('a_nav_trash')

  useEffect(() => {
    dispatch(loadTrashBinItemsFirstTimeThunk({ type: TRASH_BIN_TYPES.FILES }))
    dispatch(subscribeFilesTrashBinToWSThunk())

    return () => {
      dispatch(trashBinActions.resetState())
      dispatch(trashBinFilesActions.resetState())
      dispatch(unsubscribeFilesTrashBinFromWSThunk())
    }
  }, [])

  return (
    <>
      <PageTopbar title={t('a_nav_trash')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />

      <TrashBinTabs activeTab={TRASH_BIN_TYPES.FILES} />

      {
        status === LOADING_STATUSES.LOADING &&
                <FilesSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && !nodes.length && (
          <EmptyTrashBinPlaceholder
            texts={{
              title: t('l_trash_emptyPageTitle'),
              description: t('l_trash_emptyPageMessage')
            }}
          />
        )
      }

      {
        status !== LOADING_STATUSES.LOADING && !!nodes.length && (
          <>
            <InfoText>
              {t('l_common_trashbinMessage')}
            </InfoText>

            <PageContent>
              <FilesList
                columns={arrColumns}
                selectType={selectType}
                checkboxVisibility={checkboxVisibility}
                items={mapFileItemsToTableRow(nodes, t)}
                onRenameItem={() => false}
                onSortingChange={handleSortByColumn}
                onToggleItem={handleSelectNode}
                onChangeFolder={(node) => handleSelectNode(node, nodes)}
                onClickAllCheckbox={handleClickCheckbox}
                onClickCheckbox={handleElementsSelection}
                selectedFilesIds={selectedFilesIds}
                type={FilesTableTypes.trash}
                onEditPublicLink={() => {
                  return
                }}
                withoutContextMenu
              />
            </PageContent>
          </>
        )}

      {totalItemsCount > 0 && <MobileToolbarMenuToggle />}
    </>
  )
}

const InfoText = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.14px;
  color: var(--text-primary);
`

const PageContent = styled.div`
`

export default TrashBinFilesPage
