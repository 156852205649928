import styled from 'styled-components'

import { PersonsCard } from "./PersonsCard"

export const SkeletonPersons = ({ ownerItem }) => {
  const items = [0, 1, 2, 3, 4, 5, 6, 7]

  return (
    <>
      <PersonsCard
        key={ownerItem.id}
        isSelect={ownerItem.isSelect}
        name={ownerItem.name}
        color={ownerItem.color_id}
        onClickItem={null}
      />

      {items.map(item => (
        <SkeletonBox key={item}>
          <SkeletonAvatarBox />

          <SkeletonNameBox>
            <NameBox />
          </SkeletonNameBox>
        </SkeletonBox>
      ))}
    </>
  )
}


const SkeletonBox = styled.div`
  margin-right: 20px;
`

const SkeletonAvatarBox = styled.div`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: var(--background-tertiary);
`

const SkeletonNameBox = styled.div`
  height: 20px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NameBox = styled.div`
  height: 7px;
  width: 52px;
  border-radius: 2px;
  background-color: vat(--background-tertiary);
`
