import { useEffect } from "react"

import { PageTopbar } from '@cloudike/web_ui_components'
import { useNavigate } from "react-router-dom"
import React, { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import styled from 'styled-components'

import { getLoadingPersonsStatusSelector, getPersonsSelector } from "../../../features/documents/persons/selectors"
import { PersonsList } from "../../../features/documents/persons/PersonsList"
import { usePageTitle } from "../../../features/common/hooks/usePageTitle"
import { useDocumentsWalletAccessChecking } from "../../../features/documents/useDocumentsWalletAccessChecking"
import { getUserSettingsSelector } from "../../../features/user/selectors"
import { getPersonsThunk } from "../../../features/documents/persons/personsSlice"
import { LOADING_STATUSES } from "../../../constants/loadingStatuses"
import { SkeletonPersonsList } from "../../../features/documents/persons/SkeletonPersonsList"
import APP_CONFIG from "../../../constants/configs/app.config"
import { resetDocumentsAccessTimerThunk } from "../../../features/documents/documentsSlice"
import { AddPersonModal } from "../../../features/documents/persons/AddPersonModal"

export const getOwnerData = (t) => {
  return { name: t('l_dw_me'), color_id: '#6A6D70', isSelect: true, id: 'owner' }
}

export const PersonsPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const persons = getPersonsSelector()
  const dispatch = useAppDispatch()
  const loadingPersonsStatus = getLoadingPersonsStatusSelector()

  const userSettings = getUserSettingsSelector()
  const isPersonsVisible = !!APP_CONFIG.is_persons_visible


  usePageTitle(t('a_nav_documentWallet'))
  useDocumentsWalletAccessChecking()

  const handleBack = () => {
    navigate('/documents')
  }

  useEffect(() => {
    dispatch(resetDocumentsAccessTimerThunk())

    if (!isPersonsVisible) return handleBack()

    dispatch(getPersonsThunk({ ...getOwnerData(t) }))
  }, [])

  return (
    <>
      <PageTopbarBox
        title={t('l_dw_editPeopleList')}
        onClickMenuButton={() => {
          return
        }}
        onBack={handleBack}
        backIcon={'arrow_back'}
      />

      {loadingPersonsStatus !== LOADING_STATUSES.LOADING ? (
        <PersonsList
          items={persons}
          maxPersons={!!userSettings && userSettings['user_document_wallet'].persons_limit}
        />
      ) : (<SkeletonPersonsList />)}

      <AddPersonModal />
    </>
  )
}

const PageTopbarBox = styled(PageTopbar)`
  margin-left: 16px;
`
