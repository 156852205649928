import { getModalsRootElement } from 'constants/modals'
import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { useEffect } from 'react'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  SecondaryButton,
  PrimaryButton,
  SpriteIcon,
} from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { Checkbox } from 'ui/Checkbox'
import { Spinner } from 'ui'

import { mapFileVersionItemsToTableRows } from '../filesUtils'

import { getFileVersionsFileIdSelector, getFileVersionsItemsSelector, getFileVersionsSelectedVersionIdSelector, getFileVersionsStatusSelector } from './selectors'
import { fetchFileVersionsThunk, fileVersionsActions, restoreFileVersionThunk } from './fileVersionsSlice'

export const FileVersionsModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const nodeId = getFileVersionsFileIdSelector()
  const status = getFileVersionsStatusSelector()
  const versions = mapFileVersionItemsToTableRows(getFileVersionsItemsSelector(), t)
  const selectedVersionId = getFileVersionsSelectedVersionIdSelector()

  const tableCells = [
    {
      name: 'date',
      label: t('l_common_dateModified')
    },
    // {
    //   name: 'author',
    //   label: t('l_common_author')
    // },
    {
      name: 'size',
      label: t('l_common_size')
    },
    {
      name: 'selected',
      label: t('l_common_selected')
    }
  ]

  useEffect(() => {
    if (!nodeId) {
      return
    }

    dispatch(fetchFileVersionsThunk({ nodeId }))
  }, [nodeId])

  const handleCancel = () => {
    dispatch(fileVersionsActions.resetState())
  }

  const handleSelect = (id) => {
    dispatch(fileVersionsActions.setSelectedVerion(id))
  }

  const handleRestore = () => {
    dispatch(restoreFileVersionThunk({ versionId: selectedVersionId }))
  }

  if (!nodeId) {
    return null
  }

  return (
    <ModalDialog
      isOpened
      parentBlock={getModalsRootElement()}
      header={<ModalHeader title={t('l_common_changeHistory')} />}
      footer={(
        <ModalFooter
          buttonList={[
            <StyledSecondaryButton
              key={t('a_common_cancel')}
              onAction={handleCancel}
              actionName={t('a_common_cancel')}
            />,
            versions.length >= 2 && (
              <StyledPrimaryButton
                key={t('a_common_replace')}
                onAction={handleRestore}
                actionName={t('a_common_replace')}
                disabled={status === LOADING_STATUSES.LOADING}
              />
            ),
          ].filter(item => !!item)}
        />
      )}
      onCloseModal={handleCancel}
    >
      <Content>
        <Table>
          <colgroup>
            {tableCells.map(cell => (
              <Col className={cell.name}
                key={cell.name}
              />
            ))}
          </colgroup>

          <thead>
            <tr>
              {tableCells.map(cell => (
                <Th key={cell.name}>
                  {cell.label}
                </Th>
              ))}
            </tr>
          </thead>
        </Table>

        <Divider />

        <Table>
          <colgroup>
            {tableCells.map(cell => (
              <Col className={cell.name}
                key={cell.name}
              />
            ))}
          </colgroup>

          <TableBody>
            {
              status === LOADING_STATUSES.LOADING && (
                <tr>
                  <Td colSpan="4">
                    <Spinner />
                  </Td>
                </tr>
              )
            }

            {status !== LOADING_STATUSES.LOADING && versions.length > 1 && versions.map(version => (
              <tr key={version.id}>
                <Td>
                  <PreviewBox>
                    {
                      !!version.thumbnailLink && <PreviewImg src={version.thumbnailLink} />
                    }

                    {
                      !version.thumbnailLink && <FileIcon iconName={version.icon} />
                    }

                    <span>
                      {version.modified}
                    </span>
                  </PreviewBox>
                </Td>

                {/* <Td>
                  {version.author_id}
                </Td> */}

                <Td>
                  {version.size}
                </Td>

                <TdCheckBox>
                  <StyledCheckbox
                    checked={version.id === selectedVersionId}
                    name={version.id}
                    onChange={() => handleSelect(version.id)}
                  />
                </TdCheckBox>
              </tr>
            ))}

            {status !== LOADING_STATUSES.LOADING && versions.length < 2 && (
              <tr>
                <Td colSpan="4">
                  {t('l_common_noVersionsToRestore')}
                </Td>
              </tr>
            )}
          </TableBody>
        </Table>
      </Content>
    </ModalDialog>
  )
}

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 8px;
`

const StyledSecondaryButton = styled(SecondaryButton)`

`

const Content = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const TdCheckBox = styled.td`
  display: flex;
  border-bottom: 1px solid var(--divider-primary);
  height: 64px;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
`

const Table = styled.table`
  max-width: 618px;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
`

const TableBody = styled.tbody`
`

const Th = styled.th`
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  padding: 5px 0;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    text-align: end;
    padding-right: 10px;
  }
`

const Td = styled.td`
  height: 64px;
  border-bottom: 1px solid var(--divider-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--inc-light);

  &:first-child {
    padding-left: 16px;
  }
`

const PreviewBox = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`

const Col = styled.col`
  &.date {
    width: 55%;
  }

  &.author {
    width: auto;
  }

  &.size {
    width: 25%;
  }

  &.selected {
    width: 20%;
  }
`

const Divider = styled.div`
  height: 1px;
  background-color: var(--divider-primary);
  width: 100%;
`

const StyledCheckbox = styled(Checkbox)`
  div {
    border-radius: 50%;
  }
`

const PreviewImg = styled.img`
  display: flex;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  object-fit: cover;
  background-color: var(--background-tertiary);
`

const FileIcon = styled(SpriteIcon)`
  && {
    width: 24px;
    height: 24px;
    color: var(--icon-primary);
  }
`
