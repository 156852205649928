import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { LOADING_STATUSES } from "../../constants/loadingStatuses"
import { RootState } from "../../store"
import { photosApi } from "../../api/photos"
import { NOTIFICATION_TYPES, showNotification } from "../common/notifications"
import { getErrorData } from "../../utils/getErrorData"
import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import { SDK_TYPES } from "../../sdk/sdkConstants"

interface ISuggestion {
  "id": string
  "user_id": number,
  "lang": string,
  "name": string,
  "tokens": string[],
  "type": string,
  "subtype": string,
  "refs_count": number,
  "live_refs_count": number,
  "created": string,
  "updated": string,
  "description": string,
  "_embedded": {
    "cover_item": {
      "created": number,
      "updated": number,
      "checksum": string,
      "data_size": number,
      "description": string,
      "created_original": number,
      "type": string,
      "width": number,
      "height": number,
      "_links": {
        "self": {
          "href": string
        },
        "image_small": {
          "href": string
        },
        "image_middle": {
          "href": string
        },
        "image_album": {
          "href": string
        },
        "image_preview": {
          "href": string
        },
        "href": string
      },
    }
  }
}

interface ISuggestions {
  calendar_suggestions: ISuggestion[],
  address_suggestions: ISuggestion[],
  album_suggestions: ISuggestion[]
}

interface State {
  searchText: string,
  status: LOADING_STATUSES,
  suggestions: ISuggestions,
  type: SDK_TYPES
}

const initialState: State = {
  searchText: '',
  status: LOADING_STATUSES.LOADING,
  type: SDK_TYPES.DEFAULT,
  suggestions: {
    calendar_suggestions: [],
    address_suggestions: [],
    album_suggestions: []
  },
}

export const getSearchSuggestionsThunk = createAsyncThunk(
  'photoSearch/getSearchSuggestionsThunk',
  async ({ text = '' }: { text: string }, { getState, dispatch }) => {
    const state = getState() as RootState
    const type = state.photoSearch.type
    const userId = type === SDK_TYPES.DEFAULT ? state.user.userData.id : state.user.userData.family_user_id

    const params: { tokens__startswith?: string } = {}

    if (!!text) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_SEARCH_ENTER)
    }


    if (text) {
      params.tokens__startswith = text
    }

    try {
      const response = await photosApi.getSuggestions(userId, params)

      dispatch(actions.setSuggestions(response._embedded))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      dispatch(actions.setLoadingStatus(LOADING_STATUSES.SUCCEEDED))
    }
  }
)

export const photoSearchSlice = createSlice({
  name: 'photoSearch',
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setSuggestions: (state, action) => {
      state.suggestions = action.payload
    },
    setLoadingStatus: (state, action) => {
      state.status = action.payload
    },
    setSearchType: (state, action) => {
      state.type = action.payload
    }
  },
})

const { reducer, actions } = photoSearchSlice

export { reducer as photoSearchReducer, actions as photoSearchActions }
