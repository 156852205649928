import { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import { NOTIFICATION_TYPES, showNotification, hideNotification, INotification } from "../notifications"

export const useOnlineDetection = () => {
  const { t } = useTranslation()

  const notificationRef = useRef<INotification>(null)

  useEffect(() => {
    const offlineHandler = () => {
      notificationRef.current = showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        message: t('l_notification_offlineError'),
        title: t('l_common_tryAgainNote'),
        isPermanent: true,
        userCantHide: true
      })
    }

    const onlineHandler = () => {
      if (notificationRef.current) {
        hideNotification(notificationRef.current)
        notificationRef.current = null

        showNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: t('l_notification_internetConnectionRestored')
        })
      }
    }

    window.addEventListener('offline', offlineHandler)
    window.addEventListener('online', onlineHandler)

    return () => {
      window.removeEventListener('offline', offlineHandler)
      window.removeEventListener('online', onlineHandler)
    }
  }, [])
}