import i18n, { use } from "i18next"
import { initReactI18next } from "react-i18next"
import LngDetector from 'i18next-browser-languagedetector'
import ICU from 'i18next-icu'

import translationEN from './src/i18n/en.json'
import translationID from './src/i18n/id.json'

const resources = {
  en: {
    translation: translationEN
  },
  id: {
    translation: translationID
  }
}

use(ICU)
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "langKey",
      caches: ["localStorage"]
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
