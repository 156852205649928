
const getItems = () => {
  const array = JSON.parse(localStorage.getItem('OPENED_ITEMS'))
  return !!array && array.sort((a, b) => b.timeOpened - a.timeOpened)
}

const setItems = (array: any[]) => {
  localStorage.setItem('OPENED_ITEMS', JSON.stringify(array))
}

const openItem = (item: any) => {
  const array = getItems()

  if (!!array) {
    const isDuplicate = array.some(el => el.id === item.id )
    if(isDuplicate) {
      updateItem(item)
    } else {
      setItems([...array, { ...item, timeOpened: Date.now() }])
    }
  }
}

const initItemsArray = () => {
  const array = getItems()
  const clearDayDelay = 30
  const clearMsDelay = clearDayDelay * 24 * 60 * 60 * 1000

  if (!array) {
    setItems([])
  } else {
    const tmpArray = array.filter((item:any) => item.timeOpened > Date.now() - clearMsDelay).sort((a, b) => b.timeOpened - a.timeOpened)
    setItems(tmpArray)
  }
}

const updateItem = (item: any) => {
  const array = getItems()
  if (!!array.length) {
    const index = array.findIndex(el => el.id === item.id)
    if (index !== -1) {
      array.splice(index, 1, { ...item, timeOpened: Date.now() })
      setItems(array)
    }
  }
}

const removeItem = (id: string) => {
  const array = getItems()
  if (!!array.length) {
    const index = array.findIndex(el => el.id === id)
    if (index !== -1) {
      array.splice(index, 1)
      setItems(array)
    }
  }
}

export const OpenedItemsService = {
  initItemsArray: initItemsArray,
  openItem: openItem,
  removeItem: removeItem,
  updateItem: updateItem,
  getItems: getItems,
}


