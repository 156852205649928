import { useAppSelector } from "store"
import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"
import { createSelector } from "@reduxjs/toolkit"

import { formatDate } from "../../utils/formatDate"

import { favoritesSelectors } from "./favoritesSlice"

export const getFavoritesLoadingStatusSelector = () => useAppSelector(state =>
  state.favorites.status
)

export const getFavoritesSelectedItemsRawSelector = state => favoritesSelectors.selectAll(state.favorites)

export const getFavoritesSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getFavoritesSelectedItemsRawSelector,
    getFavoritesSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) =>
      items.filter(item => selectedItemsIds.includes(item.id))
  )
)

export const getFavoritesSelectedItemsIdsRawSelector = state => state.favorites.selectedItemsIds

export const getFavoritesSelectedItemsIdsSelector = () => useAppSelector(getFavoritesSelectedItemsIdsRawSelector)

export const getFavoritesSelectedItemsCountSelector = () => useAppSelector(state =>
  state.favorites.selectedItemsIds.length
)

export const getFavoritesItemsSelector = () => useAppSelector(
  createSelector(
    getFavoritesSelectedItemsRawSelector,
    items =>
      items.sort((a, b) => b.created_original - a.created_original)
  )
)

export const getFavoritesTotalItemsCountSelector = () => useAppSelector(state => state.favorites.totalItemsCount)

export const getFavoritesItemsByDateSelector = () => useAppSelector(
  createSelector(
    getFavoritesItemsSelector,
    items => {
      const getItemDate = (item: IItemSchema) => formatDate(item.created_original, "DD MMM YYYY")

      const itemsByDates = items.reduce((r, item) => {
        const date = getItemDate(item)

        if (!r[date]) {
          return { ...r, [date]: [item] }
        }

        return { ...r, [date]: [...r[date], item] }
      }, {})

      const itemsByDatesArr = Object.entries<IItemSchema[]>(itemsByDates)
        .map(([key, value]) => ({ date: key, items: value.sort((a, b) => b.created_original - a.created_original ) }))
        .sort((a, b) => b.items[0].created_original - a.items[0].created_original)

      return itemsByDatesArr
    })
)
