import { useAppSelector } from "store"

export const getFamilyLoadingStatusSelector = () => useAppSelector(state =>
  state.family.status
)

export const getFamilyDataSelector = () => useAppSelector(state =>
  state.family.familyData
)

export const getFamilyMembersSelector = () => useAppSelector(state =>
  state.family.familyMembers
)


export const getFamilyInviteModalIsOpenedSelector = () => useAppSelector(state =>
  state.family.isFamilyInviteModalOpened
)