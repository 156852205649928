import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useState } from 'react'

import { MenuItem, MenuWithActionItems } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserDataSelector, getUserSettingsByPathSelector } from 'features/user/selectors'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { uploadFavoriteItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { createAndShareAlbumThunk, createNewAlbumWithItemsThunk } from 'features/albums/album/albumSlice'
import { useNavigate } from 'react-router-dom'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { appActions } from 'store/app'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { getContentTypesUploadSelector } from "../common/selectors"
import { openPreviewDuplicateThunk } from "../photo/photo-preview-duplicate/photoPreviewDuplicateSlice"
import { USER_SETTINGS_PATHS } from "../user/constants"
import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  getFavoritesItemsSelector,
  getFavoritesSelectedItemsSelector,
  getFavoritesTotalItemsCountSelector
} from './selectors'
import {
  copyFavoritesToFamilyThunk,
  downloadFavoritesItemsThunk,
  removeSelectedFromFavoritesItemsThunk,
  removeSelectedItemsThunk
} from './favoritesSlice'

interface TimelineToolbarProps {
    className?: string,
    type: SDK_TYPES
}

export const FavoriteToolbar: React.FC<TimelineToolbarProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userData = getUserDataSelector()
  const selectedItems = getFavoritesSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const contentTypesUpload = getContentTypesUploadSelector()
  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled
  const totalItemsCount = getFavoritesTotalItemsCountSelector()
  const items = getFavoritesItemsSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleUploadFiles = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      dispatch(uploadFavoriteItemsThunk({ files, callback }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const handleDownloadFiles = () => {
    dispatch(downloadFavoritesItemsThunk({ items: selectedItems, type }))
  }

  const handleCreateAlbumWithSelectedItems = () => {
    const callback = (id: string) => {
      navigate(`/photos/albums/${id}`)
    }

    dispatch(createNewAlbumWithItemsThunk({
      items: selectedItems,
      callback,
      type: type === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveItems = () => {
    dispatch(removeSelectedItemsThunk({ items: selectedItems, type }))
    handleCloseRemovingConfirmationModal()
  }

  const handleRemoveFromFavoritesItems = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: selectedItems }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }


  const handleAddFavorites = () => {
    navigate('/photos/favorites/add')
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  const handleShareItems = () => {
    dispatch(createAndShareAlbumThunk({
      items: selectedItems,
      type: type === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_CREATE_LINK)
  }

  const handleCopyToFamily = () => {
    dispatch(copyFavoritesToFamilyThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDuplicate = () => {
    if (selectedItems.length === 1) {
      dispatch(openPreviewDuplicateThunk({ selectedItem: selectedItems[0], items, totalItemsCount, type }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const menuItems = (() => {
    const items: React.ComponentProps<typeof MenuItem>[] = [

      !selectedItemsCount && {
        label: t('a_common_uploadPhotos'),
        iconName: 'download2',
        onClickItem: handleUploadFiles,
        type: 'file',
        accept: contentTypesUpload
      },

      selectedItemsCount && {
        label: t('a_common_createAlbumButton'),
        iconName: 'create_album',
        onClickItem: handleCreateAlbumWithSelectedItems
      },
      selectedItemsCount && {
        label: t('a_common_createPublicLink'),
        iconName: 'link',
        onClickItem: handleShareItems
      },
      isEnabledPhotoDuplicates && selectedItems.length === 1 && selectedItems[0].type === 'image' && {
        label: t('a_common_searchForSimilarPhotos'),
        iconName: 'search_duplicate',
        onClickItem: handleDuplicate
      },
      selectedItemsCount && {
        label: t('a_common_removeFavorite'),
        iconName: 'remove_favorite',
        onClickItem: handleRemoveFromFavoritesItems
      },
      selectedItemsCount && {
        label: t('a_common_remove'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)

    if (!!userData?.family_user_id && selectedItemsCount) {
      items.unshift({
        label: type === SDK_TYPES.FAMILY ? t('a_common_copyToPersonal') : t('a_common_copyToFamily'),
        iconName: type === SDK_TYPES.FAMILY ? 'add_photo_alternate_1' : 'family',
        onClickItem: handleCopyToFamily
      })
    }

    return items
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount) {
      items.push({
        label: t('l_common_addToFavorite'),
        iconName: 'heart',
        onClickItem: handleAddFavorites
      })
    } else {
      items.push({
        label: t('a_common_download'),
        iconName: 'download',
        onClickItem: handleDownloadFiles
      })
    }

    items = [...items, ...menuItems]

    return items
  })()

  if (type === SDK_TYPES.FAMILY && !userData?.family_user_id) {
    return null
  }

  return (
    <>
      <FavoritesToolbarBox className={className}>

        {
          !selectedItemsCount ? (
            <StyledPrimaryButton
              actionName={t('a_common_addToFavorites')}
              onClick={handleAddFavorites}
            >
              {t('a_common_addToFavorites')}
            </StyledPrimaryButton>
          ) : (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onClick={handleDownloadFiles}
            >
              {t('a_common_download')}
            </StyledPrimaryButton>
          )
        }

        {
          <MenuWithActionItems menuItems={menuItems} />
        }
      </FavoritesToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={!!selectedItemsCount && (
          <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
        )}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>

    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const FavoritesToolbarBox = styled.div`
  padding-right: 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled.label`
  background: var(--button-primary-default);
  border: 0;
  border-radius: 4px;
  color: var(--button-primary-text);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }
`
