import { getModalsRootElement } from 'constants/modals'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React, { useState } from 'react'

import {
  ConfirmationModalTypes,
  CreateNewFolderForm,
  MenuItem,
  MenuWithActionItems,
  ToolBarSubtitle
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { appActions } from 'store/app'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { sharingActions } from 'features/sharing/sharingSlice'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { useParams } from "react-router-dom"
import classNames from 'classnames'
import { IFsNodeSchema } from "@cloudike/web_files"

import { parsePathFromParams } from "../common/parsePathFromParams/parsePathFromParams"
import { getUiTheme } from "../user/selectors"
import { getIsBusy } from "../sharing/selectors"
import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  getCurrentFolderIdSelector,
  getCurrentFolderSelector,
  getFilesSelectedItemsIdsSelector,
  getFilesSelectedItemsSelector
} from './selectors'
import {
  checkAndUploadFilesThunk,
  createFolderThunk,
  deleteNodesThunk,
  downloadNodesThunk,
  filesActions,
  uploadFolderThunk
} from './filesSlice'
import { validationSchemaForFileItem } from './validationSchemaForFileItem'
import { CopyMoveModal, CopyMoveModalType } from './copy-move-modal/CopyMoveModal'
import { generateRemovingText, pushCreateEditItemPublicLink } from './filesUtils'


interface FilesToolbarProps {
    className?: string,
}

export const FilesToolbar: React.FC<FilesToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItemsIds = getFilesSelectedItemsIdsSelector()
  const selectedItems = getFilesSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const catalogId = getCurrentFolderIdSelector()
  const currentFolder = getCurrentFolderSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [newFolderModalOpened, toggleNewFolderModal] = useState(false)
  const [copyMoveModalType, setCopyMoveModalType] = useState(null)

  const params = useParams()
  const paths = parsePathFromParams(params, 'drive')
  const currentFileFolder = paths.length === 0 ? '' : paths[paths.length - 1]
  const isBusy = getIsBusy()

  const theme = getUiTheme()

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenNewFolderModal = () => {
    toggleNewFolderModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseNewFolderModal = () => {
    toggleNewFolderModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadSelected = () => {
    dispatch(downloadNodesThunk({ ids: selectedItemsIds }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveSelected = () => {
    dispatch(deleteNodesThunk({ ids: selectedItemsIds }))
    handleCloseRemovingConfirmationModal()
  }

  const handleUploadFiles = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      dispatch(checkAndUploadFilesThunk({ files, callback }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const handleUploadFolder = (event) => {
    const files = event.target?.files

    if (files.length === 0) {
      return
    }

    const callback = () => {
      event.target.value = ''
    }

    dispatch(uploadFolderThunk({ files, callback }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameNode = () => {
    dispatch(filesActions.setRenamingItemId(selectedItemsIds[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCreateFolder = (name: string) => {
    if (name) {
      dispatch(
        createFolderThunk({
          name,
          parentId: catalogId,
        })
      )
    }
    toggleNewFolderModal(false)
  }

  const handleShareSelected = (item: IFsNodeSchema) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))

    dispatch(sharingActions.setSharedItemData(selectedItems[0]))
    pushCreateEditItemPublicLink(item)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleShareCurrentFolder = () => {
    if (isBusy) return

    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(currentFolder))
    dispatch(appActions.toggleRightMenuOnMobile(false))

  }

  const handleCloseCopyMoveModal = () => {
    setCopyMoveModalType(null)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount) {
      items = !!currentFolder ? [
        {
          label: t('a_files_uploadFolder'),
          iconName: 'upload_folder',
          type: 'file',
          onClickItem: handleUploadFolder,
          accept: '*',
          acceptDirectory: true
        },
        {
          label: t('a_files_createFolder'),
          iconName: 'create_new_folder',
          onClickItem: handleOpenNewFolderModal
        },
        {
          label: currentFolder.is_shared ? t('a_common_editPublicLink') : t('a_common_createPublicLink'),
          iconName: currentFolder.is_shared ? 'link_off' : 'link',
          onClickItem: handleShareCurrentFolder
        }
      ] : [
        {
          label: t('a_files_uploadFolder'),
          iconName: 'upload_folder',
          type: 'file',
          onClickItem: handleUploadFolder,
          accept: '*',
          acceptDirectory: true
        },
        {
          label: t('a_files_createFolder'),
          iconName: 'create_new_folder',
          onClickItem: handleOpenNewFolderModal
        },
      ]
    }

    if (selectedItemsCount === 1) {
      items.push({
        label: selectedItems[0].is_shared ? t('a_common_editPublicLink') : t('a_common_createPublicLink'),
        iconName: selectedItems[0].is_shared ? 'link_off' : 'link',
        onClickItem: () => handleShareSelected(selectedItems[0])
      },
      {
        label: t('a_common_move'),
        iconName: 'forward',
        onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
      },
      {
        label: t('a_common_copy'),
        iconName: 'copy',
        onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
      },
      {
        label: t('a_common_rename'),
        iconName: 'create',
        onClickItem: handleRenameNode
      },
      {
        label: t('a_common_delete'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      })
    }

    if (selectedItemsCount > 1) {
      items.push(
        {
          label: t('a_common_move'),
          iconName: 'forward',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
        },
        {
          label: t('a_common_copy'),
          iconName: 'copy',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
        },
        {
          label: t('a_common_delete'),
          iconName: 'delete',
          onClickItem: handleOpenRemovingConfirmationModal
        })
    }

    return items
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount) {
      items = [{
        label: t('a_files_uploadFiles'),
        iconName: 'download2',
        type: 'file',
        onClickItem: handleUploadFiles,
        accept: '*'
      }, ...menuItems]
    } else {
      items = [{
        label: t('a_common_download'),
        iconName: 'download',
        onClickItem: handleDownloadSelected,
      }, ...menuItems
      ]
    }

    return items
  })()

  const headerContent = () => {
    if (!!selectedItemsCount) {
      return (
        <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
      )
    }
  }

  return (
    <>
      <FilesToolbarBox className={classNames(className, 'js-files-toolbar')}>
        {selectedItemsCount > 1 &&
                    <ToolBarSubtitle label={`${t('l_files_selectedFiles', { number: selectedItemsCount })}`} />
        }

        {
          !!selectedItemsCount && (
            <StyledPrimaryButton
              onClick={handleDownloadSelected}
            >
              {t('a_common_download')}
            </StyledPrimaryButton>
          )
        }

        {
          !selectedItemsCount && (
            <StyledPrimaryButton
              actionName={t('a_files_uploadFiles')}
              htmlFor="upload-btn"
            >
              {t('a_files_uploadFiles')}

              <HiddenFilesInput
                id="upload-btn"
                onChange={handleUploadFiles}
                type="file"
                multiple
                accept="*"
              />
            </StyledPrimaryButton>
          )}

        <MenuWithActionItems menuItems={menuItems} />
      </FilesToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={headerContent()}
        className="js-files-mobile-toolbar"
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={generateRemovingText(selectedItems, t).title}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveSelected}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
        type={ConfirmationModalTypes.danger}
      >
        <TextInModalBox>
          {generateRemovingText(selectedItems, t).text}
        </TextInModalBox>
      </DialogModal>

      {
        newFolderModalOpened && (
          <CreateNewFolderForm
            theme={theme}
            texts={{
              TITLE: t('l_common_createFolderTitle'),
              SUB_TITLE: t('l_common_folderName_field'),
              PLACEHOLDER: t('l_common_folderName_field'),
              NAME_BUTTON_ACTION: t('a_common_create'),
              NAME_BUTTON_CANCEL: t('a_common_close'),
              TITLE_ICON: 'create_new_folder',
            }}
            onCreateFolder={handleCreateFolder}
            onCloseModal={handleCloseNewFolderModal}
            isShown={true}
            parentBlock={getModalsRootElement()}
            validationSchema={validationSchemaForFileItem}
            defaultValue={t('l_files_createFolderPlaceholder')}
          />
        )}

      {
        !!copyMoveModalType && (
          <CopyMoveModal
            selectedItems={selectedItems}
            type={copyMoveModalType}
            currentFileFolder={currentFileFolder}
            onClose={handleCloseCopyMoveModal}
          />
        )}
    </>
  )
}

const TextInModalBox = styled.span`
  max-width: 500px;
  font-size: 14px;
  word-wrap: anywhere;
`

const FilesToolbarBox = styled.div`
  padding-right: 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled.label`
  background: var(--button-primary-default);
  border: 0;
  border-radius: 4px;
  color: var(--brand-text);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }
`

const HiddenFilesInput = styled.input`
  display: none;
`
