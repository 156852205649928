import { useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { ProfileTabs, ProfileTabsTypes } from "features/user/features/profile/ProfileTabs"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { fetchSubscriptionsThunk } from "features/user/userSlice"
import { ProfileStorage } from "features/user/features/profile/ProfileStorage"
import { ProfileStatistics } from "features/user/features/profile/ProfileStatistics"
// import { ProfileActivityLog } from "features/user/features/profile/ProfileActivityLog"
import { ProfilePageContentBox } from "features/user/features/profile/ProfileStyledComponents"
import { usePageTitle } from "features/common/hooks/usePageTitle"

const ProfileActivity = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  usePageTitle('l_settings_profileAndSettingsTitle')

  useEffect(() => {
    dispatch(fetchSubscriptionsThunk())
  }, [])

  return (
    <>
      <PageTopbar title={t('l_settings_profileAndSettingsTitle')} />

      <ProfilePageContentBox>
        <ProfileTabs activeTab={ProfileTabsTypes.ACTIVITY} />

        <ProfileStorage />

        <ProfileStatistics />

        {/*<ProfileActivityLog />*/}
      </ProfilePageContentBox>
    </>
  )
}

export default ProfileActivity
