import { useLayoutEffect, useRef, useState } from "react"

import classNames from "classnames"

import { StyledVideo } from "./styles"

export const VideoPreview = ({ onCanPlay, onError, src }) => {
  const videoRef = useRef(null)
  const [isLoaded, toggleIsLoaded] = useState(false)

  useLayoutEffect(() => {
    videoRef.current.src = src


    return () => {
      videoRef.current.src = ''
    }
  }, [src])

  const handleOnCanPlay = () => {
    onCanPlay()
    toggleIsLoaded(true)
  }

  return (
    <StyledVideo
      onCanPlay={handleOnCanPlay}
      autoPlay={true}
      onError={onError}
      controls
      preload="auto"
      ref={videoRef}
      className={classNames({ 'not-loaded': !isLoaded })}
    />
  )
}
