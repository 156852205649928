import { useEffect, useState } from "react"

import classNames from "classnames"
import styled from 'styled-components'

interface PageOverlayProps {
  onClick?: () => void,
  active: boolean,
  animationDuration?: number
}

export const PageOverlay: React.FC<PageOverlayProps> = ({ onClick, active, animationDuration = 0.2 }) => {
  const [visible, toggleVisibility] = useState(false)

  useEffect(() => {
    if (active) {
      toggleVisibility(true)
      document.body.style.overflow = 'hidden'
    } else {
      setTimeout(() => {
        toggleVisibility(false)
        document.body.style.overflow = 'auto'
      }, animationDuration * 1000)
    }
  }, [active])

  if (!active && !visible) {
    return null
  }

  return (
    <PageOverlayBox
      onClick={onClick}
      className={classNames({ visible: visible && active })}
      animationDuration={animationDuration}
    />
  )
}

const PageOverlayBox = styled.div`
  position: fixed;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(37,42,49,.5);
  opacity: 0;
  transition: opacity ${props => `${props.animationDuration}s`} linear;

  &.visible {
    opacity: 1;
  }
`