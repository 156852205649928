import { request } from "api/request"
import i18n from "i18n"
import _ from "lodash"


export const generateKey = (prefix: string | number) => {
  return `${ prefix }_${ new Date().getTime() }`
}

export const toMb = (size: number) => {
  return (size / 1024 / 1024).toFixed(2) + ' MB'
}

export const toGb = (size: number) => {
  return Number((parseInt((size / 1024 / 1024 / 1024).toFixed(1))) % 1 === 0 ? (size / 1024 / 1024 / 1024).toFixed(2) : (size / 1024 / 1024 / 1024).toFixed(2))
}

export const convertFromBytes = (bytes) => {
  const tresshold = 1

  const kb = bytes / 1024
  const mb = kb / 1024
  const gb = mb / 1024
  const tb = gb / 1024
  const pb = tb / 1024

  const format = (value, unit, accuracy = 2) => {
    return `${Math.floor(value * Math.pow(10, accuracy)) / Math.pow(10, accuracy)} ${unit}`
  }

  if (pb >= tresshold) {
    return format(pb, i18n.t('l_common_storageUnitPB'))
  }

  if (tb >= tresshold) {
    return format(tb, i18n.t('l_common_storageUnitTB'))
  }

  if (gb >= tresshold) {
    return format(gb, i18n.t('l_common_storageUnitGB'))
  }

  if (mb >= tresshold) {
    return format(mb, i18n.t('l_common_storageUnitMB'), 1)
  }

  if (kb >= tresshold) {
    return format(kb, i18n.t('l_common_storageUnitKB'), 0)
  }

  return format(bytes, i18n.t('l_common_bytes'), 0)
}

export const getSubscriptionPageUrl = ({ url, lang, token, back_to, timestamp = new Date().getTime(), platform = 'web' }) => {
  if (!url) {
    return null
  }

  const template = _.template(url, { interpolate: /\{\{(.+?)\}\}/g })

  const urlSearchParamsObj = new URL(url).searchParams

  const availableParamsObj = {
    lang,
    token,
    timestamp,
    platform,
    back_to
  }

  const resultParams = Object.keys(urlSearchParamsObj).reduce((acc, item) => {
    if (!acc[item]) acc[item] = null
    return acc
  }, availableParamsObj)

  return template(resultParams)
}


export const downloadItemByLink = async (link) => {
  const {
    _links: {
      data: { href }
    }
  } = await request('GET', link, {}, { host: null })

  return href
}

export const getErrorByFieldName = (error: any, fieldName: string) => {
  if (_.has(error, 'cause')) {
    return getErrorByFieldName(error.cause, fieldName)
  }
  return error[fieldName]
}

export const getHighestLevelErrorByFieldName = (error: any, fieldName: string) => {
  if (error[fieldName]) {
    return error[fieldName]
  }

  if (_.has(error, 'cause')) {
    return getHighestLevelErrorByFieldName(error.cause, fieldName)
  }
}
