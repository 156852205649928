import { SkeletonPhotoGrid, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import styled from 'styled-components'

export const DocumentsBlurredSkeleton = () => {
  return (
    <>
      <SkeletonBox>
        <SkeletonTitle />

        <SkeletonPhotoGrid>
          {Array.from({ length: 16 }).map((_, index) => {
            return (
              <SkeletonCard key={index} />
            )
          })}
        </SkeletonPhotoGrid>

        <SkeletonTitle />

        <SkeletonPhotoGrid>
          {Array.from({ length: 30 }).map((_, index) => {
            return (
              <SkeletonCard key={index} />
            )
          })}
        </SkeletonPhotoGrid>

        <SkeletonOverlay />
      </SkeletonBox>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

const SkeletonBox = styled.div`
  position: absolute;
  width: 100%;
  height: 99%;
  padding-left: 36px;
  top: 10px;
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: block;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.DESKTOP}) {
    padding-right: 36px;
  }
`

const SkeletonOverlay = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-primary), 0.8;
  backdrop-filter: blur(7.5px);
`

const SkeletonTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  width: 150px;
  height: 40px;
  background: var(--background-tertiary);
`
