import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { BaseQueryFn } from "@reduxjs/toolkit/src/query/baseQueryTypes"
import {
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery"

import { getCurrentAppConfigApiHost } from "../features/common/configs/additionalConfigService"

export const baseQueryConfig: BaseQueryFn<string | FetchArgs,
  unknown,
  FetchBaseQueryError> = async (args, WebApi, extraOptions) => {
    const baseUrl = getCurrentAppConfigApiHost()

    const prepareHeaders = (headers) => {
      if (JSON.parse(localStorage.getItem('CLOUDIKECLIENT') as string)?.CurrentUser) {
        headers.set('Mountbit-Auth', JSON.parse(localStorage.getItem('CLOUDIKECLIENT') as string).CurrentUser?.token)
      } else if (JSON.parse(`${localStorage.getItem('CLIENT')}`)) {
        headers.set('Mountbit-Auth', JSON.parse(`${localStorage.getItem('CLIENT')}`).token)
      }

      return headers
    }

    const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders })

    return rawBaseQuery(args, WebApi, extraOptions)
  }

export default {
  baseQueryConfig
}
