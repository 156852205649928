import * as React from "react"

import * as Sentry from "@sentry/react"
import { Event, replayIntegration } from "@sentry/react"
import { User } from "@sentry/types/types/user"
import type { BrowserOptions } from "@sentry/browser/types/client"
import { Dedupe, HttpClient } from "@sentry/integrations"

import appConfig from "../../constants/configs/app.config"

/**
 * Initialize Sentry
 * @see https://docs.sentry.io/platforms/javascript/guides/react/
 */
export const isSentryEnabled = !!appConfig?.sentry?.enabled

export const initSentry = (options?: BrowserOptions) => {
  try {
    const settings = appConfig?.sentry
    const enabled = !!settings?.enabled
    const dsn = settings?.dsn
    const tracePropagationTargets =  settings?.tracePropagationTargets || []

    const environment = process.env.NODE_ENV
    const isProdEnv = environment === 'production'
    const release = `${process.env.APP_NAME}@${process.env.APP_VERSION}`
    const debug = process.env.NODE_ENV === 'development'
    const sampleRate = 1 // 50% of events will be sent to Sentry
    const replaysSessionSampleRate = isProdEnv ? 0.1 : 1.0
    const tracesSampleRate = isProdEnv ? 0.1 : 1.0

    const defaultOptions = {
      enabled,
      dsn,
      environment,
      release,
      debug,
      sampleRate,
      beforeSend,
      // Replays
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // Performance
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets,
      // HttpClient
      // This option is required for capturing headers and cookies.
      sendDefaultPii: true,
      integrations: [
        new Sentry.BrowserTracing(),
        replayIntegration(),
        new HttpClient(),
        new Dedupe(),
      ]
    }

    // Merge options
    if(options) {
      return Sentry.init({
        ...defaultOptions,
        ...options,
      })
    }

    Sentry.init(defaultOptions)
  } catch (e) {
    console.log("initSentry error", e)
  }
}

/**
 * Before send event
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/
 */
const beforeSend = (event) => {
  // Modify the event here
  return event
}

export const setSentrySessionUser = (user: User) => {
  try{
    Sentry.setUser(user)
  }catch(e){
    console.log('setSentrySessionUser error', e)
  }
}

export const clearSentrySessionUser = () => {
  try{
    Sentry.setUser(null)
  }catch(e){
    console.log('clearSentrySessionUser error', e)
  }
}

export const getSentryWithProfiler = (component: React.ComponentType) => {
  if(!isSentryEnabled) return component
  return Sentry.withProfiler(component)
}

// Capture events manually
export const captureSentryEvent = (event: Event) => {
  if(!isSentryEnabled) return
  Sentry.captureEvent(event)
}

// Capture exceptions manually
// example: captureSentryException(new Error('Something broke'))
export const captureSentryException = (exception: any) => {
  if(!isSentryEnabled) return
  Sentry.captureException(exception)
}

// Capture messages manually
export const captureSentryMessage = (message: string) => {
  if(!isSentryEnabled) return
  Sentry.captureMessage(message)
}

