export const DROPBOX = { name: 'dropbox', label: 'Dropbox' }
export const GOOGLE_DRIVE = { name: 'google', label: 'Google Drive' }

export const getCloudLabelByName = (type) => {
  switch (type) {
  case DROPBOX.name:
    return DROPBOX.label
  case GOOGLE_DRIVE.name:
    return GOOGLE_DRIVE.label
  default:
    return ''
  }
}