const THEME_KEY = 'theme'

function isElectronSupported() {
  return window.electron !== undefined
}

function isElectronStoreSupported() {
  return isElectronSupported() && window.electron.store !== undefined
}

function saveThemeToStore(theme: string) {
  if (isElectronStoreSupported() && theme) {
    window.electron.store.set(THEME_KEY, theme)
  }
}

function getThemeFromStore() {
  if (isElectronStoreSupported()) {
    return window.electron.store.get(THEME_KEY)
  }
  return null
}

async function handleChangeTheme(theme: string) {
  if(isElectronSupported()) {
    await window.electron.app.handleThemeChange(theme)
  }
}

export {
  isElectronSupported,
  isElectronStoreSupported,
  saveThemeToStore,
  getThemeFromStore,
  handleChangeTheme,
  THEME_KEY
}
