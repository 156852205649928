import { useEffect, useRef, useState } from "react"

import {
  ConfirmationModalTypes,
  FilesTableHeader,
  FilesTableRow,
  FilesTableTypes,
  MenuItem,
  SpriteIcon
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useWindowVirtualizer } from '@tanstack/react-virtual'

import { sharingActions } from "../sharing/sharingSlice"
import { SDK_TYPES } from "../../sdk/sdkConstants"
import { DialogModal } from "../../ui/DialogModal"
import { appActions } from "../../store/app"
import { parsePathFromParams } from "../common/parsePathFromParams/parsePathFromParams"
import { getUiTheme } from "../user/selectors"

import { validationSchemaForFileItem } from './validationSchemaForFileItem'
import { deleteNodesThunk, downloadNodesThunk, filesActions } from "./filesSlice"
import { CopyMoveModal, CopyMoveModalType } from "./copy-move-modal/CopyMoveModal"
import { formatFileName, generateRemovingText } from "./filesUtils"

export const FilesList = ({
  items,
  columns,
  selectType,
  checkboxVisibility,
  onChangeFolder,
  onToggleItem,
  onRenameItem,
  onSortingChange,
  onClickAllCheckbox,
  onClickCheckbox,
  selectedFilesIds,
  renamingFileId = null,
  onEditPublicLink,
  type = FilesTableTypes.default,
  fileForScrollingId = null,
  withoutContextMenu = false
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [copyMoveModalType, setCopyMoveModalType] = useState(null)
  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [itemRightClick, setItemRightClick] = useState(null)

  const params = useParams()
  const paths = parsePathFromParams(params, 'drive')

  const theme = getUiTheme()

  const listRef = useRef(null)

  const rowVirtualizer = useWindowVirtualizer({
    count: items.length,
    estimateSize: () => 56,
    overscan: 5,
  })

  useEffect(() => {
    if (!fileForScrollingId || !items.length) {
      return
    }

    const index = items.findIndex((item) => item.id === fileForScrollingId)

    if (index !== -1) {
      rowVirtualizer.scrollToIndex(index)
    }
  }, [fileForScrollingId, items])

  const handleRenameItem = (_, newName: string) => {
    onRenameItem(newName)
  }

  const allFileIds = items.map((item) => item.id)

  const handleRenameNode = () => {
    dispatch(filesActions.setRenamingItemId(itemRightClick.id))
  }

  const handleShareSelected = () => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(itemRightClick))
  }

  const handleCloseCopyMoveModal = () => {
    setCopyMoveModalType(null)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveSelected = () => {
    dispatch(deleteNodesThunk({ ids: [itemRightClick.id] }))
    handleCloseRemovingConfirmationModal()
  }

  const handleDownloadSelected = () => {
    dispatch(downloadNodesThunk({ ids: [itemRightClick.id] }))
  }

  const menuItems = (item) => {
    const items: React.ComponentProps<typeof MenuItem>[] = [
      {
        label: t('a_common_download'),
        icon: <SpriteIcon iconName={'download'} />,
        onClickItem: handleDownloadSelected,
      },
      {
        label: item?.is_shared ? t('a_common_editPublicLink') : t('a_common_createPublicLink'),
        iconName: 'link',
        onClickItem: handleShareSelected.bind(null, item)
      },
      {
        label: t('a_common_rename'),
        iconName: 'create',
        onClickItem: handleRenameNode
      },
      {
        label: t('a_common_move'),
        iconName: 'forward',
        onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
      },
      {
        label: t('a_common_copy'),
        iconName: 'copy',
        onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
      },
      {
        label: t('a_common_remove'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      }]

    return items
  }

  const filesTableMobileBreakpoint = 767

  const filesList = rowVirtualizer.getVirtualItems().map((virtualItem) => {
    const item = items[virtualItem.index]

    return (
      <SFilesTableRow
        theme={theme}
        onClickCheckbox={() => onClickCheckbox(item, items)}
        mobileBreakpoint={filesTableMobileBreakpoint}
        menuItems={menuItems(item)}
        sharedPromptText={item?.is_shared ? t('a_common_editPublicLink') : t('a_common_createPublicLink')}
        onRightClick={() => {
          setItemRightClick(item)
        }}
        item={item}
        formattedName={formatFileName(item.name)}
        onOpenedItem={() => onChangeFolder(item)}
        onSelectedItem={() => onToggleItem(item, items)}
        onRenamedItem={handleRenameItem}
        operationModifiers={{
          selected: selectedFilesIds.includes(item.id),
          renamed: item.id === renamingFileId ? 'renaming' : null
        }}
        validationSchema={validationSchemaForFileItem}
        type={type as FilesTableTypes}
        onEditPublicLink={() => {
          onEditPublicLink(item)
        }}
        withoutContextMenu={withoutContextMenu}
        key={virtualItem.key}
        height={`${virtualItem.size}px`}
        transform={`translateY(${virtualItem.start}px)`}
      />
    )})

  return (
    <>
      <FileListBox checkboxVisibility={checkboxVisibility}
        className="js-files-list"
      >
        <FilesTableHeader
          columns={columns}
          selectType={selectType}
          onSelectItems={() => {
            onClickAllCheckbox(allFileIds)
          }}
          onSortingChange={onSortingChange}
          type={type}
        />

        <FileListItemsWrapper ref={listRef}>
          <FileListItemsInnerWrapper height={rowVirtualizer.getTotalSize() + 'px'}>
            {filesList}
          </FileListItemsInnerWrapper>
        </FileListItemsWrapper>
      </FileListBox>

      {
        !!itemRightClick && (
          <DialogModal
            isOpened={removingConfirmationModalOpened}
            title={generateRemovingText([itemRightClick], t).title}
            onClose={handleCloseRemovingConfirmationModal}
            okText={t('a_common_ok')}
            onOk={handleRemoveSelected}
            cancelText={t('a_common_cancel')}
            onCancel={handleCloseRemovingConfirmationModal}
            type={ConfirmationModalTypes.danger}
          >
            <TextInModalBox>
              {generateRemovingText([itemRightClick], t).text}
            </TextInModalBox>
          </DialogModal>
        )}

      {
        !!copyMoveModalType && (
          <CopyMoveModal
            currentFileFolder={paths[paths.length - 1]}
            selectedItems={[itemRightClick]}
            type={copyMoveModalType}
            onClose={handleCloseCopyMoveModal}
          />
        )}
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const FileListBox = styled.div`
  display: flex;
  flex-flow: column;

  & .tableRow__check-hover {
    visibility: ${props => props.checkboxVisibility};
  }
`

const FileListItemsWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`

const FileListItemsInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
`

const SFilesTableRow = styled(FilesTableRow)`
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.height};
  transform: ${props => props.transform};
`
