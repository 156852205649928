import React, { useCallback, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useUploadingFinishDetection } from 'features/common/files-uploading/useUploadingFinishDetection'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { useTranslation } from 'react-i18next'
import { uploadFavoriteItemsThunk, uploadTimelineItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { PageSpiner, PhotoCard, PhotosGrid, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import classNames from "classnames"

import { usePreviewItemsUpdate } from '../photo/photo-preview/usePreviewItemsUpdate'
import { openPhotoPreviewThunk, PREVIEW_TYPES } from '../photo/photo-preview/photoPreviewSlice'

import {
  getFavoritesItemsSelector,
  getFavoritesSelectedItemsCountSelector,
  getFavoritesSelectedItemsIdsSelector,
  getFavoritesTotalItemsCountSelector
} from './selectors'
import { loadJustUploadedFavoritesItemsThunk, loadMoreFavoritesItemsThunk, favoritesActions } from './favoritesSlice'
import { EmptyFavoritesPlaceholder } from "./EmptyFavoritesPlaceholder"

interface TimelineProps {
  type: SDK_TYPES,
  className?: string,
  withDisabledPreview?: boolean
}

export const Favorites: React.FC<TimelineProps> = ({ className = '', type }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const items = getFavoritesItemsSelector()
  const selectedItemsIds = getFavoritesSelectedItemsIdsSelector()
  const selectedItemsCount = getFavoritesSelectedItemsCountSelector()
  const totalItemsCount = getFavoritesTotalItemsCountSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(loadMoreFavoritesItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items.length])

  usePreviewItemsUpdate(items, totalItemsCount)

  useUploadingFinishDetection(() => dispatch(loadJustUploadedFavoritesItemsThunk()))

  const handleItemSelection = useCallback((id) => {
    dispatch(favoritesActions.selectItem(id))
  }, [])

  const handleOpenPreview = useCallback((id) => {
    dispatch(openPhotoPreviewThunk({
      items,
      totalItemsCount,
      currentItemId: id,
      sdkType: type,
      type: PREVIEW_TYPES.FAVORITES,
      loadMore
    }))
  }, [items, totalItemsCount])

  const handleUploadFiles = (acceptedFiles: FileList) => {
    if (acceptedFiles.length) {
      dispatch(uploadFavoriteItemsThunk({ files: acceptedFiles }))
    }
  }

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFiles }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false,
    }))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
    }
  }, [])

  if (!items.length) {
    return (
      <FavoritesBox>
        <EmptyFavoritesPlaceholder
          texts={{
            title: t('l_favorites_noTitle'),
            description: t('l_favorites_noMessage'),
            uploadBtn: t('a_common_addToFavorites')
          }}
        />

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      </FavoritesBox>
    )
  }

  return (
    <FavoritesBox
      className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
    >
      <FavoritesItemsBox>
        <PhotosGrid>
          {items.map(item => (
            <StyledPhotoCard
              key={item.id}
              id={item.id}
              imgUrl={(item._links as any)?.image_middle?.href}
              isSelected={selectedItemsIds.includes(item.id)}
              onSelect={handleItemSelection}
              onClick={handleOpenPreview}
              isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
              type={item.type}
              isFavorite={item.favorite}
            />
          ))}
        </PhotosGrid>

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner  />
          </div>
        )}
      </FavoritesItemsBox>

      <DropzoneArea
        visible={isDropzoneAreaVisible}
      />
    </FavoritesBox>
  )
}

const FavoritesBox = styled.div`
  width: 100%;
  position: relative;
  min-height: calc(100vh - 120px);

  &.with-overflow {
    max-height: calc(100vh - 120px);
    overflow: hidden;
  }
`

const FavoritesItemsBox = styled.div`
  width: 100%;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`

const StyledPhotoCard = styled(PhotoCard)`
  @-moz-document url-prefix() {
    @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
      svg {
        filter: unset;
      }
    }
  }  
`
