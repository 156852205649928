import _ from "lodash"

export const getPreviewAlbumLink = (item, size_name?) => {
  if(_.has(item._embedded,'covers')){
    const urlTemplate = _.template(item._embedded.covers[0]._embedded.previews.url_template, { interpolate: /\{(.+?)\}/g })
    const url = urlTemplate({ size_name }) + `&jwt=${item._embedded.covers[0]._embedded.previews.jwt}`

    return url
  }

  return item._embedded?.cover_item?._links?.image_middle?.href
}
