import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { t } from "i18next"

import { LOADING_STATUSES } from "../../../constants/loadingStatuses"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { openPhotoPreviewThunk, PREVIEW_TYPES } from "../photo-preview/photoPreviewSlice"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { photosApi } from "../../../api/photos"

interface State {
  currentItemIndex: number,
  status: LOADING_STATUSES,
  totalItemsCount: number,
  hasError: boolean,
  duplicateMode: boolean,
}

interface EnhancedItemSchema extends Omit<IItemSchema, 'type'> {
  isSelect?: boolean,
  isOrigin?: boolean,
  type: 'image' | 'video' | 'pdf'
}

const adapter = createEntityAdapter<EnhancedItemSchema>()

export const getPhotosDuplicateThunk = createAsyncThunk(
  'photoPreviewDuplicate/getPhotosDuplicateThunk',
  async function({ item }: any, { getState, dispatch }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    const familyId = state.user.userData.family_user_id
    const sdkType = state.photoPreview.sdkType

    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_SEARCH_SIMILAR_CLICK)

    try {
      const rsp = await photosApi.getDuplicate(sdkType === SDK_TYPES.FAMILY ? familyId : userId, item.id)

      dispatch(actions.setStatus(LOADING_STATUSES.SUCCEEDED))
      dispatch(actions.setDuplicateMode(true))

      if(!!rsp._embedded.items.length) {
        await dispatch(actions.addOriginPhoto(item))
        dispatch(actions.addManyPhotos(rsp))
        dispatch(actions.setTotalItemsCount(rsp._embedded.items.length + 1))
      }
    } catch (e) {
      dispatch(actions.setStatus(LOADING_STATUSES.FAILED))
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const openPreviewDuplicateThunk = createAsyncThunk(
  'photoPreviewDuplicate/openPreviewDuplicateThunk',
  async function({ selectedItem, items, totalItemsCount, type }: any, { dispatch }) {

    await dispatch(openPhotoPreviewThunk({
      items,
      totalItemsCount,
      currentItemId: selectedItem.id,
      sdkType: type,
      type: PREVIEW_TYPES.TIMELINE,
    }))
    await dispatch(getPhotosDuplicateThunk({ item: selectedItem }))
  }
)

export const photoPreviewDuplicateSelectors = adapter.getSelectors()

export const photoPreviewDuplicateSlice = createSlice({
  name: 'photoPreviewDuplicate',
  initialState: adapter.getInitialState<State>({
    currentItemIndex: 0,
    status: LOADING_STATUSES.LOADING,
    totalItemsCount: 0,
    hasError: false,
    duplicateMode: false,
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    addOriginPhoto: (state, action) => {
      adapter.addOne(state, { ...action.payload, isSelect: true, isOrigin: true })
    },
    setDuplicateMode: (state, action) => {
      state.duplicateMode = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    addManyPhotos: (state, action) => {
      const array = action.payload._embedded.items.map(item => {
        return { ...item, isSelect: false, isOrigin: false }
      })
      adapter.addMany(state, array)
    },
    changeSelectPhoto: (state, action) => {
      const items = photoPreviewDuplicateSelectors.selectAll(state)
      items.forEach( item => {
        item.id === action.payload
          ? adapter.updateOne(state, { id: item.id,
            changes: { isSelect: true }
          })
          : adapter.updateOne(state, { id: item.id,
            changes: { isSelect: false }
          })
      })
    },
    setTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload
    },
    deleteItem: (state, action) => {
      const items = photoPreviewDuplicateSelectors.selectAll(state)
      const currentItem = items.find(item => item.id === action.payload)
      const indexItem = items.findIndex(item => item.id === action.payload)
      const updateIndex = items.length === indexItem + 1 ? indexItem - 1 : indexItem + 1

      if (!!currentItem && currentItem.isSelect) {
        items.forEach(item => {
          adapter.updateOne(state, { id: item.id,
            changes: { isSelect: false }
          })
        })

        if (items.length > 1) adapter.updateOne(state, { id: items[updateIndex].id,
          changes: { isSelect: true }
        })
      }

      adapter.removeOne(state, action.payload)
    },
    resetState: (state) => {
      adapter.removeAll(state)
      state.duplicateMode = false
      state.status = LOADING_STATUSES.LOADING
      state.hasError = false
      state.totalItemsCount = 0
    }
  },
})

const {
  reducer, actions
} = photoPreviewDuplicateSlice

export { reducer as photoPreviewDuplicateReducer, actions as photoPreviewDuplicateActions }
