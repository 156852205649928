import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { useNavigate } from "react-router-dom"

import { AddingToolbar } from "../common/add-toolbar/AddingToolbar"
import { appActions } from "../../store/app"
import { getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "../photo/timeline/selectors"
import { addTimelineItemsToFamilyThunk } from "../photo/timeline/timelineSlice"

export const AddingPhotosToFamilyToolbar = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()

  const handleAddPhotos = () => {
    const callback = () => {
      navigate('/family/photos')
    }

    dispatch(addTimelineItemsToFamilyThunk({ items: selectedItems, callback }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return (
    <AddingToolbar onAction={handleAddPhotos}
      actionName={t('a_family_addToFamily')}
      selectedItemsCount={selectedItemsCount}
    />
  )
}
