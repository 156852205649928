import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect } from "react"

import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { loadTrashBinItemsFirstTimeThunk, subscribeTrashBinToWSThunk, trashBinActions, TRASH_BIN_TYPES, unsubscribeTrashBinFromWSThunk } from "features/trash-bin/trashBinSlice"
import { TrashBinTabs } from "features/trash-bin/TrashBinTabs"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { TrashBinPhotos } from "features/trash-bin/TrashBinPhotos"
import { PhotosGrid } from "@cloudike/web_ui_components"
import styled from 'styled-components'
import { getTrashBinLoadingStatusSelector, getTrashBinSelectedItemsCountSelector, getTrashBinTotalItemsCountSelector } from "features/trash-bin/selectors"
import { useMobileDetection } from "features/common/hooks"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"

interface TrashBinPhotosPage {
  type: TRASH_BIN_TYPES
}

const TrashBinPhotosPage: React.FC<TrashBinPhotosPage> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const status = getTrashBinLoadingStatusSelector()
  const selectedItemsCount = getTrashBinSelectedItemsCountSelector()
  const totalItemsCount = getTrashBinTotalItemsCountSelector()

  const isMobile = useMobileDetection()

  const handleCancelSelection = useCallback(() => {
    dispatch(trashBinActions.unselectAll())
  }, [])

  usePageTitle('a_nav_trash')

  useEffect(() => {
    dispatch(loadTrashBinItemsFirstTimeThunk({ type }))
    dispatch(subscribeTrashBinToWSThunk())

    return () => {
      dispatch(trashBinActions.resetState())
      dispatch(unsubscribeTrashBinFromWSThunk())
    }
  }, [])

  return (
    <>
      <PageTopbar title={t('a_nav_trash')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />

      <TrashBinTabs activeTab={type} />

      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING &&
        <TrashBinPhotos />
      }

      {totalItemsCount > 0 && <MobileToolbarMenuToggle />}
    </>
  )
}

const Skeleton = () => {
  return (
    <>
      <PhotosGrid>
        {Array.from({ length: 12 }).map((_, index) => {
          return (
            <SkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

export default TrashBinPhotosPage
