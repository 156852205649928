import { useAppSelector } from "store"
import { NodeTypes } from "@cloudike/web_files"
import { createSelector } from "@reduxjs/toolkit"

import { copyMoveModalSelectors } from "./copyMoveModalSlice"

const getCopyMoveModalNodesRawSelector = state => copyMoveModalSelectors.selectAll(state.copyMoveModal)

export const getCopyMoveModalNodesSelector = () => useAppSelector(
  createSelector(
    getCopyMoveModalNodesRawSelector,
    items =>
      items.filter(node => node.type === NodeTypes.DIR)
  ))

export const getCopyMoveModalBreadcrumbsSelector = () => useAppSelector(state => state.copyMoveModal.breadcrumbs)

export const getCopyMoveModalStatusSelector = () => useAppSelector(state => state.copyMoveModal.status)
