import { STYLED_VARIABLES } from 'constants/styledVariables'
import { formatImgUrl } from 'constants/configs/theme.config'

import React from 'react'

import { SecondaryButton } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { useNavigate } from 'react-router-dom'
import { SDK_TYPES } from 'sdk/sdkConstants'
import styled from 'styled-components'

import { albumActions, createNewEmptyAlbumThunk } from '../album/albumSlice'
import { getUiTheme } from "../../user/selectors"
import { THEME } from "../../user/userSlice"

interface EmptyAlbumsListPlaceholderProps {
  className?: string,
  type: SDK_TYPES
}

export const EmptyAlbumsListPlaceholder: React.FC<EmptyAlbumsListPlaceholderProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const theme = getUiTheme()

  const handleCreateNewAlbum = () => {
    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }
    }

    dispatch(createNewEmptyAlbumThunk({ type, name: '', callback }))
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
  }

  return (
    <EmptyAlbumsListPlaceholderBox
      className={className}
    >
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/no-albums.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/no-albums.svg")} />}

      <EmptyTitle>
        {t('l_common_noAlbumsPlaceholder')}
      </EmptyTitle>

      <EmptyText>
        {t('l_common_photosEmptyPlaceholder', {
          HIGHLIGHTED_TEXT: (
            <span key="1">
              {t('a_common_createAlbumButton')}
            </span>)
        })}
      </EmptyText>

      <StyledSecondaryButton
        actionName={t('a_common_createAlbumButton')}
        onAction={handleCreateNewAlbum}
      >
        {t('a_common_createAlbumButton')}
      </StyledSecondaryButton>
    </EmptyAlbumsListPlaceholderBox>
  )
}

const EmptyAlbumsListPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
`

const ContainerImg = styled.img`
  width: 292px;
  height: 140px;
`

const EmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
  color: var(--text-primary);
  max-width: 520px;
  text-align: center;

  span {
    font-weight: 500;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: block;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 32px;
  height: 40px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`
