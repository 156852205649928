import { createSelector } from "@reduxjs/toolkit"
import _ from "lodash"
import { useAppSelector } from "store"

import { filesSelectors } from "./trashBinFilesSlice"

export const getTrashBinFilesLoadingStatusSelector = () => useAppSelector(state =>
  state.trashBinFiles.status
)

export const getTrashBinFilesSelectType = () => useAppSelector(state =>
  state.trashBinFiles.selectType
)

export const getTrashBinFilesCheckboxVisibilitySelector = () => useAppSelector(state =>
  state.trashBinFiles.checkboxVisibility
)

export const getTrashBinFilesNodesRawSelector = state => filesSelectors.selectAll(state.trashBinFiles)

export const getTrashBinFilesSelectedItemsIdsRawSelector = state => state.trashBinFiles.selectedItemsIds

export const getTrashBinFilesNodesSelector = () => useAppSelector(getTrashBinFilesNodesRawSelector)

export const getTrashBinFilesSortSelector = () => useAppSelector(state =>
  state.trashBinFiles.sort
)

export const getTrashBinFilesSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getTrashBinFilesNodesRawSelector,
    getTrashBinFilesSelectedItemsIdsRawSelector,
    (nodes, selectedItemsIds) => nodes.filter(item => selectedItemsIds.includes(item.id))
  )
)

export const getTrashBinFilesSelectedItemsIdsSelector = () => useAppSelector(getTrashBinFilesSelectedItemsIdsRawSelector)

export const getTrashBinFilesSelectedItemsCountSelector = () => useAppSelector(state =>
  state.trashBinFiles.selectedItemsIds.length
)

const getSortedEntities = createSelector(
  getTrashBinFilesNodesRawSelector,
  state => state.trashBinFiles.sort,
  (entities, { by, direction }) => {
    const orderByType = (entity) => entity.type

    return _.chain(entities)
      .orderBy(by, [direction])
      .orderBy(orderByType)
      .value()
  }
)

export const getTrashBinFilesSortedNodesSelector = () => useAppSelector(getSortedEntities)
