import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { addTimelineItemsToFamilyThunk, loadTimelineItemsFirstTimeThunk, timelineActions } from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import { getTimelineLoadingStatusSelector, getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "features/photo/timeline/selectors"
import { useNavigate } from "react-router-dom"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"

import { STYLED_VARIABLES } from "../../../constants/styledVariables"
import { appActions } from "../../../store/app"

const AddingPhotosToFamilyCloudPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const status = getTimelineLoadingStatusSelector()

  const isMobile = useMobileDetection()

  usePageTitle('a_nav_familyManage')

  useEffect(() => {
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.DEFAULT))
    dispatch(loadTimelineItemsFirstTimeThunk())

    return () => {
      dispatch(timelineActions.resetState())
    }
  }, [])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleBack = () => {
    navigate('/family/photos')
  }

  const handleAddPhotos = () => {
    const callback = () => {
      navigate('/family/photos')
    }

    dispatch(addTimelineItemsToFamilyThunk({ items: selectedItems, callback }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return (
    <>
      <PageTopbarBox
        title={t('l_family_addToFamily')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
        description={!isMobile ? t('l_family_timelineMessage') : ''}
        onAccept={isMobile && handleAddPhotos}
        isAcceptBtnActive={isMobile && !!selectedItems.length}
        onBack={handleBack}
        isMobile={isMobile}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSceleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <TimelineBox
            type={SDK_TYPES.DEFAULT}
            withDisabledPreview
          />
        )
      }
    </>
  )
}

const StyledSceleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

const PageTopbarBox = styled(PageTopbar)`
  position: fixed !important;
  top: 0;
  background: var(--background-primary);
  z-index: 5;
  width: calc(100% - 542px) !important;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    width: calc(100% - 330px) !important;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: calc(100% - 48px) !important;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: calc(100%) !important;
    margin-left: -16px;
  }
`

const TimelineBox = styled(Timeline)`
  margin-top: 100px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 80px;
  }
`

export default AddingPhotosToFamilyCloudPage
