import { useEffect } from "react"

import { useLocation } from "react-router-dom"

export default function useScrollToTopAfterLocationChanging() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}