import React from "react"

import styled from 'styled-components'

export const AlbumsPlacesTitle = ({ totalCount, countAlbums, handleSeeAllClick, textTitle, textButton }) => {
  return (
    <TitleBlockBox>
      <TitleTextBox>
        {textTitle}
      </TitleTextBox>

      {totalCount > countAlbums && (
        <SeeAllButtonBox onClick={handleSeeAllClick}>
          {textButton}
        </SeeAllButtonBox>
      )}
    </TitleBlockBox>
  )
}

const TitleBlockBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-primary);
`

const SeeAllButtonBox = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--accent-normal);
  cursor: pointer;
`

const TitleTextBox = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
`

