import React from 'react'

import styled from 'styled-components'
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"

export interface IAlbumsGrid {
    className?: string;
    children: React.ReactNode
}

export const AlbumsSeasonGrid = ({ className = '', children }: IAlbumsGrid) => {

  return (
    <AlbumsGridBox className={className}>
      {children}
    </AlbumsGridBox>
  )
}

const AlbumsGridBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(135px, 1fr));
  grid-template-rows: auto;
  grid-column-gap: 19px;
  grid-row-gap: 24px;

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    grid-template-columns: repeat(2, minmax(281px, 281px));
    grid-gap: 24px;
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.DESKTOP}) {
    grid-template-columns: repeat(3, minmax(281px, 281px));
    padding: 0;
  }
`
