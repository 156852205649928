import React from 'react'

import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { Spinner } from './Spinner'

interface Props {
  className?: string,
  text: string,
  visible: boolean
}

export const LoadingOverlay: React.FC<Props> = ({ className = '', text, visible }) => {
  if (!visible) {
    return null
  }

  return ReactDOM.createPortal(
    <LoadingOverlayBox className={className}>
      <Overlay />

      <LoaderBox>
        <StyledSpinner />

        <Text>
          {text}
        </Text>
      </LoaderBox>
    </LoadingOverlayBox>, document.body
  )
}

const LoadingOverlayBox = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: var(--text-primary);
  opacity: 0.3;
  backdrop-filter: blur(25px);
  position: absolute;
  top: 0;
  left: 0;
`

const LoaderBox = styled.div`
  min-width: 197px;
  min-height: 92px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--text-primary);
  box-shadow: 0px 0.25px 3px rgba(0, 0, 0, 0.039), 0px 2.75px 9px rgba(0, 0, 0, 0.19);
  border-radius: 16px;
  padding: 16px;
  position: relative;
`

const StyledSpinner = styled(Spinner)`
  div {
    border-color: var(--background-primary) transparent transparent transparent;
    border-width: 3px;
  }
`

const Text = styled.p`
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: var(--text-toast-primary);
  width: 100%;
`
