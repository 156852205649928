import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { filesSelectors } from "./publicLinksFilesSlice"


export const getPublicLinksFilesLoadingStatusSelector = () => useAppSelector(state =>
  state.publicLinksFiles.status
)

export const getPublicLinksFilesSelectType = () => useAppSelector(state =>
  state.publicLinksFiles.selectType
)

export const getPublicLinksFilesCheckboxVisibility = () => useAppSelector(state =>
  state.publicLinksFiles.checkboxVisibility
)

export const getPublicLinksFilesNodesRawSelector = state =>
  filesSelectors.selectAll(state.publicLinksFiles)


export const getPublicLinksFilesSortSelector = () => useAppSelector(state =>
  state.publicLinksFiles.sort
)

export const getPublicLinksFilesSelectedItemsIdsRawSelector = state =>
  state.publicLinksFiles.selectedItemsIds

export const getPublicLinksFilesSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getPublicLinksFilesNodesRawSelector,
    getPublicLinksFilesSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) =>
      items.filter(item => selectedItemsIds.includes(item.id))
  ))

export const getPublicLinksFilesSelectedItemsIdsSelector = () => useAppSelector(getPublicLinksFilesSelectedItemsIdsRawSelector)

export const getPublicLinksFilesSelectedItemsCountSelector = () => useAppSelector(state =>
  state.publicLinksFiles.selectedItemsIds.length
)

export const getAllPublicLinksFilesNodesSelector = () => useAppSelector(getPublicLinksFilesNodesRawSelector)
