import { useEffect, useRef } from "react"

import { getUploadedFilesCountSelector } from "./selectors"

export const useUploadingFinishDetection = (callback) => {
  const uploadedFilesCount = getUploadedFilesCountSelector()

  const isFirstCallRef = useRef(true)

  useEffect(() => {
    if (isFirstCallRef.current) {
      isFirstCallRef.current = false

      return
    }

    callback()
  }, [uploadedFilesCount])
}