export const WS_EVENTS_NAMES = {
  // FS group
  STORAGE_INFO: 'storage_info',
  FOLDER_CREATED: 'folder_created',
  FOLDER_DELETED: 'folder_deleted',
  FOLDER_RENAMED: 'folder_renamed',
  FOLDER_MOVED: 'folder_moved',
  FOLDER_COLLAB_REMOVED: 'folder_collab_removed',
  FOLDER_UNSHARED: 'folder_unshared',
  FOLDER_UNDELETED: 'folder_undeleted',
  FOLDER_PERMISSIONS_CHANGED: 'folder_permissions_changed',
  FILE_CREATED: 'file_created',
  FILE_DELETED: 'file_deleted',
  FILE_NEW_CONTENT: 'file_new_content',
  FILE_VERSION_RESTORED: 'file_version_restored',
  FILE_RENAMED: 'file_renamed',
  FILE_MOVED: 'file_moved',
  FILE_UNDELETED: 'file_undeleted',
  SHARE_INVITATION_SENT: 'share_invitation_sent',
  SHARE_INVITATION_ACCEPTED: 'share_invitation_accepted',
  SHARE_INVITATION_DECLINED: 'share_invitation_declined',
  SHARE_INVITATION_REVOKED: 'share_invitation_revoked',
  LINK_CREATED: 'link_created',
  LINK_DELETED: 'link_deleted',
  EXTRADATA_CREATED: 'extradata_created',
  EXTRADATA_UPDATED: 'extradata_updated',
  // trash
  TRASH_CLEARED: 'trash_cleared',
  PHOTOS_TRASH_OPERATION_DONE: 'photos_trash_operation_done',
  COPIED_INTO_TRASH: 'copied_into_trash',
  DELETED_FROM_TRASH: 'deleted_from_trash',
  METADATA_FULL_LISTING: 'metadata_full_listing',
  POST_ENTIRE_FOLDER_CREATE: 'post_entire_folder_create',
  SNAPSHOT_CREATED: 'snapshot_created',
  TOO_MANY_EVENTS: 'too_many_events',
  CHECK_WS: 'check_ws',
  // Family group
  FAMILY_CREATED: 'family_created',
  FAMILY_DELETED: 'family_deleted',
  FAMILY_USER_JOINED: 'family_user_joined',
  FAMILY_MEMBER_LEFT: 'family_member_left',
  FAMILY_MEMBER_REVOKED: 'family_member_revoked',
  FAMILY_MEMBER_ROLE_CHANGED: 'family_member_role_changed',
  FAMILY_MEMBER_RENAMED: 'family_member_renamed',
  FAMILY_CHANGED: 'family_changed',
  FAMILY_PHOTOS_ITEMS_CHANGED: 'family_photos_items_changed',
  FAMILY_ALBUM_ITEMS_CHANGED: 'family_album_items_changed',
  // Photos group
  PHOTOS_ITEM_UPDATED: 'photos_item_updated',
  PHOTOS_ITEM_DELETED: 'photos_item_deleted',
  PHOTOS_ALBUM_CREATED: 'photos_album_created',
  PHOTOS_ALBUM_DELETED: 'photos_album_deleted',
  PHOTOS_ALBUM_CHANGED: 'photos_album_changed',
  PHOTOS_ALBUM_SHARED: 'photos_album_shared',
  PHOTOS_OPERATION_DONE: 'photos_operation_done',
  PHOTOS_ALBUM_OPERATION_DONE: 'photos_album_operation_done',
  PHOTOS_ITEMS_MOVED_INTO_TRASH: 'photos_items_moved_into_trash',
  UNAUTHORIZED: 'Unauthorized',
  AUTH_TOKEN_DELETED: 'auth_token_deleted'
}
