import React, { useState } from 'react'

import ReactDOM from 'react-dom'
import './i18n'
import { Provider } from 'react-redux'

import { store } from './store'
import App from "./App"
import { useApiSwitcher } from "./features/common/hooks/useApiSwitcher"
import { SentryErrorBoundary } from "./features/sentry/SentryErrorBoundary"
import { initSentry, isSentryEnabled } from "./features/sentry"


const isDev = process.env.NODE_ENV === 'development'

// analytics for customer
try {
  require(`./apps/${process.env.APP_NAME}/src/analytics/analytics.ts`)
} catch (error) {
  if (isDev) {
    console.log(`Analytics: Analytics config for ${process.env.APP_NAME} not found. You should add "apps/${process.env.APP_NAME}/src/analytics/analytics.ts" if you want to enable analytics`)
  }
}

if(isSentryEnabled) {
  initSentry()
}

const Root = () => {
  const [configsInitialized, setConfigsInitialized] = useState(false)

  useApiSwitcher({
    onSuccess:() => setConfigsInitialized(true),
    onError:() => setConfigsInitialized(true)
  })

  if (!configsInitialized) {
    return null
  }

  const MainApp = () => {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    )
  }

  if(isSentryEnabled) {
    return (
      <SentryErrorBoundary>
        <MainApp />
      </SentryErrorBoundary>
    )
  }
  return <MainApp />
}

const container = document.getElementById('root')

console.log(`React frontend: ${process.env.APP_VERSION}`)

ReactDOM.render(<Root />, container)
