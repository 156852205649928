import React, { useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { PageSpiner, PhotoCard, PhotosGrid } from '@cloudike/web_ui_components'

import { getTrashBinItemsSelector, getTrashBinSelectedItemsCountSelector, getTrashBinTotalItemsCountSelector, getTrashBinSelectedItemsIdsSelector } from './selectors'
import { loadMoreTrashBinItemsThunk, trashBinActions } from './trashBinSlice'
import { EmptyTrashBinPlaceholder } from './EmptyTrashBinPlaceholder'

interface TrashBinPhotosProps {
  className?: string
}

export const TrashBinPhotos: React.FC<TrashBinPhotosProps> = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const items = getTrashBinItemsSelector()
  const totalItemsCount = getTrashBinTotalItemsCountSelector()
  const selectedItemsCount = getTrashBinSelectedItemsCountSelector()
  const selectedItemsIds = getTrashBinSelectedItemsIdsSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(loadMoreTrashBinItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items])

  const handleItemSelection = useCallback((id) => {
    dispatch(trashBinActions.selectItem(id))
  }, [])

  if (!items.length) {
    return (
      <TrashBinBox
        className={className}
      >
        <EmptyTrashBinPlaceholder
          texts={{
            title: t('l_common_noPhotosVideos'),
            description: t('l_common_trashPlaceholderPhotosDescription')
          }}
        />
      </TrashBinBox>
    )
  }

  return (
    <TrashBinBox
      className={className}
    >
      <InfoText>
        {t('l_common_trashbinMessage')}
      </InfoText>

      <AlbumItemsBox>
        <PhotosGrid>
          {items.map(item => (
            <PhotoCard
              key={item.id}
              id={item.id}
              imgUrl={(item._links as any)?.image_middle?.href}
              isSelected={selectedItemsIds.includes(item.id)}
              onSelect={handleItemSelection}
              onClick={() => false}
              isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
              type={item.type}
            />
          ))}
        </PhotosGrid>

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner  />
          </div>
        )}
      </AlbumItemsBox>
    </TrashBinBox>
  )
}

const TrashBinBox = styled.div`
  width: 100%;
  position: relative;
  min-height: calc(100vh - 120px);
`

const AlbumItemsBox = styled.div`
  width: 100%;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`

const InfoText = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.14px;
  color: var(--text-primary);
`
