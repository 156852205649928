import * as Sentry from "@sentry/react"

const errorFallback = () => {
  return (
    <div>
      <h1>
        An error has occurred
      </h1>
    </div>
  )
}
export const SentryErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={errorFallback}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
