import { USER_DATA_KEY_IN_STORAGE } from "features/user/constants"
import heic2any from "heic2any"
import _ from "lodash"
import { IFsNodeSchema } from "@cloudike/web_files"

import { NOTIFICATION_TYPES, showNotification } from "../features/common/notifications"
import i18n from "../i18n"
import { calcMb } from "../features/common/files-uploading/filesUploadingSlice"
import { filesApi } from "../api/filesApi"
import { request } from "../api/request"

export const getFileContentUrl = async (url, abortController: AbortController) => {
  const headers = new Headers()

  headers.append("Mountbit-Auth", `${localStorage.getItem(USER_DATA_KEY_IN_STORAGE) && JSON.parse(`${localStorage.getItem(USER_DATA_KEY_IN_STORAGE)}`).CurrentUser.token}`)
  headers.append("Mountbit-User-Agent", "web-client")
  headers.append("Content-Type", "application/json")

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: headers
  }

  if (abortController) {
    requestOptions.signal = abortController.signal
  }

  const response = await fetch(url, requestOptions)
  const json = await response.json()

  return json._links.data.href
}

export const getFileTypeKey = (fileTypeName) => {
  const divider = '_'
  const keyPrefix = 'l_storageUsageType'

  const kebabToCamel = (string) =>
    string
      .split('_')
      .map((name, index) => {
        return index > 0 ? name[0].toUpperCase() + name.slice(1) : name
      })
      .join('')

  switch (fileTypeName) {
  case 'document_office':
    return keyPrefix + divider + 'officeDocument'
  case 'spreadsheet_office':
    return keyPrefix + divider + 'officeSpreadsheet'
  case 'presentation_office':
    return keyPrefix + divider + 'officePresentation'
  case 'ebook_adobe':
    return keyPrefix + divider + 'adobe'
  case 'database':
    return keyPrefix + divider + 'db'
  case 'emaildata':
    return keyPrefix + divider + 'emailData'
  case 'document_text':
    return keyPrefix + divider + 'textDocument'
  case 'application':
    return keyPrefix + divider + 'app'
  case 'other':
    return keyPrefix + divider + 'unknown'
  default:
    return keyPrefix + divider + kebabToCamel(fileTypeName)
  }
}

export const splitFilesByAccept = (files: FileList, extensions: string[], maxPhotoSize) => {
  const acceptedFiles = []
  const rejectedFiles = []
  const filesArray = Array.from(files)

  filesArray.forEach(file => {

    if (!!maxPhotoSize && file.size > maxPhotoSize) {
      return showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: i18n.t('l_notification_largeError', { size: calcMb(maxPhotoSize) })
      })
    }

    const splittedName = file.name.split('.')
    let extension = ''

    if (splittedName.length === 1 || (splittedName[0] === '' && splittedName.length === 2) ) {
      extension =  ''
    } else {
      extension = `.${splittedName.pop()}`
    }

    if (!!extension && !extensions.includes(extension.toLowerCase())) {
      rejectedFiles.push(file)
    } else {
      acceptedFiles.push(file)
    }
  })

  return {
    acceptedFiles,
    rejectedFiles
  }
}

export const generateImageFromPdf = async (fileBytesArray: Uint8Array) => {
  const pdfJs = await import('pdfjs-dist')
  const worker = await import('pdfjs-dist/build/pdf.worker.entry')

  pdfJs.GlobalWorkerOptions.workerSrc = worker

  const pdf = await pdfJs.getDocument(fileBytesArray).promise

  const firstPage = await pdf.getPage(1)

  const scale = 1.5
  const viewport = firstPage.getViewport({ scale: scale })

  const canvas = document.createElement('canvas')

  const context = canvas.getContext('2d')
  canvas.height = viewport.height
  canvas.width = viewport.width

  const renderContext = { canvasContext: context, viewport: viewport }

  await firstPage.render(renderContext).promise

  return await new Promise(res => {
    canvas.toBlob(blob => {
      const file = new File([blob], "image.png", { type: 'image/png' })

      res(file)
    })
  })
}

export const generatePngFromHeic = async (fileBytesArray: Uint8Array) => {
  const blob = new Blob([fileBytesArray])

  const pngBlob = await heic2any({ blob }) as Blob

  return new File([pngBlob], "image.png", { type: 'image/png' })
}

export const fetchFileFullData = async (userId, nodeId) => {
  const { _links: { node: nodeData } } = await filesApi.getFsRoot(userId)
  const urlTemplate = _.template(nodeData.href, { interpolate: /\{(.+?)\}/g })
  const url = urlTemplate({ node_id: nodeId })

  const data = await request('GET', url, { preview: true, preview_jwt: true }, { host: null }) as IFsNodeSchema

  return data
}
