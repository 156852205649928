import React, { useEffect, useRef, useState } from "react"

import styled from 'styled-components'
import { SpriteIcon, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { useAppDispatch } from  "store"
import { useTranslation } from "react-i18next"

import { Spinner } from "../../../ui"
import { useMobileDetection } from "../../common/hooks"
import { useDocumentKeyDown } from "../../common/hooks/useDocumentKeyDown"

import { getPhotoPreviewDuplicateItemsSelector } from "./selectors"
import { photoPreviewDuplicateActions } from "./photoPreviewDuplicateSlice"

export const DuplicateLine = () => {
  const items = getPhotoPreviewDuplicateItemsSelector()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobile = useMobileDetection()
  const refDuplicate = useRef<any>()

  const scrollWidth = window.innerWidth * 0.5
  const [left, setLeft] = useState<number>(0)
  const [widthElementScroll, setWidthElementScroll] = useState<number>(0)
  const [widthElementClient, setWidthElementClient] = useState<number>(0)

  const handleResize = () => {
    if (!!refDuplicate.current) {
      setWidthElementScroll(refDuplicate.current.scrollWidth)
      setLeft(refDuplicate.current.scrollLeft)
      setWidthElementClient(refDuplicate.current.clientWidth)
    }
  }

  useEffect(() => {
    handleResize()
  }, [refDuplicate.current, items])

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleNext = () => {
    refDuplicate.current.scrollBy(scrollWidth, 0)
    setLeft((prev) => prev + scrollWidth)
  }

  const handlePrev = () => {
    refDuplicate.current.scrollBy(-scrollWidth, 0)
    setLeft((prev) => prev - scrollWidth)
  }

  useDocumentKeyDown(37, handlePrev)
  useDocumentKeyDown(39, handleNext)

  useEffect(() => {
    return () => {
      dispatch(photoPreviewDuplicateActions.resetState())
    }
  }, [])

  return (
    <DuplicateLineBox
      justifyContent={(widthElementClient === widthElementScroll) && 'center'}
      marginTop={isMobile ? '40px' : '20px'}
    >

      {left > 0 && !isMobile && (
        <ArrowContainer className="left">
          {
            (
              <ArrowBox
                onClick={handlePrev}
              >
                <ArrowIcon iconName="arrow_left" />
              </ArrowBox>
            )
          }
        </ArrowContainer>
      )}

      {!!items.length ? (
        <ImagesBox
          overflow={isMobile ? 'auto' : 'hidden'}
          ref={refDuplicate}
        >
          {items.map(item => (
            <ImageDuplicate
              key={item.id}
              item={item}
            />
          ))}
        </ImagesBox>
      ) : (
        <InfoTextBox>
          <InfoTextTitle>
            {t('l_photos_similarNotFound_title')}
          </InfoTextTitle>

          <InfoTextDescription>
            {t('l_photos_similarNotFound_message')}
          </InfoTextDescription>
        </InfoTextBox>
      )
      }

      {widthElementClient + left < widthElementScroll && !isMobile && (
        <ArrowContainer className="right">
          {
            (
              <ArrowBox
                className="rotated"
                onClick={handleNext}
              >
                <ArrowIcon iconName="arrow_left" />
              </ArrowBox>
            )
          }
        </ArrowContainer>
      )}

    </DuplicateLineBox>
  )
}

const ImageDuplicate = ({ item }) => {
  const dispatch = useAppDispatch()

  const [isLoadingImage, setLoadingImage] = useState(true)
  const [hasLoadingError, setHasLoadingError] = useState(false)

  const handleFinishLoading = () => {
    setLoadingImage(false)
  }

  const handleError = () => {
    setLoadingImage(false)
    setHasLoadingError(true)
  }

  const handleClickImage = (item) => () => {
    dispatch(photoPreviewDuplicateActions.changeSelectPhoto(item.id))
  }

  return (
    <ImageBox onClick={handleClickImage(item)}
      background={(isLoadingImage || hasLoadingError) && 'var(--background-tertiary)'}
      minHeight={(isLoadingImage || hasLoadingError) && '92px'}
      minWidth={(isLoadingImage || hasLoadingError) && '76px'}
      border={item.isOrigin && !hasLoadingError && !isLoadingImage && '2px solid rgba(255, 255, 255, 0.60)'}
    >
      <Box maxHeight={item.height > item.width ? '92px' : '84px'}>
        <Img
          transform={item.isSelect ? 'scale(0.85)' : 'unset'}
          src={item._links.image_small.href}
          onLoad={handleFinishLoading}
          onError={handleError}
        />

        {item.isSelect && !hasLoadingError && !isLoadingImage && (
          <IconBox>
            <CheckedIcon iconName="circle_checked" />
          </IconBox>
        )}

        {item.favorite && (
          <FavoriteImg
            bottom={item.isSelect ? '9px' : '2px'}
            right={item.isSelect ? '9px' : '2px'}
            iconName={'heart'}
          />
        )}

        {isLoadingImage && <StyledSpinner />}
      </Box>


    </ImageBox>
  )
}

const FavoriteImg = styled(SpriteIcon)`
  width: 20px !important;
  height: 20px !important;;
  position: absolute;
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  color: #FFFFFF;
  filter: drop-shadow(rgb(0, 0, 0) -4px 4px 25px);
  transition: all 500ms ease;
`

const DuplicateLineBox = styled.div`
  margin-top: ${props => props.marginTop};
  height: 128px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
  justify-content: ${props => props.justifyContent};

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    overflow: auto;
  }
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ImagesBox = styled.div`
  height: 100%;
  padding: 0 24px;
  display: flex;
  position: relative;
  align-items: center;
  overflow: ${props => props.overflow};
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    padding: 0 16px;
  }
`

const ImageBox = styled.div`
  display: flex;
  position: relative;
  margin-right: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: ${props => props.minHeight};
  min-width: ${props => props.minWidth};
  border: ${props => props.border};
  padding: 2px;

  &:first-child {
    margin-right: 6px;
  }
`

const Img = styled.img`
  max-height: 92px;
  max-width: 112px;
  width: auto;
  height: auto;
  object-fit: contain;
  user-select: none;
  transform: ${props => props.transform};
  transition: all 500ms ease;
`

const Box = styled.div`
  max-height: ${props => props.maxHeight};
  background: var(--background-tertiary);
`

const IconBox = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
`

const CheckedIcon = styled(SpriteIcon)`
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  background-color: var(--background-primary);
  color: var(--accent-normal);
`

const InfoTextBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const InfoTextTitle = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

const InfoTextDescription = styled.div`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`

const ArrowContainer = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  z-index: 11;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
  -webkit-tap-highlight-color: var(--divider-primary);

  &.right {
    left: unset;
    right: 20px;
  }
`

const ArrowBox = styled.button`
  display: flex;
  border: none;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: #232323;
  align-items: center;
  justify-content: center;

  &.rotated {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: #717171;
  }
`

const ArrowIcon = styled(SpriteIcon)`
  && {
    color: #ffffff;
  }
`
