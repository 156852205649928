import React from 'react'

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { getUserDataSelector } from "../../userSlice"

export const ProfileGooglePolicyText = () => {
  const { t } = useTranslation()
  const userData = getUserDataSelector()
  const currentLanguage = userData.lang

  return currentLanguage === 'tr' ? (
    <TextBox>
      {t('l_common_googleInfoOne') + ' '}

      {t('l_common_googleInfoTwo') + ' '}

      <LinkBox href={'https://developers.google.com/terms/api-services-user-data-policy'}
        target={'_blank'}
      >
        {t('l_common_googleInfoThree')}
      </LinkBox>
    </TextBox>
  ) : (
    <TextBox>
      {t('l_common_googleInfoOne') + ' '}

      <LinkBox href={'https://developers.google.com/terms/api-services-user-data-policy'}
        target={'_blank'}
      >
        {t('l_common_googleInfoTwo') + ' '}
      </LinkBox>

      {t('l_common_googleInfoThree')}
    </TextBox>
  )
}

const TextBox = styled.div`
  margin-top: 20px;
  max-width: 715px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-secondary);
`

const LinkBox = styled.a`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-primary);
`
