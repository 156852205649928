import React from 'react'

import { useAppDispatch } from  "store"
import { appActions } from 'store/app'
import { PageTopbar } from '@cloudike/web_ui_components'

export type ConnectedPageTopbarProps = Omit<React.ComponentProps<typeof PageTopbar>, 'onClickMenuButton'>

const ConnectedPageTopbar: React.FC<ConnectedPageTopbarProps> = (props) => {
  const dispatch = useAppDispatch()

  const handleMenuButtonClick = () => {
    dispatch(appActions.toggleLeftMenuOnMobile(true))
  }

  return (
    <PageTopbar
      {...props}
      onClickMenuButton={handleMenuButtonClick}
    />
  )
}

export { ConnectedPageTopbar as PageTopbar }
