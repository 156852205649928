import { getModalsRootElement } from 'constants/modals'

import React from 'react'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  SecondaryButton,
} from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FileItemsTypes } from './types'

interface ReplaceFilesItemModalProps {
  data: any,
  onCancel: () => void,
  onKeepBoth: () => void,
  onReplace: () => void
}

export const ReplaceFilesItemModal: React.FC<ReplaceFilesItemModalProps> = ({ data, onCancel, onKeepBoth, onReplace }) => {
  const { t } = useTranslation()

  const texts = {
    TITLE: data.nodeType === FileItemsTypes.DIR ? t('l_common_folderExists') : t('l_common_fileExists'),
    SUB_TITLE: data.nodeType === FileItemsTypes.DIR ? t(`l_common_folderExistsMessage`, { name: data.name }) : t(`l_common_fileExistsMessage`, { name: data.name }),
    STOP: t('a_common_cancel'),
    KEEP_BOTH: t('a_common_keepBoth'),
    REPLACE: t('a_common_replace'),
  }

  if (!data.opened) {
    return null
  }

  return (
    <ModalDialog
      isOpened
      parentBlock={getModalsRootElement()}
      header={<ModalHeader title={texts.TITLE} />}
      footer={(
        <ModalFooter
          buttonList={[
            <StyledSecondaryButton
              key={texts.STOP}
              onAction={onCancel}
              actionName={texts.STOP}
            />,
            <StyledSecondaryButton
              key={texts.KEEP_BOTH}
              onAction={onKeepBoth}
              actionName={texts.KEEP_BOTH}
            />,
            <StyledSecondaryButton
              key={texts.REPLACE}
              onAction={onReplace}
              actionName={texts.REPLACE}
            />,
          ]}
        />
      )}
      onCloseModal={onCancel}
    >
      <Text>
        {texts.SUB_TITLE}
      </Text>
    </ModalDialog>
  )
}

const StyledSecondaryButton = styled(SecondaryButton)`
  & ~ & {
    margin-left: 8px;
  }
`

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--text-secondary);
  max-width: 500px;
  word-wrap: break-word;
`
