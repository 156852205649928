import _ from "lodash"
import { WSClientEvents } from "@cloudike/web_photos"

import { store } from "../store"
import { appActions } from "../store/app"

let SDK = null
let WSClient = null

export const getSdkWS = (sdk) => {
  if(_.isNull(SDK)) {
    SDK = sdk
  }
  if(SDK && _.isNull(WSClient)) {
    WSClient = SDK.webSocketService.client()
    addListeners(WSClient)
  }

  return WSClient
}

function addListeners(ws) {
  ws.on(WSClientEvents.OPEN, onChangeWSState)
  ws.on(WSClientEvents.CLOSE, onChangeWSState)
  ws.on(WSClientEvents.ERROR, onChangeWSState)
}
function onChangeWSState({ wsClient }) {
  store.dispatch(appActions.setWSState(wsClient.readyState))
}

export const refreshSdkWSConnection = () => {
  WSClient = SDK.webSocketService.client()
  return WSClient
}

