import { STYLED_VARIABLES } from 'constants/styledVariables'

import { UserMenu } from 'features/user/UserMenu'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

export const RightSidebar = ({ className = '' }) => {
  return (
    <RightSidebarBox className={className}>
      <StyledUserMenu />

      <Outlet />
    </RightSidebarBox>
  )
}

const RightSidebarBox = styled.div`
  width: 100%;
`

const StyledUserMenu = styled(UserMenu)`
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`
