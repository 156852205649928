import { STYLED_VARIABLES } from 'constants/styledVariables'

import { PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'

export const AddingToolbar = ({ onAction, actionName, selectedItemsCount }) => {
  return (
    <>
      <AddToolbarBox>
        <StyledPrimaryButton
          disabled={selectedItemsCount === 0}
          actionName={actionName}
          onAction={onAction}
        />
      </AddToolbarBox>
    </>
  )
}

const AddToolbarBox = styled.div`
  padding-right: 24px;
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`
