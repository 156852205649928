import { getModalsRootElement } from 'constants/modals'

import { FamilyInviteModal } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { useAppDispatch } from  "store"

import { getFamilyDataSelector, getFamilyInviteModalIsOpenedSelector } from './selectors'
import { familyActions } from './familySlice'

const ConnectedFamilyInviteModal = () => {
  const { t } = useTranslation('')
  const dispatch = useAppDispatch()

  const familyData = getFamilyDataSelector()
  const opened = getFamilyInviteModalIsOpenedSelector()

  if (!opened) {
    return null
  }

  const link = familyData.invite_hash ? `${window.location.origin}/invite?fchash=${familyData.invite_hash}` : ''

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)

    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_notification_inviteLinkCopied')
    })
  }

  const handleClose = () => {
    dispatch(familyActions.toggleFamilyinviteModal(false))
  }

  return (
    <FamilyInviteModal
      opened={opened}
      onCopyLink={handleCopyLink}
      onClose={handleClose}
      link={link}
      texts={{
        title: t('l_common_sendInviteToFamily'),
        description: t('l_family_sendInviteDescription'),
        button: t('a_common_close')
      }}
      parentBlock={getModalsRootElement()}
    />
  )
}

export { ConnectedFamilyInviteModal as FamilyInviteModal }
