import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { albumsSeasonsSelectors } from "./albumsSeasonsSlice"

export const getAlbumsSeasonsLoadingStatusSelector = () => useAppSelector(state =>
  state.albumsSeasons.status
)

export const getAlbumsSeasonsTotalCountSelector = () => useAppSelector(state =>
  state.albumsSeasons.totalCount
)

export const getAlbumsSeasonsItemsRawSelector = state => albumsSeasonsSelectors.selectAll(state.albumsSeasons)

export const getAlbumsSeasonsItemsSelector = () => useAppSelector(getAlbumsSeasonsItemsRawSelector)

export const getAlbumsSeasonsSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getAlbumsSeasonsItemsRawSelector,
    albums => albums.filter(item => item.selected)
  )
)

export const getAlbumsSeasonsSelectedItemsCountSelector = () => useAppSelector(
  createSelector(
    getAlbumsSeasonsItemsRawSelector,
    albums => albums.filter(item => item.selected).length
  )
)
