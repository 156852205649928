import { useRef } from "react"

interface IUseExponentialBackoffOptions { maxRetries?: number, initialDelay?: number, maxDelay?: number }

export const useExponentialBackoff = (
  options:IUseExponentialBackoffOptions = {}
) => {
  const { maxRetries, initialDelay, maxDelay } = options
  const MAX_RETRIES = maxRetries || 100
  const INITIAL_DELAY = initialDelay || 100
  const MAX_DELAY = maxDelay || 5000
  const retryCounter = useRef(0)
  const delay = useRef(INITIAL_DELAY)

  return backOff

  function backOff(fn: () => void) {
    const retries = retryCounter.current
    const currentDelay = delay.current

    if (retries === 0) {
      retry()
    } else if (retries < MAX_RETRIES) {
      setTimeout(retry, currentDelay)
    }

    if (currentDelay > MAX_DELAY) {
      resetDelay()
    }

    function retry() {
      fn()
      incrementRetryCounter()
      increaseDelay()
    }

    function resetDelay() {
      delay.current = INITIAL_DELAY
    }

    function incrementRetryCounter() {
      retryCounter.current++
    }

    function increaseDelay() {
      delay.current *= 2
    }
  }
}
