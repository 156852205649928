import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { PHOTOS_COUNT_PER_PAGE } from 'constants/pagination'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { request } from 'api/request'
import { IExtendedItemSchema } from 'features/albums/album/albumSlice'
import { NavigateFunction } from 'react-router-dom'
import { getPhotoSdk } from 'sdk/photo'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { RootState } from 'store'
import { getPhotoTimelineSdk } from 'sdk/timeline'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import i18n from 'i18n'
import { t } from 'i18next'
import { initPublicLinkSocket } from 'sdk/publicLinkSocket'
import { WebSocketService } from 'sdk/webSocketService'

import { TOTAL_COUNT_HEADER } from "../../constants/headers"

export enum PublicLinksErrorTypes {
  NOT_EXIST = 'NOT_EXIST',
  EMPTY_ALBUM = 'EMPTY_ALBUM',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  NO_ACCESS = 'NO_ACCESS',
}

interface State {
  itemsLoadingStatus: LOADING_STATUSES,
  loadingMoreStatus: LOADING_STATUSES,
  totalItemsCount: number,
  passwordModalOpened: boolean,
  error: null | PublicLinksErrorTypes
  selectedItemsIds: string[],
  albumData: null | IAlbumSchema,
  notMyResourseHref: string,
  itemsHref: string,
  token: null | string,
  sharedId: null | string,
  permission: null | string,
  isSizeQuotaExceedError: boolean,
}

const sortByDate = (items: IItemSchema[]) => items.sort((a, b) => b.created_original - a.created_original)

const adapter = createEntityAdapter<IExtendedItemSchema>()

export const publicLinkSelectors = adapter.getSelectors()

let currentAlbumItemsPaginator = null
let publicLinkSocket: WebSocketService

export const initAlbumItemsPaginator = (url: string, pageSize = 20, token = undefined) => {
  currentAlbumItemsPaginator = getPhotoSdk().publicLinksService.getSharedWithMeItemsPaginatorByLink(url, pageSize, { total_count: true }, token)

  return currentAlbumItemsPaginator
}

export const getCurrentAlbumItemsPaginator = () => {
  return currentAlbumItemsPaginator
}

const fetchItems = async (href, dispatch, token = undefined) => {
  const paginator = initAlbumItemsPaginator(href, 20, token)

  const paginatorResponse = await paginator.next()

  const items = paginatorResponse.data._embedded.items
  const totalCount = Number(paginatorResponse.headers[TOTAL_COUNT_HEADER])

  dispatch(actions.setItems(items))
  dispatch(actions.setTotalCount(totalCount))
}

export const subscribePublicLinkToWsThunk = createAsyncThunk(
  'publicLink/subscribePublicLinkToWsThunk',
  async function({ id, jwtToken }: { id: string, jwtToken?:string }, { getState, dispatch }) {
    publicLinkSocket = initPublicLinkSocket({ id, jwtToken })

    publicLinkSocket.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_OPERATION_DONE, ({ action, output }) => {
      const state = (getState() as RootState).publicLink
      const totalCount = state.totalItemsCount

      if (action === 'add_items') {
        dispatch(loadJustUploadedPublickLinkItemsThunk())
      }

      if (action === 'delete_items') {
        const ids = output.map(item => item.detail.item_id)

        dispatch(actions.removeItems(ids))
        dispatch(actions.setTotalCount(totalCount - ids.length))
        dispatch(actions.unselectAll())
      }
    })

    publicLinkSocket.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH, ({ output }) => {
      const state = (getState() as RootState).publicLink
      const totalCount = state.totalItemsCount

      dispatch(actions.removeItems([output.item_id]))
      dispatch(actions.setTotalCount(totalCount - 1))
      dispatch(actions.unselectAll())
    })

    publicLinkSocket.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED, ({ output, }) => {
      const state = (getState() as RootState).publicLink

      const currentEntities = publicLinkSelectors.selectEntities(state)

      if (!currentEntities[output.id]) {
        dispatch(loadJustUploadedPublickLinkItemsThunk())
      }
    })
  }
)

export const unSubscribePublicLinkToWsThunk = createAsyncThunk(
  'publicLink/unSubscribePublicLinkToWsThunk',
  async function() {
    publicLinkSocket?.closeConnection()
  }
)

export const loadJustUploadedPublickLinkItemsThunk = createAsyncThunk(
  'publicLink/loadJustUploadedPublickLinkItemsThunk',
  async function(_, { getState, dispatch }) {
    const state = getState() as RootState
    const publicLinksService = getPhotoSdk().publicLinksService

    const token = state.publicLink.token
    const href = state.publicLink.itemsHref
    const currentItems = publicLinkSelectors.selectAll(state.publicLink)
    const currentItemsIds = currentItems.map(item => item.id)

    const limit = Math.max(currentItems.length, PHOTOS_COUNT_PER_PAGE)

    const response = await publicLinksService.getSharedWithMeItemsByLink(href, { offset: 0, limit, total_count: true }, token)

    const itemsForInsert = response.data._embedded.items.filter(item => !currentItemsIds.includes(item.id))

    dispatch(actions.setItems(sortByDate([...itemsForInsert, ...currentItems])))
    dispatch(actions.setTotalCount(Number(response.headers[TOTAL_COUNT_HEADER])))
  }
)

export const loadJustUploadedSharedAlbumItemsThunk = createAsyncThunk(
  'publicLink/loadJustUploadedSharedAlbumItemsThunk',
  async function(_, { getState, dispatch }) {
    const publicLinksService = getPhotoSdk().publicLinksService

    const state = (getState() as RootState).publicLink
    const token = state.token
    const notMyResourseHref = state.notMyResourseHref

    const response = await publicLinksService.getSharedWithMeItemsByLink(`${notMyResourseHref}/items`, { offset: 0, limit: 20, total_count: true }, token)

    const currentItems = publicLinkSelectors.selectAll(state)
    const currentItemsIds = currentItems.map(item => item.id)

    const itemsForInsert = response.data._embedded.items.filter(item => !currentItemsIds.includes(item.id))

    dispatch(actions.setItems([...itemsForInsert, ...currentItems]))
    dispatch(actions.setTotalCount(Number(response.headers[TOTAL_COUNT_HEADER])))
  }
)

export const fetchPublicLinkInfoThunk = createAsyncThunk(
  'publicLink/fetchPublicLinkInfoThunk',
  async function({ id, navigate }: { id: string, navigate: NavigateFunction }, { dispatch, getState }) {
    try {
      const state = getState() as RootState
      const publicLinksService = getPhotoSdk().publicLinksService
      const userId = state.user.userData.id

      dispatch(actions.setSharedId(id))

      const response = await publicLinksService.getPublicShare(id, userId)
      const data = response.data

      const myResource = data?._links?.my_resource?.href
      const notMyResource = data?._links?.not_my_resource?.href
      const accessType = data.access_type

      if (myResource) {
        const myResourceResponse = await request('GET', myResource, {}, { host: null })

        navigate(`/photos/albums/${myResourceResponse.id}`)

        return
      }

      if (notMyResource) {
        const notMyResourceResponse = await request('GET', notMyResource, {}, { host: null })

        dispatch(actions.setNotMyResourceHref(notMyResource))
        dispatch(actions.setPermission(notMyResourceResponse?._embedded?.share?.permission))
        dispatch(actions.setAlbumData(notMyResourceResponse))

        const href = data?._links?.not_my_resource.href + '/items'

        fetchItems(href, dispatch)

        dispatch(actions.setItemsHref(href))
        dispatch(subscribePublicLinkToWsThunk({ id }))

        return
      }

      if (accessType === 'password') {
        const tokensLink = data?._links?.tokens?.href

        if (!tokensLink) {
          dispatch(actions.setError(PublicLinksErrorTypes.SOMETHING_WENT_WRONG))

          return
        }

        dispatch(actions.togglePasswordModal(true))

        return
      }

      dispatch(actions.setError(PublicLinksErrorTypes.NO_ACCESS))
    } catch (error) {
      if (error.code === 'NotFound') {
        dispatch(actions.setError(PublicLinksErrorTypes.NOT_EXIST))

        return
      }

      dispatch(actions.setError(PublicLinksErrorTypes.SOMETHING_WENT_WRONG))
    }
  }
)

export const checkForAccessToSharedAlbumThunk = createAsyncThunk(
  'publicLink/checkForAccessToSharedAlbumThunk',
  async function({ callback, neededPermission }: { callback: () => void, neededPermission?: string }, { getState }) {
    const state = getState() as RootState
    const id = state.publicLink.sharedId
    const userId = state.user.userData.id

    const publicLinksService = getPhotoSdk().publicLinksService

    const notificationParams = {
      type: NOTIFICATION_TYPES.WARNING,
      title: t('l_notification_somethingWrongTryAgain')
    }

    try {
      const response = await publicLinksService.getPublicShare(id, userId)

      const notMyResource = response?.data?._links?.not_my_resource?.href

      if (!notMyResource) {
        showNotification(notificationParams)

        return
      }

      if (neededPermission === 'write') {
        const notMyResourceResponse = await request('GET', notMyResource, {}, { host: null })
        const permission = notMyResourceResponse?._embedded?.share?.permission

        if (permission !== 'write') {
          showNotification(notificationParams)

          return
        }
      }

      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const removeSharedAlbumSelectedItemsThunk = createAsyncThunk(
  'publicLink/removeSharedAlbumSelectedItemsThunk',
  async function({ items }: { items: IItemSchema[]}, { dispatch, getState }) {
    try {
      const publicLinksService = getPhotoSdk().publicLinksService
      const state = (getState() as RootState).publicLink

      const totalCount = state.totalItemsCount
      const albumData = state.albumData
      const token = state.token

      showGlobalProgressLoader()

      await publicLinksService.removeSharedWithMeItemsByLink(albumData._links.operations.href, items, token)

      dispatch(actions.unselectAll())
      dispatch(actions.removeItems(items.map(item => item.id)))
      dispatch(actions.setTotalCount(totalCount - items.length))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_itemsDeletedforWeb', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const submitPasswordForSharedAlbum = createAsyncThunk(
  'publicLink/submitPasswordForSharedAlbum',
  async function({ password, errorCallback }: { password: string, errorCallback: () => void }, { getState, dispatch }) {
    try {
      const publicLinksService = getPhotoSdk().publicLinksService
      showGlobalProgressLoader()

      const id = (getState() as RootState).publicLink.sharedId

      const { data : { token } } = await publicLinksService.createShareToken(id, password)

      dispatch(actions.setToken(token))
      const response = await publicLinksService.getSharedWithMe(id, token)

      dispatch(actions.setNotMyResourceHref(response.data?._links?.self?.href))
      dispatch(actions.setAlbumData(response.data))
      dispatch(actions.setPermission(response.data?._embedded?.share?.permission))

      const href = response?.data?._links?.items.href
      fetchItems(href, dispatch, token)

      dispatch(actions.setItemsHref(href))
      dispatch(actions.togglePasswordModal(false))

      dispatch(subscribePublicLinkToWsThunk({ id, jwtToken: token }))
    } catch (error) {
      errorCallback()
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const downloadSharedAlbumThunk = createAsyncThunk(
  'publicLink/downloadSharedAlbumThunk',
  async function(_, { getState, dispatch }) {
    showGlobalProgressLoader()

    try {
      const state = (getState() as RootState).publicLink
      const token = state.token

      const {
        data: {
          _links: {
            zip_stream: { href },
          },
        },
      } = await getPhotoSdk().publicLinksService.createSharedWithMeItemsZipByLink(state.albumData._links.items_downloads.href, {}, token)

      window.location.href = href
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const downloadSharedAlbumItemThunk = createAsyncThunk(
  'publicLink/downloadSharedAlbumItemThunk',
  async function({ item }: {item: IItemSchema }, { getState, dispatch }) {
    const state = (getState() as RootState).publicLink
    const accessToken = state.token
    showGlobalProgressLoader()

    try {
      const {
        _links: {
          content: { href: contentLink }
        }
      } = item

      const href = await getPhotoSdk().publicLinksService.getDownloadLinkByContentUrl(contentLink, accessToken)

      window.location.href = href
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const loadMorePublicLinksItemsThunk = createAsyncThunk(
  'publicLink/loadMorePublicLinksItemsThunk',
  async function() {
    const paginator = getCurrentAlbumItemsPaginator()

    const response = await paginator.next()

    return response.data._embedded.items
  }
)

export const addItemsToPersonalCloudThunk = createAsyncThunk(
  'publicLink/addItemsToPersonalCloudThunk',
  async function(items: IItemSchema[], { dispatch }) {
    try {
      const timelineSdk = getPhotoTimelineSdk()

      showGlobalProgressLoader()

      await timelineSdk.addItemsToTimeline(items)
      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_copiedToPersonalCloud')
      })
    } catch (error) {

      if(error.cause.details.operations.some(item => item.status === 402 && item.code === 'SizeQuotaExceeded')) {
        return dispatch(actions.setIsSizeQuotaExceedError(true))
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const publicLinkSlice = createSlice({
  name: 'publicLink',
  initialState: adapter.getInitialState<State>({
    itemsLoadingStatus: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    totalItemsCount: 0,
    passwordModalOpened: false,
    selectedItemsIds: [],
    error: null,
    albumData: null,
    token: null,
    sharedId: null,
    permission: null,
    notMyResourseHref: null,
    itemsHref: null,
    isSizeQuotaExceedError: null,
  }),
  reducers: {
    togglePasswordModal: (state, action) => {
      state.passwordModalOpened = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setIsSizeQuotaExceedError: (state, action) => {
      state.isSizeQuotaExceedError = action.payload
    },
    setItems: (state, action) => {
      adapter.setAll(state, action.payload)
      state.itemsLoadingStatus = LOADING_STATUSES.SUCCEEDED
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    setAlbumData: (state, action) => {
      state.albumData = action.payload
    },
    removeItems: (state, action) => {
      adapter.removeMany(state, action.payload)
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [...state.selectedItemsIds, id]
      } else {
        state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
      }
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setSharedId: (state, action) => {
      state.sharedId = action.payload
    },
    setPermission: (state, action) => {
      state.permission = action.payload
    },
    setNotMyResourceHref: (state, action) => {
      state.notMyResourseHref = action.payload
    },
    setItemsHref: (state, action) => {
      state.itemsHref = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadMorePublicLinksItemsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMorePublicLinksItemsThunk.fulfilled, (state, action) => {
        state.itemsLoadingStatus = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
  },
})

const { reducer, actions } = publicLinkSlice

export { reducer as publicLinkReducer, actions as publicLinkActions }
