import APP_CONFIG from 'constants/configs/app.config'
import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { changeLanguageThunk, getUserDataSelector, THEME } from 'features/user/userSlice'
import styled from 'styled-components'
import { useAppDispatch } from  "store"

import { getUiTheme } from "../../selectors"

function getAvailableLangs() {
  const translationKeyPrefix = 'l_common_langName'
  const langs = APP_CONFIG.i18n.langs

  if (langs.length === 0) return []

  return langs.map((lang) => ({
    value: lang,
    text: `${translationKeyPrefix}_${lang}`
  }))
}

export const ProfileLanguage = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const languages = getAvailableLangs()
  const userData = getUserDataSelector()
  const theme = getUiTheme()

  const currentLanguage = userData.lang

  const handleChangeLanguage = (e) => {
    dispatch(changeLanguageThunk({ lang: e.target.value }))
  }

  if (languages.length < 2) {
    return null
  }

  const [arrowUrl, setArrowUrl] = useState('')

  useEffect(() => {
    const imgUrl = theme === THEME.LIGHT ? formatImgUrl('/assets/expand_more_black.svg') : formatImgUrl('/assets/expand_more_white.svg')
    setArrowUrl(imgUrl)
  }, [theme])

  return (
    <ProfileLanguageBox className={className}>
      <Title>
        {t('l_settings_settingsTitle')}
      </Title>

      <InfoRowBox>
        {t('l_common_language')}

        <LanguageSelect
          url={arrowUrl}
          id="language"
          name="language"
          onChange={handleChangeLanguage}
          value={currentLanguage}
        >
          {languages.map(lang => (
            <option
              value={lang.value}
              key={lang.value}
            >
              {t(lang.text)}
            </option>
          ))}
        </LanguageSelect>
      </InfoRowBox>
    </ProfileLanguageBox>
  )
}

const ProfileLanguageBox = styled.div`
  margin-top: 27px;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const InfoRowBox = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid var(--divider-primary);
  border-top: 1px solid var(--divider-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LanguageSelect = styled.select`
  background-color: var(--background-primary);
  cursor: pointer;
  height: 100%;
  display: flex;
  flex: 0 1 auto;
  margin-left: auto;
  margin-right: 0;
  max-width: 150px;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: var(--text-primary);
  border: none;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-position: 99% 50%;
  padding-right: 20px;

  &:focus {
    outline: none;
  }
`
