import { Layout } from "@cloudike/web_ui_components"
import { useAppDispatch } from  "store"
import { appActions } from "store/app"
import styled from 'styled-components'
import classNames from "classnames"

import useScrollToTopAfterLocationChanging from "../hooks/useScrollToTopAfterLocationChanging"
import {
  getIsRightSidebarTransparentSelector,
  getLayoutContentOverflowSelector,
  getMobileLeftMenuStatusSelector,
  getMobileRightMenuStatusSelector
} from "../selectors"

type ConnectedLayoutProps = Pick<React.ComponentProps<typeof Layout>, 'children' | 'className' | 'leftSidebar' | 'rightSidebar'>

export const ConnectedLayout: React.FC<ConnectedLayoutProps> = (props) => {
  const dispatch = useAppDispatch()

  const isLeftSidebarActiveOnMobile = getMobileLeftMenuStatusSelector()
  const isRightSidebarActiveOnMobile = getMobileRightMenuStatusSelector()
  const isLayoutOverflowDisabled = getLayoutContentOverflowSelector()
  const isRightSidebarTransparent = getIsRightSidebarTransparentSelector()

  useScrollToTopAfterLocationChanging()

  const handleCloseLeftSidebar = () => {
    dispatch(appActions.toggleLeftMenuOnMobile(false))
  }

  const handleCloseRightSidebar = () => {
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return (
    <LayoutBox
      {...props}
      className={classNames(props.className, { 'no-overflow': isLayoutOverflowDisabled, 'with-transparent-right-sidebar': isRightSidebarTransparent })}
      isLeftSidebarActiveOnMobile={isLeftSidebarActiveOnMobile}
      isRightSidebarActiveOnMobile={isRightSidebarActiveOnMobile}
      onCloseLeftSidebar={handleCloseLeftSidebar}
      onCloseRightSidebar={handleCloseRightSidebar}
    />
  )
}

const LayoutBox = styled(Layout)`
  // &&& override styles with higher specificity
  &&& {
    position: relative;

    main {
      overflow: hidden;
    }

    &.no-overflow {
      main {
        overflow: unset;
      }
    }
    
    &.with-transparent-right-sidebar {
      aside {
        background: transparent;
      }
    }
  }
`
