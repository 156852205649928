import { request } from "./request"

export const personsApi = {
  getPersonsList: (userId) => {
    return request('GET', `/api/3/users/${userId}/document_wallet/persons`, {}, { apiVersion: 3 })
  },
  createPerson: (userId, data) => {
    return request('POST', `/api/3/users/${userId}/document_wallet/persons`, data, { apiVersion: 3 })
  },
  deletePerson: (userId, personId) => {
    return request('DELETE', `/api/3/users/${userId}/document_wallet/persons/${personId}`, {}, { apiVersion: 3 })
  },
  patchPerson: (userId, personId, data) => {
    return request('PATCH', `/api/3/users/${userId}/document_wallet/persons/${personId}`, data, { apiVersion: 3 })
  },
  getPersonDocumentTypes: (userId, personId, lang) => {
    return request('GET', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types`, {}, { apiVersion: 3, headers: { 'X-Accept-Language': lang } })
  },
  createPersonDocumentType: (userId, personId, name) => {
    return request('POST', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types`, { name }, { apiVersion: 3 })
  },
  removePersonDocumentType: (userId, personId, typeId) => {
    return request('DELETE', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types/${typeId}`, {}, { apiVersion: 3 })
  },
  renamePersonDocumentType: (userId, personId, typeId, name) => {
    return request('PATCH', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types/${typeId}`, { name }, { apiVersion: 3 })
  },
  getPersonDocumentItems: ({ userId, personId, typeId, offset = 0, limit, total_count = true }) => {
    return request('GET', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types/${typeId}/documents`, { offset, limit, total_count }, { apiVersion: 3 })
  },
  getHeaderPersonDocumentItems: ({ userId, personId, typeId, total_count = true }) => {
    return request('HEAD', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types/${typeId}/documents?total_count=${total_count}/`, { apiVersion: 3 })
  },
  deletePersonDocumentItem: ({ userId, personId, typeId, id }) => {
    return request('DELETE', `/api/3/users/${userId}/document_wallet/persons/${personId}/document_types/${typeId}/documents/${id}`, {}, { apiVersion: 3 })
  },
  patchSortPerson: (userId, data) => {
    return request('PATCH', `/api/3/users/${userId}/document_wallet/persons_order`, data, { apiVersion: 3 })
  },
}
