import { useEffect, useState } from "react"

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"

import edit from "../../../assets/icons/edit.svg"
import menu from "../../../assets/icons/menu.svg"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"

import { AddPersonLine } from "./AddPersonLine"
import { ADD_PERSON_MODAL_TYPE, patchSortPersonsThunk, personsActions } from "./personsSlice"
import { getFirstLetter } from "./PersonsCard"

export const PersonsList = ({ items, maxPersons }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleClickEdit = async (personId, color, name) => {
    analytics.push(ANALYTICS_EVENTS.WEB_PERSON_EDIT_CLICK)

    await dispatch(personsActions.setColorPerson(color))
    await dispatch(personsActions.setNamePerson(name))
    await dispatch(personsActions.setPersonId(personId))
    await dispatch(personsActions.setTypeModal(ADD_PERSON_MODAL_TYPE.EDIT_PERSON))
    await dispatch(personsActions.togglePersonalSettingModalOpened(true))
  }

  const handleClickAdd = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PERSON_CREATE_CLICK)

    dispatch(personsActions.setTypeModal(ADD_PERSON_MODAL_TYPE.ADD_PERSON))
    dispatch(personsActions.togglePersonalSettingModalOpened(true))
  }

  const [personsList, setPersonsList] = useState(items)

  useEffect(() => {
    setPersonsList(items)
  }, [items])

  const handleDragEnd = (result) => {
    analytics.push(ANALYTICS_EVENTS.WEB_PERSON_EDIT_ORDER_CLICK)

    const array = [...personsList]
    const initialArray = [...personsList]
    const draggingElement = array[result.source.index + 1]
    array.splice(result.source.index + 1, 1)
    array.splice(result.destination.index + 1, 0, draggingElement)
    setPersonsList(array)

    dispatch(patchSortPersonsThunk({ sortArray: array, startArray: initialArray }))
  }

  return (
    <PersonsListBox>
      <AddPersonLine
        onClickAdd={handleClickAdd}
        textAdd={t('a_dw_createNewProfile')}
        isFullListPerson={items.length - 1 >= maxPersons}
        limitText={t('l_dw_maxPeople')}
      />

      <PersonsCardBox>
        <PersonBox>
          <AvatarBox
            backgroundColor={personsList[0].color_id}
          >
            <AvatarTextBox>
              {getFirstLetter(personsList[0].name)}
            </AvatarTextBox>
          </AvatarBox>

          <NameBox>
            {items[0].name}
          </NameBox>
        </PersonBox>
      </PersonsCardBox>

      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Droppable droppableId={'main'}>
          {(provided) => {
            return (
              <DndColumn
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ background: 'var(--background-primary)' }}
              >
                {personsList.slice(1).map((item, index) => (
                  <Draggable key={item.id}
                    index={index}
                    draggableId={item.id}
                  >
                    {(provided, snapshot) => {
                      return (
                        <PersonsCardBox
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          boxShadow={snapshot.isDragging && '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)'}
                          backgroundColor={snapshot.isDragging && 'var(--background-primary)'}
                        >
                          <PersonBox>
                            <AvatarBox
                              backgroundColor={item.color_id}
                            >
                              <AvatarTextBox>
                                {getFirstLetter(item.name)}
                              </AvatarTextBox>

                            </AvatarBox>

                            <NameBox>
                              <TextNameBox>
                                {item.name}
                              </TextNameBox>

                            </NameBox>
                          </PersonBox>

                          <ImagesBox>
                            <EditImage src={edit}
                              onClick={() => handleClickEdit(item.id, item.color_id, item.name)}
                            />

                            <MenuBox>
                              <MenuImg src={menu} />
                            </MenuBox>
                          </ImagesBox>
                        </PersonsCardBox>
                      )
                    }
                    }
                  </Draggable>
                ))}

                {provided.placeholder}
              </DndColumn>
            )
          }}
        </Droppable>
      </DragDropContext>
    </PersonsListBox>
  )
}

const PersonsListBox = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`
const PersonsCardBox = styled.div`
  user-select: none;
  height: 70px;
  width: 100%;
  display: flex;
  border-bottom: solid 1px var(--divider-primary);
  align-items: center;
  justify-content: space-between;
  box-shadow: ${props => props.boxShadow};
  background-color: ${props => props.backgroundColor};
`

const PersonBox = styled.div`
  display: flex;
  padding-left: 16px;
`

const AvatarTextBox = styled.label`
  color: white;
  font-size: 22px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 28px;
`

const AvatarBox = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  margin-right: 12px;
`

const NameBox = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_50}) {
    max-width: 140px;
  }
`

const TextNameBox = styled.label`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`

const ImagesBox = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

const EditImage = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 16px;
  -webkit-tap-highlight-color: var(--divider-primary);
`

const MenuImg = styled.img`
  cursor: move;
  -webkit-tap-highlight-color: var(--divider-primary);
`

const MenuBox = styled.div`
  border-left: solid 1px var(--divider-primary);
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  height: 100%;
`

const DndColumn = styled.div``

