// @ts-ignore
import template from './templates/test.hbs';
import './css/styles.css';
import { getAPIKey, getWidgetData, setAPIKey, setWidgetData } from './store';

interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  currentValue?: string;
  switcherOptions: IOption[];
  onChange?: (value: any) => void;
  minimized?: boolean;
}

const defaultOptions: IOptions = {
  currentValue: null,
  switcherOptions: [],
  onChange: () => {},
  minimized: false,
};

const SWITCHER_BOX_CLASS = 'switcher-box';
const API_SWITCHER_ID = 'api-switcher';
const SELECT_CLASS = 'switcher';
const MINIMIZED = 'minimized';
const HOLDER_CLASS = 'switcher-holder';
const WIDGET_WIDTH = 196;
const WIDGET_HEIGHT = 70;

let widgetEl: HTMLElement;
let switcherEl: HTMLSelectElement;
let widgetHolderEl: HTMLElement;
const body = document.body;
const html = document.documentElement;

let { height, width } = getPageSize(html);
let maxX = width;
let maxY = height;

function onSwitch(callback: (value: string) => void, event: Event) {
  let value = '';
  const { target } = event;
  if (target) {
    value = (target as HTMLSelectElement).value;
  }
  if (value) {
    setAPIKey(value);
    callback(value);
  }
}

function getPageSize(html: HTMLElement) {
  const width = Math.max(
    window.innerWidth,
    document.documentElement.clientWidth,
    document.body.clientWidth
  );
  const height = Math.max(
    window.innerHeight,
    document.documentElement.clientHeight,
    document.body.clientHeight
  );

  return {
    height,
    width,
  };
}

function setCursorPosition(
  element: HTMLElement,
  position: { x: number; y: number }
) {
  const { x, y } = position;
  const Y = y - element.offsetHeight / 2;
  const X = x - 20;
  setElPosition(element, { x: X, y: Y });
}

function setElPosition(element, position: { x: number; y: number }) {
  const { x, y } = position;
  element.style.position = 'fixed';
  element.style.top = y + 'px';
  element.style.left = x + 'px';
}

function getPosition(element: HTMLElement): { x: number; y: number } {
  const x = parseInt(element.style.left);
  const y = parseInt(element.style.top);
  return {
    x,
    y,
  };
}

function onMouseDown(event: Event) {
  document.addEventListener('mousemove', onMouseMove);
}

function onMouseUp() {
  document.removeEventListener('mousemove', onMouseMove);
}

function onMouseMove(event: MouseEvent) {
  const [x, y] = [event.pageX, event.pageY];
  moveWidget(x, y);
}

function moveWidget(x: number, y: number) {
  const { width, height } = getPageSize(html);
  let X = width - WIDGET_WIDTH + 30;
  let Y = height - WIDGET_HEIGHT / 2;
  if (x > X || y > Y || x < 20 || y < 20) return;
  setCursorPosition(widgetEl, { x, y });
  setWidgetData({ position: { x, y } });
}

function onPageResize() {
  const { width, height } = getPageSize(body);
  const { x, y } = getPosition(widgetEl);
  let X = width - WIDGET_WIDTH;
  let Y = height - WIDGET_HEIGHT;
  const dX = X - x;
  const dY = Y - y;
  const newX = dX >= 0 ? x : X;
  const newY = dY >= 0 ? y : Y;
  setElPosition(widgetEl, { x: newX, y: newY });
  setWidgetData({ position: { x: newX, y: newY } });
}

function onClickHolder(minimized = false, event) {
  event.preventDefault();
  if (!minimized) return;
  widgetEl.classList.toggle(MINIMIZED);
}

function getInitialPosition() {
  const x = WIDGET_WIDTH + 5;
  const y = WIDGET_HEIGHT + 5;
  return { x, y };
}

function getCurrentValue(options, value) {
  const item = options.find((item) => item.value === value);
  return !!item ? item.value : null;
}

function init(options: IOptions = defaultOptions) {
  if (!options.switcherOptions || options.switcherOptions.length === 0)
    throw new Error('switcher options unavailable');

  const defaultValue =
    (options?.switcherOptions &&
      options?.switcherOptions.length > 0 &&
      options?.switcherOptions[0]?.value) ||
    null;

  const currentValue =
    getCurrentValue(options.switcherOptions, options.currentValue) ||
    getAPIKey() ||
    defaultValue;

  const widgetData = getWidgetData();
  const { onChange, minimized } = options;
  const { x, y } =
    (!!widgetData?.position && {
      x: widgetData.position?.x,
      y: widgetData.position?.y,
    }) ||
    getInitialPosition();

  const component = createWidgetComponent(options, {
    x,
    y,
  });

  body.appendChild(component);
  widgetEl = body.querySelector(`#${API_SWITCHER_ID}`);
  switcherEl = widgetEl.querySelector(`.${SELECT_CLASS}`);
  widgetHolderEl = widgetEl.querySelector(`.${HOLDER_CLASS}`);

  if (currentValue) setSwitcherValue(switcherEl, currentValue);

  switcherEl.addEventListener('change', onSwitch.bind(null, onChange));
  widgetEl.addEventListener('mousedown', onMouseDown);
  widgetHolderEl.addEventListener('click', onClickHolder.bind(null, minimized));
  document.addEventListener('mouseup', onMouseUp);
  window.addEventListener('resize', onPageResize);

  minimized
    ? widgetEl.classList.add(MINIMIZED)
    : widgetEl.classList.remove(MINIMIZED);

  return () => {
    switcherEl.removeEventListener('change', onChange);
    widgetEl.removeEventListener('mousedown', onMouseDown);
    widgetHolderEl.removeEventListener(
      'click',
      onClickHolder.bind(null, minimized)
    );
    document.removeEventListener('mouseup', onMouseUp);
  };
}

function createWidgetComponent(
  { switcherOptions, minimized }: Partial<IOptions>,
  { x, y }
) {
  const component = document.createElement('div');
  component.classList.add(SWITCHER_BOX_CLASS);
  component.id = API_SWITCHER_ID;
  setCursorPosition(component, { x, y });
  component.innerHTML = template({
    options: switcherOptions,
    minimized,
  });
  return component;
}

function setSwitcherValue(selectEl: HTMLSelectElement, value) {
  if (!!value) selectEl.value = value;
}

export { init };
