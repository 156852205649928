import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { useCallback, useEffect, useRef } from 'react'

import styled from 'styled-components'
import { PublicLinkPasswordModal } from 'features/public-link/PublicLinkPasswordModal'
import { useAppDispatch } from  "store"
import { useNavigate, useParams } from 'react-router-dom'
import { PublicLinkPlaceholder, PublicLinkPlaceholderBtnTypes } from 'features/public-link/PublicLinkPlaceholder'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import { useTranslation } from 'react-i18next'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { PageTopbar } from 'features/common/page-topbar'
import { useMobileDetection } from 'features/common/hooks'
import { usePageTitle } from 'features/common/hooks/usePageTitle'
import { FilesSkeleton } from 'features/files/FilesSkeleton'
import {
  fetchFilesPublicLinkBreadcrumbsThunk,
  fetchFilesPublicLinkInfoThunk,
  fetchFilesPublicLinkItems,
  FilesPublicLinksErrorTypes,
  publicLinkFilesActions,
  unsubscribeFilesPublicLinkToWsThunk
} from 'features/public-link-files/publicLinkFilesSlice'
import {
  getFilesPublicLinkErrorSelector,
  getFilesPublicLinkLoadingStatusSelector,
  getFilesPublicLinkNodeDataSelector,
  getFilesPublicLinkNodesHrefSelector,
  getFilesPublicLinkSelectedItemsCountSelector,
  getIsSizeQuotaExceedErrorSelector,
  getFilesPublicLinkBreadcrumbsSelector
} from 'features/public-link-files/selectors'
import { PublicFiles } from 'features/public-link-files/PublicFiles'
import { parsePathFromParams } from 'features/common/parsePathFromParams/parsePathFromParams'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { FilesTableBreadcrumbs, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import classNames from 'classnames'
import { MobileToolbarMenuToggle } from 'features/common/right-sidebar/MobileToolbarMenuToggle'

import { hideNotification, NOTIFICATION_TYPES, showNotification } from "../../../features/common/notifications"
import { goToSubscriptionsPage } from "../../../utils/subscriptions"
import { useResizeWindow } from "../../../features/common/hooks/useResizeWindow"

export const PublicFilesPage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams()
  const isMobile = useMobileDetection()

  const isAuthorized = getIsUserAuthorizedSelector()
  const error = getFilesPublicLinkErrorSelector()
  const selectedItemsCount = getFilesPublicLinkSelectedItemsCountSelector()
  const status = getFilesPublicLinkLoadingStatusSelector()
  const nodeData = getFilesPublicLinkNodeDataSelector()
  const nodesHref = getFilesPublicLinkNodesHrefSelector()
  const breadcrumbs = getFilesPublicLinkBreadcrumbsSelector()
  const widthWindow = useResizeWindow()

  usePageTitle('a_nav_publicFiles')

  const paths = parsePathFromParams(params, 'public')
  const rootId = paths[0]

  useEffect(() => {
    dispatch(fetchFilesPublicLinkInfoThunk({ id: rootId, navigate }))

    return () => {
      dispatch(unsubscribeFilesPublicLinkToWsThunk())
    }
  }, [])


  useEffect(() => {
    if (!nodesHref) {
      return
    }

    const id = paths.length > 1 ? paths[paths.length - 1] : ''

    dispatch(fetchFilesPublicLinkItems({ id }))
  }, [paths[paths.length - 1], nodesHref])

  useEffect(() => {
    if (status === LOADING_STATUSES.LOADING) {
      return
    }

    const paths = parsePathFromParams(params, 'public')

    dispatch(fetchFilesPublicLinkBreadcrumbsThunk({ paths }))
  }, [params['*'], status])

  const handleCancelSelection = useCallback(() => {
    dispatch(publicLinkFilesActions.unselectAll())
  }, [])

  const sizeQuotaExceedNotification = useRef(null)
  const isSizeQuotaExceedError = getIsSizeQuotaExceedErrorSelector()

  useEffect(() => {
    if (isSizeQuotaExceedError && !sizeQuotaExceedNotification.current) {
      const notificationOptions = {
        isPermanent: true,
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_spaceError'),
        message: t('l_notification_copyError'),
        callback: () => {
          hideNotification(sizeQuotaExceedNotification.current)
          sizeQuotaExceedNotification.current = null
          goToSubscriptionsPage()
        }
      }

      sizeQuotaExceedNotification.current = showNotification(notificationOptions)
    }
  }, [isSizeQuotaExceedError])

  const getPlaceholderProps = (error) => {
    switch (error) {
    case FilesPublicLinksErrorTypes.NOT_EXIST:
      return {
        texts: {
          title: t('l_common_linkNotExist'),
          description: t('l_common_linkExpiredMessage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn')
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO()
      }
    case FilesPublicLinksErrorTypes.EMPTY_FOLDER:
      return {
        texts: {
          title: t('l_albums_noPhotosTitle'),
          description: '',
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn')
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO()
      }
    case FilesPublicLinksErrorTypes.SOMETHING_WENT_WRONG:
      return {
        texts: {
          title: t('l_common_linkExpired'),
          description: t('l_common_linkExpiredMessage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn'),
          createAccount: !isAuthorized && t('a_common_signUp'),
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO(),
        onCreateAccount: isAuthorized ? null : () => redirectToExternalSSO('/signup'),
      }
    case FilesPublicLinksErrorTypes.NO_ACCESS:
      return {
        texts: {
          title: t('l_common_noAccessTitle'),
          description: !isAuthorized ? t('l_common_logInToSee') : t('l_common_noAccessMesage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn'),
          createAccount: !isAuthorized && t('a_common_signUp'),
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO(),
        onCreateAccount: isAuthorized ? null : () => redirectToExternalSSO('/signup'),
      }
    }
  }

  function handleBreadcrumbClick(item) {
    const paths = parsePathFromParams(params, 'drive')
    const indexOfPath = paths.findIndex(path => path === item.id)

    dispatch(publicLinkFilesActions.unselectAll())

    if (!item.name) {
      navigate(`/public/${paths[0]}`)

      return
    }

    navigate(`/public/${paths.slice(0, indexOfPath + 1).join('/')}`)
  }

  return (
    <>
      {status !== LOADING_STATUSES.LOADING && !error && (
        <PageHeaderBox>
          <StyledPageTopbar
            title={nodeData.name}
            selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
            selectedItemsCount={selectedItemsCount}
            onCancelSelection={handleCancelSelection}
            className={classNames({ 'with-bigger-height': !!selectedItemsCount })}
          />

          {
            breadcrumbs.length >= 1 && !selectedItemsCount && (
              <FilesTableBreadcrumbsWrapper>
                <FilesTableBreadcrumbs
                  widthWindow={widthWindow}
                  filePath={breadcrumbs}
                  firstItem={breadcrumbs.length < 2 ? t('l_common_sharedWithYou') : t('l_common_allFiles')}
                  onClickedPath={handleBreadcrumbClick}
                />
              </FilesTableBreadcrumbsWrapper>
            )}
        </PageHeaderBox>
      )}

      {!!error && (
        <PublicLinkPlaceholder {...getPlaceholderProps(error)} />
      )}

      {
        status !== LOADING_STATUSES.LOADING && !error && (
          <PublicFiles />
        )
      }

      {
        status === LOADING_STATUSES.LOADING && !error && (
          <FilesSkeleton />
        )
      }

      <PublicLinkPasswordModal type={SDK_TYPES.FILES} />

      <MobileToolbarMenuToggle iconName="more_horiz"
        className="js-mobile-toolbar-menu-toggle"
      />
    </>
  )
}

const FilesTableBreadcrumbsWrapper = styled.div`
  padding-left: 60px;
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    padding-left: 0;
  }
`

const PageHeaderBox = styled.div`
  display: flex;
  flex-flow: column;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin: 0;
  }
`

const StyledPageTopbar = styled(PageTopbar)`
  && {
    min-height: 40px;

    &.with-bigger-height {
      min-height: 88px;
    }
  }
`
