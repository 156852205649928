import React, { CSSProperties, useEffect, useRef } from 'react'

import styled from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { Icon, STYLED_VARIABLES } from '@cloudike/web_ui_components'

import { INotification, NOTIFICATION_TYPES } from './types'


interface NotificationProps {
    className?: string,
    notification: INotification,
    style?: CSSProperties,
    state: TransitionStatus,
    onClick: (notification: INotification) => void,
    onSetHeight: (height: number) => void
}

export const Notification: React.FC<NotificationProps> = ({
  className = '',
  notification,
  style,
  state,
  onClick,
  onSetHeight
}) => {
  const handleClick = () => {
    onClick(notification)
  }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    onSetHeight(ref.current.getBoundingClientRect().height)
  }, [])

  return (
    <NotificationsBox
      className={className}
      style={style}
      state={state}
      onClick={handleClick}
      ref={ref}
    >
      <NotificationTypeIcon
        iconName={notification.type === NOTIFICATION_TYPES.SUCCESS ? 'CheckCircle' : 'WarningIcon'}
        id={notification.type}
      />

      <Text>
        {!!notification.message && (
          <Description>
            {notification.message}
          </Description>
        )}

        <Title>
          {notification.title}
        </Title>
      </Text>

      {!!notification.callback && !notification.withHiddenArrow && <ArrowIcon iconName="ArrowForward" />}
    </NotificationsBox>
  )
}

const NotificationsBox = styled.div`
  background-color: var(--background-toast-primary);
  color: var(--text-toast-primary);
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 8%);
  max-width: unset;
  width: unset;
  position: unset;
  cursor: pointer;
  user-select: none;
  transition: all ease .5s;
  margin: 0 8px 8px;

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    z-index: 15;
    left: 10px;
    bottom: 10px;
    max-width: 440px;
    width: 90%;
    transform: translateY(100%);
  }
`

const NotificationTypeIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`

const Text = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  width: 80%;
`

const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
`

const Description = styled.span`
  margin-bottom: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`

const ArrowIcon = styled(Icon)`
  margin-left: auto;
  width: 24px;
  height: 24px;
`
