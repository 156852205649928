import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useCallback, useEffect, useMemo, useState } from "react"

import styled from 'styled-components'
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { PublicLinksTabs } from "features/public-links-albums/PublicLinksTabs"
import {
  PUBLIC_LINKS_TYPES, checkPublicLinksTotalItemsCountsThunk,
} from "features/public-links-albums/publicLinksSlice"
import { FilesSkeleton } from "features/files/FilesSkeleton"
import {
  getAllPublicLinksFilesNodesSelector,
  getPublicLinksFilesCheckboxVisibility,
  getPublicLinksFilesLoadingStatusSelector,
  getPublicLinksFilesSelectType,
  getPublicLinksFilesSelectedItemsCountSelector,
  getPublicLinksFilesSelectedItemsIdsSelector,
  getPublicLinksFilesSortSelector
} from "features/public-links-files/selectors"
import {
  fetchNodesThunk,
  publicLinksFilesActions,
  subscribePublicLinksFilesToWSThunk,
  unSubscribePublicLinksFilesFromWSThunk
} from "features/public-links-files/publicLinksFilesSlice"
import { FilesList } from "features/files/FilesList"
import { mapFileItemsToTableRow } from "features/files/filesUtils"
import { NodeTypes } from "@cloudike/web_files"
import { openFilesPreviewThunk, PREVIEW_TYPES } from "features/files/files-preview/filesPreviewSlice"
import { useGetFilesTableColumns } from "features/files/useGetFilesTableColumns"
import { useNavigate } from "react-router-dom"
import { SelectType } from "@cloudike/web_ui_components"
import { sharingActions } from "features/sharing/sharingSlice"
import { SDK_TYPES } from "sdk/sdkConstants"

import { EmptyPublicLinksPlaceholder } from "../../../features/public-links-albums/EmptyPublicLinksPlaceholder"

const PublickLinksFilesPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const nodes = getAllPublicLinksFilesNodesSelector()
  const sortState = getPublicLinksFilesSortSelector()
  const selectedItemsCount = getPublicLinksFilesSelectedItemsCountSelector()
  const status = getPublicLinksFilesLoadingStatusSelector()
  const selectType = getPublicLinksFilesSelectType()
  const checkboxVisibility = getPublicLinksFilesCheckboxVisibility()

  const selectedFilesIds = getPublicLinksFilesSelectedItemsIdsSelector()

  const navigate = useNavigate()

  const [arrColumns, handleSortByColumn] = useGetFilesTableColumns(
    onChangeSorting,
    sortState
  )

  const [withPressedCtrl, setWithPressedCtrl] = useState(false)
  const [withPressedShift, setWithPressedShift] = useState(false)

  const previewNodes = useMemo(() => nodes.filter(node => node.type === NodeTypes.FILE), [nodes])

  const selectStartListener = useCallback((e) => {
    e.preventDefault()

    return false
  }, [])

  function downHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(true)
    }

    if (key === 'Shift') {
      setWithPressedShift(true)

      document.addEventListener('selectstart', selectStartListener)
    }
  }

  function upHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(false)
    }

    if (key === 'Shift') {
      setWithPressedShift(false)

      document.removeEventListener('selectstart', selectStartListener)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  usePageTitle('a_nav_publicLinks')

  useEffect(() => {
    dispatch(fetchNodesThunk())
    dispatch(checkPublicLinksTotalItemsCountsThunk())
    dispatch(subscribePublicLinksFilesToWSThunk())

    return () => {
      dispatch(publicLinksFilesActions.resetState())
      dispatch(unSubscribePublicLinksFilesFromWSThunk())
    }
  }, [])

  function onChangeSorting(sortState) {
    dispatch(publicLinksFilesActions.setSort(sortState))
  }


  const handleSelectNode = (node, items) => {
    if (withPressedShift) {
      dispatch(publicLinksFilesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    if (withPressedCtrl) {
      dispatch(publicLinksFilesActions.selectItemWithPressedCtrl({ id: node.id, items }))

      return
    }

    dispatch(publicLinksFilesActions.selectItem({ id: node.id, items }))
  }

  const handleElementsSelection = (node, items) => {
    if (withPressedShift) {
      dispatch(publicLinksFilesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    dispatch(publicLinksFilesActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleCancelSelection = () => {
    dispatch(publicLinksFilesActions.unselectAll())
  }

  const handleSelectAll = (items) => {
    dispatch(publicLinksFilesActions.selectAll(items))
  }

  const handleClickCheckbox = (allFileIds) => {
    if (selectType === SelectType.ALL) {
      handleCancelSelection()
    } else {
      handleSelectAll(allFileIds)
    }
  }

  const handleShareSelected = (item) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(item))
  }

  const handleChangeFolder = (node) => {
    dispatch(publicLinksFilesActions.unselectAll())

    if (node.type !== NodeTypes.DIR) {
      dispatch(openFilesPreviewThunk({
        type: PREVIEW_TYPES.FILES_PUBLIC_LINKS,
        items: previewNodes as any,
        currentItemId: node.id,
        totalItemsCount: previewNodes.length
      }))

      return
    }

    navigate(`/drive/${node.id}`, { state: { needToCheckIdsPath: true, nodeType: NodeTypes.DIR } })
  }

  const addOnSelectStart = node => {
    if (!node || !withPressedCtrl) return
    node.addEventListener("selectstart", e => {
      e.preventDefault()
    })
  }

  return (
    <>
      <PageTopbar
        title={t('a_nav_publicLinks')}
      />

      <PublicLinksTabs activeTab={PUBLIC_LINKS_TYPES.FILES} />

      <PageContent ref={addOnSelectStart}>
        {status === LOADING_STATUSES.LOADING && <FilesSkeleton />}

        {
          !nodes.length && status !== LOADING_STATUSES.LOADING && (
            <EmptyPublicLinksPlaceholder
              texts={{
                title: t('l_more_pubLinksEmptyPageTitle'),
                description: t('l_common_driveEmptyPlaceholder')
              }}
            />
          )}

        {!!nodes.length && status !== LOADING_STATUSES.LOADING && (
          <FilesList
            columns={arrColumns}
            selectType={selectType}
            checkboxVisibility={checkboxVisibility}
            items={mapFileItemsToTableRow(nodes, t)}
            onRenameItem={() => false}
            onSortingChange={handleSortByColumn}
            onToggleItem={handleSelectNode}
            onChangeFolder={handleChangeFolder}
            onClickCheckbox={handleElementsSelection}
            selectedFilesIds={selectedFilesIds}
            renamingFileId={null}
            onEditPublicLink={handleShareSelected}
            onClickAllCheckbox={handleClickCheckbox}
          />
        )}

        {!!selectedItemsCount && <MobileToolbarMenuToggle iconName='more_horiz' />}
      </PageContent>
    </>
  )
}

const PageContent = styled.div`
`

export default PublickLinksFilesPage
