import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { userApi } from "../api/userApi"
import { offersApi } from "../api/offersApi"

import { RootState } from "./index"

const initialState = {
  leftMenuOpenedOnMobile: false,
  rightMenuOpenedOnMobile: false,
  isRightSidebarTransparent: false,
  noLayoutContentOverflow: false,
  isFeedbackModalVisibility: false,
  contentTypesUpload: '',
  contentTypesDropzone: {},
  extensionAvailableForUploading: [],
  webSocketState: null
}

export const addContentTypesThunk = createAsyncThunk(
  'app/addContentTypesThunk',
  async function(_, { dispatch , getState }) {

    const { id } = (getState() as RootState).user.userData

    const response = await userApi.getContentTypes(id)

    const initialContentTypesForDropzone = {
      'image/*': [],
      'video/*': [],
    }

    const contentTypes = response.content_types
    const extensions = response.extensions

    const addPointForExtension = extensions.map(item => '.' + item)

    for (const key in initialContentTypesForDropzone) {
      initialContentTypesForDropzone[key] = [...addPointForExtension]
    }

    const contentTypesForUpload = contentTypes.join() + ',' + addPointForExtension.join()

    const contentTypesForDropzone = contentTypes.reduce((object, key) => ({ ...object, [key]: [] }) , {})

    if (!!contentTypes && !!extensions) {
      dispatch(actions.setContentTypesUpload(contentTypesForUpload))
      dispatch(actions.setContentTypesDropzone({ ...contentTypesForDropzone }))
      dispatch(actions.setExtensionsAvailableForUploading(addPointForExtension))
    }
  }
)


export const sendActivityThunk = createAsyncThunk(
  'app/sendActivityThunk',
  async function(_, { getState }) {

    const { id } = (getState() as RootState).user.userData

    try {
      await userApi.sendActivity(id)
    } catch (e) {
      console.log(e)
    }
  }
)

export const getOffersContentThunk = createAsyncThunk<{content: string, url: string}, {offerId: string}>
('app/getOffersContentUrl',
  async function({ offerId }) {
    return await offersApi.getOfferContent(offerId)
  })


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleLeftMenuOnMobile: (state, action) => {
      state.leftMenuOpenedOnMobile = action.payload
    },
    toggleRightMenuOnMobile: (state, action) => {
      state.rightMenuOpenedOnMobile = action.payload
    },
    setContentTypesUpload: (state, action) => {
      state.contentTypesUpload = action.payload
    },
    setFeedbackModalVisibility: (state, action) => {
      state.isFeedbackModalVisibility = action.payload
    },
    setContentTypesDropzone: (state, action) => {
      state.contentTypesDropzone = action.payload
    },
    setExtensionsAvailableForUploading: (state, action) => {
      state.extensionAvailableForUploading = action.payload
    },
    toggleLayoutOverflowEnabling: (state, action) => {
      state.noLayoutContentOverflow = action.payload
    },
    setWSState: (state, action) => {
      state.webSocketState = action.payload
    },
    setIsRightSidebarTransparent: (state, action) => {
      state.isRightSidebarTransparent = action.payload
    }
  },
})

const {
  reducer, actions
} = appSlice

export { reducer as appReducer, actions as appActions }
