import { IGetEmbeddedItemsSchema } from "@cloudike/web_photos/dist/types/intarfaces/IGetEmbeddedItemsSchema"
import { AlbumItemsPaginator } from "@cloudike/web_photos/dist/types/lib/Paginator/AlbumItemsPaginator"

import { getPhotoSdk } from "./photo"
import { SDK_TYPES } from "./sdkConstants"

export const getDefaultAlbumsSdk = () => {
  const sdk = getPhotoSdk()
  const albums = sdk.albums

  return albums
}

export const getFamilyAlbumsSdk = () => {
  const sdk = getPhotoSdk()
  const albums = sdk.familyAlbums

  return albums
}

export const getAlbumsSdkByType = (type: SDK_TYPES) => {
  if (type === SDK_TYPES.FAMILY) {
    return getFamilyAlbumsSdk()
  }

  return getDefaultAlbumsSdk()
}

let currentAlbumItemsPaginator: AlbumItemsPaginator | null = null

export const initAlbumItemsPaginator = (albumId: string, type: SDK_TYPES, pageSize = 20, params?:IGetEmbeddedItemsSchema) => {
  currentAlbumItemsPaginator = getAlbumsSdkByType(type).getAlbumItemsPaginator(albumId, pageSize, params)

  return currentAlbumItemsPaginator
}

export const getCurrentAlbumItemsPaginator = () => {
  return currentAlbumItemsPaginator
}