import { Navigate, Outlet, Route, Routes, } from "react-router-dom"
import { RightSidebar } from "features/common/right-sidebar"
import { LeftSidebar } from "features/common/left-sidebar"
import { ConnectedLayout } from "features/common/connected-layout"
import { SharingModal } from "features/sharing/SharingModal"
import { PhotoPreviewModal } from "features/photo/photo-preview/PhotoPreviewModal"
import { FamilyInviteModal } from "features/family/FamilyInviteModal"
import FamilyInvitation from "pages/Family/FamilyInvitation"
import { AUTH_STATUSES, useAuth } from "features/user/hooks/useAuth"
import { PublicAlbumPage } from "pages/Public/Album"
import { PublicLinkSidebar } from "features/public-link/PublicLinkSidebar"
import { FilesPreviewModal } from "features/files/files-preview/FilesPreviewModal"
import { PublicFilesPage } from "pages/Public/Files"
import { PublicLinkFilesSidebar } from "features/public-link-files/PublicLinkFilesSidebar"

import { PhotoPreviewFlashbackModal } from "../features/photo/photo-preview-flashback/PhotoPreviewFlashbackModal"
import SignIn from "../pages/SignIn"
import OfferView from "../features/offer/OfferView"

import { routes } from "./routes"

const RightSidebarRouting = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const SidebarComponent = route.rightSidebarComponent

        return (
          <Route
            path={route.path}
            element={<RightSidebar />}
            key={route.path}
          >
            {
              !!route.nestedRoutes && (
                <Route element={<Outlet />}>
                  {route.nestedRoutes.map((route, index) => {
                    const Component = route.rightSidebarComponent

                    if (!Component) {
                      return null
                    }

                    return (
                      <Route
                        index={route.isIndex}
                        path={route.path}
                        key={route.path || index}
                        element={<Component />}
                      />
                    )}
                  )}
                </Route>
              )}

            {
              !route.nestedRoutes && !!route.rightSidebarComponent && (
                <Route
                  index
                  element={<SidebarComponent />}
                />
              )}
          </Route>
        )
      })}

      <Route path="*"
        element={null}
      />
    </Routes>
  )
}

export const Routing = () => {
  const authStatus = useAuth()

  return (
    <Routes>
      <Route
        path="/signin"
        element={<SignIn />}
      />

      <Route
        path="/offers/:offerId"
        element={<OfferView />}
      />

      {authStatus !== AUTH_STATUSES.UNKNOWN && (
        <>
          <Route
            path="/invite"
            element={<FamilyInvitation />}
          />

          <Route
            path="/links/pa/:id"
            element={(
              <ConnectedLayout
                leftSidebar={<LeftSidebar />}
                rightSidebar={<PublicLinkSidebar />}
              >
                <PublicAlbumPage />

                <PhotoPreviewModal />
              </ConnectedLayout>
            )}
          />

          <Route
            path="/public/*"
            element={(
              <ConnectedLayout
                leftSidebar={<LeftSidebar />}
                rightSidebar={<PublicLinkFilesSidebar />}
              >
                <PublicFilesPage />

                <FilesPreviewModal />
              </ConnectedLayout>
            )}
          />

          <Route
            path="/links/fs/*"
            element={(
              <ConnectedLayout
                leftSidebar={<LeftSidebar />}
                rightSidebar={<PublicLinkFilesSidebar />}
              >
                <PublicFilesPage />

                <FilesPreviewModal />
              </ConnectedLayout>
            )}
          />
        </>
      )}

      {authStatus === AUTH_STATUSES.AUTHORIZED && (
        <Route
          path="*"
          element={(
            <ConnectedLayout
              leftSidebar={<LeftSidebar />}
              rightSidebar={<RightSidebarRouting />}
            >
              <Routes>
                {routes.map((route) => {
                  const Component = route.component

                  return (
                    <Route
                      path={route.path}
                      key={route.path}
                      element={<Component />}
                    >
                      {!!route.nestedRoutes && route.nestedRoutes.map((route, index) => {
                        const Component = route.component

                        return (
                          <Route
                            index={route.isIndex}
                            path={route.path}
                            key={route.path || index}
                            element={<Component />}
                          />
                        )}
                      )}
                    </Route>
                  )})}

                <Route
                  path="*"
                  element={(
                    <Navigate
                      to="/drive"
                      replace
                    />
                  )}
                />
              </Routes>

              <SharingModal />

              <FamilyInviteModal />

              <PhotoPreviewModal />

              <FilesPreviewModal />

              <PhotoPreviewFlashbackModal />
            </ConnectedLayout>
          )}
        />
      )}

      <Route
        path="*"
        element={<div />}
      />
    </Routes>
  )
}
