import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { STYLED_VARIABLES } from 'constants/styledVariables'
import { DATES_FORMATS } from 'constants/datesFormats'

import React, { useEffect, useState } from 'react'

import { applyPromocodeThunk, fetchPromocodesThunk } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserPromocodesSelector } from 'features/user/selectors'
import { formatDate } from 'utils/formatDate'
import { PrimaryButton } from '@cloudike/web_ui_components'
import { Form, Formik } from 'formik'

import { ProfileTable, ProfileTableBox, ProfileTableText, ProfileTBody, ProfileTd, ProfileTHead, ProfileTHeadTh, ProfileTHeadTr, ProfileTr } from './ProfileStyledComponents'

export const ProfilePromocodes = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { items: promocodes, status } = getUserPromocodesSelector()

  useEffect(() => {
    dispatch(fetchPromocodesThunk())
  }, [])

  return (
    <ProfileSubscriptionsBox className={className}>
      <Title>
        {t('l_settings_promoCodesTitle')}
      </Title>

      <ProfileTableBox>
        <ProfileTable>
          <ProfileTHead>
            <ProfileTHeadTr>
              <ProfileTHeadTh>
                {t('l_common_description')}
              </ProfileTHeadTh>

              <ProfileTHeadTh className="align-right">
                {t('l_common_activated')}
              </ProfileTHeadTh>
            </ProfileTHeadTr>
          </ProfileTHead>

          {status === LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="2">
                  <ProfileTableText>
                    {t('l_common_loading')}
                    ...
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!promocodes.length && status !== LOADING_STATUSES.LOADING && (
            <ProfileTBody>
              <ProfileTr>
                <ProfileTd colSpan="2">
                  <ProfileTableText>
                    {t('l_common_noCodes')}
                  </ProfileTableText>
                </ProfileTd>
              </ProfileTr>
            </ProfileTBody>
          )}

          {!!promocodes.length && (
            <ProfileTBody>
              {promocodes.map(promocode => (
                <ProfileTr key={promocode.name}>
                  <ProfileTd>
                    <ProfileTableText className="ellipsis">
                      {promocode.name}
                    </ProfileTableText>
                  </ProfileTd>

                  <ProfileTd>
                    <ProfileTableText className="align-right">
                      {formatDate(promocode.activated, DATES_FORMATS.profileTables)}
                    </ProfileTableText>
                  </ProfileTd>
                </ProfileTr>
              ))}
            </ProfileTBody>
          )}
        </ProfileTable>
      </ProfileTableBox>

      <EnterPromocode />
    </ProfileSubscriptionsBox>
  )
}

const ProfileSubscriptionsBox = styled.div`
  margin-top: 16px;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const EnterPromocode = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [isInputVisible, toggleInputVisibility] = useState(false)

  const handleShowInput = () => {
    toggleInputVisibility(true)
  }

  const handleSubmit = (values, { resetForm }) => {
    const callback = () => {
      toggleInputVisibility(false)
      resetForm()
    }

    dispatch(applyPromocodeThunk({
      code: values.promocode,
      callback
    }))
  }

  return (
    <EnterPromocodeBox>
      {!isInputVisible && (
        <EnterPromocodeButton onClick={handleShowInput}>
          {t('a_settings_enterYourPromoCode')}
        </EnterPromocodeButton>
      )}

      {!!isInputVisible && (
        <Formik
          initialValues={{ promocode: '' }}
          onSubmit={handleSubmit}
          validateOnChange
          enableReinitialize
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form>
              <PromocodeLabel htmlFor="promocode">
                {t('l_common_promoCode')}
              </PromocodeLabel>

              <PromocodeInputBox>
                <PromocodeInput
                  placeholder={t('a_settings_enterYourPromoCode')}
                  name="promocode"
                  id="promocode"
                  onChange={handleChange}
                  value={values.promocode}
                  maxLength="64"
                />

                <ApplyPromocodeBtn
                  actionName={t('a_common_apply')}
                  onAction={handleSubmit}
                  disabled={!values.promocode}
                />
              </PromocodeInputBox>
            </Form>
          )}
        </Formik>
      )}
    </EnterPromocodeBox>
  )
}

const EnterPromocodeBox = styled.div`
  margin-top: 16px;
`

const EnterPromocodeButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 152.34%;
  color: var(--button-secondary-default);
`

const PromocodeInputBox = styled.div`
  display: flex;
  align-items: center;
`

const PromocodeLabel = styled.label`
  margin-bottom: 9px;
  font-weight: 700;
  font-size: 16px;
  line-height: 152.34%;
  color: var(--button-secondary-default);
  display: flex;
`

const PromocodeInput = styled.input`
  background: var(--background-primary);
  border: 1px solid var(--text-disable);
  border-radius: 4px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-primary);
  height: 40px;
  max-width: 293px;
  flex: 1;

  &::placeholder {
    color: var(--text-disable);
  }

  :active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    max-width: 190px;
    padding: 0 8px;
  }
`

const ApplyPromocodeBtn = styled(PrimaryButton)`
  margin-left: 16px;
  min-width: 98px;
  height: 44px;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 8px 12px !important;
  }`
