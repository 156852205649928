import { useEffect, useRef, useState } from 'react'

import { convertMilliseconds } from 'utils/time'

export const useTimer = (ms) => {
  const [timeLost, setTime] = useState(ms)
  const [isTimerActive, setTimerStatus] = useState(false)

  const intervalRef = useRef(null)
  const resetTimer = () => {
    setTime(ms)
  }
  const startTimer = () => {
    resetTimer()
    intervalRef.current = setInterval(() => {
      setTime(time => {
        const newTimeLost = time - 1000

        if (newTimeLost <= 0) {
          clearInterval(intervalRef.current)
          setTimerStatus(false)
        }

        return newTimeLost
      })
    }, 1000)

    setTimerStatus(true)
  }

  const stopTimer = () => {
    clearInterval(intervalRef.current)
    setTimerStatus(false)
  }

  useEffect(() => {
    setTime(ms)
  }, [ms])

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return {
    ms: timeLost,
    isTimerActive,
    startTimer,
    resetTimer,
    stopTimer,
    formattedData: convertMilliseconds(timeLost)
  }
}
