import APP_CONFIG from 'constants/configs/app.config'

import { WSClientEvents } from '@cloudike/web_core'
import { USER_DATA_KEY_IN_STORAGE } from 'features/user/constants'
import SockJS from 'sockjs-client'

import { getCurrentAppConfig } from "../features/common/configs/additionalConfigService"

import { WebSocketService } from './webSocketService'

let sock: SockJS
let publicLinkSocket: WebSocketService
let reconnectTries = 0
const listeners = []

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))

const createClient = ({ id, jwtToken }) => {
  sock = new SockJS(getCurrentAppConfig().sockjs_for_shares)

  sock.onopen = function() {
    const token = localStorage.getItem(USER_DATA_KEY_IN_STORAGE) && JSON.parse(`${localStorage.getItem(USER_DATA_KEY_IN_STORAGE)}`)?.CurrentUser?.token

    const data: { token?: string, event_groups: string[], share_id: string, jwt_token?: string, timestamp: number } = {
      event_groups: ["fs", "photos", "family"],
      share_id: id,
      timestamp: new Date().getTime()
    }

    if (token) {
      data.token = token
    }

    if (jwtToken) {
      data.jwt_token = jwtToken
    }

    sock.send(JSON.stringify(data))
  }

  sock.onclose = async function(event: CloseEvent) {
    if (event.wasClean) {
      console.info(
        `Connection closed code = ${event.code} reason = ${event.reason}`
      )
    } else {
      listeners.forEach(({ eventName, listener }) => {
        sock.removeEventListener(eventName, listener)
      })

      await wait(2 ** ++reconnectTries * 10)

      const newSock = createClient({ id, jwtToken })

      listeners.forEach(listener => {
        newSock.addEventListener(listener.eventName, listener.listener)
      })

      console.info(
        `Connection interrupted code = ${event.code} reason = ${event.reason}`
      )
    }
  }

  return sock
}

export const initPublicLinkSocket = ({ id, jwtToken }) => {
  if (publicLinkSocket) {
    return publicLinkSocket
  }

  const sock = createClient({ id, jwtToken })

  const wrappedSock = {
    client: sock,
    addListener: (eventName: WSClientEvents, listener) => {
      if (eventName === WSClientEvents.MESSAGE) {
        listeners.push({
          eventName,
          listener
        })

        sock.addEventListener(WSClientEvents.MESSAGE, listener)
      }
    }
  }

  return new WebSocketService(wrappedSock)
}

export const initFilesPublicLinkSocket = ({ id, jwtToken }) => {
  if (publicLinkSocket) {
    return publicLinkSocket
  }

  const sock = createClient({ id, jwtToken })

  const wrappedSock = {
    client: sock,
    addListener: (eventName: WSClientEvents, listener) => {
      if (eventName === WSClientEvents.MESSAGE) {
        listeners.push({
          eventName,
          listener
        })

        sock.addEventListener(WSClientEvents.MESSAGE, listener)
      }
    }
  }

  return new WebSocketService(wrappedSock, false, true)
}

