import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useCallback, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { AlbumSkeleton, PhotosGrid } from "@cloudike/web_ui_components"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { useNavigate } from "react-router-dom"

import {
  albumsSeasonsActions,
  fetchSeasonsAlbumsThunk,
  subscribeAlbumsSeasonsToWSThunk,
  unsubscribeAlbumsSeasonsFromWSThunk
} from "../../../features/albums/albums-season/albumsSeasonsSlice"
import {
  getAlbumsSeasonsItemsSelector,
  getAlbumsSeasonsLoadingStatusSelector,
  getAlbumsSeasonsSelectedItemsCountSelector
} from "../../../features/albums/albums-season/selectors"
import { AlbumsSeasons } from "../../../features/albums/albums-season/AlbumsSeasons"
import { AlbumsSeasonsPageTopbar } from "../../../features/albums/albums-season/AlbumsSeasonsTopbar"
import { MobileToolbarMenuToggle } from "../../../features/common/right-sidebar/MobileToolbarMenuToggle"

const AlbumsSeasonsPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const status = getAlbumsSeasonsLoadingStatusSelector()
  const items = getAlbumsSeasonsItemsSelector()

  usePageTitle('a_files_uploadPhotoAlbums_title')

  useEffect(() => {
    dispatch(subscribeAlbumsSeasonsToWSThunk())
    dispatch(fetchSeasonsAlbumsThunk(300))

    return () => {
      dispatch(albumsSeasonsActions.resetState())
      dispatch(unsubscribeAlbumsSeasonsFromWSThunk())
    }
  }, [])

  const handleBackButtonClick = () => {
    navigate(`/photos/albums`)
  }

  const selectedItemsCount = getAlbumsSeasonsSelectedItemsCountSelector()

  const handleCancelSelection = useCallback(() => {
    dispatch(albumsSeasonsActions.unselectAll())
  }, [])

  return (
    <>
      <AlbumsSeasonsPageTopbar
        title={t('l_albums_memories')}
        onBack={handleBackButtonClick}
        selectedItemsCount={selectedItemsCount}
        selectedText={t('l_common_selectedObjects', { number: selectedItemsCount })}
        onCancelSelection={handleCancelSelection}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && !!items.length && (
          <AlbumsSeasons isAlbums={false}
            countAlbums={items.length}
          />
        )
      }

      {!!selectedItemsCount && <MobileToolbarMenuToggle />}

    </>
  )
}

const Skeleton = () => {
  return (
    <PhotosGrid>
      {Array.from({ length: 16 }).map((_, index:number) => <AlbumSkeleton key={index} />)}
    </PhotosGrid>
  )
}

export default AlbumsSeasonsPage
