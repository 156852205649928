export const API_UTILS = {
  ACTIONS_TYPES: {
    MOVE: 'move',
    COPY: 'copy',
    UPLOAD: 'upload',
    UPLOAD_TO_FAMILY: 'uploadToFamily',
    COPY_TO_FAMILY: 'copyToFamily',
    COPY_TO_PERSONAL: 'copyToPersonal',
    RESTORE: 'restore',
    SYNC_OTHER_CLOUD: 'syncOtherCloud'
  },
  ITEM_TYPE: {
    FILE: 'file',
    PHOTO: 'photo'
  }
}