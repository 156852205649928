import { DATES_FORMATS } from "constants/datesFormats"

import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"
import moment from "moment"

export const getAlbumDateStr = (album: IAlbumSchema) => {
  if (!album.items_count) {
    return ''
  }

  const startDate = moment(album.first_photo_created).format(DATES_FORMATS.albumStartDate)
  const endDate = moment(album.last_photo_created).format(DATES_FORMATS.albumEndDate)
  const startYear = moment(album.first_photo_created).format("YYYY")
  const endYear = moment(album.last_photo_created).format("YYYY")
  const startMonth = moment(album.first_photo_created).format(DATES_FORMATS.albumStartMonth)
  const endMonth = moment(album.last_photo_created).format(DATES_FORMATS.albumEndMonth)
  const startDay = moment(album.first_photo_created).format("D")
  const endDay = moment(album.last_photo_created).format("D")
  const endDayWithMonth = moment(album.last_photo_created).format(`D ${DATES_FORMATS.albumEndMonth}`)

  if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
    return startDate
  }

  if (startYear === endYear && startMonth === endMonth) {
    return `${startDay} - ${endDayWithMonth} ${startYear}`
  }

  if (startYear === endYear && startMonth !== endMonth) {
    return `${startMonth} - ${endMonth} ${endYear}`
  }

  if (startYear !== endYear) {
    return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
  }

  return `${startDate} - ${endDate}`
}