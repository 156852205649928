import APP_CONFIG from 'constants/configs/app.config'

import React, { useEffect, useState } from 'react'

import { getUserDataSelector, removeAccountThunk, userActions } from 'features/user/userSlice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import styled from 'styled-components'
import { DialogModal } from 'ui/DialogModal'

import { getFormattedUsername } from "../../../../utils/getFormattedUsername"
import { getAllowPasswordChangeSelector } from "../../selectors"

import { ProfileNameModal } from './ProfileNameModal'
import { ProfilePasswordChangingModal } from './ProfilePasswordChangingModal'

let  ReservePhone
try {
  ReservePhone = require(`../../../../apps/${process.env.APP_NAME}/src/features/user/features/reserve-phone/ReservePhone.tsx`).ReservePhone
} catch (e) {
  ReservePhone = require(`../reserve-phone/ReservePhone.tsx`).ReservePhone
}

export const ProfileMainInfo = ({ className = '', isChangePassword = false }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userData = getUserDataSelector()
  const isAllowPasswordChange = getAllowPasswordChangeSelector()

  useEffect(() => {
    dispatch(userActions.toggleUserPasswordModal(isChangePassword))
  }, [isChangePassword])

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const getUserLogin = () => {
    const logins = userData?.logins

    if (!logins || !logins.length) {
      return ''
    }

    const emailLogin = logins.find(login => login.startsWith('email:'))

    if (!emailLogin) {
      return ''
    }

    return emailLogin.split('email:')[1]
  }

  const phoneMask = APP_CONFIG.user_phone_mask

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleRemoveAccount = () => {
    dispatch(removeAccountThunk())
  }

  const handleOpenUserNameModal = () => {
    dispatch(userActions.toggleUserNameModal(true))
  }

  const handleOpenPasswordModal = () => {
    dispatch(userActions.toggleUserPasswordModal(true))
  }

  return (
    <ProfileMainInfoBox className={className}>
      <InfoRow
        label={t('l_common_displayName')}
        value={getFormattedUsername(userData, phoneMask)}
        btnText={t('l_common_edit')}
        onBtnClick={handleOpenUserNameModal}
      />

      {APP_CONFIG.user_email_support && !!getUserLogin() && (
        <InfoRow
          label={t('l_common_email')}
          value={getUserLogin()}
        />
      )}

      {APP_CONFIG.user_backup_number_support && (
        <ReservePhone>
          {({ phone, openChangePhoneModal, isPhoneExists }) => (
            <InfoRow
              label={t('l_settings_backupNumber')}
              value={phone}
              btnText={isPhoneExists ? t('a_common_edit') : t('a_common_add')}
              onBtnClick={openChangePhoneModal}
            />
          )}
        </ReservePhone>

      )}

      {isAllowPasswordChange && (
        <InfoRow
          label={t('l_common_passwordField')}
          value={"*********"}
          btnText={t('l_settings_changePasswordTitle')}
          onBtnClick={handleOpenPasswordModal}
        />
      )}

      <RemoveAccountBtn onClick={handleOpenRemovingConfirmationModal}>
        {t('a_common_removeAccount')}
      </RemoveAccountBtn>

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_areYouSure')}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveAccount}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_accountDeletedMessage')}
        </TextInModalBox>
      </DialogModal>

      <ProfileNameModal />

      <ProfilePasswordChangingModal />
    </ProfileMainInfoBox>
  )
}

interface InfoRowProps {
    label: string,
    value: string,
    btnText?: string,
    onBtnClick?: () => void
}

const InfoRow: React.FC<InfoRowProps> = ({ label, value, btnText, onBtnClick }) => (
  <InfoRowBox>
    <InfoTextBox>
      <Label>
        {label}
      </Label>

      <Value>
        {value}
      </Value>
    </InfoTextBox>

    {!!btnText && (
      <RowBtn onClick={onBtnClick}>
        {btnText}
      </RowBtn>
    )}
  </InfoRowBox>
)

const TextInModalBox = styled.span`
  font-size: 14px;
`

const ProfileMainInfoBox = styled.div`

`

const InfoTextBox = styled.div`
  overflow: hidden;
`

const InfoRowBox = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid var(--divider-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    border-top: 1px solid var(--divider-primary);
  }
`

const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  color: var(--text-secondary);
`

const Value = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  color: var(--text-primary);
`

const RowBtn = styled.button`
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--accent-normal);
  cursor: pointer;
  margin-left: 20px;
`

const RemoveAccountBtn = styled.button`
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: var(--text-secondary);
  cursor: pointer;
`
