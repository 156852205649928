import { WSClientEvents } from "@cloudike/web_photos"

type Listener = (data: any) => void

interface Listeners {
  [key: string]: Listener[]
}

export class WebSocketService {
  private client: any
  private listeners: Listeners
  private handleEventsByGroupName: boolean
  private handleEventsWithoutMessageType: boolean

  constructor(client, handleEventsByGroupName = false, handleEventsWithoutMessageType = false) {
    this.client = client
    this.listeners = {}

    this.handleEventsByGroupName = handleEventsByGroupName
    this.handleEventsWithoutMessageType = handleEventsWithoutMessageType

    this.init()
  }

  private init = () => {
    this.client.addListener(WSClientEvents.MESSAGE, this.startHandlingMessages)
  }

  private callListeners = (type, output, action, group) => {
    this.listeners[type].forEach(listener => listener({ type, output, action, group }))
  }

  private startHandlingMessages = (message: MessageEvent<any>) => {
    try {
      const parsedData = JSON.parse(message.data)

      if (this.handleEventsWithoutMessageType) {
        this.callListeners('default', parsedData, null, null)

        return
      }

      if (parsedData.group === 'service' && this.listeners[parsedData.type]) {
        this.callListeners(parsedData.type, parsedData, null, parsedData.group)
        return
      }

      if (parsedData._error && this.listeners[parsedData._error.code]) {
        this.callListeners(parsedData._error.code, parsedData, null, parsedData.group)
        return
      }

      const { resource, group, type } = parsedData

      if (!this.listeners[this.handleEventsByGroupName ? group : type]) {
        return
      }

      if (resource && resource.output) {
        const { output, action } = resource

        this.callListeners(this.handleEventsByGroupName ? group : type, output, action, group)

        return
      }

      if (resource) {
        this.callListeners(this.handleEventsByGroupName ? group : type, resource, null, group)

        return
      }

      this.callListeners(this.handleEventsByGroupName ? group : type, parsedData, null, group)

    } catch (error) {
      console.error("WS Message couldn't be parsed ", error)
    }
  }

  addEventListener = (name = 'default', listener: Listener) => {
    if (this.listeners[name]) {
      this.listeners[name].push(listener)

      return
    }

    this.listeners[name] = [listener]
  }

  removeEventListener = (name = 'default', listener?: Listener) => {
    if (!this.listeners[name]) {
      return
    }

    if (listener) {
      const indexOfListener = this.listeners[name].indexOf(listener)

      if (indexOfListener !== -1) {
        this.listeners[name] = [...this.listeners[name].slice(0, indexOfListener), ...this.listeners[name].slice(indexOfListener + 1)]
      }

      return
    }

    delete this.listeners[name]
  }

  closeConnection = () => {
    this.listeners = {}
    this.client?.end()
  }
}
