import { useEffect, useRef } from "react"

import { useAppDispatch } from  "store"
import _ from "lodash"

import { FileItemsTypes } from "../types"

import { filesPreviewActions } from "./filesPreviewSlice"
import { getCurrentFilesPreviewItemIndexSelector, getFilesPreviewItemsSelector } from "./selectors"

export const useFilesPreviewItemsUpdate = (nodes) => {
  const dispatch = useAppDispatch()
  const currentItems = getFilesPreviewItemsSelector()
  const currentIndex = getCurrentFilesPreviewItemIndexSelector()
  const currentItemIdRef = useRef<string>()

  currentItemIdRef.current = currentItems[currentIndex]?.id

  useEffect(() => {
    const newItems = nodes.filter(node => node.type === FileItemsTypes.FILE)

    if (currentItems.length > 0 && currentItems.length !== newItems.length) {
      const currentItemId = currentItemIdRef.current

      dispatch(filesPreviewActions.setAllItems(newItems))
      dispatch(filesPreviewActions.setTotalItemsCount(newItems.length))

      if (newItems.length > currentItems.length) {
        dispatch(filesPreviewActions.setCurrentItemIndexById(currentItemId))
      }

      return
    }

    if (!_.isEqual(currentItems[currentIndex], newItems[currentIndex])) {
      dispatch(filesPreviewActions.setAllItems(newItems))
    }
  }, [nodes, currentItems])
}
