import APP_CONFIG from 'constants/configs/app.config'

import moment from 'moment'
import _ from 'lodash'
import { IFsNodeSchema } from "@cloudike/web_files"

import { analytics, ANALYTICS_EVENTS } from "../common/analytics"

import { FileItemsTypes } from './types'

const defaultFileNameBreakpoints = [
  { width: 0, length: 20 },
  { width: 340, length: 26 },
  { width: 361, length: 30 },
  { width: 450, length: 35 },
  { width: 1024, length: 13 },
  { width: 1249, length: 25 },
  { width: 1279, length: 15 },
  { width: 1350, length: 25 },
  { width: 1439, length: 35 },
]
export const formatFileName = (name: string, breakpoints = defaultFileNameBreakpoints) => {
  const width = window.innerWidth
  let maxLength: number

  breakpoints.forEach(breakpoint => {
    if (width > breakpoint.width) {
      maxLength = breakpoint.length
    }
  })

  const splittedByDot = name.split('.')

  const extension = splittedByDot.length > 1 ? splittedByDot.slice(-1) : ''
  const nameWithoutDot = splittedByDot.slice(0, extension ? splittedByDot.length - 1 : 1).join('.')
  const ellipsis = '…'
  if (nameWithoutDot.length > maxLength) {
    return nameWithoutDot.substring(0, Math.floor((maxLength - 3) / 2))
      + ellipsis
      + nameWithoutDot.substring(nameWithoutDot.length - Math.floor((maxLength - 3) / 2), nameWithoutDot.length)
      + (extension ? '.' + extension : '')
  } else {
    return name
  }
}

export const mapFileItemsToTableRow = (items, t) => {
  return items.map((item) => {
    let icon
    const date = moment(item.updated).utc(true).format('MMM DD, yyyy, HH:mm')

    if (item.type === FileItemsTypes.DIR) {
      icon = 'folder'
    } else {
      if (item?.file_info?.type !== null) {
        switch (item?.file_info?.type) {
        case 'image':
          icon = 'file_image'
          break
        case 'video':
          icon = 'file_video'
          break
        case 'audio':
          icon = 'file_audio'
          break
        }
      }
    }

    return {
      ...item,
      folder: item.type === FileItemsTypes.DIR,
      name: item.name,
      shared: item.is_shared,
      modified: date,
      size: calculateItemSize(item, t),
      access: item.is_shared ? t('l_files_statusShared') : t('l_common_onlyYou'),
      icon: icon,
      id: item.id,
      thumbnailLink: item?.file_info?.type !== 'audio' ? getSmallPreviewLink(item) : null
    }
  })
}

export const mapFileVersionItemsToTableRows = (items, t) => {
  return items.map((item) => {
    let icon
    const date = moment(item.updated || item.created).utc(true).format('MMM DD, yyyy, HH:mm')

    if (item.type === FileItemsTypes.DIR) {
      icon = 'folder'
    } else {
      if (item?.type !== null) {
        switch (item?.type) {
        case 'image':
          icon = 'file_image'
          break
        case 'video':
          icon = 'file_video'
          break
        case 'audio':
          icon = 'file_audio'
          break
        }
      }
    }


    return {
      ...item,
      name: item.name,
      modified: date,
      size: calculateItemSize(item, t),
      icon: icon,
      id: item.id,
      thumbnailLink: item?.file_info?.type !== 'audio' ? getSmallPreviewLink(item) : null
    }
  })
}

export function calculateItemSize(file, t) {
  const bytes = file?.file_info?.size || file?.size
  return calculateSize(bytes, t)
}

export function calculateSize(bytes: number, t) {
  const sizeName = [t('l_common_bytes'),
    t('common__storageUnit__KB'),
    t('common__storageUnit__MB'),
    t('common__storageUnit__GB'),
    t('common__storageUnit__TB')]

  const number = bytes ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0
  const size =
    Math.trunc(bytes / Math.pow(1024, number)) + ' ' + sizeName[number]

  return size
}

function getSmallPreviewLink(item) {
  if (!item?._embedded?.previews?.sizes?.small) {
    return ''
  }

  const urlTemplate = _.template(item._embedded.previews.url_template, { interpolate: /\{(.+?)\}/g })
  const url = urlTemplate({ size_name: 'small' }) + `&jwt=${item._embedded.previews.jwt}`

  return url
}

export const generateRemovingText = (nodes, t) => {
  if (!nodes.length) {
    return {}
  }

  if (nodes.length > 1) {
    return {
      title: t('l_common_headerRemoveContent'),
      text: t('l_common_areYouSure')
    }
  }

  if (nodes[0].type === FileItemsTypes.DIR) {
    return {
      title: t('l_common_headerRemoveFolder'),
      text: t('l_common_folderDeletionConfirmation', { FILE_NAME: nodes[0].name, APP_NAME: APP_CONFIG.app_name })
    }
  }

  return {
    title: t('l_common_headerRemoveContent'),
    text: t('l_common_deleteFileConfirmation', { FILE_NAME: nodes[0].name, APP_NAME: APP_CONFIG.app_name })
  }
}

export function generateFilesItemName(
  itemName,
  justNeedToAddSuffix = false,
  isFolder = false
) {
  const spacer = ' '
  const startValue = 1
  const regExp = /\((\d+?)\)$/

  let newName = ''
  const dot = '.'
  let count = startValue

  const splittedByDot = itemName.split(dot)

  const extension = isFolder ? '' : splittedByDot.length > 1 ? splittedByDot.slice(-1) : ''
  const name = isFolder ? splittedByDot.join('.') : splittedByDot.slice(0, extension ? splittedByDot.length - 1 : 1).join('.')

  const matches = name.match(regExp)

  const tail = `${dot}${extension}`

  if (matches && !justNeedToAddSuffix) {
    const [, countStr] = matches
    if (countStr) {
      count = parseInt(countStr)
      count++
    }
    newName = name.replace(regExp, `(${count})`)
  } else {
    newName = `${name}${spacer}(${count})`
  }

  if (extension) {
    newName += tail
  }

  return newName
}

export function isFolderExist(items, folderName) {
  return isItemExistInFolder(items, folderName, FileItemsTypes.DIR)
}

export function isFileExist(items, fileName) {
  return isItemExistInFolder(items, fileName, FileItemsTypes.FILE)
}

export function getNewFolderName(items, folderName, needToJustAddSuffix = true) {
  const newName = generateFilesItemName(folderName, needToJustAddSuffix, true)

  if (isFolderExist(items, newName)) return getNewFolderName(items, newName, false)

  return newName
}

export function getNewFileName(items, fileName, needToJustAddSuffix = true) {
  const newName = generateFilesItemName(fileName, needToJustAddSuffix)

  if (isFileExist(items, newName)) return getNewFileName(items, newName, false)

  return newName
}

function isItemExistInFolder(items, itemName: string, itemType: FileItemsTypes) {
  return (
    _.filter(items, (item) => item.type === itemType && item.name === itemName)
      .length > 0
  )
}

export function pushCreateEditItemPublicLink(item: IFsNodeSchema) {
  let eventData = {}
  const DIR_TYPE = 'dir'
  if(item.is_shared) {
    eventData = item.type === DIR_TYPE ? ANALYTICS_EVENTS.WEB_FOLDER_EDIT_LINK : ANALYTICS_EVENTS.WEB_FILES_EDIT_LINK
  } else {
    eventData = item.type === DIR_TYPE ? ANALYTICS_EVENTS.WEB_FOLDER_CREATE_LINK : ANALYTICS_EVENTS.WEB_FILES_CREATE_LINK
  }
  analytics.push(eventData)
}

export const pathsToTree = (paths) => {
  const result = {
    children: []
  }

  const generateChildrenStructure = (obj, paths) => {
    if (paths.length === 1) {
      const path = paths[0]

      obj.children.push({
        name: path,
        type: 'file'
      })
    } else {
      let currentStructure = obj.children.find(item => item.name === paths[0])

      if (!currentStructure) {
        currentStructure = {
          name: paths[0],
          children: [],
          type: 'dir'
        }

        obj.children.push(currentStructure)
      }

      generateChildrenStructure(currentStructure, paths.slice(1))
    }
  }

  paths.forEach(path => {
    const split = path.split('/')
    const pathParts = split.length > 1 ? path.split('/').slice(1) : split

    generateChildrenStructure(result, pathParts)
  })

  return result
}
