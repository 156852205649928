import { API_UTILS } from 'constants/apiUtils'
import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userApi } from 'api/userApi'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { t } from 'i18next'
import { request } from 'api/request'
import { RootState } from 'store'
import { getErrorData } from 'utils/getErrorData'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { NavigateFunction } from 'react-router-dom'

import { DROPBOX, getCloudLabelByName } from './constants'

const TIMEOUT_VALUE = 5000

const checkingCloudsTimeouts = {}

const setCheckingCloudsTimeout = (cloudId, dispatch) => {
  if (checkingCloudsTimeouts[cloudId]) {
    clearTimeout(checkingCloudsTimeouts[cloudId])
  }

  checkingCloudsTimeouts[cloudId] = setTimeout(() => dispatch(checkImportTaskThunk({ cloudId })), TIMEOUT_VALUE)
}

const clearCheckingCloudsTimeout = (cloudId) => {
  clearTimeout(checkingCloudsTimeouts[cloudId])
  delete checkingCloudsTimeouts[cloudId]
}

const clearAllCheckingTimeouts = () => {
  Object.keys(checkingCloudsTimeouts).forEach((key) => {
    clearCheckingCloudsTimeout(key)
  })
}

const initialState = {
  clouds: [],
  addModalOpened: false,
  status: LOADING_STATUSES.LOADING
}

function parseConnectionStatusUri(state) {
  const decodedState = decodeURI(state)
  const tokens = decodedState.split('|')

  if (tokens.length > 1) {
    return tokens[1]
  }
  return ''
}

export const fetchLinkedCloudsSourcesThunk = createAsyncThunk(
  'otherClouds/fetchLinkedCloudsSourcesThunk',
  async function({ params, navigate }: { params?: any, navigate?: NavigateFunction }, { dispatch, getState }) {
    try {
      const state = getState() as RootState

      const response = await userApi.getAllLinkedAccounts(state.user.userData.id)

      const linkedAccounts = response._embedded.linked_accounts
      const { source, code, state: paramsState } = params || {}

      // checking for new accounts
      if (code && paramsState && source) {
        const url = source === DROPBOX.name ? parseConnectionStatusUri(paramsState) : paramsState

        if (url) {
          const label = getCloudLabelByName(source)

          showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: t('l_notification_tryingConnectDropbox', { NAME: label })
          })

          try {
            await request('POST', url, { code, state: paramsState }, { host: null })

            showNotification({
              type: NOTIFICATION_TYPES.SUCCESS,
              title: t('l_notification_accountConnected', { NAME: label })
            })

            navigate('/profile/account', { replace: true })
          } catch (error) {
            showNotification({
              type: NOTIFICATION_TYPES.WARNING,
              title: t('l_common_connectDropboxFail', { NAME: label })
            })

            navigate('/profile/account', { replace: true })
          } finally {
            dispatch(fetchLinkedCloudsSourcesThunk({}))
          }
        }

        return
      }

      const mappedClouds = linkedAccounts.map(account => ({ ...account, task: account?._embedded?.last_import_task }))

      dispatch(actions.setLinkedClouds(mappedClouds))
      dispatch(actions.setStatus(LOADING_STATUSES.SUCCEEDED))

      const cloudsWithTaskInProgress = mappedClouds.filter((account) => {
        return account.task && account.task.state !== 'done'
      })

      if (cloudsWithTaskInProgress.length > 0) {
        cloudsWithTaskInProgress.forEach(cloud => setCheckingCloudsTimeout(cloud.id, dispatch))
      }
    } catch (error) {
      if (navigator.onLine) {
        return
      }
    }
  }
)

export const checkImportTaskThunk = createAsyncThunk(
  'otherClouds/checkImportTaskThunk',
  async function({ cloudId }: { cloudId: string }, { dispatch, getState }) {
    const state = (getState() as RootState)
    const clouds  = state.otherClouds.clouds
    const userId = state.user.userData.id

    const cloud = clouds.find(cloud => cloud.id === cloudId)
    const task = cloud.task

    try {
      const response = await userApi.checkImportTask(userId, task.id)

      dispatch(actions.setCloudTask({ cloudId, task: response }))

      if (response.state === 'done') {
        if (response.result.status !== 200 && response.result.code !== 'Ok') {
          switch (response.result.status) {
          case 402:
            showNotification({
              type: NOTIFICATION_TYPES.WARNING,
              ...getErrorData({ data: response.result, action: API_UTILS.ACTIONS_TYPES.SYNC_OTHER_CLOUD, nameOfCheckingCloud: getCloudLabelByName(cloud.type) })
            })
            return
          case 403:
            if (response.result.code === 'InvalidToken') {
              showNotification({
                type: NOTIFICATION_TYPES.WARNING,
                title: t('l_notification_importTokenErrorMessage'),
                message: t('l_notification_importTokenError')
              })
            }

            return
          default:
            showNotification({
              type: NOTIFICATION_TYPES.WARNING,
              title: t('l_common_tryAgainNote'),
              message: t('l_notification_somethingWrongTryAgain')
            })
          }
        } else {
          if (response.items_imported === response.items_count) {
            showNotification({
              type: NOTIFICATION_TYPES.SUCCESS,
              title: t('l_notification_importComplete', { NAME: getCloudLabelByName(cloud.type) })
            })
          }
        }

        clearCheckingCloudsTimeout(cloud.id)

        return
      }

      setCheckingCloudsTimeout(cloud.id, dispatch)
    } catch (error) {
      if (navigator.onLine) {
        return
      }

      setCheckingCloudsTimeout(cloud.id, dispatch)
    }
  }
)


export const startImportFromOtherCloudThunk = createAsyncThunk(
  'otherClouds/startImportFromOtherCloudThunk',
  async function({ accountId, name }: { accountId: string, name: string }, { dispatch, getState }) {
    const state = getState() as RootState

    showGlobalProgressLoader()

    try {
      const response = await userApi.importTasks(state.user.userData.id, accountId)

      dispatch(actions.setCloudTask({ cloudId: accountId, task: response }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_importStarted', { NAME: name })
      })

      setCheckingCloudsTimeout(accountId, dispatch)
    } catch (error) {

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const cancelImportingFromOtherCloudThunk = createAsyncThunk(
  'otherClouds/cancelImportingFromOtherCloudThunk',
  async function({ taskId }: { taskId: string }, { dispatch, getState }) {
    try {
      const state = getState() as RootState
      const cloud = state.otherClouds.clouds.find(cloud => cloud?.task?.id === taskId)

      showGlobalProgressLoader()

      const response = await userApi.cancelImportTask(state.user.userData.id, taskId)

      dispatch(actions.setCloudTask({ cloudId: cloud.id, task: response }))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const removeOtherCloudThunk = createAsyncThunk(
  'otherClouds/removeOtherCloudThunk',
  async function({ accountId, type, name }: { accountId: string, type: string, name: string }, { dispatch, getState }) {
    const state = getState() as RootState

    showGlobalProgressLoader()

    try {
      await userApi.removeLinkedCloud(state.user.userData.id, accountId, type)

      dispatch(fetchLinkedCloudsSourcesThunk({}))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t( 'l_notification_accountDisabled', { NAME: name })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_common_removeAccountFail', { NAME: name })
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const addOtherCloudThunk = createAsyncThunk(
  'otherClouds/addOtherCloud',
  async function({ source }: { source: string }, { getState, dispatch }) {
    const state = getState() as RootState

    showGlobalProgressLoader()

    try {
      const response = await userApi.getAuthUrl(state.user.userData.id, source)

      const {
        _links: {
          authorize_url: { href }
        }
      } = response

      if (href) {
        window.location.href = href
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.toggleAddModal(false))
    }
  }
)

export const otherCloudsSlice = createSlice({
  name: 'otherClouds',
  initialState,
  reducers: {
    setLinkedClouds: (state, action) => {
      state.clouds = action.payload
    },
    toggleAddModal: (state, action) => {
      state.addModalOpened = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setCloudTask: (state, action) => {
      const clouds = state.clouds

      const index = clouds.findIndex(cloud => cloud.id === action.payload.cloudId)

      state.clouds = [...clouds.slice(0, index), { ...clouds[index], task: action.payload.task }, ...clouds.slice(index + 1)]
    },
    resetState: (state) => {
      state.clouds = []
      state.status = LOADING_STATUSES.LOADING

      clearAllCheckingTimeouts()
    }
  },
})

const { reducer, actions } = otherCloudsSlice

export { reducer as otherCloudsReducer, actions as otherCloudsActions }
