import { createApi } from '@reduxjs/toolkit/query/react'

import api from "../utils/api"

export const loginByKey = createApi({
  reducerPath: 'loginByKey',
  baseQuery: api.baseQueryConfig,
  endpoints: (builder) => ({
    loginByKey: builder.mutation<any, any>({
      query: ( key ) => ({
        url: `/api/2/accounts/login_by_key`,
        method: 'POST',
        body: { key: key },
      }
      ),
    }),
  }),
})

export const { useLoginByKeyMutation } = loginByKey
