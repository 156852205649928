import { request } from "./request"

export const documentsWalletApi = {
  getUserSettings: (userId) => {
    return request('GET', `/api/3/users/${userId}/document_wallet`, {}, { apiVersion: 3 })
  },
  getCryptoAlgorithms: () => {
    return request('GET', `/api/3/document_wallet/algorithms`, {}, { apiVersion: 3 })
  },
  setPassword: ({ userId, data }) => {
    return request('PUT', `/api/3//users/${userId}/document_wallet/keys`, data, { apiVersion: 3 })
  },
  checkPassword: ({ userId, token }) => {
    return request('GET', `/api/3//users/${userId}/document_wallet/keys`, null, { apiVersion: 3, headers: {
      'Validation-Token': token
    } })
  },
  deleteDocumentsWallet: (userId) => {
    return request('DELETE', `/api/3/users/${userId}/document_wallet`, {}, { apiVersion: 3 })
  },
  getPreviewSizes: () => {
    return request('GET', '/api/3/document_wallet/preview_sizes', {}, { apiVersion: 3 })
  },
  getDocumentTypes: (userId, lang) => {
    return request('GET', `/api/3/users/${userId}/document_wallet/document_types`, {}, { apiVersion: 3, headers: { 'X-Accept-Language': lang } })
  },
  createDocumentType: (userId, name) => {
    return request('POST', `/api/3/users/${userId}/document_wallet/document_types`, { name }, { apiVersion: 3 })
  },
  removeDocumentType: (userId, typeId) => {
    return request('DELETE', `/api/3/users/${userId}/document_wallet/document_types/${typeId}`, {}, { apiVersion: 3 })
  },
  renameDocumentType: (userId, typeId, name) => {
    return request('PATCH', `/api/3/users/${userId}/document_wallet/document_types/${typeId}`, { name }, { apiVersion: 3 })
  },
  uploadDocument: ({ url, uploadParamsData, file }) => {
    return request('PUT', ``, file, {
      headers: {
        'upload-params': `b64:${btoa(
          unescape(encodeURIComponent(JSON.stringify(uploadParamsData)))
        )}`,
        'content-length': file.size
      },
      host: url
    })
  },
  getDocumentItems: ({ userId, typeId, offset = 0, limit, total_count = true }) => {
    return request('GET', `/api/3/users/${userId}/document_wallet/document_types/${typeId}/documents`, { offset, limit, total_count }, { apiVersion: 3 })
  },
  getHeaderDocumentItems: ({ userId, typeId, total_count = true }) => {
    return request('HEAD', `/api/3/users/${userId}/document_wallet/document_types/${typeId}/documents?total_count=${total_count}/`, { apiVersion: 3 })
  },
  deleteDocumentItem: ({ userId, typeId, id }) => {
    return request('DELETE', `/api/3/users/${userId}/document_wallet/document_types/${typeId}/documents/${id}`, {}, { apiVersion: 3 })
  }
}
