import { useState } from 'react'

import { MenuWithActionItems, PrimaryButton, STYLED_VARIABLES, UserMenu as UserMenuForUnauthorized } from '@cloudike/web_ui_components'
import { uploadSharedAlbumItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { UserMenu } from 'features/user/UserMenu'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { appActions } from 'store/app'
import styled from 'styled-components'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { DialogModal } from 'ui/DialogModal'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'

import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  addItemsToPersonalCloudThunk,
  checkForAccessToSharedAlbumThunk,
  downloadSharedAlbumItemThunk,
  downloadSharedAlbumThunk,
  PublicLinksErrorTypes,
  removeSharedAlbumSelectedItemsThunk
} from './publicLinkSlice'
import {
  getPublicLinkErrorSelector,
  getPublicLinkSelectedItemsSelector,
  getPublicLinksNotMyResourceHrefSelector,
  getPublicLinkPermissionSelector,
  getPublicLinkSelectedItemsCountSelector,
  getPublicLinksTokenSelector,
  getPublicLinkTotalItemsCountSelector
} from './selectors'


export const PublicLinkSidebar = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isAuthorized = getIsUserAuthorizedSelector()
  const selectedItemsCount = getPublicLinkSelectedItemsCountSelector()
  const selectedItems = getPublicLinkSelectedItemsSelector()
  const permission = getPublicLinkPermissionSelector()
  const token = getPublicLinksTokenSelector()
  const error = getPublicLinkErrorSelector()
  const totalItemsCount = getPublicLinkTotalItemsCountSelector()
  const notMyResourceHref = getPublicLinksNotMyResourceHrefSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleSignIn = () => {
    redirectToExternalSSO()
  }

  const handleSignUp = () => {
    redirectToExternalSSO('/signup')
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadSharedAlbumThunk())
  }

  const handleDownloadItem = () => {
    dispatch(downloadSharedAlbumItemThunk({ item: selectedItems[0] }))
  }

  const handleAddItemsToPersonalCloud = () => {
    dispatch(addItemsToPersonalCloudThunk(selectedItems))
  }

  const handleRemoveItems = () => {
    dispatch(removeSharedAlbumSelectedItemsThunk({ items: selectedItems }))
    handleCloseRemovingConfirmationModal()
  }

  const handleUploadFiles = (event) => {
    const files = event.target.files

    const callback = () => {
      if(files.length > 0) {
        dispatch(uploadSharedAlbumItemsThunk({ files, url: notMyResourceHref, token }))
        dispatch(appActions.toggleRightMenuOnMobile(false))

        event.target.value = ''
      }
    }

    dispatch(checkForAccessToSharedAlbumThunk({ callback, neededPermission: 'write' }))
  }

  const menuItems = (() => {
    return [
      !selectedItemsCount && permission === 'write' && isAuthorized && {
        label: t('a_common_uploadPhotos'),
        iconName: 'download2',
        type: 'file',
        onClickItem: handleUploadFiles
      },
      !!selectedItemsCount && permission === 'write' && {
        label: t('a_common_remove'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      !!selectedItemsCount && {
        label: t('a_common_toolbarAddToMyCloud'),
        iconName: 'add_photo',
        onClickItem: handleAddItemsToPersonalCloud
      },
    ].filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    return [
      !selectedItemsCount && !!totalItemsCount && {
        label: t('a_albums_downloadAlbum'),
        iconName: 'download',
        onClickItem: handleDownloadAlbum
      },
      selectedItemsCount === 1 && {
        label: t('a_common_download'),
        iconName: 'download',
        onClickItem: handleDownloadItem
      },
      !selectedItemsCount && permission === 'write' && isAuthorized && {
        label: t('a_common_uploadPhotos'),
        iconName: 'download2',
        type: 'file',
        onClickItem: handleUploadFiles
      },
      !!selectedItemsCount && permission === 'write' && {
        label: t('a_common_remove'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      !!selectedItemsCount && isAuthorized && {
        label: t('a_common_toolbarAddToMyCloud'),
        iconName: 'add_photo',
        onClickItem: handleAddItemsToPersonalCloud
      },
    ].filter(item => !!item)
  })()

  return (
    <PublicLinkSidebarBox className={className}>
      {
        !isAuthorized && (
          <StyledUserMenuForUnauthorized
            onLogIn={handleSignIn}
            onCreateAccount={handleSignUp}
            texts={{
              logIn: t('a_common_logIn'),
              or: t('l_common_or'),
              createAccount: t('a_common_signUp')
            }}
          />
        )
      }

      {
        !!isAuthorized && (
          <StyledUserMenu />
        )
      }

      {
        (!error || error === PublicLinksErrorTypes.EMPTY_ALBUM) && (
          <>
            <ToolbarBox>
              {!selectedItemsCount && !!totalItemsCount && (
                <StyledPrimaryButton
                  actionName={t('a_albums_downloadAlbum')}
                  onAction={handleDownloadAlbum}
                />
              )}

              {selectedItemsCount === 1 && (
                <StyledPrimaryButton
                  actionName={t('a_common_download')}
                  onAction={handleDownloadItem}
                />
              )}

              {
                isAuthorized && !!menuItems.length &&
                <MenuWithActionItems menuItems={menuItems} />
              }
            </ToolbarBox>

            <MobileToolbar
              items={mobileMenuItems}
              headContent={!!selectedItemsCount && (
                <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
              )}
            />
          </>
        )}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>
    </PublicLinkSidebarBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const PublicLinkSidebarBox = styled.div`
  width: 100%;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledUserMenuForUnauthorized = styled(UserMenuForUnauthorized)`
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: block;
    padding: 0 24px;
  }
`

const StyledUserMenu = styled(UserMenu)`
  display: none;
  
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
   display: block;
  }
`

const ToolbarBox = styled.div`
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: block;
    padding: 0 24px;
  }
`
