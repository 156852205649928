import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { personsItemsSelectors } from "./personsSlice"

export const getPersonsRawSelector = state => personsItemsSelectors.selectAll(state.persons)

export const getPersonsSelector = () => useAppSelector(getPersonsRawSelector)

export const getLoadingPersonsStatusSelector = () => useAppSelector(state =>
  state.persons.status
)

export const getOpenedPersonalSettingModalSelector = () => useAppSelector(state =>
  state.persons.personalSettingModal.opened
)

export const getStepAddPersonModalSelector = () => useAppSelector(state =>
  state.persons.personalSettingModal.step
)

export const getNamePersonSelector = () => useAppSelector(state =>
  state.persons.personalSettingModal.name
)

export const getColorPersonSelector = () => useAppSelector(state =>
  state.persons.personalSettingModal.color
)

export const getPersonIdSelector = () => useAppSelector(state =>
  state.persons.personalSettingModal.personId
)
export const getTypeModalSelector = () => useAppSelector(state =>
  state.persons.personalSettingModal.typeModal
)

export const getCurrentPersonIdSelector = () => useAppSelector(
  createSelector(
    getPersonsRawSelector,
    items => {
      const currentPerson = items.find( item => item.isSelect)
      return !!currentPerson && currentPerson.id
    }
  ))
