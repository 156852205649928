import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useCallback, useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import styled from 'styled-components'
import {
  favoritesActions,
  loadFavoritesItemsFirstTimeThunk,
  subscribeFavoritesToWSThunk,
  unsubscribeFavoritesFromWSThunk
} from "features/favorites/favoritesSlice"
import { getFavoritesLoadingStatusSelector, getFavoritesSelectedItemsCountSelector } from "features/favorites/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"

import { Favorites } from "../../features/favorites/Favorites"
import { photoPreviewActions } from "../../features/photo/photo-preview/photoPreviewSlice"
import { WithSearch } from "../../features/photo-search/WithSearch"
import { fetchPersonAlbumsThunk } from "../../features/albums/albums-person/albumPersonSlice"

const FavoritesPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItemsCount = getFavoritesSelectedItemsCountSelector()

  const status = getFavoritesLoadingStatusSelector()

  const isMobile = useMobileDetection()

  usePageTitle('a_nav_favorites')

  useEffect(() => {
    dispatch(favoritesActions.setCurrentTimelineType(SDK_TYPES.DEFAULT))
    dispatch(subscribeFavoritesToWSThunk())
    dispatch(loadFavoritesItemsFirstTimeThunk())
    dispatch(fetchPersonAlbumsThunk({}))

    return () => {
      dispatch(unsubscribeFavoritesFromWSThunk())
      dispatch(favoritesActions.resetState())
      dispatch(photoPreviewActions.resetState())
    }
  }, [])

  const handleCancelSelection = useCallback(() => {
    dispatch(favoritesActions.unselectAll())
  }, [])

  const pageContent = (
    <>

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <Favorites type={SDK_TYPES.DEFAULT} />
        )
      }

      <MobileToolbarMenuToggle iconName={!selectedItemsCount ? 'plus' : 'more_horiz'} />
    </>
  )

  return (
    <WithSearch
      isSearchHidden={!!selectedItemsCount}
      defaultPageContent={pageContent}
    >
      <PageTopbar
        title={t('l_common_FavoritesTitle')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const StyledSkeleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

export default FavoritesPage
