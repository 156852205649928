import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { AlbumPlaceCard, AlbumsPlacesGrid } from '@cloudike/web_ui_components'

import { IExtendedAlbumSchema } from "../albums-list/albumsSlice"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"

import {  getAlbumsPlacesItemsSelector } from "./selectors"


export const AlbumsPlaces = ({ className = '', type }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const albumsPlaces = getAlbumsPlacesItemsSelector()

  const handleCardClick = (album: IExtendedAlbumSchema) => {
    const link = type === SDK_TYPES.FAMILY ? `/family/photos/albums/places/${album.id}` : `/photos/albums/places/${album.id}`

    if(type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_PLACES_CLICK)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_ALBUM_PLACES_CLICK)
    }

    navigate(link)
  }

  return (
    <>
      <AlbumsBox className={className}>
        <AlbumsPlacesGrid>
          {albumsPlaces.map(album => (
            <AlbumPlaceCard
              key={album.id}
              id={album.id}
              albumName={album.description || t('l_common_untitledAlbum')}
              albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
              imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
              onClick={() => handleCardClick(album)}
            />
          ))}
        </AlbumsPlacesGrid>
      </AlbumsBox>
    </>
  )
}

const AlbumsBox = styled.div`
  width: 100%;
  padding-bottom: 40px;
`
