import React from 'react'

import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string,
  mask?: string,
  maskPlaceholder?: string,
  inputRef?: React.Ref<HTMLInputElement> | undefined;
}

export const Input: React.FC<InputProps> = ({ className = '', ...props }) => {

  return (
    <StyledInput
      className={className}
      {...props}
    />
  )
}

const StyledInput = styled(ReactInputMask)`
  width: 100%;
  height: 40px;
  border: 1px solid var(--stroke-primary);
  background: transparent;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: var(--text-primary);
  padding: 0 16px;
  outline: none;

  &::placeholder {
    color: var(--text-secondary);
  }
`
