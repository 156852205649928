import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { AlbumType } from '@cloudike/web_photos'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getAlbumsSdkByType } from 'sdk/albums'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'

import { IExtendedAlbumSchema } from "../albums-list/albumsSlice"
import { getPhotosWS } from "../../../sdk/photo"
import { WS_EVENTS_NAMES } from "../../../constants/wsEventsNames"
import { TOTAL_COUNT_HEADER } from "../../../constants/headers"

export type IExtendedAlbumSeasonsSchema =  IExtendedAlbumSchema

const adapter = createEntityAdapter<IExtendedAlbumSeasonsSchema>()

export const albumsSeasonsSelectors = adapter.getSelectors()

const getCurrentAlbumsType = (state: RootState) => state.albumsSeasons.type
const getPlaceType = (state: RootState) => state.albumsSeasons.typeAlbum
const getSmartAlgType = (state: RootState) => state.albumsSeasons.smartAlg

export const subscribeAlbumsSeasonsToWSThunk = createAsyncThunk(
  'timeline/subscribeAlbumsSeasonsToWSThunk',
  async function(_, { dispatch }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_DELETED, () => {
      dispatch(fetchSeasonsAlbumsThunk(300))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED, ({ output }) => {
      dispatch(actions.updateItem(output))
    })
  }
)

export const unsubscribeAlbumsSeasonsFromWSThunk = createAsyncThunk(
  'timeline/unsubscribeAlbumsSeasonsFromWSThunk',
  async function() {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_DELETED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED)
  }
)

export const fetchSeasonsAlbumsThunk = createAsyncThunk(
  'albumsSeasons/fetchSeasonsAlbumsThunk',
  async function( limit: number, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumsSdk = getAlbumsSdkByType(type)
    const typePlace = getPlaceType(state)
    const smartAlg = getSmartAlgType(state)

    const response = await albumsSdk.getAlbums({ offset: 0, type: [typePlace], smart_alg: smartAlg, covers_count: 3, limit: limit, total_count: true })

    return {
      items: response.data._embedded.albums,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }
  }
)

export const albumsSeasonsSlice = createSlice({
  name: 'albumsSeasons',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    error: '',
    type: SDK_TYPES.DEFAULT,
    typeAlbum: AlbumType.SMART,
    smartAlg: 'seasons',
    totalCount: 0
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setCurrentAlbumsType: (state, action) => {
      state.type = action.payload
    },
    setAlbumType: (state, action) => {
      state.typeAlbum = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      adapter.removeAll(state)
    },
    unselectAll: (state) => {
      adapter.updateMany(state, state.ids.map(id => ({
        id,
        changes: {
          selected: false
        }
      })))
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonsAlbumsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchSeasonsAlbumsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalCount = action.payload.totalCount
      })
      .addCase(fetchSeasonsAlbumsThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

const {
  reducer, actions
} = albumsSeasonsSlice

export { reducer as albumsSeasonsReducer, actions as albumsSeasonsActions }
