import styled from 'styled-components'
import { SpriteIcon } from "@cloudike/web_ui_components"

import plus from '../../../assets/icons/plus.svg'

export const AddPersonCard = ({ onClickItem, textAdd }) => {

  return (
    <PersonsCardBox onClick={onClickItem}>
      <ImageBox>
        <PlusIcon iconName={'add'} />
      </ImageBox>

      <NameBox>
        {textAdd}
      </NameBox>
    </PersonsCardBox>
  )
}

const PlusIcon = styled(SpriteIcon)`
  color: var(--text-secondary);
  width: 32px;
  height: 32px;
`

const ImageBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: var(--background-tertiary);
  margin-right: 12px;
  border-radius: 50%;
`

const PersonsCardBox = styled.div`
  height: 92px;
  width: 64px;
  -webkit-tap-highlight-color: var(--divider-primary);
`

const NameBox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--text-secondary);
  cursor: pointer;
`
