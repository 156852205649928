import { useEffect } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { getDocumentsMasterKeySelector } from "./selectors"

export const useDocumentsWalletAccessChecking = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const masterKey = getDocumentsMasterKeySelector()

  useEffect(() => {
    if (location.pathname.startsWith('/documents') && location.pathname !== '/documents' && !masterKey) {
      navigate('/documents')
    }
  }, [location.pathname, masterKey])
}
