import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { SDK_TYPES } from 'sdk/sdkConstants'
import { FilesTableTypes, FileTableContainer, SelectType, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import { useTranslation } from 'react-i18next'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { MobileToolbarMenuToggle } from 'features/common/right-sidebar/MobileToolbarMenuToggle'
import { mapFileItemsToTableRow } from 'features/files/filesUtils'
import { FilesList } from 'features/files/FilesList'
import { NodeTypes } from '@cloudike/web_files'
import { useGetFilesTableColumns } from 'features/files/useGetFilesTableColumns'
import { useNavigate } from 'react-router-dom'
import { useFilesPreviewItemsUpdate } from 'features/files/files-preview/useFilesPreviewItemsUpdate'
import { FileItemsTypes } from 'features/files/types'
import { openFilesPreviewThunk, PREVIEW_TYPES } from 'features/files/files-preview/filesPreviewSlice'
import { sharingActions } from 'features/sharing/sharingSlice'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { ReplaceFilesItemModal } from 'features/files/ReplaceFilesItemModal'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'

import { LOADING_STATUSES } from "../../constants/loadingStatuses"

import {
  getFilesPublicLinkAllNodesSelector,
  getFilesPublicLinkCheckboxVisibility,
  getFilesPublicLinkPermissionSelector,
  getFilesPublicLinkReplaceItemModalData,
  getFilesPublicLinkRootNodeTypeSelector,
  getFilesPublicLinkSelectedItemsIdsSelector,
  getFilesPublicLinkSelectType,
  getFilesPublicLinkSortSelector,
  getSharedFilesRenamingItemIdSelector
} from './selectors'
import { PublicLinkPlaceholder, PublicLinkPlaceholderBtnTypes } from './../public-link/PublicLinkPlaceholder'
import {
  cancelSharedFileReplacingThunk,
  checkAndUploadSharedFilesThunk,
  keepBothSharedFileVersionsThunk,
  publicLinkFilesActions,
  renameSharedFileNodeThunk,
  ReplaceModalTypes,
  replaceSharedFileThunk
} from './publicLinkFilesSlice'

const MAP_FOLDER_NAME_ID_KEY = 'MAP_FOLDER_NAME_ID'

export const PublicFiles: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isAuthorized = getIsUserAuthorizedSelector()
  const permission = getFilesPublicLinkPermissionSelector()

  const nodes = getFilesPublicLinkAllNodesSelector()
  const sortState = getFilesPublicLinkSortSelector()
  const selectType = getFilesPublicLinkSelectType()
  const checkboxVisibility = getFilesPublicLinkCheckboxVisibility()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const replaceModalData = getFilesPublicLinkReplaceItemModalData()
  const renamingFileId = getSharedFilesRenamingItemIdSelector()
  const rootNodeType = getFilesPublicLinkRootNodeTypeSelector()

  const selectedFilesIds = getFilesPublicLinkSelectedItemsIdsSelector()

  const navigate = useNavigate()

  const [arrColumns, handleSortByColumn] = useGetFilesTableColumns(
    onChangeSorting,
    sortState,
    rootNodeType === NodeTypes.FILE ? ['name', 'modified', 'size'] : undefined
  )

  const [withPressedCtrl, setWithPressedCtrl] = useState(false)
  const [withPressedShift, setWithPressedShift] = useState(false)

  const selectStartListener = useCallback((e) => {
    e.preventDefault()

    return false
  }, [])

  function downHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(true)
    }

    if (key === 'Shift') {
      setWithPressedShift(true)

      document.addEventListener('selectstart', selectStartListener)
    }
  }

  function upHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(false)
    }

    if (key === 'Shift') {
      setWithPressedShift(false)

      document.removeEventListener('selectstart', selectStartListener)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFilesFromDropzone }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false
    }))
    dispatch(dropzoneActions.setLocalAvailableExtensions([]))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
      dispatch(dropzoneActions.setLocalAvailableExtensions(null))

    }
  }, [])

  const previewNodes = useMemo(() => nodes.filter(node => node.type === NodeTypes.FILE), [nodes])

  useFilesPreviewItemsUpdate(previewNodes)

  const handleChangeFolder = (node) => {
    dispatch(publicLinkFilesActions.unselectAll())

    if (node.type !== FileItemsTypes.DIR) {
      dispatch(openFilesPreviewThunk({
        items: previewNodes as any,
        currentItemId: node.id,
        totalItemsCount: previewNodes.length,
        type: PREVIEW_TYPES.SHARED_FILES
      }))

      return
    }

    dispatch(publicLinkFilesActions.setItemsLoadingStatus(LOADING_STATUSES.LOADING))

    const nameIdMapFromStorage = JSON.parse(sessionStorage.getItem(MAP_FOLDER_NAME_ID_KEY))
    const newData = { ...nameIdMapFromStorage, [node.id]: node.name }
    sessionStorage.setItem(MAP_FOLDER_NAME_ID_KEY, JSON.stringify(newData))

    dispatch(publicLinkFilesActions.setCurrentFolder(node))
    navigate(`${location.pathname}/${node.id}`)
  }

  function onChangeSorting(sortState) {
    dispatch(publicLinkFilesActions.setSort(sortState))
  }

  const handleUploadFiles = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      dispatch(checkAndUploadSharedFilesThunk({ files, callback }))
    }
  }

  const handleUploadFilesFromDropzone = (files) => {
    if (files.length > 0) {
      dispatch(checkAndUploadSharedFilesThunk({ files }))
    }
  }

  const handleSelectNode = (node, items) => {
    if (withPressedShift) {
      dispatch(publicLinkFilesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    if (withPressedCtrl) {
      dispatch(publicLinkFilesActions.selectItemWithPressedCtrl({ id: node.id, items }))

      return
    }

    dispatch(publicLinkFilesActions.selectItem({ id: node.id, items }))
  }

  const handleElementsSelection = (node, items) => {
    if (withPressedShift) {
      dispatch(publicLinkFilesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    dispatch(publicLinkFilesActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleCancelSelection = () => {
    dispatch(publicLinkFilesActions.unselectAll())
  }

  const handleRenameItem = (name) => {
    dispatch(renameSharedFileNodeThunk({ name }))
  }

  const handleSelectAll = (items) => {
    dispatch(publicLinkFilesActions.selectAll(items))
  }

  const handleClickCheckbox = (allFileIds) => {
    if (selectType === SelectType.ALL) {
      handleCancelSelection()
    } else {
      handleSelectAll(allFileIds)
    }
  }

  const handleShareSelected = (item) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(item))
  }

  const handleReplaceCancel = () => {
    dispatch(publicLinkFilesActions.setReplaceFilesNodeModalData({ opened: false }))
    dispatch(cancelSharedFileReplacingThunk())
  }

  const handleKeepBoth = () => {
    dispatch(publicLinkFilesActions.setReplaceFilesNodeModalData({
      opened: false,
      type: ReplaceModalTypes.KEEP_BOTH
    }))
    dispatch(keepBothSharedFileVersionsThunk())
  }

  const handleReplace = () => {
    dispatch(publicLinkFilesActions.setReplaceFilesNodeModalData({ opened: false, type: ReplaceModalTypes.REPLACE }))
    dispatch(replaceSharedFileThunk())
  }

  const placeholderBtnText = isAuthorized ? permission === 'write' ? t('a_files_uploadFiles') : t('a_common_backToMyFiles') : t('a_common_logIn')

  if (!nodes.length) {
    return (
      <FileTableContainer
        isDropzoneAreaVisible={isDropzoneAreaVisible}
      >
        <PublicLinkPlaceholder
          texts={{
            title: t('l_common_noSharedFiles'),
            description: '',
            btn: placeholderBtnText
          }}
          btnType={PublicLinkPlaceholderBtnTypes.SECONDARY}
          onButtonClick={isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO()}
          isFileUploading={isAuthorized && permission === 'write'}
          onUpload={handleUploadFiles}
        />

        {
          permission === 'write' && (
            <DropzoneArea
              visible={isDropzoneAreaVisible}
            />
          )}

        {
          permission === 'write'
                    && <StyledMobileToolbarMenuToggle />
        }
      </FileTableContainer>
    )
  }

  return (
    <FileTableContainer
      isDropzoneAreaVisible={isDropzoneAreaVisible}
    >
      <FilesList
        columns={arrColumns}
        selectType={selectType}
        checkboxVisibility={checkboxVisibility}
        items={mapFileItemsToTableRow(nodes, t)}
        onRenameItem={handleRenameItem}
        onSortingChange={handleSortByColumn}
        onToggleItem={handleSelectNode}
        onChangeFolder={handleChangeFolder}
        onClickCheckbox={handleElementsSelection}
        selectedFilesIds={selectedFilesIds}
        renamingFileId={renamingFileId}
        onEditPublicLink={rootNodeType === NodeTypes.FILE ? () => undefined : handleShareSelected}
        onClickAllCheckbox={handleClickCheckbox}
        type={rootNodeType === NodeTypes.FILE ? FilesTableTypes.publicFile : undefined}
      />

      <ReplaceFilesItemModal
        data={replaceModalData}
        onCancel={handleReplaceCancel}
        onKeepBoth={handleKeepBoth}
        onReplace={handleReplace}
      />

      {
        permission === 'write' && (
          <DropzoneArea
            visible={isDropzoneAreaVisible}
          />
        )}
    </FileTableContainer>
  )
}

const StyledMobileToolbarMenuToggle = styled(MobileToolbarMenuToggle)`

  svg {
    color: var(--background-primary)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none !important;
  }
`
