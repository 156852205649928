import { getModalsRootElement } from "constants/modals"

import React, { useEffect, useRef, useState } from "react"

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PasswordChangeModal,
  PrimaryButton,
  SpriteIcon, STYLED_VARIABLES
} from "@cloudike/web_ui_components"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import * as yup from "yup"
import { getUserPasswordModalStatusSelector } from "features/user/selectors"
import { changePasswordThunk, userActions } from "features/user/userSlice"
import styled from "@emotion/styled"
import { FormikProps } from "formik/dist/types"

import { USER_PASSWORD_REGEX } from "../../../../constants/user"

export const ProfilePasswordChangingModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const opened = getUserPasswordModalStatusSelector()

  const [isForgotPasswordModalOpened, setIsForgotPasswordModalOpened] = useState(false)
  const [isAttemptsModalOpened, setIsAttemptsModalOpened] = useState(false)

  const formikRef = useRef<FormikProps<any>>()

  useEffect(() => {
    if (!opened && formikRef.current) {
      formikRef.current.resetForm()
    }
  }, [opened])

  const handleClose = () => {
    dispatch(userActions.toggleUserPasswordModal(false))
  }

  const handleCurrentPasswordError = () => {
    if (!formikRef.current) {
      return
    }

    formikRef.current.setErrors({ currentPassword: t('l_common_incorrectPassword') })
  }

  const handleTooManyAttemptsError = () => {
    setIsAttemptsModalOpened(true)
  }

  const handleSubmit = (values) => {
    dispatch(changePasswordThunk({
      password: values.newPassword,
      currentPassword: values.currentPassword,
      currentPasswordErrorCallback: handleCurrentPasswordError,
      tooManyAttemptsCallback: handleTooManyAttemptsError
    }))
  }

  const handleClickForgotPassword = () => {
    setIsForgotPasswordModalOpened(true)
  }

  const handleCloseForgotPasswordModal = () => {
    setIsForgotPasswordModalOpened(false)
  }

  const handleCloseTooManyAttemptsModal = () => {
    setIsAttemptsModalOpened(false)
  }

  const texts = {
    title: t('a_settings_changePassword'),
    newPassword: t('l_settings_newPassField'),
    repeatPassword: t('l_settings_repeatPassField'),
    buttonClose: t('a_common_cancel'),
    buttonAction: t('a_common_save'),
    errorMessageNewPassword: t('l_notification_passNotEqual'),
    errorMessageCurrentPassword: t('l_notification_passNotEqual'),

    description: t('l_settings_changePassMessage'),
    currentPasswordPlaceholder: t('l_common_enterCurrentPasswordField'),
    repeatPasswordPlaceholder: t('l_common_enterNewPasswordField'),
    newPasswordPlaceholder: t('l_common_enterNewPasswordField'),
    currentPassword: t('l_settings_currentPassField'),
    forgotPassword: t('a_common_restoreAccess')
  }

  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(t('l_notification_emptyField')),
    newPassword: yup
      .string()
      .required(t('l_notification_emptyField'))
      .min(4, t('l_dw_passwordReq'))
      .max(64, t('l_dw_passwordReq'))
      .matches(USER_PASSWORD_REGEX, t('l_dw_passwordReq')),
    repeatPassword: yup
      .string()
      .required(t('l_notification_emptyField'))
      .min(4, t('l_dw_passwordReq'))
      .max(64, t('l_dw_passwordReq'))
      .matches(USER_PASSWORD_REGEX, t('l_dw_passwordReq')),
  })

  return (
    <>
      <PasswordChangeModal
        texts={texts}
        isDisabledButtonAction={false}
        onSubmit={handleSubmit}
        onClose={handleClose}
        isOpened={opened}
        validationSchema={validationSchema}
        parentBlock={getModalsRootElement()}
        onForgotPasswordClick={handleClickForgotPassword}
        ref={formikRef}
      />

      <ModalDialog
        isOpened={isForgotPasswordModalOpened}
        parentBlock={getModalsRootElement()}
        header={(
          <SHeaderBox>
            <SModalHeader
              title={t('l_settings_forgotPassword')}
              titleIcon={<SSpriteIcon iconName={'info'} />}
            />

            <SHeaderCloseButton iconName="close"
              onClick={handleCloseForgotPasswordModal}
            />
          </SHeaderBox>
        )}
        footer={(
          <ModalFooter
            withoutShadowLine
            buttonList={[
              <SOkButton
                key={'buttonAction'}
                actionName={t('a_common_ok')}
                onAction={handleCloseForgotPasswordModal}
              />
            ]}
          />
        )}
        onCloseModal={handleCloseForgotPasswordModal}
      >
        <SForgotPasswordText>
          <p>
            {t('l_settings_forgotPasswordMessageOne')}
          </p>

          <p>
            {t('l_settings_forgotPasswordMessageTwo')}
          </p>

          <p>
            {t('l_settings_forgotPasswordMessageThree')}
          </p>
        </SForgotPasswordText>
      </ModalDialog>

      <ModalDialog
        isOpened={isAttemptsModalOpened}
        parentBlock={getModalsRootElement()}
        header={(
          <SHeaderBox>
            <SModalHeader
              title={t('l_common_tooManyAttemptsError')}
              titleIcon={<SSpriteIcon iconName={'info'} />}
            />

            <SHeaderCloseButton iconName="close"
              onClick={handleCloseTooManyAttemptsModal}
            />
          </SHeaderBox>
        )}
        footer={(
          <ModalFooter
            withoutShadowLine
            buttonList={[
              <SOkButton
                key={'buttonAction'}
                actionName={t('a_common_ok')}
                onAction={handleCloseTooManyAttemptsModal}
              />
            ]}
          />
        )}
        onCloseModal={handleCloseTooManyAttemptsModal}
      >
        <TooManyAttemptsModalContent />
      </ModalDialog>
    </>
  )
}

const SHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const SHeaderCloseButton = styled(SpriteIcon)`
  color: var(--icon-primary);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
`

const SModalHeader = styled(ModalHeader)`
  h2 {
    align-items: center;
  }
`


const SSpriteIcon = styled(SpriteIcon)`
  && {
    width: 32px;
    height: 32px;
  }
`

const SForgotPasswordText = styled.div`
  margin-top: 10px;
  
  p {
    max-width: 572px;
    margin-bottom: 16px;
    color: var(--text-secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

const SOkButton = styled(PrimaryButton)`
  height: 40px;
  border-radius: 4px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--button-primary-text);
  cursor: pointer;
`

const TooManyAttemptsModalContent = styled.div`
  width: 564px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_70}) {
    width: 100%;
  }
`
