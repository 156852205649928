import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import thunkMiddleware from 'redux-thunk'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { sharingReducer } from 'features/sharing/sharingSlice'
import { filesUploadingReducer } from 'features/common/files-uploading/filesUploadingSlice'
import { albumsReducer } from 'features/albums/albums-list/albumsSlice'
import { albumReducer } from 'features/albums/album/albumSlice'
import { photoPreviewReducer } from 'features/photo/photo-preview/photoPreviewSlice'
import { photoPreviewDuplicateReducer } from 'features/photo/photo-preview-duplicate/photoPreviewDuplicateSlice'
import { familyReducer } from 'features/family/familySlice'
import { dropzoneReducer } from 'features/common/dropzone/dropzoneSlice'
import { otherCloudsReducer } from 'features/user/features/other-clouds/otherCloudsSlice'
import { publicLinkReducer } from 'features/public-link/publicLinkSlice'
import { trashBinReducer } from 'features/trash-bin/trashBinSlice'
import { publicLinksReducer } from 'features/public-links-albums/publicLinksSlice'
import { documentsReducer } from 'features/documents/documentsSlice'
import { photoPreviewFlashbackReducer } from 'features/photo/photo-preview-flashback/photoPreviewFlashbackSlice'
import { filesReducer } from 'features/files/filesSlice'
import { copyMoveModalReducer } from 'features/files/copy-move-modal/copyMoveModalSlice'
import { filesPreviewReducer } from 'features/files/files-preview/filesPreviewSlice'
import { fileVersionsReducer } from 'features/files/file-versions/fileVersionsSlice'
import { trashBinFilesReducer } from 'features/trash-bin/files/trashBinFilesSlice'
import { publicLinksFilesReducer } from 'features/public-links-files/publicLinksFilesSlice'
import { publicLinkFilesReducer } from 'features/public-link-files/publicLinkFilesSlice'
import { publicLinkCopyMoveModalReducer } from 'features/public-link-files/copy-move-modal/publicLinkCopyMoveModalSlice'

import { clients } from "../api/getClients"
import { timelineReducer } from '../features/photo/timeline/timelineSlice'
import { userSlice } from '../features/user/userSlice'
import { favoritesReducer } from "../features/favorites/favoritesSlice"
import { albumsPlacesReducer } from "../features/albums/albums-places/albumsPlacesSlice"
import { albumsSeasonsReducer } from "../features/albums/albums-season/albumsSeasonsSlice"
import { loginByKey } from "../api/loginByKey"
import { personsReducer } from "../features/documents/persons/personsSlice"
import { photoSearchReducer } from "../features/photo-search/photoSearchSlice"
import { albumsPersonReducer } from "../features/albums/albums-person/albumPersonSlice"
import { reservePhoneReducerType } from "../apps/beelinecloud/src/features/user/features/reserve-phone/reservePhoneSlice"

import { appSlice } from './app'

let reservePhoneReducer
try {
  reservePhoneReducer = require(`../apps/${process.env.APP_NAME}/src/features/user/features/reserve-phone/reservePhoneSlice`).reservePhoneReducer
} catch (e) {
  reservePhoneReducer = require(`../features/user/features/reserve-phone/reservePhoneSlice`).reservePhoneReducer
}

export const store = configureStore({
  reducer: {
    [loginByKey.reducerPath]: loginByKey.reducer,
    [clients.reducerPath]: clients.reducer,
    user: userSlice.reducer,
    otherClouds: otherCloudsReducer,
    app: appSlice.reducer,
    timeline: timelineReducer,
    favorites: favoritesReducer,
    sharing: sharingReducer,
    filesUploading: filesUploadingReducer,
    albums: albumsReducer,
    album: albumReducer,
    albumsPlaces: albumsPlacesReducer,
    albumsPerson: albumsPersonReducer,
    albumsSeasons: albumsSeasonsReducer,
    photoPreviewDuplicate: photoPreviewDuplicateReducer,
    photoPreview: photoPreviewReducer,
    photoPreviewFlashback: photoPreviewFlashbackReducer,
    family: familyReducer,
    dropzone: dropzoneReducer,
    reservePhone: reservePhoneReducer as reservePhoneReducerType,
    publicLink: publicLinkReducer,
    trashBin: trashBinReducer,
    publicLinks: publicLinksReducer,
    documents: documentsReducer,
    files: filesReducer,
    copyMoveModal: copyMoveModalReducer,
    filesPreview: filesPreviewReducer,
    fileVersions: fileVersionsReducer,
    trashBinFiles: trashBinFilesReducer,
    publicLinksFiles: publicLinksFilesReducer,
    publicLinkFiles: publicLinkFilesReducer,
    persons: personsReducer,
    publicLinkCopyMoveModal: publicLinkCopyMoveModalReducer,
    photoSearch: photoSearchReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    thunkMiddleware,
    clients.middleware,
    loginByKey.middleware,
  ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch
