import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { publicLinkSelectors } from "./publicLinkFilesSlice"

export const getFilesPublicLinkPasswordModalStatusSelector = () => useAppSelector(state => state.publicLinkFiles.passwordModalOpened)

export const getFilesPublicLinkErrorSelector = () => useAppSelector(state => state.publicLinkFiles.error)

export const getIsSizeQuotaExceedErrorSelector = () => useAppSelector(state => state.publicLinkFiles.isSizeQuotaExceedError)

export const getFilesPublicLinkLoadingStatusSelector = () => useAppSelector(state =>
  state.publicLinkFiles.itemsLoadingStatus
)

export const getFilesPublicLinkItemsSelector = () => useAppSelector(getFilesPublicLinkNodesRawSelector)

export const getFilesPublicLinkTotalItemsCountSelector = () => useAppSelector(state => state.publicLinkFiles.totalItemsCount)

export const getFilesPublicLinkNodeDataSelector = () => useAppSelector(state => state.publicLinkFiles.nodeData)

export const getFilesPublicLinkPermissionSelector = () => useAppSelector(state => state.publicLinkFiles.permission)

export const getFilesPublicLinksTokenSelector = () => useAppSelector(state => state.publicLinkFiles.token)

export const getFilesPublicLinksNotMyResourceHrefSelector = () => useAppSelector(state => state.publicLinkFiles.notMyResourseHref)

export const getFilesPublicLinkNodesHrefSelector = () => useAppSelector(state => state.publicLinkFiles.itemsHref)

export const getFilesPublicLinkSelectType = () => useAppSelector(state =>
  state.publicLinkFiles.selectType
)

export const getFilesPublicLinkCheckboxVisibility = () => useAppSelector(state =>
  state.publicLinkFiles.checkboxVisibility
)

export const getFilesPublicLinkNodesRawSelector = state => publicLinkSelectors.selectAll(state.publicLinkFiles)

export const getFilesPublicLinkNodesSelector = () => useAppSelector(getFilesPublicLinkNodesRawSelector)

export const getFilesPublicLinkSortSelector = () => useAppSelector(state =>
  state.publicLinkFiles.sort
)

export const getFilesPublicLinkSelectedItemsSelector = () =>
  useAppSelector(
    createSelector(
      getFilesPublicLinkNodesRawSelector,
      getFilesPublicLinkSelectedItemsIdsRawSelector,
      (items, selectedItemsIds) => items.filter(item => selectedItemsIds.includes(item.id))
    )
  )


export const getFilesPublicLinkSelectedItemsIdsRawSelector = state => state.publicLinkFiles.selectedItemsIds

export const getFilesPublicLinkSelectedItemsIdsSelector = () => useAppSelector(getFilesPublicLinkSelectedItemsIdsRawSelector)

export const getFilesPublicLinkSelectedItemsCountSelector = () => useAppSelector(state =>
  state.publicLinkFiles.selectedItemsIds.length
)

export const getFilesPublicLinkCurrentFolderIdSelector = () => useAppSelector(state =>
  state.publicLinkFiles.currentFolderId
)

export const getFilesPublicLinkCurrentFolderSelector = () => useAppSelector(state =>
  state.publicLinkFiles.currentFolder
)

export const getFilesPublicLinkReplaceItemModalData = () => useAppSelector(state =>
  state.publicLinkFiles.replaceFilesNodeModalData
)

export const getFilesPublicLinkAllNodesSelector = () => useAppSelector(state => publicLinkSelectors.selectAll(state.publicLinkFiles))

export const getSharedFilesRenamingItemIdSelector = () => useAppSelector(state =>
  state.publicLinkFiles.renamingItemId
)

export const getFilesPublicLinkShareIdSelector = () => useAppSelector(state =>
  state.publicLinkFiles.sharedId
)

export const getFilesPublicLinkBreadcrumbsSelector = () => useAppSelector(state =>
  state.publicLinkFiles.breadcrumbs
)

export const getFilesPublicLinkRootNodeTypeSelector = () => useAppSelector(state =>
  state.publicLinkFiles.rootNodeType
)
