import React from 'react'

import { Outlet } from 'react-router-dom'

import { AddPersonModal } from "./persons/AddPersonModal"

export const DocumentsRoot = () => {

  return (
    <>
      <Outlet />

      <AddPersonModal />
    </>
  )
}

