import { useEffect, useRef } from "react"

import { useAppDispatch } from  "store"

import { photoPreviewActions } from "./photoPreviewSlice"
import { getCurrentPhotoPreviewItemIndexSelector, getPhotoPreviewItemsSelector } from "./selectors"

export const usePreviewItemsUpdate = (newItems, newTotalItemsCount) => {
  const dispatch = useAppDispatch()
  const currentItems = getPhotoPreviewItemsSelector()
  const currentIndex = getCurrentPhotoPreviewItemIndexSelector()
  const currentItemIdRef = useRef<string>()

  currentItemIdRef.current = currentItems[currentIndex]?.id

  useEffect(() => {
    if (currentItems.length > 0 && currentItems.length !== newItems.length) {
      const currentItemId = currentItemIdRef.current

      dispatch(photoPreviewActions.setAllItems(newItems))

      if (newItems.length > currentItems.length) {
        if(!!currentItemId) dispatch(photoPreviewActions.setCurrentItemIndexById(currentItemId))
      }
    }
  }, [newItems, currentItems])

  useEffect(() => {
    dispatch(photoPreviewActions.setTotalItemsCount(newTotalItemsCount))
  }, [newTotalItemsCount])
}
