import { request } from "./request"

export const feedbackApi = {
  sendFeedback: (data) => {
    return request('POST', `/api/2/accounts/feedback`, data)
  },
  sendFilesFeedback: (url, data) => {
    return request('POST', url, data, { host: null , isUploadLog: true })
  },
}
