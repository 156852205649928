import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import styled from 'styled-components'
import { MobileToolbarMenuToggle } from '@cloudike/web_ui_components'
import { useAppDispatch } from  "store"
import { appActions } from 'store/app'

import { getMobileRightMenuStatusSelector } from '../selectors'

type Props = Omit<React.ComponentProps<typeof MobileToolbarMenuToggle>, 'onClick' | 'visible'> & {
    onClick?: () => void
}

const ConnectedMobileToolbarMenuToggle: React.FC<Props> = ({ className = '', iconName, onClick }) => {
  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (onClick) {
      onClick()

      return
    }

    dispatch(appActions.toggleRightMenuOnMobile(true))
  }

  const isRightSidebarActiveOnMobile = getMobileRightMenuStatusSelector()

  return (
    <StyledMobileToolbarMenuToggle
      visible={!isRightSidebarActiveOnMobile}
      onClick={handleClick}
      className={className}
      iconName={iconName}
    />
  )
}

const StyledMobileToolbarMenuToggle = styled(MobileToolbarMenuToggle)`

  svg {
    color: var(--background-primary)
  }

  &&& {
    transition: all 0.3s linear;

    @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
      display: none;
    }
  }
`

export { ConnectedMobileToolbarMenuToggle as MobileToolbarMenuToggle }
