export interface IGetFaceBoxSize {
    naturalImgWidth: number,
    naturalImgHeight: number,
    imgParentWidth: number,
    imgParentHeight: number,
    faceBorderCoordinate: {
        top: number,
        right: number,
        bottom: number,
        left: number
    }
}

export const getFaceBoxSize = ({
  naturalImgWidth,
  naturalImgHeight,
  imgParentWidth,
  imgParentHeight,
  faceBorderCoordinate
}: IGetFaceBoxSize) => {
  let currentWidth = imgParentWidth // Текущая ширина по которой будем считать границы лица
  let currentHeight = imgParentHeight // Текущая высота по которой будем считать границы лица

  const coefficientHeightByParentBlock = imgParentHeight / naturalImgHeight // Считаем коэффициент увеличения фотографии по высоте
  const coefficientWidthByParentBlock = imgParentWidth / naturalImgWidth // Считаем коэффициент увеличения фотографии по ширине
  // Ориентация родительского блока горизонтальная
  if (imgParentWidth > imgParentHeight) {
    // Проверяем в какой ориентации фотография
    if ((naturalImgWidth / naturalImgHeight) <= 1) {
      // Понимаем что вертикальная
      // Получаем ширину картинки при ее пропорциональном увеличении
      currentWidth = naturalImgWidth * coefficientHeightByParentBlock
    } else {
      // Понимем что фотография горизонтальная и считаем ее высоту
      currentHeight = coefficientWidthByParentBlock * naturalImgHeight
      // Проверяем если ширина картинки после увеличения по коэффициенту меньше ширины родительского блока
      if ((coefficientHeightByParentBlock * naturalImgWidth) < currentWidth) {
        // То ее высота будет 100% родительского блока и считаем ширину
        currentHeight = imgParentHeight
        currentWidth = (naturalImgWidth / naturalImgHeight) * currentHeight
      }
    }
  } else {
    // Ориентация родительского блока вертикальная
    // Понимаем ориентацию фотографии
    if ((naturalImgWidth / naturalImgHeight) <= 1) {
      // Она вертикальная
      // Проверяем если высота картинки после увеличения по коэффициенту меньше высоты родительского блока
      if (imgParentHeight > (coefficientWidthByParentBlock * naturalImgHeight)) {
        // Считаем ее высоту
        currentHeight = coefficientWidthByParentBlock * naturalImgHeight
      }
    } else {
      // Она горизонтальная
      currentHeight = (imgParentWidth / naturalImgWidth) * naturalImgHeight
      // Понимаем что ширина фотографии меньше родительской ширины
      if (imgParentWidth > (coefficientHeightByParentBlock * naturalImgWidth)) {
        // Считаем ширину фотографии
        currentWidth = coefficientWidthByParentBlock * naturalImgWidth
      }
    }
  }
  // Коэффициенты изменения границ лица
  const coefficientWidthFaceBox = currentWidth / naturalImgWidth
  const coefficientHeightFaceBox = currentHeight / naturalImgHeight

  const heightFaceBox = faceBorderCoordinate.bottom - faceBorderCoordinate.top
  const widthFaceBox = faceBorderCoordinate.right - faceBorderCoordinate.left

  // Разница размеров для правильного опредиления левого верхнего угла фотографии
  const diffWidth = (imgParentWidth - currentWidth) / 2
  const diffHeight = (imgParentHeight - currentHeight) / 2

  return {
    top: (faceBorderCoordinate.top * coefficientHeightFaceBox) + diffHeight,
    left: (faceBorderCoordinate.left * coefficientWidthFaceBox) + diffWidth,
    width: widthFaceBox * coefficientWidthFaceBox,
    height: heightFaceBox * coefficientHeightFaceBox,
  }
}
