import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs } from 'ui/Tabs'
import styled from 'styled-components'

export enum ProfileTabsTypes {
  ACCOUNT,
  PURCHASES,
  SECURITY,
  ACTIVITY
}

interface ProfileTabsProps {
  className?: string,
  activeTab: ProfileTabsTypes
}

export const ProfileTabs: React.FC<ProfileTabsProps> = ({ className = '', activeTab }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tabs = [
    {
      label: t('l_nav_accountTab'),
      active: activeTab === ProfileTabsTypes.ACCOUNT,
      link: '/profile/account'
    },
    {
      label: t('l_settings_purchasesTab'),
      active: activeTab === ProfileTabsTypes.PURCHASES,
      link: '/profile/details'
    },
    {
      label: t('l_settings_securityTab'),
      active: activeTab === ProfileTabsTypes.SECURITY,
      link: '/profile/settings'
    },
    {
      label: t('l_settings_activityTab'),
      active: activeTab === ProfileTabsTypes.ACTIVITY,
      link: '/profile/log'
    }
  ]

  const handleChangeTab = (tab: Tab) => {
    navigate(tab.link)
  }

  return (
    <StyledTabs
      items={tabs}
      onChangeTab={handleChangeTab}
      className={className}
    />
  )
}

const StyledTabs = styled(Tabs)`
  margin-bottom: 16px;
`