import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { useState } from 'react'

import { MenuWithActionItems, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { useNavigate } from 'react-router-dom'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { getUserDataSelector } from 'features/user/selectors'
import { updateUserDataThunk } from 'features/user/userSlice'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { appActions } from "../../store/app"

import { deleteFamilyCloudThunk, leaveFromFamilyCloudThunk } from './familySlice'
import { getFamilyDataSelector, getFamilyLoadingStatusSelector } from './selectors'

interface ManageFamilyToolbarProps {
  className?: string,
}

export const ManageFamilyToolbar: React.FC<ManageFamilyToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [leavingConfirmationModalOpened, toggleLeavingConfirmationModal] = useState(false)

  const familyData = getFamilyDataSelector()
  const userData = getUserDataSelector()
  const status = getFamilyLoadingStatusSelector()

  const isOwner = userData.id == familyData.owner_id

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleOpenLeavingConfirmationModal = () => {
    toggleLeavingConfirmationModal(true)
  }

  const handleCloseLeavingConfirmationModal = () => {
    toggleLeavingConfirmationModal(false)
  }

  const handleRemoveFamilyCloud = () => {
    const callback = () => {
      dispatch(updateUserDataThunk({ family_user_id: null }))
      navigate('/family/photos')
    }

    dispatch(deleteFamilyCloudThunk(callback))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_FC_MANAGE_DELETE_FC_CLICK)
  }

  const handleLeaveFromFamilyCloud = () => {
    const callback = () => {
      dispatch(updateUserDataThunk({ family_user_id: null }))
      navigate('/family/photos')
    }

    dispatch(leaveFromFamilyCloudThunk({ userId: userData.id, callback }))
  }

  const menuItems = (() => {
    const items = isOwner ? [{
      label: t('a_family_deleteFamilyCloud'),
      iconName: 'delete_forever',
      onClickItem: handleOpenRemovingConfirmationModal
    }] :
      [{
        label: t('a_common_leaveFamilyCloud'),
        iconName: 'exit_to_app',
        onClickItem: handleOpenLeavingConfirmationModal
      }]

    return items
  })()

  const mobileMenuItems = (() => {
    return menuItems
  })()

  if (status === LOADING_STATUSES.LOADING) {
    return null
  }

  return (
    <>
      <ManageFamilyToolbarBox className={className}>
        <MenuWithActionItems menuItems={menuItems} />
      </ManageFamilyToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={null}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('a_common_manageFamily')}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_remove')}
        onOk={handleRemoveFamilyCloud}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_confirmationToRemoveFamily')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={leavingConfirmationModalOpened}
        title={t('a_nav_familyManage')}
        onClose={handleCloseLeavingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleLeaveFromFamilyCloud}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseLeavingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_leaveApp')}
        </TextInModalBox>
      </DialogModal>
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const ManageFamilyToolbarBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`
