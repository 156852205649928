import { STYLED_VARIABLES } from 'constants/styledVariables'
import { formatImgUrl } from 'constants/configs/theme.config'

import React from 'react'

import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from '@cloudike/web_ui_components'

import { getContentTypesUploadSelector } from "../common/selectors"
import { getUiTheme } from "../user/selectors"
import { THEME } from "../user/userSlice"

export enum PublicLinkPlaceholderBtnTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface EmptyAlbumPlaceholderProps {
    className?: string,
    texts: {
        title: string,
        description: string,
        btn: string
        createAccount?: string,
    },
    btnType: PublicLinkPlaceholderBtnTypes,
    onButtonClick: () => void,
    onCreateAccount?: () => void,
    isFileUploading?: boolean,
    onUpload?: (files: FileList) => void
}

export const PublicLinkPlaceholder: React.FC<EmptyAlbumPlaceholderProps> = ({
  className = '',
  texts,
  onButtonClick,
  btnType,
  isFileUploading,
  onUpload,
  onCreateAccount
}) => {

  const contentTypesUpload = getContentTypesUploadSelector()

  const theme = getUiTheme()

  return (
    <PublicLinkPlaceholderBox className={className}>
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/not-found.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/not-found.svg")} />}


      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>

      {
        btnType === PublicLinkPlaceholderBtnTypes.SECONDARY && !isFileUploading && (
          <StyledSecondaryButton
            actionName={texts.btn}
            onAction={onButtonClick}
          />
        )
      }

      {
        btnType === PublicLinkPlaceholderBtnTypes.PRIMARY && !isFileUploading && (
          <StyledPrimaryButton
            actionName={texts.btn}
            onAction={onButtonClick}
          />
        )
      }

      {
        texts.createAccount && onCreateAccount && (
          <CreateAccountLinkBox
            onClick={onCreateAccount}
          >
            {texts.createAccount}
          </CreateAccountLinkBox>
        )
      }

      {isFileUploading && (
        <ButtonForUploadingBox
          htmlFor="public-link-upload"
        >
          <StyledSecondaryButton
            actionName={(
              <>
                <ButtonForUploadingBox
                  htmlFor="public-link-upload"
                >
                  {texts.btn}
                </ButtonForUploadingBox>

                <HiddenFilesInput
                  id="public-link-upload"
                  onChange={onUpload}
                  type="file"
                  multiple
                  accept={contentTypesUpload}
                />
              </>
            )}
            onAction={() => true}
          />
        </ButtonForUploadingBox>
      )}

    </PublicLinkPlaceholderBox>
  )
}

const PublicLinkPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
  height: 100%;
`

const ContainerImg = styled.img`
  width: 292px;
  height: 186px;
`

const EmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 310px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin: 20px 20px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
  height: 40px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 32px;
  height: 40px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`

const ButtonForUploadingBox = styled.label`
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
`

const HiddenFilesInput = styled.input`
  display: none;
`

const CreateAccountLinkBox = styled.a`
  display: flex;
  color: var(--accent-normal);
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;

  :hover {
    cursor: pointer;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: none;
  }
`
