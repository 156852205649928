import { STYLED_VARIABLES } from 'constants/styledVariables'
import { formatImgUrl } from 'constants/configs/theme.config'
import { ACCEPTED_FILES_TYPES_FOR_DOCUMENTS } from 'constants/acceptedFileTypes'

import React from 'react'

import styled from 'styled-components'

import { getUiTheme } from "../user/selectors"
import { THEME } from "../user/userSlice"

interface EmptyDocumentAlbumPlaceholderProps {
    className?: string,
    texts: {
        title: string,
        description: string,
        uploadBtn: string
    },
    onUploadFiles: (files: FileList) => void
}

export const EmptyDocumentAlbumPlaceholder: React.FC<EmptyDocumentAlbumPlaceholderProps> = ({
  className = '',
  texts,
  onUploadFiles
}) => {
  const handleUploadFiles = (e) => {
    onUploadFiles(e.target.files)
  }

  const theme = getUiTheme()

  return (
    <EmptyDocumentAlbumPlaceholderBox className={className}>
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/no-photos.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/no-photos.svg")} />}

      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>

      <StyledSecondaryButton
        htmlFor="documents-upload"
      >
        {texts.uploadBtn}

        <HiddenFilesInput
          id="documents-upload"
          onChange={handleUploadFiles}
          type="file"
          multiple
          accept={ACCEPTED_FILES_TYPES_FOR_DOCUMENTS.join(',')}
        />
      </StyledSecondaryButton>
    </EmptyDocumentAlbumPlaceholderBox>
  )
}

const EmptyDocumentAlbumPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
`

const ContainerImg = styled.img`
  width: 292px;
  height: 186px;
`

const EmptyTitle = styled.h2`
  margin-top: 22px;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;
  font-weight: 500;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
  }
`

const EmptyText = styled.p`
  margin-top: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 80%;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 20px;
  }
`

const StyledSecondaryButton = styled.label`
  margin-top: 32px;
  background: transparent;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  padding: 8px 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-secondary-hover)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`

const HiddenFilesInput = styled.input`
  display: none;
`
