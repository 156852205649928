import { useState } from 'react'

import { ConfirmationModalTypes, SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useTranslation } from 'react-i18next'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { DialogModal } from 'ui/DialogModal'
import { toMb } from 'utils/utils'
import moment from 'moment'
import classNames from 'classnames'
import { sharingActions } from 'features/sharing/sharingSlice'
import { downloadNodesThunk as downloadPublicLinksNodesThunk } from 'features/public-links-files/publicLinksFilesSlice'
import { IFsNodeSchema } from "@cloudike/web_files"

import { deleteNodesThunk, downloadNodesThunk } from '../filesSlice'
import { generateRemovingText,pushCreateEditItemPublicLink } from '../filesUtils'
import { fileVersionsActions } from '../file-versions/fileVersionsSlice'
import {
  deleteFilesPublicLinkNodesThunk,
  downloadSharedFilesArchiveThunk
} from "../../public-link-files/publicLinkFilesSlice"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { InfoModalPreview } from "../../common/info-modal/InfoModalPreview"
import { useMobileDetection } from "../../common/hooks"

import { filesPreviewActions, PREVIEW_TYPES } from './filesPreviewSlice'
import {
  getCurrentFilesPreviewItemIndexSelector,
  getFilesPreviewItemsSelector,
  getFilesPreviewTypeSelector
} from './selectors'


export const FilesPreviewToolbar = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isMobile = useMobileDetection()

  const items = getFilesPreviewItemsSelector()
  const currentIndex = getCurrentFilesPreviewItemIndexSelector()
  const type = getFilesPreviewTypeSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [isItemsHiddenOnMobile, toggleMobileItemsVisibility] = useState(true)
  const [isOpenedInfoModal, setOpenedInfoModal] = useState(false)

  const currentItem = items[currentIndex]

  const handleClose = () => {
    dispatch(filesPreviewActions.resetState())
  }

  const handleCloseClick = () => {
    handleClose()
    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_CLOSE)
  }

  const handleRemoveSelected = () => {
    if (type === PREVIEW_TYPES.SHARED_FILES) {
      dispatch(deleteFilesPublicLinkNodesThunk({ ids: [currentItem.id] }))
    } else {
      dispatch(deleteNodesThunk({ ids: [currentItem.id] }))
    }

    handleCloseRemovingConfirmationModal()

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(filesPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DELETE)
  }

  const handleShare = (item: IFsNodeSchema) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(currentItem))
    pushCreateEditItemPublicLink(item)
    analytics.push(ANALYTICS_EVENTS.WEB_FILES_CREATE_LINK)
  }

  const handleDownload = () => {
    if (type === PREVIEW_TYPES.SHARED_FILES) {
      dispatch(downloadSharedFilesArchiveThunk({ ids: [currentItem.id] }))

      return
    }

    if (type === PREVIEW_TYPES.FILES_PUBLIC_LINKS) {
      dispatch(downloadPublicLinksNodesThunk({ ids: [currentItem.id] }))

      return
    }

    dispatch(downloadNodesThunk({ ids: [currentItem.id] }))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DOWNLOAD)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleShowHiddenItemsOnMobile = () => {
    toggleMobileItemsVisibility(false)
  }

  const handleOpenRestoreModal = () => {
    dispatch(fileVersionsActions.setNodeId(currentItem.id))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_VERSIONS)
  }

  const handleInfo = (event: MouseEvent) => {

    if (isMobile) {
      setOpenedInfoModal(true)
    } else {
      event.stopPropagation()
      event.preventDefault()
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_INFO)
  }

  const onCloseInfoModal = () => {
    setOpenedInfoModal(false)
  }

  const actionItems = (() => {
    const items = [
      {
        handler: handleDownload,
        title: t('a_common_download'),
        iconName: 'download',
        hiddenOnMobile: false,
      },
      type !== PREVIEW_TYPES.SHARED_FILES && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
        handler: handleShare.bind(null, currentItem),
        title: t('a_common_createPublicLink'),
        iconName: 'link',
        hiddenOnMobile: isItemsHiddenOnMobile,
      },
      type !== PREVIEW_TYPES.SHARED_FILES && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
        handler: handleOpenRestoreModal,
        title: t('l_common_versions'),
        iconName: 'restore',
        hiddenOnMobile: isItemsHiddenOnMobile,
      },
      {
        handler: handleInfo,
        title: '',
        iconName: 'info_outline',
        popoverContent:
          !isMobile && (
            <>
              {t('l_common_size')}

            :
              {toMb(currentItem?.file_info.size)}

              {' '}

              <br />

              {t('l_common_added', { DATE: moment(currentItem?.created).format("DD.MM.YYYY, HH:mm") })}
            </>
          ),
        hiddenOnMobile: isItemsHiddenOnMobile,
      },
      type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
        handler: handleOpenRemovingConfirmationModal,
        title: t('a_common_remove'),
        iconName: 'delete',
        hiddenOnMobile: isItemsHiddenOnMobile,
      },
      {
        handler: handleShowHiddenItemsOnMobile,
        title: 'Show more',
        iconName: 'action_container',
        hiddenOnMobile: !isItemsHiddenOnMobile,
        hiddenOnDesktop: true,
      },
      {
        handler: handleCloseClick,
        title: t('a_common_close'),
        iconName: 'close',
      },
    ].filter(item => !!item)

    return items
  })()

  return (
    <FilesPreviewToolbarBox className={className}>
      {
        actionItems.map(item => (
          <ActionItem
            key={item.title}
            item={item}
          />
        ))
      }

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={generateRemovingText([currentItem], t).title}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveSelected}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
        type={ConfirmationModalTypes.danger}
      >
        <TextInModalBox>
          {generateRemovingText([currentItem], t).text}
        </TextInModalBox>
      </DialogModal>

      <InfoModalPreview isOpened={isOpenedInfoModal}
        handleCloseModal={onCloseInfoModal}
        size={toMb(currentItem?.file_info.size)}
        date={moment(currentItem?.created).format("DD.MM.YYYY, HH:mm")}
      />

    </FilesPreviewToolbarBox>
  )
}

const ActionItem = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)

  const handleClick = (event: MouseEvent) => {
    if (item.popoverContent) {
      togglePopover(state => !state)
    }

    item.handler(event)
  }

  return (
    <ActionIconBox
      onClick={handleClick}
      title={item.title}
      className={classNames(className, { 'hidden-on-mobile': item.hiddenOnMobile, 'hidden-on-desktop': item.hiddenOnDesktop })}
      orderOnMobile={item.orderOnMobile}
    >
      <ActionIcon iconName={item.iconName} />

      {!!item.popoverContent && popoverOpened && (
        <Popover>
          {item.popoverContent}
        </Popover>
      )}
    </ActionIconBox>
  )
}

const TextInModalBox = styled.span`
  max-width: 500px;
  font-size: 14px;
  word-wrap: anywhere;
`

const FilesPreviewToolbarBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ActionIconBox = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: #DADADA;
  order: ${props => props.orderOnMobile};

  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }

  &.hidden-on-mobile {
    display: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    order: unset;

    &.hidden-on-desktop {
      display: none;
    }

    &.hidden-on-mobile {
      display: flex;
    }
  }
`

const ActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  background: #fff;
  border-radius: 6px;
  width: 180px;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

  &.active {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: #fff;
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`
