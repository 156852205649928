import { useAppSelector } from "store"
import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"
import { createSelector } from "@reduxjs/toolkit"

import { formatDate } from "../../../utils/formatDate"

import { albumsSelectors } from "./albumSlice"

export const getAlbumSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getAlbumItemsRawSelector,
    getAlbumSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) =>
      items.filter(item => selectedItemsIds.includes(item.id))
  )
)

export const getAlbumSelectedItemsCountSelector = () => useAppSelector(state =>
  state.album.selectedItemsIds.length
)
export const getAlbumSelectedItemsIdsRawSelector = state => state.album.selectedItemsIds

export const getAlbumSelectedItemsIdsSelector = () => useAppSelector(getAlbumSelectedItemsIdsRawSelector)

export const getAlbumItemsLoadingStatusSelector = () => useAppSelector(state =>
  state.album.itemsLoadingStatus
)

export const getAlbumDataLoadingStatusSelector = () => useAppSelector(state => state.album.albumDataLoadingStatus)

export const getAlbumDataSelector = () => useAppSelector(state => state.album.albumData)

export const getIsAlbumNameEditingSelector = () => useAppSelector(state => state.album.isAlbumNameEditing)

export const getAlbumItemsRawSelector = state => albumsSelectors.selectAll(state.album)

export const getAlbumItemsSelector = () => useAppSelector(getAlbumItemsRawSelector)

export const getAlbumTotalItemsCountSelector = () => useAppSelector(state => state.album.totalItemsCount)

export const getAlbumDateFormatSelector = () => useAppSelector(state => state.album.dateFormat)

export const getAlbumSortFormatSelector = () => useAppSelector(state => state.album.sortFormat)

export const getPersonItemsByDateSelector = (dateFormat: string) => useAppSelector(
  createSelector(getAlbumItemsRawSelector,
    items => {

      const getItemDate = (item: IItemSchema) => formatDate(item.created_original, dateFormat)

      const itemsByDates = items.reduce((r, item) => {
        const date = getItemDate(item)

        if (!r[date]) {
          return { ...r, [date]: [item] }
        }

        return { ...r, [date]: [...r[date], item] }
      }, {})

      return Object.entries<IItemSchema[]>(itemsByDates)
        .map(([key, value]) => ({ date: key, items: value.sort((a, b) => b.created_original - a.created_original) }))
        .sort((a, b) => b.items[0].created_original - a.items[0].created_original)
    })
)
