import React from 'react'

import { ConnectedPageTopbarProps } from 'features/common/page-topbar'
import styled from 'styled-components'
import { STYLED_VARIABLES } from '@cloudike/web_ui_components'

import { CircleButtonBack } from "../../common/circle-button-back/CircleButtonBack"

type AlbumPageTopbarProps = ConnectedPageTopbarProps & {
  onBack: () => void
}

export const AlbumsPlacesPageTopbar: React.FC<AlbumPageTopbarProps> = ({
  className = '',
  title,
  onBack
}) => {
  return (
    <AlbumPageTopbarBox className={className}>
      <CircleButtonBack onClick={onBack} />

      <AlbumInfo>
        <AlbumName>
          {title}
        </AlbumName>
      </AlbumInfo>

    </AlbumPageTopbarBox>
  )
}

const AlbumPageTopbarBox = styled.div`
  width: 100%;
  min-height: 96px;
  display: flex;
  padding-top: 20px;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding-left: 16px;
  }
`

const AlbumInfo = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  margin-right: 0;
  padding-left: 16px;
  cursor: pointer;
`

const AlbumName = styled.h3`
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 40px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.placeholder {
    /* Ink / Ink Lighter */
    color: var(--text-disable);
  }
`
