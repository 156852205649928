import { useEffect, useState } from "react"

import lifecycle from 'page-lifecycle'
import _ from "lodash"

export enum PageLifecycleState {
  ACTIVE = 'active',
  PASSIVE = 'passive',
  HIDDEN = 'hidden',
  FROZEN = 'frozen',
  TERMINATED = 'terminated'
}

const STATECHANGE = 'statechange'

export const usePageLifecycle = (): [PageLifecycleState] => {
  const [pageState, setPageState] = useState(lifecycle.state)
  const onStateChangeDeb = _.debounce(onStateChange, 500, { leading: true })

  useEffect(() => {
    lifecycle.addEventListener(STATECHANGE, onStateChangeDeb)
    return () => {
      lifecycle.removeEventListener(STATECHANGE, onStateChangeDeb)
    }
  }, [])

  function onStateChange({ newState }) {
    setPageState(newState)
  }

  return [pageState]
}
