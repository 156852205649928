import { refreshSdkWSConnection } from "../../../sdk/sdkWS"
import { getWSStateSelector } from "../selectors"

import { PageLifecycleState, usePageLifecycle } from "./usePageLifecycle"
import { useExponentialBackoff } from "./useExponentialBackoff"

export const useRefreshWSConnection = (enabled = true): void => {
  if(!enabled) return
  const wsState = getWSStateSelector()
  const [pageLifeCycleState] = usePageLifecycle()
  const isPageStateActive = pageLifeCycleState === PageLifecycleState.ACTIVE
  const isWSConnectionClosed = wsState === WebSocket.CLOSED
  const backOff = useExponentialBackoff()

  if(isPageStateActive && isWSConnectionClosed) {
    backOff(refreshSdkWSConnection)
  }
}
