import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getFamilySdk } from 'sdk/family'
import { IFamilySchema } from '@cloudike/web_photos/dist/types/intarfaces/IFamilySchema'
import { IMember } from '@cloudike/web_photos/dist/types/intarfaces/IMember'
import { RootState } from 'store'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { getErrorData } from 'utils/getErrorData'
import { getPhotosWS } from 'sdk/photo'
import { t } from 'i18next'
import { updateUserDataThunk } from 'features/user/userSlice'
import { NavigateFunction } from 'react-router-dom'
import { getPhotoTimelineSdk } from 'sdk/timeline'

import { getErrorByFieldName } from "../../utils/utils"
import { TOTAL_COUNT_HEADER } from "../../constants/headers"

interface State {
  status: LOADING_STATUSES,
  familyData: IFamilySchema,
  familyMembers: IMember[],
  error?: string,
  isFamilyInviteModalOpened: boolean
}

export const subscribeFamilyToWSThunk = createAsyncThunk(
  'family/subscribeFamilyToWSThunk',
  async function ({ navigate }: { navigate: NavigateFunction }, { dispatch, getState }) {
    const state = getState() as RootState
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_REVOKED, ({ output }) => {
      if (Number(state.user.userData.id) === Number(output.id)) {
        dispatch(updateUserDataThunk({ family_user_id: null }))
        dispatch(actions.resetState())
        navigate('/family/photos')

        return
      }

      dispatch(actions.deleteMemberById(output.id))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_USER_JOINED, ({ output }) => {
      dispatch(actions.addMember(output))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_LEFT, ({ output }) => {
      dispatch(actions.deleteMemberById(output.id))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_CHANGED, ({ output }) => {
      dispatch(actions.updateFamilyData(output))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_DELETED, () => {
      dispatch(updateUserDataThunk({ family_user_id: null }))
      dispatch(actions.resetState())
      navigate('/family/photos')
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_familyDeleted')
      })
    })
  }
)

export const unsubscribeFamilyFromWSThunk = createAsyncThunk(
  'family/subscribeFamilyToWSThunk',
  async function () {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_USER_JOINED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_LEFT)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_CHANGED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_REVOKED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_DELETED)
  }
)

export const fetchFamilyThunk = createAsyncThunk(
  'family/fetchFamilyThunk',
  async function () {
    const sdk = getFamilySdk()

    const familyDataResponse = await sdk.getUserFamilies({ limit: 1 })

    const familyData = familyDataResponse.data._embedded.families[0]

    const familyMembers = await (await sdk.getFamilyMembers(familyData.id, { limit: 1000 })).data._embedded.members

    return {
      familyData,
      familyMembers
    }
  }
)

export const deleteFamilyMemberThunk = createAsyncThunk(
  'family/deleteFamilyMemberThunk',
  async function (member: IMember, { getState, dispatch }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      await sdk.deleteFamilyMember(familyId, member.id)
      dispatch(actions.deleteMember(member))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteFamilyLinkThunk = createAsyncThunk(
  'family/deleteFamilyLinkThunk',
  async function (_, { getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      const response = await sdk.lockUserFamily(familyId)

      hideGlobalProgressLoader()
      return response.data
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createFamilyLinkThunk = createAsyncThunk(
  'family/createFamilyLinkThunk',
  async function (_, { getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      const response = await sdk.unlockUserFamily(familyId)

      hideGlobalProgressLoader()
      return response.data
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteFamilyCloudThunk = createAsyncThunk(
  'family/deleteFamilyCloudThunk',
  async function (callback: () => void, { getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      await sdk.deleteUserFamily(familyId)

      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createFamilyCloudThunk = createAsyncThunk(
  'family/createFamilyCloudThunk',
  async function (callback: (familyUserId: number, userHasTimelineItems: boolean) => void, { dispatch }) {
    try {
      const sdk = getFamilySdk()
      const timelineSdk = getPhotoTimelineSdk()

      showGlobalProgressLoader()

      const familyData = (await sdk.createUserFamily({})).data

      const timelineItemsResponse = await timelineSdk.getTimelineItems({ offset: 0, limit: 20, total_count: true })

      const userHasTimelineItems = Number(timelineItemsResponse.headers[TOTAL_COUNT_HEADER]) > 0

      dispatch(actions.setFamilyData(familyData))

      callback(familyData.shared_user_id, userHasTimelineItems)
    } catch (error) {
      const errorData = getErrorByFieldName(error, 'response')
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(errorData)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const leaveFromFamilyCloudThunk = createAsyncThunk(
  'family/leaveFromFamilyCloudThunk',
  async function ({ userId, callback }: { callback: () => void, userId: string }, { getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      await sdk.deleteFamilyMember(familyId, userId)

      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const joinToFamilyCloudThunk = createAsyncThunk(
  'family/joinToFamilyCloudThunk',
  async function ({ hash, successCallback, errorCallback }: { successCallback: (id: number) => void, errorCallback: (error: any) => void, hash: string }) {
    try {
      showGlobalProgressLoader()
      const sdk = getFamilySdk()

      await sdk.joinFamilyMember(hash, {})
      const familyDataResponse = await sdk.getUserFamilies({ limit: 1 })

      const familyData = familyDataResponse.data._embedded.families[0]

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_inviteAccepted')
      })

      successCallback(familyData.shared_user_id)
    } catch (error) {
      errorCallback(error.cause)
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const familySlice = createSlice({
  name: 'family',
  initialState: {
    status: LOADING_STATUSES.LOADING,
    familyData: {},
    familyMembers: [],
    error: '',
    isFamilyInviteModalOpened: false
  } as State,
  reducers: {
    setFamilyData: (state, action) => {
      state.familyData = action.payload
    },
    updateFamilyData: (state, action) => {
      state.familyData = { ...state.familyData, ...action.payload }
    },
    deleteMember: (state, action) => {
      const member = state.familyMembers.find(member => member.id === action.payload.id)
      const index = state.familyMembers.indexOf(member)

      state.familyMembers = [...state.familyMembers.slice(0, index), ...state.familyMembers.slice(index + 1)]
    },
    deleteMemberById: (state, action) => {
      const member = state.familyMembers.find(member => member.id == action.payload)
      const index = state.familyMembers.indexOf(member)

      if (index === -1) {
        return
      }

      state.familyMembers = [...state.familyMembers.slice(0, index), ...state.familyMembers.slice(index + 1)]
    },
    addMember: (state, action) => {
      state.familyMembers.push(action.payload)
    },
    toggleFamilyinviteModal: (state, action) => {
      state.isFamilyInviteModalOpened = action.payload
    },
    resetState: (state) => {
      state.familyData = {} as IFamilySchema
      state.familyMembers = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFamilyThunk.fulfilled, (state, action) => {
        state.familyData = action.payload.familyData
        state.familyMembers = action.payload.familyMembers
        state.status = LOADING_STATUSES.SUCCEEDED
      })
      .addCase(fetchFamilyThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(deleteFamilyLinkThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.familyData = action.payload
        }
      })
      .addCase(createFamilyLinkThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.familyData = action.payload
        }
      })
  },
})

const {
  reducer, actions
} = familySlice

export { reducer as familyReducer, actions as familyActions }
