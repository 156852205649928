import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { DropzoneOptions } from 'react-dropzone'

interface State {
  dropzoneOptions: DropzoneOptions,
  isDropzoneAreaVisible: boolean,
  localAvailableExtensions: string[] | null
}

let uploadHandler: (acceptedFiles: File[], rejectedFiles: File[]) => void

export const setUploadHandlerThunk = createAsyncThunk(
  'dropzone/setUploadHandlerThunk',
  ({ callback }: any) => {
    uploadHandler = callback
  }
)

export const callUploadHandlerThunk = createAsyncThunk(
  'dropzone/callUploadHandlerThunk',
  ({ acceptedFiles, rejectedFiles }: { acceptedFiles: File[], rejectedFiles: File[] }) => {
    if (!uploadHandler) {
      return
    }

    uploadHandler(acceptedFiles, rejectedFiles)
  }
)

export const removeUploadHandlerThunk = createAsyncThunk(
  'dropzone/removeUploadHandlerThunk',
  () => {
    uploadHandler = null
  }
)

export const dropzoneSlice = createSlice({
  name: 'dropzone',
  initialState: {
    dropzoneOptions: {
      disabled: true,
      noClick: true
    },
    isDropzoneAreaVisible: false
  } as State,
  reducers: {
    updateDropzoneOptions: (state, action) => {
      state.dropzoneOptions = { ...state.dropzoneOptions, ...action.payload }
    },
    toggleDropzoneAreaVisibility: (state, action) => {
      state.isDropzoneAreaVisible = action.payload
    },
    setLocalAvailableExtensions: (state, action) => {
      state.localAvailableExtensions = action.payload
    }
  },
})

const {
  reducer, actions
} = dropzoneSlice

export { reducer as dropzoneReducer, actions as dropzoneActions }
