import { useAppSelector } from "store"

export const getAlbumsSharingAlbumDataSelector = () => useAppSelector(state => state.sharing.sharedItemData)

export const getAlbumsSharingDataSelector = () => useAppSelector(state => state.sharing.sharingData)

export const getAlbumsSharingDataCollaboratorsSelector = () => useAppSelector(state => state.sharing.collaborators)

export const getAlbumsSharingDataCollaboratorsForAddSelector = () => useAppSelector(state => state.sharing.collaboratorsForAdd)

export const getAlbumsSharingConfigSelector = () => useAppSelector(state => state.sharing.config)

export const getAvailableTypesPublicLinkSelector = () => useAppSelector(state => state.sharing.availableTypesPublicLink)

export const getIsBusy = () => useAppSelector(state => state.sharing.isBusy)
