import { useAppSelector } from "store"

import { albumsPlacesSelectors } from "./albumsPlacesSlice"

export const getAlbumsPlacesLoadingStatusSelector = () => useAppSelector(state =>
  state.albumsPlaces.status
)

export const getAlbumsPlacesTotalCountSelector = () => useAppSelector(state =>
  state.albumsPlaces.totalCount
)

export const getAlbumsPlacesItemsSelector = () => useAppSelector(state => albumsPlacesSelectors.selectAll(state.albumsPlaces))
