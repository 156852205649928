import { API_KEY_NAME } from './constants';

const WIDGET_DATA = 'WIDGET_DATA';
const storage = window.localStorage;

function getWidgetData() {
  return JSON.parse(storage.getItem(WIDGET_DATA));
}

function setWidgetData(data: { position: { x: number; y: number } }) {
  storage.setItem(WIDGET_DATA, JSON.stringify(data));
}

function getAPIKey() {
  return storage.getItem(API_KEY_NAME);
}

function setAPIKey(value: string) {
  storage.setItem(API_KEY_NAME, value);
}

function clearAPIKey() {
  storage.removeItem(API_KEY_NAME);
}

export { getAPIKey, setAPIKey, clearAPIKey, getWidgetData, setWidgetData };
