import APP_CONFIG from 'constants/configs/app.config'

import React from 'react'

import { getUserDataSelector } from 'features/user/selectors'
import styled from 'styled-components'
import { convertFromBytes, getSubscriptionPageUrl } from 'utils/utils'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import classNames from 'classnames'

interface StorageWidget {
    className?: string,
    quotaSize: number,
    storageSize: number,
    texts: {
        used: string,
        button?: string
    },
    subscriptionsUrl?: string,
    isSubscriptionMenuHidden: boolean,
    onClick: () => void
    buttonType?: 'default' | 'filled',
}

const StorageWidget: React.FC<StorageWidget> = ({
  className = '',
  quotaSize,
  storageSize,
  texts,
  subscriptionsUrl,
  isSubscriptionMenuHidden,
  buttonType = 'default',
  onClick
}) => {
  const buttonColor = process.env.APP_NAME === 'beelinecloud' ? 'var(--brand-normal)' : 'var(--accent-normal)'

  const openPackagePage = () => {
    window.open(subscriptionsUrl)
  }

  return (
    <StorageWidgetBox className={className}>
      <StorageWidgetLinkBox onClick={onClick}>
        <ProgressLineBox>
          <ProgressLineInner width={storageSize / quotaSize * 100} />
        </ProgressLineBox>

        <UsedText>
          {texts.used}
        </UsedText>
      </StorageWidgetLinkBox>

      {!isSubscriptionMenuHidden && (
        <ButtonBox
          onClick={openPackagePage}
          color={buttonColor}
        >
          <ButtonTextBox
            className={classNames({ filled: buttonType === 'filled' })}
            color={buttonColor}
          >
            {texts.button}
          </ButtonTextBox>

          <ButtonBackground
            color={buttonColor}
            className={classNames({ filled: buttonType === 'filled' })}
          />
        </ButtonBox>
      )}
    </StorageWidgetBox>
  )
}

const ButtonBox = styled.div`
  position: relative;
  font-style: normal;
  user-select: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.color};
  cursor: pointer;
`

const StorageWidgetBox = styled.div`
  width: 218px;
  background: var(--background-primary);
  border: 1px solid var(--divider-primary);
  border-radius: 4px;
  padding: 16px;
`

const ProgressLineBox = styled.div`
  width: 100%;
  height: 4px;
  background: var(--background-tertiary);
  border-radius: 100px;
  overflow: hidden;
`

const ProgressLineInner = styled.div`
  height: 100%;
  width: ${props => props.width}%;
  background: var(--brand-normal);
  border-radius: 100px;
`

const UsedText = styled.p`
  margin-top: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150.04%;
  color: var(--text-secondary);
`

const ButtonBackground = styled.div`
  margin-top: 14px;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.08;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.filled {
    background: var(--button-primary-default);
    border: 1px solid var(--button-primary-default);
    color: var(--brand-text);
    opacity: 1;

    :hover {
      background: var(--button-primary-hover);
    }
  }

  :hover {
    background: ${(props) => props.color};
  }
`

const ButtonTextBox = styled.a`
  position: absolute;
  width: 184px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 4px;

  &.filled {
    border: 0;
    color: var(--brand-text);
  }
`

const StorageWidgetLinkBox = styled.a`
  cursor: pointer;
  user-select: none;
`

const ConnectedStorageWidget = (props) => {
  const { t, i18n } = useTranslation()

  const userData = getUserDataSelector()

  const { storage_size: storageSize, quota_size: quotaSize } = userData

  const percents = storageSize && quotaSize ? Math.round(((storageSize * 100) / quotaSize) * 10) / 10 : 0
  const percentsText = (storageSize > 0 && quotaSize === 0) || storageSize > quotaSize ? 100 : percents

  const usedText = quotaSize < 0 ?
    t('l_common_quotaUnlim', { STORAGE_SIZE: convertFromBytes(storageSize) }) :
    t('l_common_quotaSize', {
      PERCENT: percentsText,
      STORAGE_SIZE: convertFromBytes(storageSize),
      QUOTA_SIZE: convertFromBytes(quotaSize)
    })
  const back_to = window.location.href
  const subscriptionsUrl = getSubscriptionPageUrl({
    url: APP_CONFIG.subscriptions_page_url,
    lang: i18n.language,
    token: _.get(userData, 'token', ''),
    back_to
  })

  const buttonType = userData.p_factor === 0 && APP_CONFIG.p_factor_check ? 'filled' : 'default'

  return (
    <StorageWidget
      storageSize={userData.storage_size}
      quotaSize={userData.quota_size}
      texts={{
        used: usedText,
        button: t('a_nav_getMoreStorage')
      }}
      subscriptionsUrl={subscriptionsUrl}
      isSubscriptionMenuHidden={!!APP_CONFIG.is_subscription_menu_hidden}
      buttonType={buttonType}
      {...props}
    />
  )
}

export { ConnectedStorageWidget as StorageWidget }
