import { t } from 'i18next'
import * as yup from 'yup'

export const validationSchemaForFileItem = () => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('l_common_fillField'))
  })
}
