import { useEffect, useState } from "react"

export const useMobileDetection = (MOBILE_BREAKPOINT = 1023) => {
  const [isMobile, toggleMobileStatus] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  useEffect(() => {
    const listener = () => {
      const currentWidth = window.innerWidth

      if (currentWidth <= MOBILE_BREAKPOINT) {
        toggleMobileStatus(true)
      } else {
        toggleMobileStatus(false)
      }
    }

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [])

  return isMobile
}
