import { useEffect } from "react"

import _ from "lodash"
import { useAppDispatch } from  "store"

import { sendActivityThunk } from "../../../store/app"
import { getUserDataSelector } from "../../user/userSlice"

export const useActivityDetection = (hoursTimeout: number) => {
  const dispatch = useAppDispatch()
  const userData = getUserDataSelector()

  const calcMilliSeconds = (hours: number) => hours * 3_600 * 1_000

  let lastActiveTimestamp = 0

  const checkSendRequest = () => {
    if (new Date().getTime() - lastActiveTimestamp < calcMilliSeconds(hoursTimeout)) {
      dispatch(sendActivityThunk())
    }
  }

  const resetUserActivity = _.debounce(() => {
    lastActiveTimestamp = new Date().getTime()
  }, 1000)

  useEffect(() => {

    const intervalIdRequest = setInterval(checkSendRequest, calcMilliSeconds(hoursTimeout))

    window.addEventListener('touchstart', resetUserActivity)
    window.addEventListener("mousemove", resetUserActivity)
    window.addEventListener("scroll", resetUserActivity)
    window.addEventListener("keydown", resetUserActivity)
    window.addEventListener('click', resetUserActivity)

    return () => {

      clearInterval(intervalIdRequest)

      window.removeEventListener("touchstart", resetUserActivity)
      window.removeEventListener("mousemove", resetUserActivity)
      window.removeEventListener("scroll", resetUserActivity)
      window.removeEventListener("keydown", resetUserActivity)
      window.removeEventListener('click', resetUserActivity)
    }
  }, [])

  useEffect(() => {
    if (!userData.id) {
      return
    }

    dispatch(sendActivityThunk())
  }, [userData.id])

}
