import { STYLED_VARIABLES } from 'constants/styledVariables'
import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useState } from 'react'

import { SecondaryButton } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DialogModal } from 'ui/DialogModal'
import { useAppDispatch } from  "store"
import { useNavigate } from 'react-router-dom'
import { THEME, updateUserDataThunk } from 'features/user/userSlice'

import { getUiTheme } from "../user/selectors"

import { createFamilyCloudThunk } from './familySlice'

interface CreateFamilyPlaceholderProps {
  className?: string,
  texts: {
    title: string,
    description: string,
    createBtn: string
  }
}

export const CreateFamilyPlaceholder: React.FC<CreateFamilyPlaceholderProps> = ({ className = '', texts }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [creatingConfirmationModalOpened, toggleCreatingConfirmationModal] = useState(false)

  const theme = getUiTheme()

  const handleOpenConfirmationModal = () => {
    toggleCreatingConfirmationModal(true)
  }

  const handleCloseConfirmationModal = () => {
    toggleCreatingConfirmationModal(false)
  }

  const handleCreateFamilyCloud = () => {
    const callback = (familyUserId, userHasTimelineItems) => {
      if (userHasTimelineItems) {
        navigate('/family/photos/add')
      }

      dispatch(updateUserDataThunk({ family_user_id: familyUserId }))
    }

    dispatch(createFamilyCloudThunk(callback))
  }

  return (
    <CreateFamilyPlaceholderBox
      className={className}
    >
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/family-create.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/family-create.svg")} />}

      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>

      <StyledSecondaryButton
        actionName={texts.createBtn}
        onAction={handleOpenConfirmationModal}
      >
        {texts.createBtn}
      </StyledSecondaryButton>

      <DialogModal
        isOpened={creatingConfirmationModalOpened}
        title={t('l_settings_familyCloud')}
        onClose={handleCloseConfirmationModal}
        okText={t('a_common_create')}
        onOk={handleCreateFamilyCloud}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseConfirmationModal}
      >
        <TextInModalBox>
          {t('l_notification_creatingFamilyCloudMessage')}
        </TextInModalBox>
      </DialogModal>
    </CreateFamilyPlaceholderBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const CreateFamilyPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 16rem);
`

const ContainerImg = styled.img`
  width: 293px;
  height: 214px;
`

const EmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
  align-items: center;
  text-align: center;
  color: var(--text-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: flex;
    max-width: 520px;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 32px;
  height: 40px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`
