import APP_CONFIG from "constants/configs/app.config"

import { useEffect } from "react"

import { useTranslation } from "react-i18next"

const prefix = APP_CONFIG.app_name

export const usePageTitle = (titleKey) => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${prefix} - ${t(titleKey)}`
  }, [t, titleKey])
}