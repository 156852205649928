import { useEffect } from "react"

import { useAppDispatch } from  "store"

import { fetchUserSettingsThunk, getUserDataSelector } from "../userSlice"

export const useUserSettings = () => {
  const userData = getUserDataSelector()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!userData.id) {
      return
    }

    dispatch(fetchUserSettingsThunk())
  }, [userData.id])
}
