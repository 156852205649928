import { useAppSelector } from "store"
import { NodeTypes } from "@cloudike/web_files"
import { createSelector } from "@reduxjs/toolkit"

import { publicLinkCopyMoveModalSelectors } from "./publicLinkCopyMoveModalSlice"

const getPublicLinkCopyMoveModalAllNodesRawSelector = state => publicLinkCopyMoveModalSelectors.selectAll(state.publicLinkCopyMoveModal)

export const getPublicLinkCopyMoveModalNodesSelector = () => useAppSelector(
  createSelector(
    getPublicLinkCopyMoveModalAllNodesRawSelector,
    items =>
      items.filter(node => node.type === NodeTypes.DIR)
  ))

export const getPublicLinkCopyMoveModalBreadcrumbsSelector = () => useAppSelector(state => state.publicLinkCopyMoveModal.breadcrumbs)

export const getPublicLinkCopyMoveModalStatusSelector = () => useAppSelector(state => state.publicLinkCopyMoveModal.status)
