import iosEn from 'assets/app-links/ios/en.svg'
import iosRu from 'assets/app-links/ios/ru.svg'
import iosPt from 'assets/app-links/ios/pr.svg'
import iosId from 'assets/app-links/ios/in.svg'
import iosEs from 'assets/app-links/ios/sp.svg'
import iosTr from 'assets/app-links/ios/tu.svg'
import macosEn from 'assets/app-links/macos/en.svg'
import macosRu from 'assets/app-links/macos/ru.svg'
import macosPt from 'assets/app-links/macos/pr.svg'
import macosId from 'assets/app-links/macos/in.svg'
import macosEs from 'assets/app-links/macos/sp.svg'
import macosTr from 'assets/app-links/macos/tu.svg'
import winEn from 'assets/app-links/win/en.svg'
import winRu from 'assets/app-links/win/ru.svg'
import winPt from 'assets/app-links/win/pt.svg'
import winId from 'assets/app-links/win/in.svg'
import winEs from 'assets/app-links/win/sp.svg'
import winTr from 'assets/app-links/win/tu.svg'
import androidRustore from 'assets/app-links/android-rustore/ru.svg'
import androidEn from 'assets/app-links/android/en.svg'
import androidRu from 'assets/app-links/android/ru.svg'
import androidPt from 'assets/app-links/android/pt.svg'
import androidId from 'assets/app-links/android/in.svg'
import androidEs from 'assets/app-links/android/sp.svg'
import androidKz from 'assets/app-links/android/kz.svg'
import androidTr from 'assets/app-links/android/tr.svg'
import androidAppGalleryEn from 'assets/app-links/android-app-gallery/en.svg'
import androidAppGalleryRu from 'assets/app-links/android-app-gallery/ru.svg'
import androidAppGalleryId from 'assets/app-links/android-app-gallery/in.svg'
import androidAppGalleryTr from 'assets/app-links/android-app-gallery/tr.svg'

export const CLIENTS = {
  BADGE_FILE_NAMES: {
    ios: {
      en: iosEn,
      ru: iosRu,
      pt: iosPt,
      id: iosId,
      es: iosEs,
      tr: iosTr,
      kk: iosRu,
    },
    macos: {
      en: macosEn,
      ru: macosRu,
      pt: macosPt,
      id: macosId,
      es: macosEs,
      tr: macosTr,
      kk: macosRu,
    },
    win: {
      en: winEn,
      ru: winRu,
      pt: winPt,
      id: winId,
      es: winEs,
      tr: winTr,
      kk: winRu,
    },
    android: {
      en: androidEn,
      ru: androidRu,
      pt: androidPt,
      id: androidId,
      es: androidEs,
      tr: androidTr,
      kk: androidKz,
    },
    'android-rustore': {
      en: androidRustore,
      ru: androidRustore,
      pt: androidRustore,
      id: androidRustore,
      es: androidRustore,
      tr: androidRustore,
      kk: androidRustore,
    },
    'android-app-gallery': {
      en: androidAppGalleryEn,
      ru: androidAppGalleryRu,
      pt: androidAppGalleryEn,
      id: androidAppGalleryId,
      es: androidAppGalleryEn,
      tr: androidAppGalleryTr,
      kk: androidAppGalleryRu,
    },
  },
  LABELS: {
    browser: /* i18nextract */ 'l_common_browser',
    'web-client': /* i18nextract */ 'l_common_browser',
    'win-client': /* i18nextract */ 'l_common_win',
    'macos-client': /* i18nextract */ 'l_common_macos',
    'android-client': /* i18nextract */ 'l_common_android',
    'ios-client': /* i18nextract */ 'l_common_ios',
    'android-rustore-client': /* i18nextract */ 'l_common_android',
    'android-app-gallery-client':/* i18nextract */ 'l_common_android',
  }
}
