export const converFileListToFileObjectList = (files: File[], modificators = []) => {
  return files.map((file, index) => ({
    data: file,
    name: file.name,
    file_name: file.name,
    size: file.size,
    type: file.type,
    ...(modificators[index] || {})
  }
  ))
}